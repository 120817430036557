import React, { Component } from "react";
import SelectSearch from "react-select-search";
import _ from "lodash";
import moment from "moment";
import localization from "moment/locale/fr";

import { getPizzerias } from "../../services/pizzeriaService";
import { getVentes, getTauxTVAVentes } from "../../services/statsService";
import { fullColorHex, roundDecimal } from "../../utils/tools";
import ChartBar from "../chart/chartBar";
import ChartStackedBar from "../chart/chartStackedBar";
import ChartPie from "../chart/chartPie";

moment.locale("fr", localization);
const colorScaleTVARush = ["#4E4B4A", "#f68131", "#ffc000"];
const colorScaleMois = [
  "#6E0B14",
  "#884da7",
  "#7FFFD4",
  "#b9f2ff",
  "#287233",
  "#c5c3c2",
  "#e0115f",
  "#90997a",
  "#909cd8",
  "#142d36",
  "#777f2d",
  "#faea73",
];
class StatCA extends Component {
  state = {
    traitEnCours: false,
    monoSociete: true,
    societesForSelect: [],
    selectedMonoSociete: 0,
    selectedSocietes: [],
    annees: [],
    selectedAnnee: null,
    agregatPeriode: [],
    selectedAgregatPeriode: "week",
    joursSemaine: [],
    selectedJour: null,
    mois: [],
    selectedMois: null,
    rushs: [],
    selectedRush: [],
    tauxTVA: [],
    selectedTaux: null,
    tauxTVALabel: [],
    typeSynthese: "detail",
    detailParSociete: false,
    dataToShow: [],
    dataToShow_1: [],
    dataToShow_2: [],
    tabSemaine: [],
    tabTotal: [],
    tabMois: [],
  };

  async componentDidMount() {
    const dataTauxTVA = await getTauxTVAVentes();

    const tauxTVA = [];
    const tauxTVALabel = [];
    tauxTVA.push({ value: "-1", name: "Toutes" });
    dataTauxTVA.data.data.map((x) => {
      tauxTVA.push({ value: x._id.toString(), name: x.taux.toString() + " %" });
      tauxTVALabel.push(x.taux.toString() + " %");
      return true;
    });

    const societes = await getPizzerias();
    let societesForSelect = [];
    let selectedSocietes = [];
    societes.data.data.map((item) => {
      const ok = item.listAPE.filter((pizz) => {
        return pizz.codeAPE === "5610C";
      });
      if (ok.length !== 0) {
        const tabCouleur = item.couleur.split(",");
        societesForSelect.push({
          value: item._id.toString(),
          name: item.denomination,
          couleur: fullColorHex(
            parseInt(tabCouleur[0]),
            parseInt(tabCouleur[1]),
            parseInt(tabCouleur[2])
          ),
        });
      }
      return true;
    });
    selectedSocietes.push(societesForSelect[0].value);
    const annees = [];
    //annees.push({ value: "-1", name: "Toutes les années" });
    const annee = new Date().getFullYear();
    for (let index = annee; index >= 2016; index--) {
      annees.push({ value: index.toString(), name: index.toString() });
    }

    const agregatPeriode = [];
    agregatPeriode.push({ value: "day", name: "Jour" });
    agregatPeriode.push({ value: "week", name: "Semaine" });
    agregatPeriode.push({ value: "month", name: "Mois" });
    agregatPeriode.push({ value: "year", name: "Année" });

    const joursSemaine = [];
    // Value SQL
    joursSemaine.push({ value: "-1", name: "Tous" });
    joursSemaine.push({ value: "2", name: "Lundi" });
    joursSemaine.push({ value: "3", name: "Mardi" });
    joursSemaine.push({ value: "4", name: "Mercredi" });
    joursSemaine.push({ value: "5", name: "Jeudi" });
    joursSemaine.push({ value: "6", name: "Vendredi" });
    joursSemaine.push({ value: "7", name: "Samedi" });
    joursSemaine.push({ value: "1", name: "Dimanche" });

    const mois = [];
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    mois.push({ value: "-1", name: "Tous" });
    for (let index = 0; index <= 11; index++) {
      mois.push({ value: (index + 1).toString(), name: months[index] });
    }
    const rushs = [];
    rushs.push({ value: "-1", name: "Tous", shortName: "" });
    rushs.push({ value: "1", name: "Midi", shortName: "M" });
    rushs.push({ value: "2", name: "Soir", shortName: "S" });

    this.setState({
      societesForSelect,
      selectedSocietes,
      selectedMonoSociete: societesForSelect[0].value,
      annees,
      selectedAnnee: annees[0].value,
      agregatPeriode,
      selectedAgregatPeriode: agregatPeriode[1].value,
      joursSemaine,
      selectedJour: joursSemaine[0].value,
      mois,
      selectedMois: mois[0].value,
      rushs,
      selectedRush: rushs[0].value,
      tauxTVA,
      selectedTaux: tauxTVA[0].value,
      tauxTVALabel,
      traitEnCours: false,
    });
    await this.traitValiderClick(
      societesForSelect[0].value,
      selectedSocietes,
      annees[0].value,
      agregatPeriode[1].value,
      joursSemaine.find((x) => x.value === joursSemaine[0].value).value,
      mois[0].value,
      rushs[0].value,
      tauxTVA[0].value,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  }

  handleSelectAllClick = async (event) => {
    event.preventDefault();
    const societesForSelect = this.state.societesForSelect;
    let selectedSocietes = [];
    societesForSelect.map((item) => {
      selectedSocietes.push(item.value);
      return true;
    });
    this.setState({
      selectedSocietes,
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };

  handleUnSelectAllClick = async (event) => {
    event.preventDefault();
    this.setState({
      selectedSocietes: [],
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      [],
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };

  handleMonoSocieteSelect = async (societe) => {
    this.setState({ selectedMonoSociete: societe });

    await this.traitValiderClick(
      societe,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };

  handleSocieteSelect = async (societes) => {
    this.setState({
      selectedSocietes: societes,
    });

    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      societes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };

  handleRushSelect = async (rush) => {
    this.setState({
      selectedRush: rush,
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      rush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };
  handleAnneeSelect = async (annee) => {
    this.setState({
      selectedAnnee: annee,
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      annee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };

  handleTVASelect = async (tva) => {
    this.setState({
      selectedTaux: tva,
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      tva,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };

  handleAgregatPeriodeSelect = async (agregatPeriode) => {
    this.setState({
      selectedAgregatPeriode: agregatPeriode,
      typeSynthese: "detail",
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      agregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      "detail",
      this.state.monoSociete
    );
  };
  handleJourSelect = async (jour) => {
    this.setState({
      selectedJour: jour,
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === jour).value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };
  handleMoisSelect = async (mois) => {
    this.setState({
      selectedMois: mois,
    });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      this.state.monoSociete
    );
  };

  handleTypeSyntheseChange = async (event) => {
    this.setState({ typeSynthese: event.target.value });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      event.target.value,
      this.state.monoSociete
    );
  };

  handleTypeSyntheseClick = async (val) => {
    this.setState({ typeSynthese: val });
    await this.traitValiderClick(
      this.state.selectedMonoSociete,
      this.state.selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      val,
      this.state.monoSociete
    );
  };

  handleMonoSocieteChange = async () => {
    let {
      monoSociete,
      societesForSelect,
      selectedSocietes,
      selectedMonoSociete,
    } = this.state;

    if (monoSociete) {
      selectedSocietes = [];
    } else {
      selectedMonoSociete = societesForSelect[0].value;
    }
    this.setState({
      monoSociete: !monoSociete,
      selectedSocietes,
      selectedMonoSociete,
    });
    await this.traitValiderClick(
      selectedMonoSociete,
      selectedSocietes,
      this.state.selectedAnnee,
      this.state.selectedAgregatPeriode,
      this.state.joursSemaine.find((x) => x.value === this.state.selectedJour)
        .value,
      this.state.selectedMois,
      this.state.selectedRush,
      this.state.selectedTaux,
      this.state.typeSynthese,
      !monoSociete
    );
  };

  traitValiderClick = async (
    selectedMonoSociete,
    selectedSocietes,
    selectedAnnee,
    selectedAgregatPeriode,
    selectedJour,
    selectedMois,
    selectedRush,
    selectedTaux,
    typeSynthese,
    monoSociete
  ) => {
    const {
      traitEnCours,
      tauxTVA,
      tauxTVALabel,
      rushs,
      mois,
      joursSemaine,
    } = this.state;
    if (!traitEnCours) {
      this.setState({ traitEnCours: true });
      const param = {};
      param.annee = selectedAnnee;
      param.groupBy = selectedAgregatPeriode;
      param.jour = parseInt(selectedJour);
      param.mois = parseInt(selectedMois);
      param.rush = parseInt(selectedRush);
      param.tva = parseInt(selectedTaux);
      param.withN_1 =
        selectedAnnee !== "-1" &&
        param.groupBy !== "day" &&
        typeSynthese === "detail";
      param.withN_2 =
        selectedAnnee !== "-1" &&
        param.groupBy !== "day" &&
        typeSynthese === "detail";
      param.syntheseMois = typeSynthese === "mois";
      param.syntheseTVA = typeSynthese === "tva";
      param.syntheseRush = typeSynthese === "rush";
      param.syntheseJour = typeSynthese === "jour";
      param.avecSynthese = typeSynthese !== "detail";
      param.societeIdTab = [];
      if (monoSociete) {
        param.societeIdTab.push(parseInt(selectedMonoSociete));
      } else {
        if (selectedSocietes.length !== 0) {
          selectedSocietes.map((x) => {
            param.societeIdTab.push(parseInt(x));
            return true;
          });
        }
      }
      param.tabSynthese = [];
      if (typeSynthese === "tva") {
        param.tabSynthese = tauxTVA.filter((x) => x.value !== "-1");
      }
      if (typeSynthese === "rush") {
        param.tabSynthese = rushs.filter((x) => x.value !== "-1");
      }
      if (typeSynthese === "jour") {
        param.tabSynthese = joursSemaine.filter((x) => x.value !== "-1");
      }
      if (typeSynthese === "mois") {
        param.tabSynthese = mois.filter((x) => x.value !== "-1");
      }

      param.detailParSociete = false;

      const ret = await getVentes(param);
      // console.log("ret.data.data.dataN", ret.data.data.dataN);
      // console.log("ret.data.data.dataN_1", ret.data.data.dataN_1);
      // console.log("ret.data.data.dataN_2", ret.data.data.dataN_2);
      let dataToShow = [];
      let tabTotal = [];
      let tabMois = [];
      dataToShow = this.genereDataToShow({
        dataIn: ret.data.data.dataN,
        param: param,
        tauxTVALabel: tauxTVALabel,
        rushsLabel: rushs,
        moisLabel: mois,
        joursLabel: joursSemaine,
      });

      let dataToShow_1 = this.genereDataToShow({
        dataIn: ret.data.data.dataN_1,
        param: param,
        tauxTVALabel: tauxTVALabel,
        rushsLabel: rushs,
        moisLabel: mois,
        joursLabel: joursSemaine,
      });
      //console.log("dataToShow_1", dataToShow_1);

      let dataToShow_2 = this.genereDataToShow({
        dataIn: ret.data.data.dataN_2,
        param: param,
        tauxTVALabel: tauxTVALabel,
        rushsLabel: rushs,
        moisLabel: mois,
        joursLabel: joursSemaine,
      });
      //console.log("dataToShow_2", dataToShow_2);

      if (param.groupBy === "day") {
        tabMois = this.genereTabMois({ dataToShow: dataToShow });
      }

      if (param.groupBy === "year") {
        dataToShow = dataToShow.concat(dataToShow_1);
        dataToShow = dataToShow.concat(dataToShow_2);
        dataToShow = dataToShow.sort((a, b) => parseInt(a.x) - parseInt(b.x));
        dataToShow_1 = [];
        dataToShow_2 = [];
      }

      if (param.avecSynthese) {
        tabTotal = this.genereTabTotal({ dataToShow });

        dataToShow.map((x) => {
          const leTotal = tabTotal.find((t) => t.x === x.x);
          //console.log("leTotal", x.sousId, leTotal);
          if (leTotal && param.groupBy !== "year") {
            x.label =
              x.label +
              " soit " +
              leTotal.pourcentLigne[parseInt(x.sousId) - 1].toString() +
              " % de " +
              leTotal.y;
          }
          if (leTotal && param.groupBy === "year") {
            x.synthese =
              x.label +
              " soit " +
              leTotal.pourcentLigne[parseInt(x.sousId) - 1].toString() +
              " % de " +
              leTotal.y;
          }
          return true;
        });
      }
      this.setState({
        traitEnCours: false,
        dataToShow: dataToShow,
        dataToShow_1: dataToShow_1,
        dataToShow_2: dataToShow_2,
        tabSemaine: ret.data.data.tabSemaine,
        tabTotal: tabTotal,
        tabMois: tabMois,
        isN: false,
      });
    }
  };
  genereDataToShow = ({
    dataIn,
    param,
    tauxTVALabel,
    rushsLabel,
    moisLabel,
    joursLabel,
  }) => {
    const dataToShow = [];
    dataIn.map((x) => {
      let axeX = "";
      let labelAxeX = "";

      switch (param.groupBy) {
        case "year":
          if (param.annee === "-1") {
            axeX = x.uniteTemps;
          } else {
            axeX = x.uniteTemps;
          }
          labelAxeX = Math.round(x.montant);

          break;
        case "month":
          if (param.annee === "-1") {
            axeX = x.uniteTemps;
          } else {
            axeX = x.uniteTemps.substring(4, 6);
          }
          labelAxeX = Math.round(x.montant);

          break;
        case "day":
          if (param.annee === "-1") {
            axeX = x.uniteTemps;
          } else {
            axeX =
              x.uniteTemps.substring(6, 8) + "/" + x.uniteTemps.substring(4, 6);
          }
          const date = moment(x.uniteTemps, "YYYYMMDD");
          labelAxeX =
            " " +
            date.format("dddd") +
            " " +
            axeX +
            " : " +
            Math.round(x.montant);
          break;
        default:
          // semaine
          labelAxeX = Math.round(x.montant);
          axeX = x.uniteTemps;

          break;
      }

      if (param.syntheseTVA) {
        labelAxeX =
          param.groupBy !== "year"
            ? " " + tauxTVALabel[x.sousId - 1] + " : " + labelAxeX
            : "TVA " + tauxTVALabel[x.sousId - 1] + " : " + labelAxeX;
      }
      if (param.syntheseRush) {
        labelAxeX =
          param.groupBy !== "year"
            ? " " + rushsLabel[parseInt(x.sousId)].shortName + " : " + labelAxeX
            : rushsLabel[parseInt(x.sousId)].name + " : " + labelAxeX;
      }
      if (param.syntheseMois) {
        labelAxeX =
          param.groupBy !== "year"
            ? " " + moisLabel[parseInt(x.sousId)].name + " : " + labelAxeX
            : moisLabel[parseInt(x.sousId)].name + " : " + labelAxeX;
      }
      if (param.syntheseJour) {
        labelAxeX =
          param.groupBy !== "year"
            ? " " + joursLabel[parseInt(x.sousId)].name + " : " + labelAxeX
            : joursLabel[parseInt(x.sousId)].name + " : " + labelAxeX;
      }
      dataToShow.push({
        x: axeX,
        y: x.montant !== 0 ? Math.round(Number(x.montant)) : null,
        label: labelAxeX,
        sousId: x.sousId,
        montantLigne: Math.round(Number(x.montant)),
      });
      return true;
    });

    return dataToShow;
  };

  genereTabTotal = ({ dataToShow }) => {
    const tabTotal = [];
    const groupedX = _.groupBy(dataToShow, ({ x }) => x);
    Object.entries(groupedX).map(([key, dataX]) => {
      let total = 0;
      const label = [];
      let x = "";
      const montantLigne = [];

      dataX.map((y, index) => {
        //console.log("y", y);
        x = y.x;
        total = roundDecimal(total + y.y);
        label.push(y.label);
        montantLigne.push(y.montantLigne);
        return true;
      });
      tabTotal.push({
        x: x,
        y: total,
        label: label,
        montantLigne: montantLigne,
      });
      return true;
    });
    tabTotal.map((x) => {
      x.pourcentLigne = [];
      x.montantLigne.map((l, index) => {
        const pc = roundDecimal((x.montantLigne[index] / x.y) * 100);
        x.label[index] = x.label[index] + ", " + pc.toString() + " %";
        x.pourcentLigne.push(pc);
        return true;
      });

      x.label.push("Total : " + x.y.toString());
      return true;
    });
    return tabTotal;
  };

  genereTabMois = ({ dataToShow }) => {
    const tabMois = [];
    let nbJourProg = 0;
    for (let i = 1; i < 13; i++) {
      const filtered = dataToShow.filter((x) => {
        return (
          parseInt(x.x.substring(3)) === i && (!x.sousId || x.sousId === 1)
        );
      });

      let sum = 0.0;
      if (filtered.length !== 0) {
        filtered.map((x) => {
          sum = sum + Number(x.y !== null && x.y !== 0 ? x.y : 0);
          return true;
        });
      }
      tabMois.push({
        x: i.toString().padStart(2, "0"),
        y: sum,
        jourDebut: nbJourProg,
        jourFin: nbJourProg + filtered.length,
      });
      nbJourProg = nbJourProg + filtered.length;
    }
    return tabMois;
  };

  render() {
    const {
      monoSociete,
      societesForSelect,
      selectedSocietes,
      selectedMonoSociete,
      annees,
      selectedAnnee,
      agregatPeriode,
      selectedAgregatPeriode,
      joursSemaine,
      selectedJour,
      mois,
      selectedMois,
      rushs,
      selectedRush,
      tauxTVA,
      selectedTaux,
      typeSynthese,
      dataToShow,
      dataToShow_1,
      dataToShow_2,
      tabSemaine,
      tabTotal,
      tabMois,
      tauxTVALabel,
      traitEnCours,
    } = this.state;

    let fillColor = "red";
    let fileExport = "multi";
    if (monoSociete && societesForSelect.length !== 0) {
      fillColor = monoSociete
        ? societesForSelect
            .find((x) => x.value === selectedMonoSociete)
            .couleur.toString()
        : societesForSelect
            .find((x) => x.value === selectedSocietes[0])
            .couleur.toString();
      fileExport = monoSociete
        ? societesForSelect.find((x) => x.value === selectedMonoSociete).name
        : societesForSelect.find((x) => x.value === selectedSocietes[0]).name;
    }
    fileExport = fileExport + "_";
    if (agregatPeriode.find((x) => x.value === selectedAgregatPeriode)) {
      fileExport =
        fileExport +
        agregatPeriode.find((x) => x.value === selectedAgregatPeriode).name +
        "_";
    }

    if (rushs.find((x) => x.value === selectedRush)) {
      fileExport =
        fileExport + rushs.find((x) => x.value === selectedRush).name + "_";
    }
    fileExport = fileExport + selectedAnnee + "_";
    if (tauxTVA.find((x) => x.value === selectedTaux)) {
      fileExport =
        fileExport + tauxTVA.find((x) => x.value === selectedTaux).name + "_";
    }
    if (joursSemaine.find((x) => x.value === selectedJour)) {
      fileExport =
        fileExport +
        joursSemaine.find((x) => x.value === selectedJour).name +
        "_";
    }
    if (mois.find((x) => x.value === selectedMois)) {
      fileExport =
        fileExport + mois.find((x) => x.value === selectedMois).name + "_";
    }

    return (
      <React.Fragment>
        <h2 className="mb-4">Statistiques sur le CA {monoSociete}</h2>
        <div className="row">
          <div className="col-2">
            <div style={{ fontSize: "14px" }}>
              <div className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="monoSociete"
                  checked={monoSociete}
                  onChange={this.handleMonoSocieteChange}
                ></input>
                <label className="form-check-label" htmlFor="monoSociete">
                  Mono société
                </label>
              </div>
            </div>

            {monoSociete && (
              <div className="mb-2" style={{ fontSize: "14px" }}>
                <div>
                  <label className="font-weight-bold" htmlFor="societes">
                    Société
                  </label>
                </div>
                <div className="maxHauteur300">
                  <SelectSearch
                    options={societesForSelect}
                    value={selectedMonoSociete}
                    name="societes"
                    search
                    onChange={this.handleMonoSocieteSelect}
                  />
                </div>
              </div>
            )}
            {!monoSociete && (
              <div style={{ marginBottom: "340px" }}>
                <div>
                  <label className="font-weight-bold" htmlFor="societes">
                    Société(s)
                  </label>
                </div>

                <div className="btn-group mb-2">
                  <button
                    className="btn btn-outline-secondary"
                    style={{ fontSize: "12px" }}
                    onClick={this.handleSelectAllClick}
                  >
                    Tout sélectionner
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    style={{ fontSize: "12px" }}
                    onClick={this.handleUnSelectAllClick}
                  >
                    Tout désélectionner
                  </button>
                </div>

                <div className="maxHauteur300" style={{ fontSize: "14px" }}>
                  <SelectSearch
                    options={societesForSelect}
                    value={selectedSocietes}
                    name="societes"
                    multiple
                    search
                    onChange={this.handleSocieteSelect}
                  />
                </div>
              </div>
            )}
            <div className="mb-2 maxhauteur100" style={{ fontSize: "14px" }}>
              <label className="font-weight-bold" htmlFor="agregatPeriode">
                Regr.
              </label>
              <div style={{ marginTop: "-30px", marginLeft: "50px" }}>
                <SelectSearch
                  options={agregatPeriode}
                  value={selectedAgregatPeriode}
                  name="agregatPeriode"
                  onChange={this.handleAgregatPeriodeSelect}
                />
              </div>
            </div>
            <div className="mb-2 maxhauteur100" style={{ fontSize: "14px" }}>
              <label className="font-weight-bold" htmlFor="rush">
                Rush
              </label>
              <div style={{ marginTop: "-30px", marginLeft: "50px" }}>
                <SelectSearch
                  options={rushs}
                  value={selectedRush}
                  name="rush"
                  onChange={this.handleRushSelect}
                />
              </div>
            </div>
            <div className="mb-2 maxhauteur100" style={{ fontSize: "14px" }}>
              <label className="font-weight-bold" htmlFor="annee">
                Année
              </label>
              <div style={{ marginTop: "-30px", marginLeft: "50px" }}>
                <SelectSearch
                  options={annees}
                  value={selectedAnnee}
                  name="annee"
                  onChange={this.handleAnneeSelect}
                />
              </div>
            </div>
            <div className="mb-2 maxhauteur100" style={{ fontSize: "14px" }}>
              <label className="font-weight-bold" htmlFor="tva">
                TVA
              </label>
              <div style={{ marginTop: "-30px", marginLeft: "50px" }}>
                <SelectSearch
                  options={tauxTVA}
                  value={selectedTaux}
                  name="annee"
                  onChange={this.handleTVASelect}
                />
              </div>
            </div>
            <div className="mb-2 maxhauteur100" style={{ fontSize: "14px" }}>
              <label className="font-weight-bold" htmlFor="jour">
                Jour
              </label>
              <div style={{ marginTop: "-30px", marginLeft: "50px" }}>
                <SelectSearch
                  options={joursSemaine}
                  value={selectedJour}
                  name="jour"
                  onChange={this.handleJourSelect}
                />
              </div>
            </div>
            <div className="mb-2 maxhauteur100" style={{ fontSize: "14px" }}>
              <label className="font-weight-bold" htmlFor="mois">
                Mois
              </label>
              <div style={{ marginTop: "-30px", marginLeft: "50px" }}>
                <SelectSearch
                  options={mois}
                  value={selectedMois}
                  name="mois"
                  onChange={this.handleMoisSelect}
                />
              </div>
            </div>
          </div>
          <div className="col-10">
            {traitEnCours && (
              <div className="mt-5 mb-5">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {!traitEnCours && dataToShow && dataToShow.length !== 0 && (
              <React.Fragment>
                <h5 style={{ marginBottom: "20px" }}>
                  Résultat selon les filtres
                </h5>
                <div className="row" style={{ fontSize: "14px" }}>
                  <div className="col-12 mb-2">
                    <React.Fragment>
                      <label className="font-weight-bold">
                        Type de synthèse
                      </label>
                      <div className="radio-item ml-2">
                        <input
                          type="radio"
                          name="type"
                          value="detail"
                          checked={typeSynthese === "detail"}
                          onChange={(e) => this.handleTypeSyntheseChange(e)}
                        />
                        <label
                          onClick={() => this.handleTypeSyntheseClick("detail")}
                        >
                          En synthèse
                        </label>
                      </div>
                      <div className="radio-item ml-2">
                        <input
                          type="radio"
                          name="type"
                          value="tva"
                          checked={typeSynthese === "tva"}
                          onChange={(e) => this.handleTypeSyntheseChange(e)}
                        />
                        <label
                          onClick={() => this.handleTypeSyntheseClick("tva")}
                        >
                          Détail par taux de TVA
                        </label>
                      </div>
                      <div className="radio-item ml-2">
                        <input
                          type="radio"
                          name="type"
                          value="tva"
                          checked={typeSynthese === "rush"}
                          onChange={(e) => this.handleTypeSyntheseChange(e)}
                        />
                        <label
                          onClick={() => this.handleTypeSyntheseClick("rush")}
                        >
                          Détail par rush
                        </label>
                      </div>
                      {selectedAgregatPeriode !== "day" && (
                        <div className="radio-item ml-2">
                          <input
                            type="radio"
                            name="type"
                            value="jour"
                            checked={typeSynthese === "jour"}
                            onChange={(e) => this.handleTypeSyntheseChange(e)}
                          />
                          <label
                            onClick={() => this.handleTypeSyntheseClick("jour")}
                          >
                            Détail par jour
                          </label>
                        </div>
                      )}
                      {selectedAgregatPeriode === "year" && (
                        <div className="radio-item ml-2">
                          <input
                            type="radio"
                            name="type"
                            value="tva"
                            checked={typeSynthese === "mois"}
                            onChange={(e) => this.handleTypeSyntheseChange(e)}
                          />
                          <label
                            onClick={() => this.handleTypeSyntheseClick("mois")}
                          >
                            Détail par mois
                          </label>
                        </div>
                      )}
                    </React.Fragment>
                  </div>
                </div>
                <div className="row">
                  <React.Fragment>
                    {typeSynthese === "detail" && (
                      <div className="col-12">
                        <ChartBar
                          anneeN={selectedAnnee}
                          dataToShow={dataToShow}
                          dataToShow_1={dataToShow_1}
                          dataToShow_2={dataToShow_2}
                          tickCount={dataToShow.length}
                          agregatPeriode={selectedAgregatPeriode}
                          fillColor={fillColor}
                          tabMois={tabMois}
                          fileExport={fileExport}
                        ></ChartBar>
                      </div>
                    )}
                    {typeSynthese !== "detail" &&
                      selectedAgregatPeriode !== "year" && (
                        <div className="col-12">
                          <ChartStackedBar
                            dataToShow={dataToShow}
                            agregatPeriode={selectedAgregatPeriode}
                            typeSynthese={typeSynthese}
                            tabTotal={tabTotal}
                            tabMois={tabMois}
                            tabLabel={
                              typeSynthese === "tva"
                                ? tauxTVALabel
                                : typeSynthese === "rush"
                                ? rushs
                                : joursSemaine
                            }
                            fillColor={fillColor}
                            colorScaleMois={colorScaleMois}
                            colorScaleTVARush={colorScaleTVARush}
                            fileExport={fileExport}
                            anneeN={selectedAnnee}
                          ></ChartStackedBar>
                        </div>
                      )}
                    {typeSynthese !== "detail" &&
                      selectedAgregatPeriode === "year" && (
                        <React.Fragment>
                          <div className="col-6">
                            <ChartPie
                              dataToShow={dataToShow}
                              typeSynthese={typeSynthese}
                              tabLabel={
                                typeSynthese === "tva"
                                  ? tauxTVALabel
                                  : typeSynthese === "rush"
                                  ? rushs
                                  : typeSynthese === "jour"
                                  ? joursSemaine
                                  : mois
                              }
                              colorScaleMois={colorScaleMois}
                              colorScaleTVARush={colorScaleTVARush}
                              fileExport={fileExport}
                            ></ChartPie>
                          </div>
                          <div className="col-6">
                            <ul>
                              {dataToShow.map((x, index) => (
                                <li key={index}>{x.synthese}</li>
                              ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      )}
                    {tabSemaine.length !== 0 && (
                      <div className="col-12 mt-5">
                        <div
                          style={{
                            marginLeft: "15px",
                            maxWidth: "100%",
                            overflowX: "scroll",
                          }}
                        >
                          <table
                            className="table table-bordered"
                            style={{ fontSize: "12px" }}
                          >
                            <tbody>
                              <tr className="mr-0 ml-0">
                                <td>N</td>
                                {tabSemaine.map((s, index) => (
                                  <td key={"1_" + index}>{s.numero}</td>
                                ))}
                              </tr>
                              <tr className="mr-0 ml-0">
                                <td></td>
                                {tabSemaine.map((s, index) => (
                                  <td key={"2_" + index}>
                                    <span className="text-nowrap">
                                      {s.jourDebutN} au {s.jourFinN}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                              <tr className="mr-0 ml-0">
                                <td className="text-nowrap">N-1</td>
                                {tabSemaine.map((s, index) => (
                                  <td key={"3_" + index}>
                                    <span className="text-nowrap">
                                      {s.numeroN_1}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                              <tr className="mr-0 ml-0">
                                <td></td>
                                {tabSemaine.map((s, index) => (
                                  <td key={"4_" + index}>
                                    <span className="text-nowrap">
                                      {s.jourDebutN_1} au {s.jourFinN_1}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                              <tr className="mr-0 ml-0">
                                <td className="text-nowrap">N-2</td>
                                {tabSemaine.map((s, index) => (
                                  <td key={"5_" + index}>
                                    <span className="text-nowrap">
                                      {s.numeroN_2}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                              <tr className="mr-0 ml-0">
                                <td></td>
                                {tabSemaine.map((s, index) => (
                                  <td key={"6_" + index}>
                                    <span className="text-nowrap">
                                      {s.jourDebutN_2} au {s.jourFinN_2}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </React.Fragment>

                  {/* {!monoSociete && selectedSocietes.length > 1 && (
                    <div className="col-6">
                      <VictoryChart
                        responsive={true}
                        animate={{
                          duration: 500,
                          onLoad: { duration: 200 },
                        }}
                        theme={VictoryTheme.material}
                        domainPadding={20}
                        padding={50}
                      >
                        <VictoryAxis
                          fixLabelOverlap
                          style={{
                            tickLabels: { padding: 10, fontSize: 8 },
                          }}
                        />
                        <VictoryAxis
                          dependentAxis
                          style={{
                            tickLabels: { padding: 8, fontSize: 6 },
                          }}
                        />
                        {selectedSocietes.map((s) => (
                          <VictoryLine
                            key={s}
                            labelComponent={<VictoryTooltip />}
                            style={{
                              data: {
                                stroke: societesForSelect
                                  .find((x) => x.value === s)
                                  .couleur.toString(),
                              },
                              //parent: { border: "1px solid #ccc" },
                            }}
                            data={dataToShow.filter(
                              (f) => f.societeId.toString() === s
                            )}
                            labels={({ data, index }) => data[index].y}
                          />
                        ))}
                      </VictoryChart>
                    </div>
                  )} */}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StatCA;
