import React, { Component } from "react";
import SelectSearch from "react-select-search";

import Table from "../common/table";

class VilleLesSocietes extends Component {
  state = {
    newSociete: false,
    allPizzeriasForSelect: [],
    tabSocieteId: [],
    societe: "",
  };

  columns = [
    {
      path: "denomination",
      label: "Societe",
      tdClassName: "p-2 w-50",
      className: "",
      content: (societe) => (
        <span style={{ fontSize: "12px" }}>{societe.denomination}</span>
      ),
    },
    {
      path: "isDefaut",
      label: "Defaut",
      tdClassName: "p-2 w-50",
      className: "",
      content: (societe) => (
        <div className="form-group">
          <div className="form-check">
            <input
              name="isDefaut"
              id={societe._id}
              type="checkbox"
              checked={societe.isDefaut === 1}
              onChange={this.props.handleIsDefautChange}
              className="form-check-input"
            />
            <label
              htmlFor="isDefaut"
              className="form-check-label"
              style={{ fontSize: "12px" }}
            >
              Par défaut
            </label>
          </div>
        </div>
      ),
    },
    {
      key: "suppr",
      tdClassName: "text-center m-0",
      className: "",
      content: (societe) => (
        <button
          className="btn btn-outline-secondary p-0"
          onClick={() => this.handleSupprSocieteVilleOnClick(societe)}
        >
          <i
            className="far fa-trash-alt p-1"
            style={{ fontSize: "24px" }}
            aria-hidden="true"
          ></i>
        </button>
      ),
    },
  ];

  async componentDidMount() {
    const { tabSocieteId, allPizzeriasForSelect } = this.props;

    this.setState({
      allPizzeriasForSelect,
      tabSocieteId,
    });
  }

  handleNewSociete = () => {
    const { newSociete } = this.state;
    this.setState({ newSociete: !newSociete });
  };

  handleCancelNewSociete = () => {
    const { newSociete } = this.state;
    this.setState({ newSociete: !newSociete });
  };

  handleSocieteChange = (event) => {
    this.setState({ societe: event });
  };

  handleSubmitNewSociete = async () => {
    const { newSociete, societe } = this.state;
    let { tabSocieteId } = this.state;
    await this.props.handleAddSocieteVille({
      societeId: parseInt(societe),
      idInsee: this.props.idInsee,
    });
    tabSocieteId.push(parseInt(societe));
    this.setState({
      newSociete: !newSociete,
      societe: "",
      tabSocieteId: JSON.parse(JSON.stringify(tabSocieteId)),
    });
  };
  handleSupprSocieteVilleOnClick = async (societe) => {
    let { tabSocieteId } = this.state;
    await this.props.handleSupprSocieteVilleOnClick(societe);
    tabSocieteId = tabSocieteId.filter((s) => s !== societe.societeId);
    this.setState({ tabSocieteId });
  };

  render() {
    const { societes } = this.props;
    const { newSociete, societe, tabSocieteId } = this.state;
    let { allPizzeriasForSelect } = this.state;
    allPizzeriasForSelect = allPizzeriasForSelect.filter(
      (s) => !tabSocieteId.includes(parseInt(s.value))
    );

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-3">
            <button
              onClick={this.handleNewSociete}
              className="btn btn-primary text-white ml-2"
              style={{ marginBottom: 20, height: 40 }}
              disabled={newSociete}
            >
              +
            </button>

            <div>
              {newSociete && (
                <React.Fragment>
                  <div className="ml-4 mb-4">
                    <div className="form-group">
                      <label htmlFor="societe">Société</label>
                      <SelectSearch
                        options={allPizzeriasForSelect}
                        value={societe}
                        name="societe"
                        search={true}
                        onChange={this.handleSocieteChange}
                      />
                    </div>

                    <button
                      className="btn btn-primary mr-2"
                      disabled={societe === ""}
                      onClick={this.handleSubmitNewSociete}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewSociete}
                    >
                      Annuler
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="col-9">
            <Table noHeader={true} columns={this.columns} data={societes} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default VilleLesSocietes;
