import http from "./httpService";
import { urlApiGeneral } from "../config.js";

export function getAllContactList() {
  return http.get(urlApiGeneral + "/contacts/allcontactslist");
}

export function addContact({ dataContact, userId }) {
  return http.post(urlApiGeneral + "/contacts/addcontact", {
    dataContact,
    userId,
  });
}

export function getContactsLikeNom({ nom }) {
  return http.post(urlApiGeneral + "/contacts/getcontactslikenom", {
    nom,
  });
}

export function getContactsByNom({ nom }) {
  return http.post(urlApiGeneral + "/contacts/getcontactsbynom", {
    nom,
  });
}

export function updateContact(contactId, { dataContact, userId }) {
  return http.put(urlApiGeneral + "/contacts/updatecontact/" + contactId, {
    dataContact,
    userId,
  });
}

export function getAllFournisseursPizztList() {
  return http.get(urlApiGeneral + "/contacts/allfournisseurspizzlist");
}
