import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export function getProduitsFournisseurs() {
  return http.get(
    urlApiComptabilite + "/produitsfournisseurs/getProduitsFournisseurs"
  );
}

export function getProduitFournisseurs(id) {
  return http.get(
    urlApiComptabilite + "/produitsfournisseurs/getProduitFournisseurs/" + id
  );
}

export function getAllGroupeProduit() {
  return http.get(
    urlApiComptabilite + "/produitsfournisseurs/getAllGroupeProduit"
  );
}

export function getAllCaisseCategorieProduit() {
  return http.get(
    urlApiComptabilite + "/produitsfournisseurs/getAllCaisseCategorieProduit"
  );
}

export function addProduitFournisseur(data) {
  return http.post(
    urlApiComptabilite + "/produitsfournisseurs/addProduitFournisseur",
    data
  );
}

export function deleteProduitFournisseur(id) {
  return http.post(
    urlApiComptabilite + "/produitsfournisseurs/deleteProduitFournisseur/" + id,
    {}
  );
}

export function getSomeRefProduitFournisseur(param) {
  return http.post(
    urlApiComptabilite + "/produitsfournisseurs/getSomeRefProduitFournisseur/",
    param
  );
}

export function updateQteCommandeeLigne({ cdeLigneId, cdeLigneQteCommandee }) {
  return http.post(
    `${urlApiComptabilite}/produitsfournisseurs/updateQteCommandeeLigneCommande/${cdeLigneId}`,
    {
      cdeLigneQteCommandee,
    }
  );
}

export function prepareSocieteFournisseur({
  fournisseurId,
  societeId,
  tabSociete,
}) {
  return http.post(
    `${urlApiComptabilite}/produitsfournisseurs/prepareSocieteFournisseur`,
    {
      fournisseurId,
      societeId,
      tabSociete,
    }
  );
}

export function getListeCommandes({
  tabSocieteId,
  date,
  tabFournisseurId,
  tabCategorieId,
}) {
  return http.post(
    urlApiComptabilite + "/produitsfournisseurs/getListeCommandes",
    {
      tabSocieteId,
      date,
      tabFournisseurId,
      tabCategorieId,
    }
  );
}

export function traiteSocieteFournisseur(param) {
  return http.post(
    urlApiComptabilite + "/produitsfournisseurs/traiteSocieteFournisseur",
    param
  );
}

export function getFlatListeCommandes(param) {
  return http.post(
    urlApiComptabilite + "/produitsfournisseurs/getFlatListeCommandes/",
    param
  );
}

export function getMaxDateInventaire() {
  return http.get(
    urlApiComptabilite + "/produitsfournisseurs/getMaxDateInventaire"
  );
}

export function updateNomCommandeProduit({ produitId, nomCommande }) {
  return http.post(
    `${urlApiComptabilite}/produitsfournisseurs/updateNomCommandeProduit/${produitId}`,
    {
      nomCommande,
    }
  );
}
