import React, { Component } from "react";

import { VictoryPie, VictoryContainer } from "victory";
import { CSVLink } from "react-csv";

const headersCSVYear = [
  { label: "Année", key: "x" },
  { label: "Sous Id", key: "sousId" },
  { label: "Valeur", key: "y" },
];

class ChartPie extends Component {
  render() {
    const {
      dataToShow,
      typeSynthese,
      colorScaleMois,
      colorScaleTVARush,
      fileExport,
    } = this.props;

    const colorScale =
      typeSynthese !== "mois" && typeSynthese !== "jour"
        ? colorScaleTVARush
        : colorScaleMois;

    return (
      <React.Fragment>
        <div>
          <CSVLink
            data={dataToShow}
            headers={headersCSVYear}
            separator={"\t"}
            enclosingCharacter={``}
            filename={fileExport + ".txt"}
            className="btn btn-outline-secondary mb-2"
            style={{ fontSize: "14px" }}
          >
            <i
              className="fas fa-cloud-download-alt mr-2"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
            Télécharger le fichier des valeurs
          </CSVLink>
        </div>
        <VictoryPie
          containerComponent={<VictoryContainer responsive={true} />}
          colorScale={colorScale}
          startAngle={
            typeSynthese !== "mois" && typeSynthese !== "jour" ? 90 : 360
          }
          endAngle={
            typeSynthese !== "mois" && typeSynthese !== "jour" ? -90 : 0
          }
          data={dataToShow.filter((x) => x.y)}
          sortKey={(datum) => datum.sousId}
          sortOrder="descending"
          padding={{ top: 10, bottom: 10, left: 100, right: 100 }}
          labelPosition={({ index }) => (index ? "centroid" : "startAngle")}
          style={{ labels: { fill: "black", fontSize: 8 } }}
        ></VictoryPie>
      </React.Fragment>
    );
  }
}

export default ChartPie;
