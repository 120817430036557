import React, { Component } from "react";
import DetailRapprochementListe from "./detailRapprochementListe";

import { getRapprochementListGroupeEcriture } from "../services/saisieService";

class DetailRapprochement extends Component {
  state = {
    rapprochement: "",
    groupes: [],
  };
  async componentDidMount() {
    const rapprochement = this.props.match.params.rapprochement;
    const param = {};
    param.rapprochement = rapprochement;
    const data = await getRapprochementListGroupeEcriture(param);

    this.setState({
      rapprochement,
      groupes: data.data.data,
    });
  }

  handleBackClick = (event) => {
    event.preventDefault();
    this.props.history.push("/saisie/1");
  };

  render() {
    const { rapprochement, groupes } = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <h2>Détail du rapprochement {rapprochement}</h2>
          <DetailRapprochementListe
            groupes={groupes}
          ></DetailRapprochementListe>

          <button
            className="btn btn-outline-secondary "
            onClick={this.handleBackClick}
          >
            <i className="fa fa-arrow-left mr-1"></i>
            Retour
          </button>
        </div>
      </div>
    );
  }
}

export default DetailRapprochement;
