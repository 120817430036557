import React, { Component } from "react";
import Table from "../common/table";

class ListeCodesJournal extends Component {
  columns = [
    {
      path: "code",
      label: "Code",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{
              fontSize: "14px",
            }}
          >
            <a
              className="btn btn-link"
              role="button"
              href="#/"
              onClick={() => this.props.onSelectCodeJournal(j)}
            >
              <i className="fas fa-edit mr-3" style={{ fontSize: "18px" }}></i>
              {j.code}
            </a>
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "libelle",
      label: "Libellé",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{j.libelle}</div>
        </React.Fragment>
      ),
    },
    {
      path: "utilise",
      label: "Utilisé",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          {(j.nbUtilisationBanque !== 0 ||
            j.nbUtilisationTypeEcriture !== 0) && (
            <div style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check "
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "delete",
      label: "",
      tdClassName: "p-2",
      className: "",
      content: (j) =>
        j.nbUtilisationBanque === 0 &&
        j.nbUtilisationTypeEcriture === 0 && (
          <button
            className="btn btn-sm btn-outline-secondary mb-1"
            onClick={() => this.props.handleSupprCodejournal(j)}
          >
            <i
              className="fa fa-trash"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
          </button>
        ),
    },
  ];
  render() {
    const { codesJournal, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={codesJournal}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeCodesJournal;
