import React, { Component } from "react";
import SelectSearch from "react-select-search";
import ListeCtrlCompta from "./listeCtrlCompta";
import { getPizzerias } from "../services/pizzeriaService";
import { getControleList } from "../services/ctrlService";
import { getUrlHlpBackMenu } from "../utils/tools";

class CtrlCompta extends Component {
  state = {
    controleEnCours: false,
    traitEnCours: false,
    societesForSelect: [],
    selectedSocietes: [],
    ctrlsFaits: false,
    tabCtrl: [],
    urlVisuGroupe: "",
  };
  async componentDidMount() {
    const urlVisuGroupe = await getUrlHlpBackMenu(58);

    const societes = await getPizzerias();
    let societesForSelect = [];
    let selectedSocietes = [];
    societes.data.data.map((item) => {
      societesForSelect.push({
        value: item._id.toString(),
        name: item.denomination,
      });
      selectedSocietes.push(item._id.toString());
      return true;
    });
    this.setState({ societesForSelect, selectedSocietes, urlVisuGroupe });
  }

  handleSelectAllClick = (event) => {
    event.preventDefault();
    const societesForSelect = this.state.societesForSelect;
    let selectedSocietes = [];
    societesForSelect.map((item) => {
      selectedSocietes.push(item.value);
      return true;
    });
    this.setState({
      selectedSocietes,
      ctrlsFaits: false,
    });
  };

  handleUnSelectAllClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedSocietes: [],
      ctrlsFaits: false,
    });
  };

  handleSocieteSelect = (societes) => {
    this.setState({
      selectedSocietes: societes,
      ctrlsFaits: false,
    });
  };

  handleSuivantClick = async (event) => {
    event.preventDefault();
    const {
      selectedSocietes,
      societesForSelect,
      traitEnCours,
      controleEnCours,
    } = this.state;
    if (!traitEnCours && !controleEnCours) {
      this.setState({ controleEnCours: true });
      const param = {};
      param.limitPreviousMonth = false;
      if (selectedSocietes.length === societesForSelect.length) {
        param.societeIdTab = [];
        param.allSocietes = true;
      } else {
        param.societeIdTab = [];
        selectedSocietes.map((x) => {
          param.societeIdTab.push(parseInt(x));
          return true;
        });
        param.allSocietes = false;
      }
      const ret = await getControleList(param);
      this.setState({
        controleEnCours: false,
        tabCtrl: ret.data.data,
        ctrlsFaits: true,
      });
    }
  };

  render() {
    const {
      societesForSelect,
      selectedSocietes,
      tabCtrl,
      ctrlsFaits,
      controleEnCours,
      urlVisuGroupe,
    } = this.state;
    return (
      <React.Fragment>
        <h2 className="mb-4">Contrôle de la comptabilité</h2>
        <div className="row">
          <div className="col-2">
            <div>
              <div>
                <label htmlFor="societes">Sociétés sélectionnées :</label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleSelectAllClick}
                >
                  Tout sélectionner
                </button>
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleUnSelectAllClick}
                >
                  Tout désélectionner
                </button>
              </div>
              <div>
                <SelectSearch
                  options={societesForSelect}
                  value={this.state.selectedSocietes}
                  name="societes"
                  multiple
                  onChange={this.handleSocieteSelect}
                />
              </div>
            </div>

            <div style={{ marginTop: "600px", marginBottom: "20px" }}>
              <button
                className="btn btn-primary ml-2"
                onClick={this.handleSuivantClick}
                disabled={selectedSocietes.length === 0 || controleEnCours}
              >
                <i className="fa fa-arrow-right mr-2"></i>
                Contrôler
              </button>
              {controleEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-10">
            {ctrlsFaits && !controleEnCours && (
              <React.Fragment>
                <h5 style={{ marginBottom: "20px" }}>
                  Résultat pour les sociétés sélectionnées
                </h5>
                <ListeCtrlCompta
                  tabCtrl={tabCtrl}
                  urlVisuGroupe={urlVisuGroupe}
                ></ListeCtrlCompta>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CtrlCompta;
