import React from "react";

const BlocListeAPE = (props) => {
  const { societe } = props;

  return (
    <React.Fragment>
      <div className="row mt-2">
        {societe.backgroundColor && societe.backgroundColor.length !== 0 && (
          <div className="col-6">
            <div
              className="badge badge-pill pl-2 pr-2 pt-2 pb-2"
              style={{ backgroundColor: societe.backgroundColor }}
            >
              Couleur de planning associée
            </div>
          </div>
        )}
        <div className="col-6">
          <div>
            Jour d'inventaire{" "}
            {societe.itemJourSemaine && societe.itemJourSemaine.label
              ? societe.itemJourSemaine.label
              : ""}
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12">
          <ul className="row" style={{ listStyle: "none", paddingInline: "0" }}>
            {societe.listAPE.map((e) => (
              <li key={e.codeAPE} className="col-6">
                {e.codeAPE} {e.nomInterneAPE}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlocListeAPE;
