import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "./common/table";

class ListeGroupeTable extends Component {
  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{ marginTop: "-20px", marginBottom: "5px" }}
          >
            <Link to={`/saisiegroupe/edit/${groupe._id}`}>{groupe._id}</Link>
          </div>
          {groupe.isSaisieManuelle === 1 && (
            <div style={{ marginBottom: "-20px" }} className="w-100">
              <Link to={`/saisiegroupe/edit/${groupe._id}`}>
                <i
                  className="fas fa-edit text-warning mt-1 ml-2"
                  style={{ fontSize: "18px" }}
                  aria-hidden="true"
                ></i>
              </Link>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "societeId",
      label: "Société",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => (
        <React.Fragment>
          <span>{groupe.societeDenomination}</span>
        </React.Fragment>
      ),
    },
    {
      path: "idTypeEcriture",
      label: "Type",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{ marginTop: "-20px", marginBottom: "5px" }}
          >
            {groupe.idTypeEcriture}
          </div>
          <div style={{ marginBottom: "-20px" }} className="w-100">
            {groupe.typeEcritureLibelle}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "dateComptable",
      label: "Date",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => <span>{groupe.dateComptableJJMMAA}</span>,
    },
    {
      path: "totalDebit",
      label: "Débit",
      tdClassName: "p-1 text-right",
      className: "col-1 p-1 text-nowrap ",
      content: (groupe) => (
        <span>
          <NumberFormat
            value={groupe.totalDebit}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            className={
              Number(groupe.totalDebit) !== Number(groupe.totalCredit)
                ? "text-danger font-weight-bold"
                : ""
            }
          />
        </span>
      ),
    },
    {
      path: "totalCredit",
      label: "Crédit",
      tdClassName: "p-1 text-right",
      className: "col-1 p-1 text-nowrap",
      content: (groupe) => (
        <span>
          {
            <NumberFormat
              value={groupe.totalCredit}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalSeparator=","
              thousandSeparator=" "
              displayType="text"
              className={
                Number(groupe.totalDebit) !== Number(groupe.totalCredit)
                  ? "text-danger font-weight-bold"
                  : ""
              }
            />
          }
        </span>
      ),
    },
    {
      path: "documentId",
      label: "Document",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => (
        <span>
          {this.props.urlVisuDoc && this.props.urlVisuDoc.length !== 0 && (
            <a
              href={this.props.urlVisuDoc + "documentfile/" + groupe.documentId}
              target="_blank"
              rel="noreferrer"
            >
              {groupe.documentId}
            </a>
          )}
          {(!this.props.urlVisuDoc || this.props.urlVisuDoc.length === 0) && (
            <span>{groupe.documentId}</span>
          )}
        </span>
      ),
    },
    {
      path: "rapprochement",
      label: "Rapprochement",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => <span>{groupe.rapprochement}</span>,
    },
    {
      path: "contactId",
      label: "Contact",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{ marginTop: "-20px", marginBottom: "5px" }}
          >
            {groupe.contactId}
          </div>
          <div style={{ marginBottom: "-20px" }} className="w-100">
            {groupe.contactNom ? groupe.contactNom : ""}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "urlId",
      label: "URL",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => (
        <span>
          {groupe.urlVisuUrl && groupe.urlVisuUrl.length !== 0 && (
            <a
              href={groupe.urlVisuUrl + "&id=" + groupe.urlId}
              target="_blank"
              rel="noreferrer"
            >
              {groupe.urlId}
            </a>
          )}
          {(!groupe.urlVisuUrl || groupe.urlVisuUrl === 0) && (
            <span>{groupe.urlId}</span>
          )}
        </span>
      ),
    },

    {
      path: "dateAjout",
      label: "Créé le\nCommentaire",
      tdClassName: "p-1",
      className: "col-1 p-1",
      content: (groupe) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{ marginTop: "-20px", marginBottom: "5px" }}
          >
            {groupe.dateAjoutJJMMAA}
          </div>
          <div style={{ marginBottom: "-20px" }} className="w-100">
            {groupe.commentaire}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "action",
      tdClassName: "p-1 mt-0 mb-0",
      className: "col-1",
      content: (groupe) => (
        <Dropdown
          style={{
            marginTop: "-20px",
            marginBottom: "-20px",
          }}
        >
          <Dropdown.Toggle
            id="dropdown-basic"
            variant="light"
            bsPrefix="nodropdown-toggle"
            className="mt-0"
          >
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-ellipsis-v "
              aria-hidden="true"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {groupe.rapprochement && groupe.rapprochement.length !== 0 && (
              <Link
                className="dropdown-item"
                to={`/detailrapprochement/${groupe.rapprochement}`}
              >
                Détail du rapprochement
              </Link>
            )}
            {groupe.isSaisieManuelle !== 0 && (
              <React.Fragment>
                <Link className="dropdown-item" to={`/extourne/${groupe._id}`}>
                  Contrepassation / Extourne
                </Link>

                <button
                  className="dropdown-item"
                  onClick={() => this.props.handleSupprGroupeOnClick(groupe)}
                >
                  Supprimer
                </button>
              </React.Fragment>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];
  render() {
    const { groupes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={groupes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeGroupeTable;
