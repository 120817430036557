import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import auth from "./services/authService";
import ProtectedRoute from "./components/common/protectedRoute";
import { getAdminArbro, getAdminFlat } from "./services/mapsService";

import NotFound from "./components/notFound";
import NotAuthorized from "./components/notAuthorized";
import NavBar from "./components/navBar";
import Logout from "./components/logout";
import ExportFec from "./components/exportFec";
import DetailErreursFec from "./components/detailErreursFec";
import GenereFec from "./components/genereFec";
import CtrlCompta from "./components/ctrlCompta";
import ComparaisonBalance from "./components/comparaisonBalance";
import SelectionTypeEcriture from "./components/selectionTypeEcriture";
import SaisieGroupeForm from "./components/saisieGroupeForm";
import DetailRapprochement from "./components/detailRapprochement";
import ExtourneForm from "./components/extourneForm";
import SoldeResultat from "./components/soldeResultat";
import InteretsCompteGroupe from "./components/interetsCompteGroupe";
import ExportImmos from "./components/exportImmos";
import StatsCA from "./components/statistiques/statsCA";
import CAMoyen from "./components/statistiques/CAMoyen";
import ValeurEncaissement from "./components/valeurEncaissement";
import StatsProduits from "./components/statistiques/statsProduits";
import StatsTypeCommandes from "./components/statistiques/statsTypeCommande";
import StatsAnciennete from "./components/statistiques/statsAnciennete";

import Fournisseurs from "./components/achat/fournisseurs";
import Commande from "./components/achat/commande";
import EmailCommande from "./components/achat/emailCommande";

import SocietesVilles from "./components/clients/societesVilles";
import VillesSocietes from "./components/clients/villesSocietes";
import Adresses from "./components/clients/adresses";
import ImportFantoir from "./components/clients/importFantoir";
import ProduitsFournisseurs from "./components/achat/produitsFournisseurs";
import FlatListeCommande from "./components/achat/flatListeCommande";

import Facture from "./components/PDF/Facture";

import PersonnesPhysiques from "./components/actionnariat/personnesPhysiques";
import Societes from "./components/actionnariat/societes";
import CodesAPE from "./components/actionnariat/codesAPE";
import SocietesActionnairesGerants from "./components/actionnariat/societesActionnairesGerants";
import CodesJournal from "./components/parametrescompta/codesJournal";
import MethodesPaiement from "./components/parametrescompta/methodesPaiement";
import TypesEcriture from "./components/parametrescompta/typesEcritures";
import TauxInteretCreances from "./components/parametrescompta/tauxInteretCreances";
import Comptes from "./components/parametrescompta/comptes";
import ComptesBanque from "./components/parametresbanque/comptesBanque";
import ScanRelevesCompteBanque from "./components/parametresbanque/scanRelevesCompte";

import { urlApiComptabilite } from "./config";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "react-simple-tree-menu/dist/main.css";
import "./App.css";
import "./App.scss";

registerLocale("fr", fr);
setDefaultLocale("fr");

class App extends Component {
  state = { shouldShowBox: false };

  constructor(props) {
    super(props);
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    if (token) {
      auth.setToken(token);
    }
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (!user) {
      this.setState({ user, menuArbo: {}, tabArbo: {}, shouldShowBox: true });
    } else {
      const resultArbo = await getAdminArbro();
      const tabArbo = await getAdminFlat();

      this.setState({
        user,
        menuArbo: resultArbo.data.data,
        tabArbo,
        shouldShowBox: true,
      });
    }
  }

  render(props) {
    const { user, menuArbo, shouldShowBox } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        {!shouldShowBox && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {shouldShowBox && (
          <React.Fragment>
            <NavBar user={user} menuArbo={menuArbo}></NavBar>
            <main className="containerHLP">
              <Switch>
                <Route
                  path="/login"
                  component={(props) => {
                    if (props.location.state.from.length !== 0) {
                      window.location.href =
                        urlApiComptabilite +
                        "/auth/login?path=" +
                        encodeURI(props.location.state.from.replace("/", ""));
                    } else {
                      window.location.href =
                        urlApiComptabilite + "/auth/login?path=";
                    }
                    return null;
                  }}
                />
                <ProtectedRoute
                  droit={"ctrlcompta"}
                  path="/ctrlcompta"
                  component={CtrlCompta}
                />
                <ProtectedRoute
                  droit={"comparaisonbalance"}
                  path="/comparaisonbalance"
                  component={ComparaisonBalance}
                />
                <ProtectedRoute
                  droit={"saisie"}
                  path="/saisie/:forceValid"
                  component={SelectionTypeEcriture}
                />
                <ProtectedRoute
                  droit={"saisie"}
                  path="/saisie"
                  component={SelectionTypeEcriture}
                />
                <ProtectedRoute
                  droit={"saisie"}
                  path="/saisiegroupe/:mode/:groupeId/:call/:paramCall"
                  component={SaisieGroupeForm}
                />
                <ProtectedRoute
                  droit={"saisie"}
                  path="/saisiegroupe/:mode/:groupeId"
                  component={SaisieGroupeForm}
                />
                <ProtectedRoute
                  droit={"saisie"}
                  path="/saisiegroupe/:mode"
                  component={SaisieGroupeForm}
                />
                <ProtectedRoute
                  droit={"saisie"}
                  path="/detailrapprochement/:rapprochement"
                  component={DetailRapprochement}
                />
                <ProtectedRoute
                  droit={"saisie"}
                  path="/extourne/:groupeId"
                  component={ExtourneForm}
                />
                <ProtectedRoute
                  droit={"exportfec"}
                  path="/exportfec"
                  component={ExportFec}
                />
                <ProtectedRoute
                  droit={"exportfec"}
                  path="/generefec"
                  component={GenereFec}
                />
                <ProtectedRoute
                  droit={"exportfec"}
                  path="/detailerreursfec/:id"
                  component={DetailErreursFec}
                />
                <ProtectedRoute
                  droit={"solderesultat"}
                  path="/solderesultat"
                  component={SoldeResultat}
                />
                <ProtectedRoute
                  droit={"interetsCompteGroupe"}
                  path="/interetsCompteGroupe"
                  component={InteretsCompteGroupe}
                />
                <ProtectedRoute
                  droit={"exportImmos"}
                  path="/exportImmos"
                  component={ExportImmos}
                />
                <ProtectedRoute
                  droit={"valeurEncaissement"}
                  path="/valeurEncaissement"
                  component={ValeurEncaissement}
                />
                <ProtectedRoute
                  droit={"statsCA"}
                  path="/statsCA"
                  component={StatsCA}
                />
                <ProtectedRoute
                  droit={"CAMoyen"}
                  path="/CAMoyen"
                  component={CAMoyen}
                />
                <ProtectedRoute
                  droit={"statsProduits"}
                  path="/statsProduits"
                  component={StatsProduits}
                />
                <ProtectedRoute
                  droit={"fournisseurs"}
                  path="/fournisseurs"
                  component={Fournisseurs}
                />
                <ProtectedRoute
                  droit={"societesVilles"}
                  path="/societesVilles"
                  component={SocietesVilles}
                />
                <ProtectedRoute
                  droit={"villesSocietes"}
                  path="/villesSocietes"
                  component={VillesSocietes}
                />
                <ProtectedRoute
                  droit={"adresses"}
                  path="/adresses"
                  component={Adresses}
                />
                <ProtectedRoute
                  droit={"importFantoir"}
                  path="/importFantoir"
                  component={ImportFantoir}
                />
                <ProtectedRoute
                  droit={"produitsFournisseurs"}
                  path="/produitsFournisseurs"
                  component={ProduitsFournisseurs}
                />
                <ProtectedRoute
                  droit={"flatListeCommande"}
                  path="/flatListeCommande"
                  component={FlatListeCommande}
                />
                <Route path="/facture/:factureId" component={Facture} />
                <ProtectedRoute
                  droit={"commandes_fournisseurs"}
                  path="/commandes_fournisseurs"
                  component={Commande}
                />
                <ProtectedRoute
                  droit={"commandes_fournisseurs"}
                  path="/email_commande_fournisseur"
                  component={EmailCommande}
                />
                <ProtectedRoute
                  droit={"personnes_physiques"}
                  path="/personnes_physiques"
                  component={PersonnesPhysiques}
                />
                <ProtectedRoute
                  droit={"personnes_physiques"}
                  path="/societes"
                  component={Societes}
                />
                <ProtectedRoute
                  droit={"personnes_physiques"}
                  path="/codesAPE"
                  component={CodesAPE}
                />
                <ProtectedRoute
                  droit={"personnes_physiques"}
                  path="/societesActionnairesGerants"
                  component={SocietesActionnairesGerants}
                />
                <ProtectedRoute
                  droit={"statsTypeCommande"}
                  path="/statsTypeCommande"
                  component={StatsTypeCommandes}
                />
                <ProtectedRoute
                  droit={"codes_journal"}
                  path="/codes_journal"
                  component={CodesJournal}
                />
                <ProtectedRoute
                  droit={"codes_journal"}
                  path="/methodes_paiement"
                  component={MethodesPaiement}
                />
                <ProtectedRoute
                  droit={"codes_journal"}
                  path="/types_ecriture"
                  component={TypesEcriture}
                />
                <ProtectedRoute
                  droit={"codes_journal"}
                  path="/taux_interet_creances"
                  component={TauxInteretCreances}
                />
                <ProtectedRoute
                  droit={"codes_journal"}
                  path="/comptes"
                  component={Comptes}
                />
                <ProtectedRoute
                  droit={"comptes_banque"}
                  path="/comptes_banque"
                  component={ComptesBanque}
                />
                <ProtectedRoute
                  droit={"comptes_banque"}
                  path="/scan_releves_compte_banque"
                  component={ScanRelevesCompteBanque}
                />
                <ProtectedRoute
                  droit={"statsAnciennete"}
                  path="/statsAnciennete"
                  component={StatsAnciennete}
                />
                <Route path="/logout/:call" component={Logout} />
                <Route path="/logout" component={Logout} />
                <Route path="/not-found" component={NotFound} />
                <Route path="/not-authorized" component={NotAuthorized} />
                <Redirect from="/" exact to="/not-found" />
                <Redirect to="/not-found" />
              </Switch>
            </main>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default App;
