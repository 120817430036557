import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";

const BlocIntroSociete = (props) => {
  const { societe } = props;

  return (
    <React.Fragment>
      <div className="row pl-2">
        <div className="font-weight-bold text-truncate mt-2">
          <span className="ml-2">
            {societe._id} - {societe.nom}
          </span>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-7">
          {societe.formeJuridique} {societe.denomination}
        </div>
        <div className="col-5 float-right">{societe.nomCommercial}</div>
      </div>
      <div className="row mt-1">
        <div className="col-7">
          <label>Capital</label>
          <NumberFormat
            value={societe.capital}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix={" €"}
            className="ml-1"
          />
        </div>
        <div className="col-5">
          <label>Nb parts</label>
          <NumberFormat
            value={societe.nbPart}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            className="ml-1"
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-12">
          <label>Date de création</label>{" "}
          {moment(societe.dateCreationAAAAMMJJ, "YYYYMMDD").format("DD/MM/YY")}
        </div>
      </div>
      <div className="row mt-1">
        {societe.dateFinExoAAAAMMJJ_1 && (
          <div className="col-12">
            <label>Fin du 1er exercice</label>{" "}
            {moment(societe.dateFinExoAAAAMMJJ_1, "YYYYMMDD").format(
              "DD/MM/YY"
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default BlocIntroSociete;
