import React, { Component } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import Table from "../common/table";

class ListeSocieteCAMoyen extends Component {
  getClassNameTotalPourcentSur = (societe) => {
    if (societe.isArretActivite) return " text-danger ";
    if (societe.totalPourcentSur === 100) return " font-weight-bold ";
    if (societe.totalPourcentSur <= 33.33) return " text-warning ";
  };

  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div style={{ fontSize: "14px" }}>
          {societe.activitePleine && !societe.isArretActivite
            ? societe._id
            : "(" + societe._id + ")"}
        </div>
      ),
    },
    {
      path: "societeDenomination",
      label: "Société",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div className={this.getClassNameTotalPourcentSur(societe)}>
          {societe.societeDenomination}
        </div>
      ),
    },
    {
      path: "dateDebutActiviteAAAAMMJJ",
      label: "Début activité",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div>
          {moment(societe.dateDebutActiviteAAAAMMJJ, "YYYYMMDD").format(
            "DD/MM/YYYY"
          )}
        </div>
      ),
    },
    {
      path: "dateFermetureClientAAAAMMJJ",
      label: "Fermeture client",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div>
          {societe.isFermeeClient && societe.dateFermetureClientAAAAMMJJ
            ? moment(societe.dateFermetureClientAAAAMMJJ, "YYYYMMDD").format(
                "DD/MM/YYYY"
              )
            : ""}
        </div>
      ),
    },
    {
      path: "nbJourPeriode",
      label: "Nb jour activité",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div>
          <NumberFormat
            value={societe.nbJourPeriode}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
          />
        </div>
      ),
    },

    {
      path: "CAReferenceHT",
      label: "CA réel",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div className="text-right">
          <NumberFormat
            value={societe.CAReferenceHT}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },
    {
      path: "totalPourcentSur",
      label: "Indice de confiance",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div className="text-right">
          <NumberFormat
            value={societe.totalPourcentSur}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
          />
        </div>
      ),
    },
    {
      path: "CACalculeHT",
      label: "CA calculé",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div className="text-right">
          <NumberFormat
            value={societe.CACalculeHT}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },

    {
      path: "CAManque",
      label: "CA non fait",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div className="text-right">
          <NumberFormat
            value={societe.CAManque}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },
    {
      path: "CACalculeEtManque",
      label: "CA Total",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div className="text-right">
          <NumberFormat
            value={societe.CACalculeEtManque}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },
    {
      path: "nbRushManquant",
      label: "Rushs non faits",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <div className="text-right">
          <NumberFormat
            value={societe.nbRushManquant}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
          />
        </div>
      ),
    },
    {
      key: "detail",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (societe) => (
        <button
          className={
            "btn btn-outline-secondary " +
            (societe.detailMois.length === 0 ? "invisible" : "visible")
          }
          onClick={() => this.props.handleDetailClick(societe)}
          disabled={societe.detailMois.length === 0}
        >
          +
        </button>
      ),
    },
  ];
  render() {
    const { societes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={societes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeSocieteCAMoyen;
