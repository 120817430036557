import jwtDecode from "jwt-decode";
import http from "./httpService";
import moment from "moment";
import { urlApiComptabilite } from "../config";

const apiEndpoint = urlApiComptabilite + "/auth";
const tokenKey = "token";

http.setJwt(getJwt());

export function setToken(token) {
  localStorage.setItem(tokenKey, token);
  http.setJwt(getJwt());
}

export async function login(email, password) {
  const ret = await http.post(apiEndpoint, {
    email: email,
    password: password,
  });
  localStorage.setItem(tokenKey, ret.data.data);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const decode = jwtDecode(jwt);
    return decode;
  } catch (ex) {
    console.log("getCurrentUser", ex);
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function getDataToken() {
  const jwt = localStorage.getItem(tokenKey);
  const decode = jwtDecode(jwt);
  return decode.data_token;
}

export function isTokenExpired() {
  const dataToken = getDataToken();
  const expired = moment(dataToken.expired).toDate();
  const date_now = moment().toDate();
  return date_now > expired;
}

// eslint-disable-next-line
export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  setToken,
  getDataToken,
  isTokenExpired,
};
