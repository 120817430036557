import React, { Component } from "react";
import Table from "../common/table";

class ListeFournisseur extends Component {
  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{
              fontSize: "14px",
            }}
          >
            <a
              className="btn btn-link"
              role="button"
              href="#/"
              onClick={() => this.props.onSelectFournisseur(fourn)}
            >
              {fourn._id}
            </a>
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "nom",
      label: "Nom",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{fourn.nom}</div>
        </React.Fragment>
      ),
    },
    {
      path: "adresse1",
      label: "Adresse 1",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{fourn.adresse1}</div>
        </React.Fragment>
      ),
    },
    {
      path: "adresse2",
      label: "Adresse 2",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{fourn.adresse2}</div>
        </React.Fragment>
      ),
    },
    {
      path: "codePostal",
      label: "CP",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{fourn.codePostal}</div>
        </React.Fragment>
      ),
    },
    {
      path: "ville",
      label: "Ville",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{fourn.ville}</div>
        </React.Fragment>
      ),
    },
    {
      path: "email",
      label: "Email",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{fourn.email}</div>
        </React.Fragment>
      ),
    },
    {
      path: "isFournisseurPizz",
      label: "Fourn. Pizz",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div
            className="text-center font-weight-bold"
            style={{ fontSize: "14px" }}
          >
            {fourn.isFournisseurPizz === 1 && <div>&#10003;</div>}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "isEnvoiMail",
      label: "Envoi mail",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div
            className="text-center font-weight-bold"
            style={{ fontSize: "14px" }}
          >
            {fourn.isEnvoiMail === 1 && <div>&#10003;</div>}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "isLivreur",
      label: "Livreur",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div
            className="text-center font-weight-bold"
            style={{ fontSize: "14px" }}
          >
            {fourn.isLivreur === 1 && <div>&#10003;</div>}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "isCdeGroupee",
      label: "Cde groupée",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div
            className="text-center font-weight-bold"
            style={{ fontSize: "14px" }}
          >
            {fourn.isCdeGroupee === 1 && <div>&#10003;</div>}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "isStockFactureAuto",
      label: "Facturation auto",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div
            className="text-center font-weight-bold"
            style={{ fontSize: "14px" }}
          >
            {fourn.isStockFactureAuto === 1 && <div>&#10003;</div>}
          </div>
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { fournisseurs, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={fournisseurs}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeFournisseur;
