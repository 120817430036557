import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import TreeMenu from "react-simple-tree-menu";

import {
  getFullComptes,
  getRecursiveCategorieBilanCR,
  getFullCompte,
  getFullCompteByNumCompteFEC,
  addCompte,
  updateCompte,
} from "../../services/parametresService";
import { paginate } from "../../utils/paginate";
import Pagination from "react-js-pagination";
import SearchBox from "../common/searchBox";
import ListeComptes from "./listeComptes";
import BarreMenu from "../common/barreMenu";
import { replaceAll } from "../../utils/tools";
import {
  typeMenuParametresComptabilite,
  menuComptes,
} from "../../utils/menuParametres";

const racine = ["1", "2", "3", "4", "5", "6", "7"];
const sens = [
  { _id: "", label: "Débit/Crédit" },
  { _id: "D", label: "Débit uniquement" },
  { _id: "C", label: "Crédit uniquement" },
];
const nature = [
  { _id: "", label: "Sans spécifité" },
  { _id: "BQ", label: "Banque" },
  { _id: "CAI", label: "Caisse" },
  { _id: "CLI", label: "Client" },
  { _id: "FRN", label: "Fournisseur" },
  { _id: "GRP", label: "Groupe" },
  { _id: "SAL", label: "Salarié" },
];

class Comptes extends Component {
  state = {
    comptes: [],
    categories: [],
    flatCategories: [],
    fournisseurs: [],
    sortColumn: { path: "compteNumero", order: "asc" },
    currentPage: 1,
    pageSize: 100,
    searchQuery: "",
    newCompte: false,
    dataCompte: {
      _id: "",
      compteNumero: "",
      compteLibelle: "",
      sensNormal: "",
      isCompteImmoAmortissable: true,
      compteNatureFEC: "",
      compteNumeroFEC: "",
      categorieId: "0",
      tabCategorie: [],
      treeCategorieId: "",
      isAffectableBilanCR: false,
    },
    selectedCompteId: -1,
    errorCompte: false,
    errorNumeroFEC: false,
    tabEcrituresComptesInexistants: [],
    tabEcrituresComptesNonRenseignes: [],
    shouldShowBox: false,
  };

  async componentDidMount() {
    const dataCategorie = await getRecursiveCategorieBilanCR();
    const dataComptes = await getFullComptes();
    this.setState({
      comptes: dataComptes.data.data.comptes,
      flatCategories: dataComptes.data.data.categories,
      categories: dataCategorie.data.data,
      tabEcrituresComptesInexistants:
        dataComptes.data.data.tabEcrituresComptesInexistants,
      tabEcrituresComptesNonRenseignes:
        dataComptes.data.data.tabEcrituresComptesNonRenseignes,
      shouldShowBox: true,
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, searchQuery, comptes, sortColumn } =
      this.state;

    let filtered = comptes;
    if (searchQuery)
      filtered = filtered.filter(
        (m) =>
          (m.compteLibelle &&
            m.compteLibelle
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (m.compteNumero && m.compteNumero.includes(searchQuery)) ||
          (m.compteNumeroFEC && m.compteNumeroFEC.includes(searchQuery))
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const comptesPage = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: comptesPage };
  };

  handleNewCompte = () => {
    const { newCompte, dataCompte } = this.state;
    dataCompte._id = "";
    dataCompte.compteNumero = "";
    dataCompte.compteLibelle = "";
    dataCompte.sensNormal = "";
    dataCompte.isCompteImmoAmortissable = true;
    dataCompte.compteNatureFEC = "";
    dataCompte.compteNumeroFEC = "";
    dataCompte.isAffectableBilanCR = true;
    dataCompte.categorieId = "0";
    dataCompte.treeCategorieId = "";
    dataCompte.tabCategorieId = [];
    dataCompte.tabCategorie = [];
    this.setState({
      newCompte: !newCompte,
      dataCompte,
      errorCompte: false,
      errorNumeroFEC: false,
    });
  };

  handleCancelNewCompte = () => {
    const { newCompte, selectedCompteId } = this.state;
    if (!newCompte && selectedCompteId !== -1) {
      const releventDiv = document.getElementById("compte_" + selectedCompteId);
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
    this.setState({
      newCompte: false,
      selectedCompteId: -1,
    });
  };

  handleIdChange = async (event) => {
    const { dataCompte } = this.state;
    let errorCompte = false;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    const stringValue = formattedValue.toString();
    if (stringValue.length !== 0 && racine.includes(stringValue[0])) {
      if (stringValue.length < 9) {
        dataCompte._id = Number(formattedValue);
        dataCompte.compteNumero = dataCompte._id.toString();
        dataCompte.compteNumeroFEC = dataCompte._id.toString().padEnd(8, "0");
        const existCompte = await getFullCompte(dataCompte._id);
        if (existCompte.data.success) errorCompte = true;
      }
    } else {
      dataCompte._id = "";
      dataCompte.compteNumero = "";
      dataCompte.compteNumeroFEC = "";
    }
    this.setState({ dataCompte, errorCompte });
  };

  handlLibelleChange = async (event) => {
    const { dataCompte } = this.state;
    dataCompte.compteLibelle = event.target.value;
    this.setState({
      dataCompte,
    });
  };

  handleIsAffectableBilanCRChange = () => {
    const { dataCompte } = this.state;
    dataCompte.isAffectableBilanCR = !dataCompte.isAffectableBilanCR;
    if (!dataCompte.isAffectableBilanCR) {
      dataCompte.categorieId = "0";
      dataCompte.treeCategorieId = "";
      dataCompte.tabCategorieId = [];
      dataCompte.tabCategorie = [];
    }
    this.setState({ dataCompte });
  };

  handlCategorieClick = ({ key }) => {
    const { dataCompte, flatCategories } = this.state;
    const tab = key.split("/");
    dataCompte.categorieId = tab[tab.length - 1].toString();
    dataCompte.treeCategorieId = key;
    dataCompte.tabCategorie = [];
    tab.forEach((c) => {
      const laCategorie = flatCategories.find((x) => x._id.toString() === c);
      if (laCategorie)
        dataCompte.tabCategorie.push({
          _id: laCategorie._id,
          nom: laCategorie.nom,
        });
    });
    this.setState({ dataCompte });
  };

  handleSensChange = (event) => {
    const { dataCompte } = this.state;
    dataCompte.sensNormal = event.target.value;
    this.setState({ dataCompte });
  };

  handleIsCompteImmoAmortissableChange = () => {
    const { dataCompte } = this.state;
    dataCompte.isCompteImmoAmortissable = !dataCompte.isCompteImmoAmortissable;
    this.setState({ dataCompte });
  };

  handleCompteNatureFECChange = (event) => {
    const { dataCompte } = this.state;
    dataCompte.compteNatureFEC = event.target.value;
    this.setState({ dataCompte });
  };

  handleCompteNumeroFECChange = async (event) => {
    const { dataCompte, selectedCompteId } = this.state;
    let errorNumeroFEC = false;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    const stringValue = formattedValue.toString();
    if (stringValue.length !== 0 && racine.includes(stringValue[0])) {
      if (stringValue.length < 9) {
        dataCompte.compteNumeroFEC = Number(formattedValue).toString();
        const existNumeroFEC = await getFullCompteByNumCompteFEC(
          dataCompte.compteNumeroFEC
        );
        if (selectedCompteId === -1) {
          if (existNumeroFEC.data.success) errorNumeroFEC = true;
        } else {
          if (
            existNumeroFEC.data.success &&
            existNumeroFEC.data.data._id !== dataCompte._id
          )
            errorNumeroFEC = true;
        }
      }
    } else {
      dataCompte.compteNumeroFEC = "";
    }
    this.setState({ dataCompte, errorNumeroFEC });
  };

  getFilArianeCategorie = (tabCategorie, excludeLast) => {
    if (excludeLast)
      return tabCategorie
        .filter((i, index) => index !== tabCategorie.length - 1)
        .map(
          (i, index) => i.nom + (index !== tabCategorie.length - 2 ? ", " : "")
        );
    return tabCategorie.map(
      (i, index) => i.nom + (index !== tabCategorie.length - 1 ? ", " : "")
    );
  };

  getErreurCategorie = (categorieId) => {
    const { flatCategories } = this.state;
    const existParent = flatCategories.filter(
      (x) => x.parentId === parseInt(categorieId)
    );
    return existParent.length !== 0;
  };

  onSelectCompte = (compte) => {
    const { selectedCompteId, newCompte, dataCompte } = this.state;
    if (selectedCompteId === -1 && !newCompte) {
      dataCompte._id = compte._id;
      dataCompte.compteNumero = compte.compteNumero;
      dataCompte.compteLibelle = compte.compteLibelle;
      dataCompte.sensNormal = compte.sensNormal;
      dataCompte.isCompteImmoAmortissable = compte.isCompteImmoAmortissable;
      dataCompte.compteNatureFEC = compte.compteNatureFEC;
      dataCompte.compteNumeroFEC = compte.compteNumeroFEC;
      dataCompte.categorieId = compte.categorieId.toString();
      dataCompte.isAffectableBilanCR = compte.categorieId > 0;
      dataCompte.treeCategorieId = compte.treeCategorieId;
      dataCompte.tabCategorieId = compte.tabCategorieId;
      dataCompte.tabCategorie = compte.tabCategorie;
      this.setState({
        selectedCompteId: compte._id,
        dataCompte,
        errorCompte: false,
        errorNumeroFEC: false,
      });
    }
    const releventDiv = document.getElementById("top-id");
    if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
  };

  handleSubmitCompte = async () => {
    const { selectedCompteId, newCompte } = this.state;
    if (newCompte && selectedCompteId === -1) {
      await this.traiteNewCompte();
    } else {
      if (!newCompte && selectedCompteId !== -1) {
        await this.traiteUpdateCompte();
      }
    }
  };

  traiteNewCompte = async () => {
    const { newCompte, dataCompte } = this.state;
    const result = await addCompte({
      dataCompte,
    });
    if (result.data.success) {
      toast.success("Le compte " + dataCompte._id + " a été créé");
      this.setState({ shouldShowBox: false });
      const dataComptes = await getFullComptes();
      this.setState({
        newCompte: !newCompte,
        comptes: dataComptes.data.data.comptes,
        currentPage: 1,
        shouldShowBox: true,
      });
    } else {
      toast.error(result.data.message);
    }
  };

  traiteUpdateCompte = async () => {
    const { selectedCompteId, dataCompte } = this.state;
    const result = await updateCompte(selectedCompteId, {
      dataCompte,
    });

    if (result.data.success && result.data.data) {
      toast.success("Le compte " + dataCompte._id + " a été modifié");
    } else {
      toast.error(result.data.message);
    }
    this.setState({ shouldShowBox: false });
    const dataComptes = await getFullComptes();

    this.setState({
      newCompte: false,
      selectedCompteId: -1,
      comptes: dataComptes.data.data.comptes,
      currentPage: 1,
      shouldShowBox: true,
    });
  };

  render() {
    const {
      pageSize,
      currentPage,
      searchQuery,
      sortColumn,
      newCompte,
      dataCompte,
      selectedCompteId,
      categories,
      errorCompte,
      errorNumeroFEC,
      tabEcrituresComptesInexistants,
      tabEcrituresComptesNonRenseignes,
      shouldShowBox,
    } = this.state;
    const { totalCount, data } = this.getPagedData();

    return (
      <div className="row">
        {!shouldShowBox && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {shouldShowBox && (
          <div className="col-12">
            <span id="top-id"></span>
            <div className="row">
              <div className="col-12">
                <BarreMenu
                  type_menu={typeMenuParametresComptabilite}
                  menu_actif={menuComptes}
                ></BarreMenu>

                {tabEcrituresComptesInexistants.length !== 0 && (
                  <div className="alert alert-warning">
                    ID des comptes inexistants avec des écritures :{" "}
                    {tabEcrituresComptesInexistants.map((r, index) => (
                      <span key={r}>{index !== 0 ? ", " + r._id : r._id}</span>
                    ))}
                  </div>
                )}
                {tabEcrituresComptesNonRenseignes.length !== 0 && (
                  <div className="alert alert-warning">
                    ID des comptes avec des écritures ayant un NUM_COMPTE non
                    renseigné ou un NUM_COMPTE avec une longueur différente de 8
                    :{" "}
                    {tabEcrituresComptesNonRenseignes.map((r, index) => (
                      <span key={r}>{index !== 0 ? ", " + r._id : r._id}</span>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {!newCompte && selectedCompteId === -1 && (
              <button
                onClick={this.handleNewCompte}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newCompte}
              >
                Nouveau compte
              </button>
            )}
            <div>
              {(newCompte || selectedCompteId !== -1) && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newCompte
                      ? "Nouveau compte"
                      : "Modification du compte numéro " + dataCompte._id}
                  </h5>
                  <div className="ml-2 mr-2" style={{ fontSize: "14px" }}>
                    <div className="form-group row">
                      <div className="col-3">
                        <label className="font-weight-bold" htmlFor="numero">
                          Numéro de compte (*)
                        </label>
                        <NumberFormat
                          className="form-control"
                          value={dataCompte._id}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator={false}
                          allowNegative={false}
                          onChange={(e, value) => {
                            this.handleIdChange(e, value);
                          }}
                          style={{ width: "150px", fontSize: "14px" }}
                          placeholder={"Numéro de compte"}
                          disabled={selectedCompteId !== -1}
                        />
                      </div>
                      <div className="col-9">
                        <label className="font-weight-bold" htmlFor="libelle">
                          Libellé (*)
                        </label>
                        <input
                          type="text"
                          value={dataCompte.compteLibelle}
                          onChange={(event) => this.handlLibelleChange(event)}
                          className="form-control"
                          placeholder="Libellé"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>
                    </div>
                    {errorCompte && (
                      <div className="alert alert-danger">
                        Ce compte existe déjà
                      </div>
                    )}
                    <div className="form-group row">
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isAffectableBilanCR"
                            checked={dataCompte.isAffectableBilanCR}
                            onChange={this.handleIsAffectableBilanCRChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isAffectableBilanCR"
                          >
                            A affecter au Bilan / CR ou compte devant être soldé
                          </label>
                        </div>
                      </div>
                      <div className="col-9">
                        {dataCompte.isAffectableBilanCR && (
                          <React.Fragment>
                            <label
                              className="font-weight-bold"
                              htmlFor="categories"
                            >
                              Affectation Bilan / CR (*)
                            </label>
                            <div
                              className={
                                "mb-2 " +
                                (this.getErreurCategorie(dataCompte.categorieId)
                                  ? "text-danger"
                                  : "text-success")
                              }
                            >
                              {this.getFilArianeCategorie(
                                dataCompte.tabCategorie,
                                false
                              )}
                            </div>
                            <TreeMenu
                              data={categories}
                              activeKey={dataCompte.treeCategorieId}
                              initialOpenNodes={dataCompte.tabCategorieId}
                              onClickItem={({ key, label, ...props }) => {
                                this.handlCategorieClick({ key });
                              }}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="sensNormal"
                        >
                          Débit/Crédit
                        </label>
                        <select
                          name="sensNormal"
                          id="sensNormal"
                          value={dataCompte.sensNormal}
                          onChange={this.handleSensChange}
                          className="form-control"
                          style={{ width: "200px", fontSize: "14px" }}
                        >
                          {sens.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isCompteImmoAmortissable"
                            checked={dataCompte.isCompteImmoAmortissable}
                            onChange={this.handleIsCompteImmoAmortissableChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isCompteImmoAmortissable"
                          >
                            Cpt Immo non amortissable
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="compteNumeroFEC"
                        >
                          Numéro de compte FEC
                        </label>
                        <NumberFormat
                          className="form-control text-right"
                          value={dataCompte.compteNumeroFEC}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator={false}
                          allowNegative={false}
                          onChange={(e, value) => {
                            this.handleCompteNumeroFECChange(e, value);
                          }}
                          style={{ width: "150px", fontSize: "14px" }}
                          placeholder={"N° de compte FEC"}
                        />
                        {errorNumeroFEC && (
                          <div className="alert alert-danger mt-2">
                            Ce compte FEC existe déjà
                          </div>
                        )}
                      </div>
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="compteNatureFEC"
                        >
                          Nature de compte (FEC)
                        </label>
                        <select
                          name="compteNatureFEC"
                          id="compteNatureFEC"
                          value={dataCompte.compteNatureFEC}
                          onChange={this.handleCompteNatureFECChange}
                          className="form-control"
                          style={{ width: "200px", fontSize: "14px" }}
                        >
                          {nature.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitCompte}
                      disabled={
                        dataCompte._id.toString().length === 0 ||
                        dataCompte.compteLibelle.length === 0 ||
                        errorCompte ||
                        errorNumeroFEC ||
                        (dataCompte.isAffectableBilanCR &&
                          parseInt(dataCompte.categorieId) === 0) ||
                        (dataCompte.compteNumeroFEC &&
                          dataCompte.compteNumeroFEC.length !== 0 &&
                          dataCompte.compteNumeroFEC.length !== 8) ||
                        (parseInt(dataCompte.categorieId) !== 0 &&
                          this.getErreurCategorie(dataCompte.categorieId))
                      }
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewCompte}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>
            <p className="w-100">{totalCount} comptes</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />

            {totalCount !== 0 && (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                prevPageText="Précédent"
                nextPageText="Suivant"
                firstPageText="Premier"
                lastPageText="Dernier"
              />
            )}
            <ListeComptes
              comptes={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onSelectCompte={this.onSelectCompte}
              getFilArianeCategorie={this.getFilArianeCategorie}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Comptes;
