import React, { Component } from "react";

// columns: array
// sortColumn: object
// onSort: function

class TableHeader extends Component {
  raiseSort = async (path) => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    await this.props.onSort(sortColumn);
  };

  // renderSortIcon = (column) => {
  //   const { sortColumn } = this.props;

  //   if (column.path !== sortColumn.path) return null;
  //   if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
  //   return <i className="fa fa-sort-desc" />;
  // };

  render() {
    const { sortColumn } = this.props;
    return (
      <thead>
        <tr>
          {this.props.columns.map((column) => (
            <th className="clickable" key={column.path || column.key}>
              <div onClick={() => this.raiseSort(column.path)}>
                <span>{column.label}</span>
                {column.path === sortColumn.path &&
                  sortColumn.order === "asc" && (
                    <span className="ml-2">&uarr;</span>
                  )}
                {column.path === sortColumn.path &&
                  sortColumn.order === "desc" && (
                    <span className="ml-2">&darr;</span>
                  )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
