import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import moment from "moment";

import Table from "../common/table";

import {
  getAllTauxInteret,
  getTauxInteret,
  addTauxInteret,
  updateTauxInteret,
  deleteTauxInteret,
} from "../../services/parametresService";
import BarreMenu from "../common/barreMenu";
import {
  typeMenuParametresComptabilite,
  menuTauxInteretCreances,
} from "../../utils/menuParametres";
import { replaceAll } from "../../utils/tools";

class TauxInteretCreances extends Component {
  state = {
    tabTauxInteret: [],
    sortColumn: { path: "_id", order: "asc" },
    newTaux: false,
    dataTaux: {
      date: "",
      dateAAAAMMJJ: "",
      taux: 0.0,
    },
    existDate: false,
    selectedDate: "@@@@@@@",
  };

  columns = [
    {
      path: "date",
      label: "Date",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{
              fontSize: "14px",
            }}
          >
            <a
              className="btn btn-link"
              role="button"
              href="#/"
              onClick={() => this.onSelectDate(t)}
            >
              <i className="fas fa-edit mr-3" style={{ fontSize: "18px" }}></i>
              {moment(t.date, "YYYYMMDD").format("DD/MM/YYYY")}
            </a>
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "taux",
      label: "Taux",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>
            <NumberFormat
              className="text-right"
              value={t.taux}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalSeparator=","
              thousandSeparator=" "
              allowNegative={false}
              displayType="text"
            />
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "delete",
      label: "",
      tdClassName: "p-2",
      className: "",
      content: (t) => (
        <button
          className="btn btn-sm btn-outline-secondary mb-1"
          onClick={() => this.handleSupprTauxInteret(t)}
        >
          <i
            className="fa fa-trash"
            style={{ fontSize: "14px" }}
            aria-hidden="true"
          ></i>
        </button>
      ),
    },
  ];

  async componentDidMount() {
    const data = await getAllTauxInteret();
    this.setState({
      tabTauxInteret: data.data.data,
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleNewTaux = () => {
    const { newTaux, dataTaux, tabTauxInteret } = this.state;
    dataTaux.date = "";
    dataTaux.dateAAAAMMJJ = "";
    dataTaux.taux = 0.0;
    if (tabTauxInteret.length !== 0) {
      const lastDate = moment(tabTauxInteret[0].date, "YYYYMMDD");
      const futureMonth = moment(lastDate).add(3, "M");
      const futureMonthEnd = moment(futureMonth).endOf("month");
      dataTaux.date = futureMonthEnd.format("YYYY-MM-DD");
      dataTaux.dateAAAAMMJJ = futureMonthEnd.format("YYYYMMDD");
    }
    this.setState({
      newTaux: !newTaux,
      dataTaux,
      existDate: false,
    });
  };

  handleCancelNewTaux = () => {
    this.setState({
      newTaux: false,
      selectedDate: "@@@@@@@",
    });
  };

  handleDateChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      const { dataTaux } = this.state;
      dataTaux.date = moment(day).format("YYYY-MM-DD");
      dataTaux.dateAAAAMMJJ = moment(day).format("YYYYMMDD");
      const exist = await getTauxInteret(dataTaux.dateAAAAMMJJ);
      this.setState({
        dataTaux,
        existDate: exist.data.success,
      });
    }
  };

  handleTauxChange = (event) => {
    const { dataTaux } = this.state;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    dataTaux.taux = Number(formattedValue);
    this.setState({ dataTaux });
  };

  onSelectDate = (taux) => {
    const { selectedDate, newTaux } = this.state;
    if (selectedDate === "@@@@@@@" && !newTaux) {
      const dataTaux = {};
      dataTaux.date = moment(taux.date, "YYYYMMDD").format("YYYY-MM-DD");
      dataTaux.dateAAAAMMJJ = taux.date;
      dataTaux.taux = taux.taux;
      this.setState({
        selectedDate: taux.date,
        dataTaux,
        existDate: false,
      });
    }
  };

  handleSubmitTaux = async () => {
    const { selectedDate, newTaux } = this.state;
    if (newTaux && selectedDate === "@@@@@@@") {
      await this.traiteNewTaux();
    } else {
      if (!newTaux && selectedDate !== "@@@@@@@") {
        await this.traiteUpdateTaux();
      }
    }
  };

  traiteNewTaux = async () => {
    const { dataTaux } = this.state;
    await addTauxInteret({
      dataTaux,
    });
    const data = await getAllTauxInteret();
    this.setState({
      newTaux: false,
      tabTauxInteret: data.data.data,
    });
  };

  traiteUpdateTaux = async () => {
    const { selectedDate, dataTaux } = this.state;
    await updateTauxInteret(selectedDate, { dataTaux });
    const data = await getAllTauxInteret();
    this.setState({
      newCodeJournal: false,
      tabTauxInteret: data.data.data,
      selectedDate: "@@@@@@@",
    });
  };

  handleSupprTauxInteret = async (t) => {
    let { tabTauxInteret } = this.state;
    confirmAlert({
      title:
        "Suppression du taux à la date du " +
        moment(t.date, "YYYYMMDD").format("DD/MM/YYYY"),
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            const retDelete = await deleteTauxInteret(t.date);
            if (retDelete.data.success) {
              tabTauxInteret = tabTauxInteret.filter((x) => x.date !== t.date);
              this.setState({ tabTauxInteret });
            } else {
              toast.error(retDelete.data.message);
            }
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  render() {
    const {
      sortColumn,
      newTaux,
      dataTaux,
      selectedDate,
      existDate,
      tabTauxInteret,
    } = this.state;

    return (
      <React.Fragment>
        <BarreMenu
          type_menu={typeMenuParametresComptabilite}
          menu_actif={menuTauxInteretCreances}
        ></BarreMenu>
        <div className="row">
          <div className="col-12">
            {!newTaux && selectedDate === "@@@@@@@" && (
              <button
                onClick={this.handleNewTaux}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newTaux}
              >
                Nouveau taux
              </button>
            )}
            <div>
              {(newTaux || selectedDate !== "@@@@@@@") && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newTaux ? "Nouveau taux" : "Modification du taux"}
                  </h5>
                  <div className="ml-2 mr-2">
                    <div className="form-group row">
                      <div className="col-6">
                        <label className="font-weight-bold" htmlFor="date">
                          Date
                        </label>
                        <input
                          type="date"
                          value={dataTaux.date}
                          onChange={(date) => this.handleDateChange(date)}
                          className="form-control"
                          style={{ width: "150px" }}
                          disabled={!newTaux}
                        ></input>
                        {existDate && (
                          <div className="alert alert-danger mt-2">
                            Cette date existe déjà
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        <label className="font-weight-bold" htmlFor="taux">
                          Taux
                        </label>
                        <NumberFormat
                          className="form-control text-right"
                          value={dataTaux.taux}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          allowNegative={false}
                          onChange={(e, value) => {
                            this.handleTauxChange(e, value);
                          }}
                          style={{ width: "150px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitTaux}
                      disabled={dataTaux.date.length === 0 || existDate}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewTaux}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="alert alert-info">
              Pour trouver les taux applicables, tapez dans google :taux
              effectif moyen pratiqué par les établissements de crédit pour les
              prêts à taux variable aux entreprises d'une durée initiale
              supérieure à deux ans
            </div>

            <Table
              columns={this.columns}
              blocs={this.blocs}
              data={tabTauxInteret}
              sortColumn={sortColumn}
              onSort={this.handleSort}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TauxInteretCreances;
