import moment from "moment";
import _ from "lodash";

import { getAdminFlat } from "../services/mapsService";

export async function getUrlHlpBackMenu(menuId) {
  const resultAdminMaps = await getAdminFlat();
  let urlHlpBack = null;
  if (resultAdminMaps) {
    const menuAdmin = resultAdminMaps.filter((item) => item._id === menuId);
    if (
      menuAdmin &&
      menuAdmin[0] &&
      menuAdmin[0].prefixeUrl &&
      menuAdmin[0].suffixeUrl
    )
      urlHlpBack = menuAdmin[0].prefixeUrl + menuAdmin[0].suffixeUrl;
  }
  return urlHlpBack;
}

export async function getPrefixeUrlHlpBackMenu(menuId) {
  const resultAdminMaps = await getAdminFlat();
  let urlHlpBack = null;
  if (resultAdminMaps) {
    const menuAdmin = resultAdminMaps.filter((item) => item._id === menuId);
    if (
      menuAdmin &&
      menuAdmin[0] &&
      menuAdmin[0].prefixeUrl &&
      menuAdmin[0].suffixeUrl
    )
      urlHlpBack = menuAdmin[0].prefixeUrl;
  }
  return urlHlpBack;
}

export function dateISOTransform(date) {
  const laDate = new Date(date);

  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = new Date(laDate - tzoffset)
    .toISOString()
    .slice(0, 16)
    .replace("T", " à ");
  return localISOTime;
}

export function dateTimeTransform(date) {
  let date2 = new Date(date);
  date2.setMinutes(date2.getMinutes() + new Date().getTimezoneOffset());

  return (
    (date2.getDate() + "").padStart(2, 0) +
    "/" +
    (date2.getMonth() + 1 + "").padStart(2, 0) +
    "/" +
    date2.getFullYear() +
    " à " +
    (date2.getHours() + "").padStart(2, 0) +
    ":" +
    (date2.getMinutes() + "").padStart(2, 0)
  );
}

export function dateTransform(date) {
  const localTime = new Date(date).toISOString().slice(0, 10);
  return localTime;
}

export function makeId() {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function makeLongId() {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 8; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function getNavbarBgColor() {
  let bgColor = "#00458B";
  switch (process.env.NODE_ENV) {
    case "development":
      bgColor = "#00458B";
      break;
    case "preproduction":
      bgColor = "#9E9E9E";
      break;
    case "production":
      bgColor = "#4E4B4A";
      break;
    default:
      break;
  }
  return bgColor;
}

export function formatNumberWithCommas(x) {
  if (!x) return "";
  let ret = x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  ret = ret.replaceAll(",", " ");
  ret = ret.replaceAll(".", ",");
  if (ret.length !== 0 && ret.indexOf(",") === -1) {
    ret = ret + ",00";
  }
  return ret;
}

export function formatIntegerWithCommas(x) {
  if (!x) return "";
  let ret = x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  ret = ret.replaceAll(",", " ");
  return ret;
}

export function getLastMonth() {
  const currentDate = moment().set("hour", 0).set("minute", 0).set("second", 0);
  const previousMonth = moment(currentDate).add(-1, "M");
  const previousMonthEnd = moment(previousMonth).endOf("month");
  return moment(previousMonthEnd).toDate();
}

export function roundDecimal(nombre, prec) {
  const precision = prec || 2;
  const tmp = Math.pow(10, precision);
  return Math.round(nombre * tmp) / tmp;
}

export function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}
export function rgbToHex(rgb) {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = "0" + hex;
  }
  return hex;
}

export function fullColorHex(r, g, b) {
  const red = rgbToHex(r);
  const green = rgbToHex(g);
  const blue = rgbToHex(b);
  return "#" + red + green + blue;
}

export function minAnneeFec() {
  const mois = new Date().getMonth();
  const annee = new Date().getFullYear();
  if (mois <= 6) return annee - 1;
  return annee;
}

export const villeDefautSociete =
  "'Par défaut' indique la pizzeria à laquelle est rattachée la ville (sert la gestion des priorités sur Village Flyers).";

export function generateTabDept() {
  let dept = [];
  for (let step = 1; step < 10; step++) {
    dept.push("0" + step.toString());
  }
  let suiteDept = _.range(10, 99);
  dept = _.concat(dept, suiteDept);
  return dept;
}

export function populateIframe(iframe, url, headers) {
  var xhr = new XMLHttpRequest();

  xhr.open("GET", url);
  xhr.onreadystatechange = handler;
  xhr.responseType = "blob";
  headers.forEach(function (header) {
    xhr.setRequestHeader(header[0], header[1]);
  });
  xhr.send();

  function handler() {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        // this.response is a Blob, because we set responseType above
        iframe.src = URL.createObjectURL(this.response);
      } else {
        console.error("XHR failed", this);
      }
    }
  }
}

export function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function generateTabJoursSemaine() {
  return [
    { _id: 1, label: "Lundi", shortLabel: "L", middleLabel: "Lun" },
    { _id: 2, label: "Mardi", shortLabel: "M", middleLabel: "Mar" },
    { _id: 3, label: "Mercredi", shortLabel: "M", middleLabel: "Mer" },
    { _id: 4, label: "Jeudi", shortLabel: "J", middleLabel: "Jeu" },
    { _id: 5, label: "Vendredi", shortLabel: "V", middleLabel: "Ven" },
    { _id: 6, label: "Samedi", shortLabel: "S", middleLabel: "Sam" },
    { _id: 7, label: "Dimanche", shortLabel: "D", middleLabel: "Dim" },
  ];
}
