import React from "react";

const BlocIdentSociete = (props) => {
  const { societe, selectEnable } = props;
  function getIconSociete(societe) {
    if (societe.codeAPE === "5610C") return "fas fa-utensils";
    if (societe.codeAPE === "6820B") return "fas fa-building";
    if (societe.codeAPE === "4332C") return "fas fa-tools";
    if (societe.codeAPE === "4511Z") return "fas fa-car";
    if (societe.codeAPE === "8559A") return "fas fa-school";
    return "";
  }

  return (
    <React.Fragment>
      <div className="row pl-2">
        {selectEnable && (
          <div
            className="font-weight-bold text-truncate pl-2 mt-2 clickable"
            onClick={() => props.onSelectSociete(societe)}
            id={"societe_" + societe._id}
          >
            <i className="fas fa-edit" style={{ fontSize: "14px" }}></i>
            <span className="ml-2">
              {societe._id} - {societe.nom}
            </span>
          </div>
        )}
        {!selectEnable && (
          <div className="font-weight-bold text-truncate mt-2">
            <span className="ml-2">
              {societe._id} - {societe.nom}
            </span>
          </div>
        )}
      </div>
      <div className="row mt-1">
        <div className="col-6">
          {societe.formeJuridique} {societe.denomination}
        </div>
        <div className="col-6 float-right">{societe.nomCommercial}</div>
      </div>
      <div className="row mt-1">
        <div className="col-4">
          <label>Siret</label> {societe.siret}
        </div>
        <div className="col-2">
          <label>RCS</label> {societe.rcs}
        </div>
        <div className="col-3">
          <label>TVA</label> {societe.numeroTVA}
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-6">
          <i
            className={"mr-2 " + getIconSociete(societe)}
            style={{ fontSize: "20px" }}
          ></i>
          <label>NAF</label> {societe.codeAPE}
        </div>
        <div className="col-6">
          <label>Id Contact interne</label> {societe.tiersId}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlocIdentSociete;
