import React, { Component } from "react";
import {
  VictoryTheme,
  VictoryAxis,
  VictoryChart,
  VictoryBar,
  VictoryLine,
  VictoryTooltip,
  VictoryLegend,
  VictoryZoomContainer,
} from "victory";
import { CSVLink } from "react-csv";

import ChartLineYear from "./chartLineYear";

const headersCSVDay = [
  { label: "Jour", key: "x" },
  { label: "Valeur", key: "y" },
];

const headersCSVWeek = [
  { label: "Semaine", key: "x" },
  { label: "Valeur", key: "y" },
];

const headersCSVMonth = [
  { label: "Mois", key: "x" },
  { label: "Valeur", key: "y" },
];

class ChartBar extends Component {
  state = {
    valeursLigneN: true,
    valeursLigneN_1: false,
    valeursLigneN_2: false,
    tabMois: [],
    zoomDomain: {},
    zoomDomainDay: {},
  };

  componentDidMount() {
    const { tabMois } = this.props;
    if (tabMois.length !== 0) {
      const zoomDomain = { x: [1, 3] };
      const zoomDomainDay = { x: [0, tabMois[2].jourFin + 1] };
      this.setState({
        zoomDomain,
        selectedDomain: zoomDomain,
        zoomDomainDay,
        tabMois,
      });
    }
  }

  getTickFormat = (t) => {
    const { dataToShow } = this.props;
    let result = "";
    switch (this.props.agregatPeriode) {
      case "week":
        result = t;
        break;
      case "day":
        if (t.substring(0, 2) === "01" || t.substring(0, 2) === "15") {
          result = t;
        } else {
          if (t === dataToShow[dataToShow.length - 1].x) result = t;
        }
        break;
      default:
        result = t;
    }
    return result;
  };
  getBarWidth = () => {
    let result = "";
    switch (this.props.agregatPeriode) {
      case "week":
        result = 5;
        break;
      case "day":
        result = 2.5;
        break;
      case "month":
        result = 15;
        break;
      default:
        result = 15;
    }
    return result;
  };

  getLabelAxeX = () => {
    let result = "";
    switch (this.props.agregatPeriode) {
      case "week":
        result = "Semaine";
        break;
      case "day":
        result = "Jour";
        break;
      case "month":
        result = "Mois";
        break;
      default:
        result = "Année";
    }
    return result;
  };

  getLabelComponent = () => {
    if (this.props.tickCount > 12)
      return (
        <VictoryTooltip
          style={{ fontSize: 10 }}
          orientation="left"
          flyoutStyle={{ stroke: "black", strokeWidth: 1 }}
        />
      );
    return;
  };

  handleValeursLigneNChange = () => {
    const { valeursLigneN } = this.state;
    this.setState({
      valeursLigneN: !valeursLigneN,
    });
  };

  handleValeursLigneN_1Change = () => {
    const { valeursLigneN_1 } = this.state;
    this.setState({
      valeursLigneN_1: !valeursLigneN_1,
    });
  };

  handleValeursLigneN_2Change = () => {
    const { valeursLigneN_2 } = this.state;
    this.setState({
      valeursLigneN_2: !valeursLigneN_2,
    });
  };

  handleUpdatezoomDomainDay(domain) {
    this.setState({ zoomDomainDay: domain });
  }

  render() {
    const {
      dataToShow,
      dataToShow_1,
      dataToShow_2,
      tickCount,
      fillColor,
      anneeN,
      agregatPeriode,
      fileExport,
      libelleLigneN,
      libelleLigneN_1,
      libelleLigneN_2,
    } = this.props;

    const {
      valeursLigneN,
      valeursLigneN_1,
      valeursLigneN_2,
      zoomDomainDay,
      selectedDomain,
      tabMois,
    } = this.state;

    const padding = { top: 10, bottom: 50, right: 10, left: 50 };
    const barWidth = this.getBarWidth();
    const height = 380;
    const width = 1000;
    let maxX = tickCount + 2;
    if (agregatPeriode === "day") maxX = 120;

    let maxY = dataToShow.reduce(
      (max, p) => (p.y > max ? p.y : max),
      dataToShow[0].y
    );
    if (dataToShow_1.length !== 0) {
      const maxY_1 = dataToShow_1.reduce(
        (max, p) => (p.y > max ? p.y : max),
        dataToShow_1[0].y
      );
      if (maxY_1 > maxY) maxY = maxY_1;
    }
    if (dataToShow_2.length !== 0) {
      const maxY_2 = dataToShow_2.reduce(
        (max, p) => (p.y > max ? p.y : max),
        dataToShow_2[0].y
      );
      if (maxY_2 > maxY) maxY = maxY_2;
    }

    let headers = [];
    if (agregatPeriode === "day") {
      headers = headersCSVDay;
    }
    if (agregatPeriode === "week") {
      headers = headersCSVWeek;
    }
    if (agregatPeriode === "month") {
      headers = headersCSVMonth;
    }

    return (
      <React.Fragment>
        {agregatPeriode !== "year" && (
          <div>
            <div className="row mb-2" style={{ fontSize: "14px" }}>
              {agregatPeriode !== "day" && (
                <React.Fragment>
                  <div className="col-2 form-check ml-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="valeurN"
                      checked={valeursLigneN}
                      onChange={this.handleValeursLigneNChange}
                    ></input>
                    <label className="form-check-label" htmlFor="valeursLigneN">
                      {libelleLigneN ? libelleLigneN : "Valeurs sur la ligne N"}
                    </label>
                  </div>
                  <div className="col-2 form-check ml-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="valeurN"
                      checked={valeursLigneN_1}
                      onChange={this.handleValeursLigneN_1Change}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="valeursLigneN_1"
                    >
                      {libelleLigneN_1
                        ? libelleLigneN_1
                        : "Valeurs sur la ligne N-1"}
                    </label>
                  </div>
                  <div className="col-2 form-check ml-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="valeurN"
                      checked={valeursLigneN_2}
                      onChange={this.handleValeursLigneN_2Change}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="valeursLigneN_2"
                    >
                      {libelleLigneN_2
                        ? libelleLigneN_2
                        : "Valeurs sur la ligne N-2"}
                    </label>
                  </div>
                </React.Fragment>
              )}
              <div
                className={agregatPeriode !== "day" ? "col-4 text-right" : ""}
              >
                <CSVLink
                  data={dataToShow}
                  headers={headers}
                  separator={"\t"}
                  enclosingCharacter={``}
                  filename={fileExport + ".txt"}
                  className="btn btn-outline-secondary mb-2"
                  style={{ fontSize: "14px" }}
                >
                  <i
                    className="fas fa-cloud-download-alt mr-2"
                    style={{ fontSize: "14px" }}
                    aria-hidden="true"
                  ></i>
                  Télécharger le fichier des valeurs de N
                </CSVLink>
              </div>
            </div>
          </div>
        )}

        {tabMois.length !== 0 && (
          <ChartLineYear
            fillColor={fillColor}
            tabMois={tabMois}
            selectedDomain={selectedDomain}
            zoomDomainDay={zoomDomainDay}
            handleUpdatezoomDomainDay={this.handleUpdatezoomDomainDay.bind(
              this
            )}
          ></ChartLineYear>
        )}

        <VictoryChart
          responsive={true}
          // animate={{
          //   duration: 500,
          //   onLoad: { duration: 200 },
          // }}
          theme={VictoryTheme.material}
          domainPadding={5}
          domain={{ y: [0, maxY * 1.1] }}
          padding={padding}
          height={height}
          width={width}
          containerComponent={
            tabMois.length !== 0 ? (
              <VictoryZoomContainer
                responsive={true}
                zoomDimension="x"
                zoomDomain={zoomDomainDay}
              />
            ) : (
              <VictoryZoomContainer
                responsive={true}
                zoomDomain={{ x: [0, maxX], y: [0, maxY * 1.1] }}
              />
            )
          }
        >
          {dataToShow_1.length !== 0 && (
            <VictoryLegend
              y={-15}
              centerTitle
              orientation="horizontal"
              gutter={20}
              style={{ border: { stroke: "black" }, labels: { fontSize: 10 } }}
              colorScale={[fillColor, "black", "grey"]}
              data={[
                { name: libelleLigneN ? libelleLigneN : anneeN.toString() },
                {
                  name: libelleLigneN_1
                    ? libelleLigneN_1
                    : (anneeN - 1).toString(),
                },
                {
                  name: libelleLigneN_2
                    ? libelleLigneN_2
                    : (anneeN - 2).toString(),
                },
              ]}
            />
          )}
          <VictoryAxis
            label={this.getLabelAxeX()}
            fixLabelOverlap
            style={{
              tickLabels: { padding: 2, fontSize: 10 },
              axisLabel: { padding: 20, fontSize: 10 },
            }}
            tickCount={tickCount}
            tickFormat={(t) => this.getTickFormat(t)}
          />
          <VictoryAxis
            dependentAxis
            style={{ tickLabels: { padding: 2, fontSize: 10 } }}
          />
          <VictoryBar
            //padding={{ top: 100, bottom: 80, left: 0, right: 80 }}
            horizontal={false}
            labelComponent={this.getLabelComponent()}
            barWidth={barWidth}
            barRatio={0.8}
            cornerRadius={0} // Having this be a non-zero number looks good when it isn't transitioning, but looks like garbage when it is....
            style={{
              data: {
                fill: fillColor,
              },
              labels: {
                fontSize: 12,
                fill: fillColor,
              },
            }}
            alignment="middle"
            labels={({ data, index }) => data[index].y}
            data={dataToShow}
          />
          {agregatPeriode !== "day" && (
            <VictoryLine
              interpolation="natural"
              horizontal={false}
              barRatio={0.8}
              cornerRadius={0} // Having this be a non-zero number looks good when it isn't transitioning, but looks like garbage when it is....
              alignment="middle"
              style={{
                data: {
                  stroke: fillColor,
                  strokeWidth: 1,
                },
                labels: {
                  fontSize: 12,
                  fill: valeursLigneN ? fillColor : "transparent",
                },
              }}
              data={dataToShow}
            />
          )}

          {dataToShow_1.length !== 0 && (
            <VictoryLine
              interpolation="natural"
              horizontal={false}
              barRatio={0.8}
              cornerRadius={0} // Having this be a non-zero number looks good when it isn't transitioning, but looks like garbage when it is....
              alignment="middle"
              style={{
                data: {
                  stroke: "black",
                  strokeWidth: 1,
                },
                labels: {
                  fontSize: 12,
                  fill: valeursLigneN_1 ? "black" : "transparent",
                },
              }}
              data={dataToShow_1}
            />
          )}
          {dataToShow_2.length !== 0 && (
            <VictoryLine
              interpolation="natural"
              horizontal={false}
              barRatio={0.8}
              cornerRadius={0} // Having this be a non-zero number looks good when it isn't transitioning, but looks like garbage when it is....
              alignment="middle"
              style={{
                data: {
                  stroke: "grey",
                  strokeWidth: 1,
                },
                labels: {
                  fontSize: 12,
                  fill: valeursLigneN_2 ? "grey" : "transparent",
                },
              }}
              data={dataToShow_2}
            />
          )}
        </VictoryChart>
      </React.Fragment>
    );
  }
}

export default ChartBar;
