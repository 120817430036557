import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

import {
  getAllContactList,
  addContact,
  getContactsLikeNom,
  getContactsByNom,
  updateContact,
} from "../../services/contactService";
import { paginate } from "../../utils/paginate";
import Pagination from "react-js-pagination";
import SearchBox from "../common/searchBox";
import ListeFournisseur from "./listeFournisseur";
import auth from "../../services/authService";
import { makeId, emailIsValid } from "../../utils/tools";

class Fournisseurs extends Component {
  state = {
    fournisseurs: [],
    sortColumn: { path: "_id", order: "asc" },
    currentPage: 1,
    pageSize: 100,
    searchQuery: "",
    newFournisseur: false,
    dataFournisseur: {
      nom: "",
      adresse1: "",
      adresse2: "",
      codePostal: "",
      ville: "",
      email: "",
      isFournisseurPizz: false,
      isEnvoiMail: false,
      isLivreur: false,
      isCdeGroupee: false,
      isStockFactureAuto: false,
      cdeTitreMail:
        "@societe - Commande @numcommande pour les sociétés Hop'La Pizza",
      cdeTexteIntroMail:
        "Voici la commande que nous viendrons chercher demain matin",
      cdeTexteIntroRepartitionPizz:
        "A titre d'information pour les employés Hop'la pizza, voici la répartition de la commande",
      cdeTexteFinMail: "Hop'la Pizza",
    },
    selectedFournisseurId: -1,
    nomLike: [],
  };

  async componentDidMount() {
    const dataFourn = await getAllContactList();
    this.setState({ fournisseurs: dataFourn.data.data });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, searchQuery, fournisseurs, sortColumn } =
      this.state;

    let filtered = fournisseurs;
    if (searchQuery)
      filtered = filtered.filter(
        (m) =>
          (m.nom && m.nom.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (m._id && m._id.toString().includes(searchQuery))
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const fournisseursPage = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: fournisseursPage };
  };

  handleNewFournisseur = () => {
    const { newFournisseur, dataFournisseur } = this.state;
    dataFournisseur.nom = "";
    dataFournisseur.adresse1 = "";
    dataFournisseur.adresse2 = "";
    dataFournisseur.codePostal = "";
    dataFournisseur.ville = "";
    dataFournisseur.email = "";
    dataFournisseur.isFournisseurPizz = false;
    dataFournisseur.isEnvoiMail = false;
    dataFournisseur.isLivreur = false;
    dataFournisseur.isCdeGroupee = false;
    dataFournisseur.isStockFactureAuto = false;
    this.setState({
      newFournisseur: !newFournisseur,
      dataFournisseur,
      nomLike: [],
    });
  };

  handleCancelNewFournisseur = () => {
    this.setState({
      newFournisseur: false,
      selectedFournisseurId: -1,
    });
  };

  handleNomChange = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.nom = event.target.value;
    const dataExist = await getContactsLikeNom({ nom: dataFournisseur.nom });

    this.setState({
      dataFournisseur,
      nomLike: dataExist.data.data,
    });
  };

  handleAdresse1Change = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.adresse1 = event.target.value;
    this.setState({
      dataFournisseur,
    });
  };
  handleAdresse2Change = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.adresse2 = event.target.value;
    this.setState({
      dataFournisseur,
    });
  };

  handleCPChange = (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.codePostal = event.target.value;
    this.setState({ dataFournisseur });
  };

  handleVilleChange = (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.ville = event.target.value;
    this.setState({ dataFournisseur });
  };

  handleEmailChange = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.email = event.target.value;
    this.setState({
      dataFournisseur,
    });
  };

  handleIsFournisseurPizzChange = () => {
    let { dataFournisseur } = this.state;
    dataFournisseur.isFournisseurPizz = !dataFournisseur.isFournisseurPizz;
    if (!dataFournisseur.isFournisseurPizz) {
      dataFournisseur.isEnvoiMail = false;
    }
    this.setState({
      dataFournisseur,
    });
  };

  handleIsEnvoiMailChange = () => {
    let { dataFournisseur } = this.state;
    dataFournisseur.isEnvoiMail = !dataFournisseur.isEnvoiMail;
    this.setState({
      dataFournisseur,
    });
  };

  handleIsLivreurChange = () => {
    let { dataFournisseur } = this.state;
    dataFournisseur.isLivreur = !dataFournisseur.isLivreur;
    this.setState({
      dataFournisseur,
    });
  };

  handleIsCdeGroupeeChange = () => {
    let { dataFournisseur } = this.state;
    dataFournisseur.isCdeGroupee = !dataFournisseur.isCdeGroupee;
    this.setState({
      dataFournisseur,
    });
  };

  handleIsStockFactureAutoChange = () => {
    let { dataFournisseur } = this.state;
    dataFournisseur.isStockFactureAuto = !dataFournisseur.isStockFactureAuto;
    this.setState({
      dataFournisseur,
    });
  };

  handlCdeTitreMailChange = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.cdeTitreMail = event.target.value;
    this.setState({
      dataFournisseur,
    });
  };

  handlCdeTexteIntroMailChange = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.cdeTexteIntroMail = event.target.value;
    this.setState({
      dataFournisseur,
    });
  };

  handlCdeTexteIntroRepartitionPizzChange = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.cdeTexteIntroRepartitionPizz = event.target.value;
    this.setState({
      dataFournisseur,
    });
  };

  handlCdeTexteFinMailChange = async (event) => {
    const { dataFournisseur } = this.state;
    dataFournisseur.cdeTexteFinMail = event.target.value;
    this.setState({
      dataFournisseur,
    });
  };

  onSelectFournisseur = (fournisseur) => {
    const { selectedFournisseurId, newFournisseur } = this.state;
    if (selectedFournisseurId === -1 && !newFournisseur) {
      const dataFournisseur = {};
      dataFournisseur._id = fournisseur._id;
      dataFournisseur.nom = fournisseur.nom;
      dataFournisseur.adresse1 = fournisseur.adresse1;
      dataFournisseur.adresse2 = fournisseur.adresse2;
      dataFournisseur.codePostal = fournisseur.codePostal;
      dataFournisseur.ville = fournisseur.ville;
      dataFournisseur.email = fournisseur.email;
      dataFournisseur.isFournisseurPizz = fournisseur.isFournisseurPizz === 1;
      dataFournisseur.isEnvoiMail = fournisseur.isEnvoiMail;
      dataFournisseur.isLivreur = fournisseur.isLivreur;
      dataFournisseur.isCdeGroupee = fournisseur.isCdeGroupee;
      dataFournisseur.isStockFactureAuto = fournisseur.isStockFactureAuto;
      dataFournisseur.cdeTitreMail = fournisseur.cdeTitreMail;
      dataFournisseur.cdeTexteIntroMail = fournisseur.cdeTexteIntroMail;
      dataFournisseur.cdeTexteIntroRepartitionPizz =
        fournisseur.cdeTexteIntroRepartitionPizz;
      dataFournisseur.cdeTexteFinMail = fournisseur.cdeTexteFinMail;
      this.setState({
        selectedFournisseurId: fournisseur._id,
        dataFournisseur,
      });
    }
  };

  handleSubmitFournisseur = async () => {
    const { selectedFournisseurId, newFournisseur } = this.state;
    const { dataFournisseur } = this.state;
    if (
      dataFournisseur.email.length !== 0 &&
      !emailIsValid(dataFournisseur.email)
    )
      return false;
    if (newFournisseur && selectedFournisseurId === -1) {
      const exist = await getContactsByNom({ nom: dataFournisseur.nom });
      if (exist.data.data.length === 0) {
        await this.traiteNewFournisseur();
      } else {
        confirmAlert({
          title: "Création d'un nouveau fournisseur",
          message:
            "Un fournisseur " +
            dataFournisseur.nom +
            " existe déjà. Confirmez-vous votre demande ?",
          buttons: [
            {
              label: "Oui",
              onClick: async () => {
                await this.traiteNewFournisseur();
              },
            },
            {
              label: "Non",
            },
          ],
        });
      }
    } else {
      if (!newFournisseur && selectedFournisseurId !== -1) {
        await this.traiteUpdateFournisseur();
      }
    }
  };

  traiteNewFournisseur = async () => {
    const { newFournisseur, dataFournisseur } = this.state;
    const result = await addContact({
      dataContact: dataFournisseur,
      userId: auth.getCurrentUser()._id,
    });

    if (result.data.success && result.data.data && result.data.data._id)
      toast.success(
        "Le fournisseur " +
          result.data.data._id +
          " " +
          dataFournisseur.nom +
          " a été créé"
      );

    const dataFourn = await getAllContactList();

    this.setState({
      newFournisseur: !newFournisseur,
      fournisseurs: dataFourn.data.data,
      currentPage: 1,
    });
  };

  traiteUpdateFournisseur = async () => {
    const { selectedFournisseurId, dataFournisseur } = this.state;
    const result = await updateContact(selectedFournisseurId, {
      dataContact: dataFournisseur,
      userId: auth.getCurrentUser()._id,
    });

    if (result.data.success && result.data.data && result.data.data._id)
      toast.success(
        "Le fournisseur " +
          result.data.data._id +
          " " +
          dataFournisseur.nom +
          " a été modifié"
      );

    const dataFourn = await getAllContactList();

    this.setState({
      newFournisseur: false,
      selectedFournisseurId: -1,
      fournisseurs: dataFourn.data.data,
      currentPage: 1,
    });
  };

  render() {
    const {
      pageSize,
      currentPage,
      searchQuery,
      sortColumn,
      newFournisseur,
      dataFournisseur,
      selectedFournisseurId,
      nomLike,
    } = this.state;
    const { totalCount, data } = this.getPagedData();

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-8">
              <h2>Fournisseurs</h2>
            </div>
          </div>
          {!newFournisseur && selectedFournisseurId === -1 && (
            <button
              onClick={this.handleNewFournisseur}
              className="btn btn-primary text-white"
              style={{ marginBottom: 20, height: 40 }}
              disabled={newFournisseur}
            >
              Nouveau fournisseur
            </button>
          )}
          <div>
            {(newFournisseur || selectedFournisseurId !== -1) && (
              <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                <h5 className="ml-2">
                  {newFournisseur
                    ? "Nouveau fournisseur"
                    : "Modification du fournisseur"}
                </h5>
                <div className="ml-2 mr-2">
                  <div className="form-group">
                    <label className="font-weight-bold" htmlFor="nom">
                      Nom
                    </label>
                    <input
                      type="text"
                      value={dataFournisseur.nom}
                      onChange={(event) => this.handleNomChange(event)}
                      className="form-control"
                      placeholder="Nom"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold" htmlFor="adresse1">
                      Adresse
                    </label>
                    <input
                      type="text"
                      value={dataFournisseur.adresse1}
                      onChange={(event) => this.handleAdresse1Change(event)}
                      className="form-control"
                      placeholder="Adresse"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold" htmlFor="adresse2">
                      Suite de l'adresse
                    </label>
                    <input
                      type="text"
                      value={dataFournisseur.adresse2}
                      onChange={(event) => this.handleAdresse2Change(event)}
                      className="form-control"
                      placeholder="Suite de l'adresse"
                    ></input>
                  </div>
                  <div className="form-group row">
                    <div className="col-2">
                      <label className="font-weight-bold" htmlFor="codePostal">
                        Code postal / Ville
                      </label>
                      <input
                        type="text"
                        value={dataFournisseur.codePostal}
                        onChange={(event) => this.handleCPChange(event)}
                        className="form-control"
                        placeholder="Code postal"
                        autoComplete={makeId()}
                      ></input>
                    </div>
                    <div className="col-10" style={{ marginTop: "2rem" }}>
                      <input
                        type="text"
                        value={dataFournisseur.ville}
                        onChange={(event) => this.handleVilleChange(event)}
                        className="form-control"
                        placeholder="Ville"
                        autoComplete={makeId()}
                      ></input>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="text"
                      value={dataFournisseur.email}
                      onChange={(event) => this.handleEmailChange(event)}
                      className="form-control"
                      placeholder="Email"
                    ></input>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isFournisseurPizz"
                      checked={dataFournisseur.isFournisseurPizz}
                      onChange={this.handleIsFournisseurPizzChange}
                    ></input>
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="isFournisseurPizz"
                    >
                      Fournisseur des pizzerias
                    </label>
                  </div>
                  <div className="form-check" style={{ display: "flex" }}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isEnvoiMail"
                      checked={dataFournisseur.isEnvoiMail}
                      onChange={this.handleIsEnvoiMailChange}
                      disabled={!dataFournisseur.isFournisseurPizz}
                    ></input>
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="isEnvoiMail"
                    >
                      Envoi des commandes par mail
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isLivreur"
                      checked={dataFournisseur.isLivreur}
                      onChange={this.handleIsLivreurChange}
                      disabled={!dataFournisseur.isFournisseurPizz}
                    ></input>
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="isLivreur"
                    >
                      Livraison directe des pizzerias
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isCdeGroupee"
                      checked={dataFournisseur.isCdeGroupee}
                      onChange={this.handleIsCdeGroupeeChange}
                      disabled={!dataFournisseur.isFournisseurPizz}
                    ></input>
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="isCdeGroupee"
                    >
                      Commande groupée pour toutes les pizzerias
                    </label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isStockFactureAuto"
                      checked={dataFournisseur.isStockFactureAuto}
                      onChange={this.handleIsStockFactureAutoChange}
                      disabled={!dataFournisseur.isFournisseurPizz}
                    ></input>
                    <label
                      className="form-check-label font-weight-bold"
                      htmlFor="isStockFactureAuto"
                    >
                      Stock - Facturation automatique
                    </label>
                  </div>
                  {dataFournisseur.isFournisseurPizz &&
                    dataFournisseur.isEnvoiMail && (
                      <React.Fragment>
                        <div className="alert alert-info mt-2">
                          Mots clés pour les mails : @societe - Nom de la
                          société, @numcommande - n° de commande
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold" htmlFor="nom">
                            Titre du mail pour les commandes
                          </label>
                          <input
                            type="text"
                            value={dataFournisseur.cdeTitreMail}
                            onChange={(event) =>
                              this.handlCdeTitreMailChange(event)
                            }
                            className="form-control"
                            placeholder="Titre du mail"
                          ></input>
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold" htmlFor="nom">
                            Texte d'introduction du mail pour les commandes
                          </label>
                          <input
                            type="text"
                            value={dataFournisseur.cdeTexteIntroMail}
                            onChange={(event) =>
                              this.handlCdeTexteIntroMailChange(event)
                            }
                            className="form-control"
                            placeholder="Texte d'introduction du mail"
                          ></input>
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold" htmlFor="nom">
                            Texte d'introduction de la répartition par pizzerias
                            pour les commandes
                          </label>
                          <input
                            type="text"
                            value={dataFournisseur.cdeTexteIntroRepartitionPizz}
                            onChange={(event) =>
                              this.handlCdeTexteIntroRepartitionPizzChange(
                                event
                              )
                            }
                            className="form-control"
                            placeholder="Texte d'introduction de la répartition par pizzerias du mail"
                            disabled={!dataFournisseur.isCdeGroupee}
                          ></input>
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold" htmlFor="nom">
                            Texte de fin du mail pour les commandes
                          </label>
                          <input
                            type="text"
                            value={dataFournisseur.cdeTexteFinMail}
                            onChange={(event) =>
                              this.handlCdeTexteFinMailChange(event)
                            }
                            className="form-control"
                            placeholder="Texte de fin du mail"
                          ></input>
                        </div>
                      </React.Fragment>
                    )}
                  {nomLike.length !== 0 && dataFournisseur.nom.length !== 0 && (
                    <div className="alert alert-warning">
                      <p>
                        {nomLike.length} fournisseur(s) ont un nom contenant "
                        {dataFournisseur.nom}"
                      </p>
                      <ul style={{ maxHeight: "150px", overflowY: "auto" }}>
                        {nomLike.map((n) => (
                          <li key={n._id}>
                            {n._id} - {n.nom}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="ml-2 mr-2">
                  <button
                    className="btn btn-primary mr-2"
                    onClick={this.handleSubmitFournisseur}
                    disabled={dataFournisseur.nom.length === 0}
                  >
                    Enregistrer
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={this.handleCancelNewFournisseur}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            )}
          </div>
          <p className="w-100">{totalCount} fournisseurs</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />

          {totalCount !== 0 && (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalCount}
              pageRangeDisplayed={10}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              prevPageText="Précédent"
              nextPageText="Suivant"
              firstPageText="Premier"
              lastPageText="Dernier"
            />
          )}
          <ListeFournisseur
            fournisseurs={data}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onSelectFournisseur={this.onSelectFournisseur}
          />
        </div>
      </div>
    );
  }
}

export default Fournisseurs;
