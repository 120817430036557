import React, { Component } from "react";
import Table from "../common/table";
import BlocIdentSociete from "./blocIdentSociete";
import BlocCapitalSociete from "./blocCapitalSociete";
import BlocAdresseSociete from "./blocAdresseSociete";
import BlocListeAPE from "./blocListeAPE";

class ListeSociete extends Component {
  blocs = [
    {
      path: "blocIdent",
      content: (societe) => (
        <BlocIdentSociete
          societe={societe}
          selectEnable={true}
          onSelectSociete={this.props.onSelectSociete}
        />
      ),
      className: "col-3 bg-light border rounded mr-2 pt-1 pb-1",
    },
    {
      path: "blocCapital",
      content: (societe) => <BlocCapitalSociete societe={societe} />,
      className: "col-2 bg-light border rounded mr-2 pt-1 pb-1",
    },
    {
      path: "blocAdresse",
      content: (societe) => <BlocAdresseSociete societe={societe} />,
      className: "col-3 bg-light border rounded mr-2 pt-1 pb-1",
    },
    {
      path: "blocListeAPE",
      content: (societe) => <BlocListeAPE societe={societe} />,
      className: "col-3 bg-light border rounded mr-2 pt-1 pb-1",
    },
  ];

  render() {
    const { societes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={societes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeSociete;
