import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function getControleList({
  allSocietes,
  societeIdTab,
  limitPreviousMonth,
}) {
  return await http.post(urlApiComptabilite + "/ctrlCompta/controleList", {
    allSocietes,
    societeIdTab,
    limitPreviousMonth,
  });
}

// eslint-disable-next-line
export default {
  getControleList,
};
