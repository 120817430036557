import React from "react";
import moment from "moment";
import SelectSearch from "react-select-search";

import Table from "../common/table";

const BlocGerants = (props) => {
  const {
    tabSocieteForSelect,
    tabPPForSelect,
    societe,
    newGerant,
    newGerantSocieteId,
    selectedGerantId,
    dataGerant,
    tabPMExclusGerant,
    tabPPExclusGerant,
  } = props;

  const columns = [
    {
      path: "gerantNom",
      label: "Nom",
      tdClassName: "p-2",
      className: "",
      content: (g) => (
        <span
          style={{ fontSize: "12px" }}
          className={"clickable " + props.handleGetColor(g)}
          onClick={() => props.onSelectGerant(g)}
        >
          <i className="fas fa-edit mr-3" style={{ fontSize: "18px" }}></i>
          <i
            style={{ fontSize: "18px" }}
            className={
              g.isPhysique === 1 ? "fas fa-user mr-2" : "fas fa-building mr-2"
            }
          ></i>{" "}
          {g.gerantNom}
        </span>
      ),
    },
    {
      path: "dateFromAAAAMMJJ",
      label: "Depuis le",
      tdClassName: "p-2",
      className: "",
      content: (g) => (
        <span style={{ fontSize: "12px" }} className={props.handleGetColor(g)}>
          Depuis : {moment(g.dateFromAAAAMMJJ, "YYYYMMDD").format("DD/MM/YY")}
        </span>
      ),
    },
    {
      path: "dateToAAAAMMJJ",
      label: "Jusqu'au",
      tdClassName: "p-2",
      className: "",
      content: (g) => (
        <span style={{ fontSize: "12px" }} className={props.handleGetColor(g)}>
          Jusqu'au :{" "}
          {g.dateToAAAAMMJJ !== null
            ? moment(g.dateToAAAAMMJJ, "YYYYMMDD").format("DD/MM/YY")
            : "Maintenant"}
        </span>
      ),
    },
    {
      path: "delete",
      label: "delete",
      tdClassName: "p-2",
      className: "",
      content: (g) => (
        <button
          className={
            "btn btn-sm btn-outline-secondary mb-1 " + props.handleGetColor(g)
          }
          onClick={() => props.handleSupprGerant(societe._id, g)}
        >
          <i
            className="fa fa-trash"
            style={{ fontSize: "14px" }}
            aria-hidden="true"
          ></i>
        </button>
      ),
    },
  ];
  const sortColumn = { path: "_id", order: "asc" };

  return (
    <React.Fragment>
      {(newGerant || selectedGerantId !== -1) &&
        newGerantSocieteId === societe._id && (
          <div className="jumbotron jumbotron-fluid pt-2 pb-2">
            <h5 className="ml-2">
              {newGerant ? "Nouveau gérant" : "Modification du gérant"}
            </h5>
            <div className="ml-2 mr-2">
              <div className="ml-2 mr-2">
                <div className="radio-item">
                  <input
                    type="radio"
                    name="type"
                    value="morale"
                    checked={dataGerant.typePersonne === "morale"}
                    onChange={(e) => props.handleTypePersonneGerantChange(e)}
                  />
                  <label
                    className="font-weight-bold"
                    onClick={() =>
                      props.handleTypePersonneGerantClick("morale")
                    }
                  >
                    Personne morale
                  </label>
                </div>
                <div className="radio-item">
                  <input
                    type="radio"
                    name="type"
                    value="physique"
                    checked={dataGerant.typePersonne === "physique"}
                    onChange={(e) => props.handleTypePersonneGerantChange(e)}
                  />
                  <label
                    className="font-weight-bold"
                    onClick={() =>
                      props.handleTypePersonneGerantClick("physique")
                    }
                  >
                    Personne physique
                  </label>
                </div>
              </div>
              {dataGerant.isPersonneMorale && (
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="nom">
                    Personne morale
                  </label>
                  <SelectSearch
                    options={tabSocieteForSelect.filter(
                      (s) =>
                        s.value !== newGerantSocieteId.toString() &&
                        !tabPMExclusGerant.includes(s.value)
                    )}
                    value={dataGerant.gerantId}
                    name="gerantId"
                    search={true}
                    onChange={props.handlePersonneGerantSelect}
                  />
                </div>
              )}
              {dataGerant.isPersonnePhysique && (
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="nom">
                    Personne physique
                  </label>
                  <SelectSearch
                    options={tabPPForSelect.filter(
                      (p) => !tabPPExclusGerant.includes(p.value)
                    )}
                    value={dataGerant.gerantId}
                    name="gerantId"
                    search={true}
                    onChange={props.handlePersonneGerantSelect}
                  />
                </div>
              )}
              <div className="form-group row">
                <div className="col-6">
                  <label className="font-weight-bold" htmlFor="nom">
                    Depuis le
                  </label>
                  <input
                    type="date"
                    value={dataGerant.date}
                    onChange={(date) => props.handleDateGerantChange(date)}
                    className="form-control"
                    style={{ fontSize: "12px", width: "150px" }}
                  ></input>
                </div>
                <div className="col-6">
                  <label className="font-weight-bold" htmlFor="nom">
                    Jusqu'au
                  </label>
                  <input
                    type="date"
                    value={dataGerant.dateTo}
                    onChange={(date) => props.handleDateToGerantChange(date)}
                    className="form-control"
                    style={{ fontSize: "12px", width: "150px" }}
                  ></input>
                </div>
              </div>
              <div className="ml-2 mr-2">
                <button
                  className="btn btn-primary mr-2"
                  onClick={props.handleSubmitGerant}
                  disabled={dataGerant.gerantId === "-1"}
                >
                  Enregistrer
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={props.handleCancelNewGerant}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        )}
      <div className="row mt-2 ml-1 mr-1">
        {!newGerant && newGerantSocieteId === -1 && (
          <button
            className="btn btn-sm btn-outline-secondary mb-2 mt-2"
            onClick={() => props.handleNewGerant(societe._id)}
          >
            <i
              className="fa fa-plus"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
          </button>
        )}
        <Table
          columns={columns}
          data={societe.tabGerant}
          sortColumn={sortColumn}
          noHeader={true}
          //onSort={this.handleSort}
        />
      </div>
    </React.Fragment>
  );
};

export default BlocGerants;
