import React from "react";
import { Link } from "react-router-dom";

import {
  menuPersonnesPhysiques,
  menuSocietes,
  menuCodesAPE,
  menuActionnairesAndGerants,
  typeMenuParametresSocietes,
  typeMenuParametresComptabilite,
  typeMenuParametresBanque,
  menuCodesJournal,
  menuMethodesPaiement,
  menuTypesEcriture,
  menuTauxInteretCreances,
  menuComptes,
  menuComptesBanque,
  menuScanRelevesComptesBanque,
} from "../../utils/menuParametres";

const BarreMenu = (props) => {
  const { type_menu, menu_actif } = props;
  let menu = [];
  if (type_menu === typeMenuParametresSocietes) {
    menu = [
      {
        _id: menuPersonnesPhysiques,
        label: "Personnes physiques",
        to: "/personnes_physiques",
      },
      { _id: menuCodesAPE, label: "Codes APE", to: "/codesAPE" },
      { _id: menuSocietes, label: "Liste des sociétés", to: "/societes" },
      {
        _id: menuActionnairesAndGerants,
        label: "Actionnaires et Gérants",
        to: "/societesActionnairesGerants",
      },
    ];
  }
  if (type_menu === typeMenuParametresComptabilite) {
    menu = [
      {
        _id: menuCodesJournal,
        label: "Codes journal (FEC)",
        to: "/codes_journal",
      },
      {
        _id: menuMethodesPaiement,
        label: "Méthodes de paiement",
        to: "/methodes_paiement",
      },
      {
        _id: menuTypesEcriture,
        label: "Types d'écriture",
        to: "/types_ecriture",
      },
      {
        _id: menuTauxInteretCreances,
        label: "Taux d'intérêt des créances",
        to: "/taux_interet_creances",
      },
      {
        _id: menuComptes,
        label: "Comptes",
        to: "/comptes",
      },
    ];
  }

  if (type_menu === typeMenuParametresBanque) {
    menu = [
      {
        _id: menuComptesBanque,
        label: "Comptes de banque",
        to: "/comptes_banque",
      },
      {
        _id: menuScanRelevesComptesBanque,
        label: "Scan des relevés de compte",
        to: "/scan_releves_compte_banque",
      },
    ];
  }

  return (
    <React.Fragment>
      <div className="row mb-4">
        <div className="col-12">
          <div
            className="btn-toolbar"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div
              className="btn-group mr-2"
              role="group"
              aria-label="First group"
            >
              {menu.map((m) => (
                <Link
                  key={m._id}
                  type="button"
                  className={
                    "btn btn-sm mr-1 " +
                    (menu_actif === m._id
                      ? "btn-warning"
                      : "btn-outline-secondary")
                  }
                  to={m.to}
                >
                  {m.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BarreMenu;
