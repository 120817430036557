import http from "./httpService";
import { urlApiGeneral, urlApiComptabilite } from "../config.js";

const pizzeriaService_sessionName = "pizzerias";

export async function getPizzerias() {
  if (sessionStorage.getItem(pizzeriaService_sessionName)) {
    return await JSON.parse(
      sessionStorage.getItem(pizzeriaService_sessionName)
    );
  } else {
    const result = await http.get(urlApiGeneral + "/pizzerias/pizzerialist");
    sessionStorage.setItem(pizzeriaService_sessionName, JSON.stringify(result));
    return JSON.parse(sessionStorage.getItem(pizzeriaService_sessionName));
  }
}

export async function getAllVillesLivrees() {
  return await http.get(urlApiComptabilite + "/societes/getAllVillesLivrees");
}

export async function getAllSocietesLivreuses() {
  return await http.get(
    urlApiComptabilite + "/societes/getAllSocietesLivreuses"
  );
}

export async function updateSocieteVilleIsDefaut({
  societeId,
  villeId,
  isDefaut,
}) {
  return await http.post(
    urlApiComptabilite +
      "/societes/updateSocieteVilleIsDefaut/" +
      societeId +
      "/" +
      villeId +
      "/" +
      isDefaut
  );
}

export async function deleteSocieteVille({ societeId, villeId }) {
  return await http.post(
    urlApiComptabilite +
      "/societes/deleteSocieteVille/" +
      societeId +
      "/" +
      villeId
  );
}

export async function addSocieteVilleInsee({ societeId, idInsee }) {
  return await http.post(
    urlApiComptabilite +
      "/societes/addSocieteVilleInsee/" +
      societeId +
      "/" +
      idInsee
  );
}

export async function getDistinctVillesLivrees() {
  return await http.get(
    urlApiComptabilite + "/societes/getDistinctVillesLivrees"
  );
}

export async function getAllIndiceRepetitionLettreNumRue() {
  return await http.get(
    urlApiComptabilite + "/societes/getAllIndiceRepetitionLettreNumRue"
  );
}

export async function getAllIndiceRepetitionAdverbeNumRue() {
  return await http.get(
    urlApiComptabilite + "/societes/getAllIndiceRepetitionAdverbeNumRue"
  );
}

export async function getAbreviationsRueFantoir(villeIdInsee) {
  return await http.get(
    urlApiComptabilite + "/societes/getAbreviationsRueFantoir/" + villeIdInsee
  );
}

export async function getRuesFantoir(param) {
  return await http.post(
    urlApiComptabilite + "/societes/getRuesFantoir/",
    param
  );
}

export async function getVilleAllRuesFantoir(param) {
  return await http.post(
    urlApiComptabilite + "/societes/getVilleAllRuesFantoir/",
    param
  );
}

export async function updateVilleIsEntierementLivree({
  villeId,
  isEntierementLivree,
}) {
  return await http.post(
    urlApiComptabilite +
      "/societes/updateVilleIsEntierementLivree/" +
      villeId +
      "/" +
      isEntierementLivree
  );
}

export async function getAllCodeAPE() {
  return await http.get(urlApiComptabilite + "/societes/getAllCodeAPE");
}

export async function getCodeAPE(code) {
  return await http.get(urlApiComptabilite + "/societes/getCodeAPE/" + code);
}

export async function addCodeAPE({ dataAPE }) {
  return await http.post(urlApiComptabilite + "/societes/addCodeAPE", {
    dataAPE,
  });
}
export async function updateCodeAPE({ code, dataAPE }) {
  return await http.put(
    urlApiComptabilite + "/societes/updateCodeAPE/" + code,
    {
      dataAPE,
    }
  );
}

export async function listenfantlieu() {
  return await http.get(urlApiComptabilite + "/societes/listenfantlieu");
}

export async function addSociete({ dataSociete }) {
  await http.post(urlApiComptabilite + "/societes/addSociete", {
    dataSociete,
  });
  const sessionName = pizzeriaService_sessionName;
  sessionStorage.removeItem(sessionName);
}

export async function updateSociete({ societeId, dataSociete }) {
  await http.put(urlApiComptabilite + "/societes/updateSociete/" + societeId, {
    dataSociete,
  });
  const sessionName = pizzeriaService_sessionName;
  sessionStorage.removeItem(sessionName);
}

export async function getActionnairesAndGerantsAllSocietes() {
  return await http.get(
    urlApiComptabilite + "/societes/getActionnairesAndGerantsAllSocietes"
  );
}

export async function deleteActionnaire(id) {
  return await http.post(
    urlApiComptabilite + "/societes/deleteActionnaire/" + id,
    {}
  );
}

export async function addActionnaire({ societeId, dataActionnaire }) {
  return await http.post(
    urlApiComptabilite + "/societes/addActionnaire/" + societeId,
    { dataActionnaire }
  );
}

export async function updateActionnaire({ id, dataActionnaire }) {
  return await http.post(
    urlApiComptabilite + "/societes/updateActionnaire/" + id,
    { dataActionnaire }
  );
}

export async function deleteGerant(id) {
  return await http.post(
    urlApiComptabilite + "/societes/deleteGerant/" + id,
    {}
  );
}

export async function addGerant({ societeId, dataGerant }) {
  return await http.post(
    urlApiComptabilite + "/societes/addGerant/" + societeId,
    { dataGerant }
  );
}

export async function updateGerant({ id, dataGerant }) {
  return await http.post(urlApiComptabilite + "/societes/updateGerant/" + id, {
    dataGerant,
  });
}

export async function getListeAssocie(annee) {
  return await http.get(
    urlApiComptabilite + "/societes/getListeAssocie/" + annee
  );
}

// eslint-disable-next-line
export default {
  getPizzerias,
};
