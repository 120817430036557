import React from "react";

const BlocAdresseSociete = (props) => {
  const { societe } = props;

  return (
    <React.Fragment>
      <div className="row mt-2">
        <div className="col-12">{societe.adresse}</div>
      </div>
      <div className="row">
        <div className="col-12">
          {societe.cp} {societe.ville}
        </div>
      </div>
      {societe.telephone && (
        <div className="row mt-1">
          <div className="col-12">
            <i className="fas fa-phone mr-2" style={{ fontSize: "14px" }}></i>
            {societe.telephone}
          </div>
        </div>
      )}
      {societe.lieuId !== 0 && (
        <React.Fragment>
          <div className="row mt-2">
            <div className="col-2">
              <label>Id lieu</label> {societe.lieuId}
            </div>
            <div className="col-10">
              <a
                href={`https://maps.google.com/?q=${societe.latitude},${societe.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="fas fa-map-marker-alt ml-2"
                  style={{ fontSize: "18px", color: "black" }}
                ></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-truncate">{societe.adresseLieu}</div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BlocAdresseSociete;
