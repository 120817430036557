import React, { Component } from "react";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";

import AdresseGPS from "./adresseGPS";
import ModificationAdresse from "./modificationAdresse";
import MessagesAdresse from "./messagesAdresse";

class ListeAdresse extends Component {
  raiseSort = async (path) => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    await this.props.onSort(sortColumn);
  };

  getIconeSort = (val) => {
    const sortColumn = { ...this.props.sortColumn };
    return (
      <React.Fragment>
        {val === sortColumn.path && sortColumn.order === "asc" && (
          <span className="ml-2">&uarr;</span>
        )}
        {val === sortColumn.path && sortColumn.order === "desc" && (
          <span className="ml-2">&darr;</span>
        )}
      </React.Fragment>
    );
  };
  render() {
    const {
      adresses,
      APIKEY_GOOGLEMAPS,
      villesForSelect,
      tabIndiceRepetitionLettreForSelect,
      tabIndiceRepetitionAdverbeForSelect,
    } = this.props;

    return (
      <table className="table table-bordered" style={{ fontSize: "12px" }}>
        <thead>
          <tr className="mr-0 ml-0">
            <th className="clickable">
              <div onClick={() => this.raiseSort("_id")}>
                <span>Id</span>
                {this.getIconeSort("_id")}
              </div>
            </th>
            <th className="clickable">
              <div onClick={() => this.raiseSort("latLon")}>
                <span>Coordonnées GPS / Précision</span>
                {this.getIconeSort("latLon")}
              </div>
            </th>
            <th className="clickable">
              <div onClick={() => this.raiseSort("fantoirId")}>
                <span>Id Fantoir</span>
                {this.getIconeSort("fantoirId")}
              </div>
            </th>
            <th>Adresse</th>
            <th>Nvx messages Livraison</th>
            <th className="clickable">
              <div onClick={() => this.raiseSort("nbCde")}>
                <span>Nb commandes</span>
                {this.getIconeSort("nbCde")}
              </div>
            </th>
            <th className="clickable">
              <div onClick={() => this.raiseSort("dateMaxCde")}>
                <span>Dernière commande</span>
                {this.getIconeSort("dateMaxCde")}
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adresses.map((adr) => (
            <React.Fragment key={adr._id}>
              <tr>
                <td>
                  <button
                    className={
                      "btn btn-link text-dark " +
                      (!adr.collapse ? "font-weight-bold" : "")
                    }
                    onClick={() => this.props.handleCollapseAdrOnClick(adr)}
                    style={{ fontSize: "14px" }}
                  >
                    <i
                      className="fas fa-map-marked mr-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                    {adr._id}
                  </button>
                </td>
                <td>
                  <div style={{ fontSize: "14px" }}>{adr.latLon}</div>
                  <div style={{ fontSize: "12px" }}>
                    {adr.typeLocalisationLib}
                  </div>
                </td>
                <td>
                  <div
                    style={{ fontSize: "14px" }}
                    className={
                      adr.fantoirExclus
                        ? "text-danger"
                        : adr.fantoirInclus
                        ? "text-success"
                        : ""
                    }
                  >
                    {adr.fantoirId}
                  </div>
                </td>
                <td>
                  <button
                    className={
                      "btn btn-link text-dark " +
                      (!adr.majCollapse ? "font-weight-bold" : "")
                    }
                    onClick={() => this.props.handleCollapseMajOnClick(adr)}
                    style={{ fontSize: "14px", marginLeft: "-10px" }}
                  >
                    <i
                      className="fas fa-address-card mr-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                    {adr.adresse}
                  </button>
                  <div style={{ fontSize: "14px" }}>
                    {adr.villeCP} {adr.villeNom}
                  </div>
                </td>
                <td>
                  {adr.nbNouveauxMessages > 0 && (
                    <React.Fragment>
                      <span
                        className="badge bg-warning"
                        style={{ fontSize: "12px" }}
                      >
                        Nouveaux messages : {adr.nbNouveauxMessages}
                      </span>
                    </React.Fragment>
                  )}
                </td>
                <td>
                  {adr.nbCde > 0 && (
                    <React.Fragment>
                      <span
                        className="badge bg-primary"
                        style={{ fontSize: "12px" }}
                      >
                        {adr.nbCde}
                      </span>
                    </React.Fragment>
                  )}
                </td>
                <td>
                  {adr.nbCde > 0 && (
                    <React.Fragment>
                      <span className="ml-2" style={{ fontSize: "12px" }}>
                        {moment(adr.dateMaxCde, "YYYYMMDD").format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </React.Fragment>
                  )}
                </td>

                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      variant="light"
                      bsPrefix="nodropdown-toggle"
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className="fa fa-ellipsis-v "
                        aria-hidden="true"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* {adr.collapse && adr.majCollapse && (
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            this.props.handleCollapseAdrOnClick(adr)
                          }
                        >
                          Voir l'adresse sur les cartes
                        </button>
                      )} */}
                      {/* {adr.collapse && adr.majCollapse && (
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            this.props.handleCollapseMajOnClick(adr)
                          }
                        >
                          Modifier l'adresse
                        </button>
                      )} */}
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          this.props.handleDeleteCoordonneesGpsClick(adr)
                        }
                      >
                        Supprimer les coordonnées GPS actuelles
                      </button>
                      <button
                        className="dropdown-item"
                        onClick={() => this.props.handleDisactiveClick(adr)}
                      >
                        Désactiver l'adresse
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              {(!adr.collapse || !adr.majCollapse) && (
                <tr>
                  <td colSpan="7">
                    <MessagesAdresse
                      adresse={adr}
                      handleValidNewMessage={this.props.handleValidNewMessage}
                      handleDisactiveMessage={this.props.handleDisactiveMessage}
                    ></MessagesAdresse>
                  </td>
                  <td colSpan="1"></td>
                </tr>
              )}
              {!adr.majCollapse && (
                <tr>
                  <td colSpan="7">
                    <ModificationAdresse
                      adresse={adr}
                      handleCollapseMajOnClick={
                        this.props.handleCollapseMajOnClick
                      }
                      handleValidModifAdresse={
                        this.props.handleValidModifAdresse
                      }
                      villesForSelect={villesForSelect}
                      tabIndiceRepetitionLettreForSelect={
                        tabIndiceRepetitionLettreForSelect
                      }
                      tabIndiceRepetitionAdverbeForSelect={
                        tabIndiceRepetitionAdverbeForSelect
                      }
                    ></ModificationAdresse>
                  </td>
                  <td colSpan="1"></td>
                </tr>
              )}
              {!adr.collapse && (
                <React.Fragment>
                  <tr>
                    <td colSpan="7">
                      <h5>Cartes et coordonnées GPS</h5>
                    </td>
                    <td colSpan="1"></td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <div style={{ fontSize: "14px" }}>
                        La carte ci-dessous affiche la carte Google Maps de
                        l'adresse recherchée
                      </div>
                      <iframe
                        title="Carte google maps"
                        id="carte"
                        width="100%"
                        height="400"
                        src={`https://www.google.com/maps/embed/v1/place?q=${adr.adresseComplete}&key=${APIKEY_GOOGLEMAPS}`}
                      />
                    </td>
                    <td colSpan="4">
                      <AdresseGPS
                        adresse={adr}
                        handleChoixCoordonnees={
                          this.props.handleChoixCoordonnees
                        }
                        APIKEY_GOOGLEMAPS={APIKEY_GOOGLEMAPS}
                      ></AdresseGPS>
                    </td>
                    <td colSpan="1"></td>
                  </tr>
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }
}

export default ListeAdresse;
