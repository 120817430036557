import React, { Component } from "react";

import {
  getAllCodeAPE,
  getCodeAPE,
  addCodeAPE,
  updateCodeAPE,
} from "../../services/pizzeriaService";
import BarreMenu from "../common/barreMenu";
import ListeCodeAPE from "./listeCodeAPE";
import {
  menuCodesAPE,
  typeMenuParametresSocietes,
} from "../../utils/menuParametres";

class CodesAPE extends Component {
  state = {
    tabAPE: [],
    sortColumn: { path: "code", order: "asc" },
    newAPE: false,
    dataAPE: {
      code: "",
      designation: "",
      nomInterne: "",
    },
    selectedCodeAPE: "@@@@@@",
    errorAlreadyExist: false,
  };

  async componentDidMount() {
    const dataAPE = await getAllCodeAPE();
    this.setState({
      tabAPE: dataAPE.data.data,
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleNewAPE = () => {
    const { newAPE, dataAPE } = this.state;
    dataAPE.code = "";
    dataAPE.designation = "";
    dataAPE.nomInterne = "";
    this.setState({
      newAPE: !newAPE,
      dataAPE,
    });
  };

  handleCancelNewAPE = () => {
    this.setState({
      newAPE: false,
      selectedCodeAPE: "@@@@@@",
    });
  };

  handleCodeChange = async (event) => {
    const { dataAPE } = this.state;
    dataAPE.code = event.target.value.toUpperCase();
    this.setState({
      dataAPE,
    });
  };

  handleDesignationChange = async (event) => {
    const { dataAPE } = this.state;
    dataAPE.designation = event.target.value;
    this.setState({
      dataAPE,
    });
  };

  handleNomInterneChange = async (event) => {
    const { dataAPE } = this.state;
    dataAPE.nomInterne = event.target.value;
    this.setState({
      dataAPE,
    });
  };

  onSelectCodeAPE = (ape) => {
    const { selectedCodeAPE, newAPE } = this.state;
    if (selectedCodeAPE === "@@@@@@" && !newAPE) {
      const dataAPE = {};
      dataAPE.code = ape.code;
      dataAPE.designation = ape.designation;
      dataAPE.nomInterne = ape.nomInterne;
      this.setState({
        selectedCodeAPE: ape.code,
        dataAPE,
      });
    }
  };

  handleSubmitAPE = async () => {
    const { selectedCodeAPE, newAPE, dataAPE } = this.state;
    this.setState({ errorAlreadyExist: false });
    if (newAPE && selectedCodeAPE === "@@@@@@") {
      const dataExist = await getCodeAPE(dataAPE.code);
      if (dataExist.data.success) {
        this.setState({ errorAlreadyExist: true });
        return;
      }
      await this.traiteNewAPE();
    } else {
      if (!newAPE && selectedCodeAPE !== "@@@@@@") {
        await this.traiteUpdateAPE();
      }
    }
  };

  traiteNewAPE = async () => {
    const { newAPE, dataAPE } = this.state;
    await addCodeAPE({
      dataAPE,
    });
    const data = await getAllCodeAPE();
    this.setState({
      newAPE: !newAPE,
      tabAPE: data.data.data,
    });
  };

  traiteUpdateAPE = async () => {
    const { selectedCodeAPE, dataAPE } = this.state;
    await updateCodeAPE({
      code: selectedCodeAPE,
      dataAPE,
    });
    const data = await getAllCodeAPE();
    this.setState({
      newAPE: false,
      tabAPE: data.data.data,
      selectedCodeAPE: "@@@@@@",
    });
  };

  render() {
    const {
      sortColumn,
      newAPE,
      dataAPE,
      selectedCodeAPE,
      tabAPE,
      errorAlreadyExist,
    } = this.state;

    return (
      <React.Fragment>
        <BarreMenu
          type_menu={typeMenuParametresSocietes}
          menu_actif={menuCodesAPE}
        ></BarreMenu>
        <div className="row">
          <div className="col-12">
            {!newAPE && selectedCodeAPE === "@@@@@@" && (
              <button
                onClick={this.handleNewAPE}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newAPE}
              >
                Nouveau code APE
              </button>
            )}
            <div>
              {(newAPE || selectedCodeAPE !== "@@@@@@") && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newAPE ? "Nouveau code APE" : "Modification du code APE"}
                  </h5>
                  <div className="ml-2 mr-2">
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="Code">
                        Code
                      </label>
                      <input
                        type="text"
                        value={dataAPE.code}
                        onChange={(event) => this.handleCodeChange(event)}
                        className="form-control"
                        placeholder="Code composé de 4 chiffres + 1 lettre"
                        disabled={!newAPE}
                      ></input>
                      {errorAlreadyExist && (
                        <div className="alert alert-danger mt-2">
                          Ce code existe déjà
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="Designation">
                        Désignation de la nomenclature
                      </label>
                      <input
                        type="text"
                        value={dataAPE.designation}
                        onChange={(event) =>
                          this.handleDesignationChange(event)
                        }
                        className="form-control"
                        placeholder="Désignation de la nomenclature"
                      ></input>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="nomInterne">
                        Nom interne
                      </label>
                      <input
                        type="text"
                        value={dataAPE.nomInterne}
                        onChange={(event) => this.handleNomInterneChange(event)}
                        className="form-control"
                        placeholder="Nom interne"
                      ></input>
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitAPE}
                      disabled={
                        dataAPE.code.length !== 5 ||
                        dataAPE.designation.length === 0 ||
                        dataAPE.nomInterne.length === 0
                      }
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewAPE}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>

            {tabAPE && (
              <ListeCodeAPE
                tabAPE={tabAPE}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onSelectCodeAPE={this.onSelectCodeAPE}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CodesAPE;
