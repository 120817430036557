import React, { Component } from "react";

import NumberFormat from "react-number-format";
import { roundDecimal } from "../utils/tools";

const resultDebit = (tab) =>
  tab.map(function (a) {
    return a.debit;
  });
const resultCredit = (tab) =>
  tab.map(function (a) {
    return a.credit;
  });
const add = (a, b) => {
  return roundDecimal(Number(a) + Number(b));
};

class DetailRapprochementListe extends Component {
  render() {
    const { groupes } = this.props;

    return (
      <div>
        {groupes.map((item, index) => (
          <React.Fragment key={index}>
            <table
              className="table table-bordered mt-4"
              style={{ fontSize: "14px" }}
              key={index}
            >
              <thead>
                <tr
                  className="row mr-0 ml-0 font-weight-bold"
                  key={"header" + index.toString()}
                >
                  <td className="col-1">Groupe Id</td>
                  <td className="col-1">Société</td>
                  <td className="col-2">Type</td>
                  <td className="col-1">Date</td>
                  <td className="col-1">Document</td>
                  <td className="col-2">Contact</td>
                  <td className="col-1">Url</td>
                  <td className="col-1">Créé le</td>
                  <td className="col-2">Commentaire</td>
                </tr>
              </thead>
              <tbody>
                <tr className="row mr-0 ml-0" key={item._id}>
                  <td className="col-1">
                    <div>{item._id}</div>
                  </td>
                  <td className="col-1">
                    <div>{item.societeDenomination}</div>
                  </td>
                  <td className="col-2">
                    <div>
                      {item.idTypeEcriture} {item.typeEcritureLibelle}
                    </div>
                  </td>
                  <td className="col-1">
                    <div>{item.dateComptableJJMMAA}</div>
                  </td>
                  <td className="col-1">
                    <div>{item.documentId}</div>
                  </td>
                  <td className="col-2">
                    <div>
                      {item.contactId} {item.contactNom}
                    </div>
                  </td>
                  <td className="col-1">
                    <div>{item.urlId}</div>
                  </td>
                  <td className="col-1">
                    <div>{item.dateAjoutJJMMAA}</div>
                  </td>
                  <td className="col-2">
                    <div>{item.commentaire}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            {item.journalCode && item.journalCode.length !== 0 && (
              <div className="mt-2 mb-2" style={{ fontStyle: "italic" }}>
                Code journal connu de l'EC : {item.journalCode}
              </div>
            )}
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                <table
                  className="table table-striped mt-4"
                  style={{ fontSize: "14px" }}
                  key={item._id}
                >
                  <thead>
                    <tr className="row font-weight-bold" key="header">
                      <td className="col-2">Compte</td>
                      <td className="col-6">Libellé</td>
                      <td className="col-2 text-right">Débit</td>
                      <td className="col-2 text-right">Crédit</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="row font-weight-bold" key="total">
                      <td className="col-2"></td>
                      <td className="col-6">Total</td>
                      <td className="col-2 text-right">
                        <NumberFormat
                          value={resultDebit(item.ecritures).reduce(add)}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </td>
                      <td className="col-2 text-right">
                        <NumberFormat
                          value={resultCredit(item.ecritures).reduce(add)}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </td>
                    </tr>
                    {item.ecritures.map((ecr) => (
                      <tr key={ecr._id} className="row">
                        <td className="col-2">{ecr.compteId}</td>
                        <td className="col-6">
                          {ecr.compteNumero} {ecr.compteLibelle}
                        </td>
                        <td className="col-2 text-right">
                          <NumberFormat
                            value={ecr.debit}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        </td>
                        <td className="col-2 text-right">
                          <NumberFormat
                            value={ecr.credit}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-3"></div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default DetailRapprochementListe;
