import React, { Component } from "react";
import NumberFormat from "react-number-format";
import Table from "../common/table";

class ListeDetailSociete extends Component {
  getClassNameTotalPourcentSur = (societe) => {
    if (societe.totalPourcentSur === 100) return " font-weight-bold ";
    if (societe.totalPourcentSur <= 33) return " text-warning ";
  };
  columns = [
    {
      key: "mois",
      label: "Mois",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => (
        <div style={{ fontSize: "14px" }}>
          {mois.mois !== 13 ? mois.mois : "Total"}
        </div>
      ),
    },
    {
      key: "nbJourTheorique",
      label: "Nb jours théorique",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => (
        <div className="text-right">{mois.nbJourTheorique}</div>
      ),
    },
    {
      key: "pourcentTheorique",
      label: "% théorique",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => (
        <div className="text-right">
          <NumberFormat
            value={mois.pourcentTheorique}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
          />
        </div>
      ),
    },
    {
      key: "nbJour",
      label: "Nb jours réel",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => <div className="text-right">{mois.nbJour}</div>,
    },
    {
      key: "pourcentSur",
      label: "% réel",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => (
        <div className="text-right">
          <NumberFormat
            value={mois.pourcentSur}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
          />
        </div>
      ),
    },
    {
      key: "totalCAHT",
      label: "CA Réel",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => (
        <div className="text-right">
          <NumberFormat
            value={mois.totalCAHT}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },
    {
      key: "CAManque",
      label: "CA non fait",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => (
        <div className="text-right">
          <NumberFormat
            value={mois.CAManque}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },
    {
      key: "nbRushManquant",
      label: "Rushs non faits",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (mois) => (
        <div className="text-right">{mois.nbRushManquant}</div>
      ),
    },
  ];
  render() {
    const { detailMois, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={detailMois}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeDetailSociete;
