import React, { Component } from "react";

import {
  VictoryTheme,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryBrushContainer,
} from "victory";

class ChartLineYear extends Component {
  handleBrush(domain) {
    let min = Math.floor(domain.x[0]) - 1;
    let max = Math.round(domain.x[1]) - 1;
    const tabMois = this.props.tabMois;
    if (min <= 0) min = 0;
    if (max > tabMois.length) max = tabMois.length - 1;
    const zoomDomainDay = {
      x: [tabMois[min].jourDebut, tabMois[max].jourFin + 1],
    };
    this.setState({ zoomDomain: domain });
    this.props.handleUpdatezoomDomainDay(zoomDomainDay);
  }
  render() {
    const { fillColor, tabMois, selectedDomain } = this.props;
    return (
      <React.Fragment>
        <label className="font-weight-bold" style={{ fontSize: "14px" }}>
          Ligne de l'année
        </label>
        <VictoryChart
          width={800}
          height={90}
          theme={VictoryTheme.material}
          domainPadding={5}
          padding={{ top: 10, bottom: 40, right: 10, left: 50 }}
          containerComponent={
            <VictoryBrushContainer
              responsive={false}
              brushDimension="x"
              brushDomain={selectedDomain}
              onBrushDomainChange={(domain) => this.handleBrush(domain)}
              //onBrushDomainChange={this.handleBrush.bind(this)}
            />
          }
        >
          <VictoryAxis tickFormat={(x) => x} label="Mois" />
          <VictoryLine
            interpolation="natural"
            style={{
              data: { stroke: fillColor },
            }}
            data={tabMois}
          />
        </VictoryChart>
      </React.Fragment>
    );
  }
}

export default ChartLineYear;
