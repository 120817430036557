import React, { Component } from "react";
import {
  VictoryTheme,
  VictoryAxis,
  VictoryChart,
  VictoryStack,
  VictoryTooltip,
  VictoryLegend,
  VictoryZoomContainer,
  VictoryBar,
  VictoryLine,
} from "victory";
import _ from "lodash";
import { CSVLink } from "react-csv";

import ChartLineYear from "./chartLineYear";

const headersCSVDay = [
  { label: "Jour", key: "x" },
  { label: "Sous Id", key: "sousId" },
  { label: "Valeur", key: "y" },
];
const headersCSVWeek = [
  { label: "Semaine", key: "x" },
  { label: "Sous Id", key: "sousId" },
  { label: "Valeur", key: "y" },
];
const headersCSVMonth = [
  { label: "Semaine", key: "x" },
  { label: "Sous Id", key: "sousId" },
  { label: "Valeur", key: "y" },
];

class ChartStackedBar extends Component {
  state = {
    tabMois: [],
    zoomDomain: {},
    zoomDomainDay: {},
    dataLegend: [],
  };

  componentDidMount() {
    const { tabMois, typeSynthese, tabLabel } = this.props;
    if (tabMois.length !== 0) {
      const zoomDomain = { x: [1, 3] };
      const zoomDomainDay = { x: [0, tabMois[2].jourFin + 1] };
      this.setState({
        zoomDomain,
        selectedDomain: zoomDomain,
        zoomDomainDay,
        tabMois,
      });
    }
    let dataLegend = [];
    if (typeSynthese === "tva") {
      dataLegend = [
        { name: tabLabel[0] },
        { name: tabLabel[1] },
        { name: tabLabel[2] },
      ];
    }
    if (typeSynthese === "rush") {
      dataLegend = [{ name: tabLabel[1].name }, { name: tabLabel[2].name }];
    }
    if (typeSynthese === "jour") {
      dataLegend = [
        { name: tabLabel[1].name },
        { name: tabLabel[2].name },
        { name: tabLabel[3].name },
        { name: tabLabel[4].name },
        { name: tabLabel[5].name },
        { name: tabLabel[6].name },
        { name: tabLabel[7].name },
      ];
    }
    this.setState({ dataLegend });
  }

  getTickFormat = (t) => {
    let result = "";
    switch (this.props.agregatPeriode) {
      case "week":
        if (parseInt(t.substring(5, 7)) % 2 === 0) result = t;
        break;
      case "day":
        if (t.substring(0, 2) === "01" || t.substring(0, 2) === "15") {
          result = t;
        } else {
          const { dataToShow } = this.props;
          if (t === dataToShow[dataToShow.length - 1].x) result = t;
        }
        break;
      default:
        result = t;
    }
    return result;
  };
  getBarWidth = () => {
    let result = "";
    switch (this.props.agregatPeriode) {
      case "week":
        result = 8;
        break;
      case "day":
        result = 2.5;
        break;
      case "month":
        result = 15;
        break;
      default:
        result = 15;
    }
    return result;
  };

  getLabelAxeX = () => {
    const { anneeN } = this.props;
    let result = "";
    switch (this.props.agregatPeriode) {
      case "week":
        result = "Semaine";
        break;
      case "day":
        result = "Jour";
        break;
      case "month":
        result = "Mois";
        break;
      default:
        result = "Année";
    }
    result = result + " - Année " + anneeN;
    return result;
  };

  getLabelComponent = () => {
    return (
      <VictoryTooltip
        style={{ fontSize: 10 }}
        orientation="left"
        flyoutStyle={{ stroke: "black", strokeWidth: 1 }}
      />
    );
  };

  handleUpdatezoomDomainDay(domain) {
    this.setState({ zoomDomainDay: domain });
  }

  render() {
    const {
      dataToShow,
      typeSynthese,
      agregatPeriode,
      tabTotal,
      fillColor,
      colorScaleTVARush,
      colorScaleMois,
      fileExport,
    } = this.props;

    const { zoomDomainDay, selectedDomain, tabMois, dataLegend } = this.state;

    const groupedData = _.groupBy(dataToShow, ({ sousId }) => sousId);
    //console.log("groupedData", groupedData);
    let maxY = 0;
    if (tabTotal && tabTotal.length !== 0) {
      maxY = tabTotal.reduce(
        (max, p) => (p.y > max ? p.y : max),
        tabTotal[0].y
      );
    }

    const tickCount = Object.entries(groupedData)[0][1].length;

    const padding = { top: 30, bottom: 50, right: 10, left: 50 };
    const barWidth = this.getBarWidth();
    const height = 380;
    const width = 1000;
    let maxX = tickCount + 2;
    if (agregatPeriode === "day") maxX = 120;

    let tickLabels = {};
    if (agregatPeriode === "week") {
      tickLabels = { padding: 10, fontSize: 10, angle: 45 };
    } else {
      tickLabels = { padding: 2, fontSize: 10 };
    }

    const colorScale =
      typeSynthese !== "mois" && typeSynthese !== "jour"
        ? colorScaleTVARush
        : colorScaleMois;

    let headers = [];
    if (agregatPeriode === "day") {
      headers = headersCSVDay;
    }
    if (agregatPeriode === "week") {
      headers = headersCSVWeek;
    }
    if (agregatPeriode === "month") {
      headers = headersCSVMonth;
    }

    return (
      <React.Fragment>
        <div>
          <CSVLink
            data={dataToShow}
            headers={headers}
            separator={"\t"}
            enclosingCharacter={``}
            filename={fileExport + ".txt"}
            className="btn btn-outline-secondary mb-2"
            style={{ fontSize: "14px" }}
          >
            <i
              className="fas fa-cloud-download-alt mr-2"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
            Télécharger le fichier des valeurs
          </CSVLink>
        </div>
        {tabMois.length !== 0 && (
          <ChartLineYear
            fillColor={fillColor}
            tabMois={tabMois}
            selectedDomain={selectedDomain}
            zoomDomainDay={zoomDomainDay}
            handleUpdatezoomDomainDay={this.handleUpdatezoomDomainDay.bind(
              this
            )}
          ></ChartLineYear>
        )}
        <VictoryChart
          responsive={true}
          animate={{
            duration: 500,
            onLoad: { duration: 200 },
          }}
          theme={VictoryTheme.material}
          domainPadding={5}
          domain={{ y: [0, maxY * 1.1] }}
          padding={padding}
          height={height}
          width={width}
          containerComponent={
            tabMois.length !== 0 ? (
              <VictoryZoomContainer
                responsive={true}
                zoomDimension="x"
                zoomDomain={zoomDomainDay}
              />
            ) : (
              <VictoryZoomContainer
                responsive={true}
                zoomDomain={{ x: [0, maxX], y: [0, maxY * 1.1] }}
              />
            )
          }
        >
          <VictoryLegend
            // x={125}
            itemsPerRow={4}
            y={0}
            centerTitle
            orientation="horizontal"
            gutter={10}
            style={{ border: { stroke: "black" }, labels: { fontSize: 10 } }}
            colorScale={colorScale}
            data={dataLegend}
          />
          <VictoryAxis
            label={this.getLabelAxeX()}
            //fixLabelOverlap
            style={{
              tickLabels: tickLabels,
              axisLabel: { padding: 20, fontSize: 10 },
            }}
            tickCount={tickCount}
            tickFormat={(t) => this.getTickFormat(t)}
          />
          <VictoryAxis
            dependentAxis
            style={{ tickLabels: { padding: 2, fontSize: 10 } }}
          />
          {agregatPeriode !== "week" && agregatPeriode !== "day" && (
            <VictoryLine
              interpolation="natural"
              horizontal={false}
              barRatio={0.8}
              cornerRadius={0} // Having this be a non-zero number looks good when it isn't transitioning, but looks like garbage when it is....
              alignment="middle"
              style={{
                data: {
                  stroke: "grey",
                  strokeWidth: 1,
                },
                labels: {
                  fontSize: 8,
                  fill: "grey",
                },
              }}
              data={tabTotal}
            />
          )}

          <VictoryStack colorScale={colorScale}>
            {Object.entries(groupedData).map(([key, dataGroup]) => {
              return (
                <VictoryBar
                  key={key}
                  barRatio={0.8}
                  data={dataGroup}
                  barWidth={barWidth}
                  labelComponent={this.getLabelComponent()}
                  style={{
                    labels: {
                      fontSize: 5,
                      fill: "transparent",
                    },
                  }}
                />
              );
            })}
          </VictoryStack>
        </VictoryChart>
      </React.Fragment>
    );
  }
}

export default ChartStackedBar;
