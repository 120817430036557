import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { makeId } from "../../utils/tools";

import { setMessagesConversationLu } from "../../services/adressesService";
import auth from "../../services/authService";

class MessagesAdresse extends Component {
  state = {
    newMessage: "",
  };

  async componentDidMount() {
    const { adresse } = this.props;
    if (adresse.conversationId && adresse.conversationId !== 0) {
      let tabMessageId = [];
      adresse.tabMessage.forEach((m) => tabMessageId.push(m.messageId));
      await setMessagesConversationLu({
        employeId: auth.getCurrentUser().typeUserId,
        conversationId: adresse.conversationId,
        tabMessageId,
      });
    }
  }

  handleNewMessageChange = (event) => {
    this.setState({ newMessage: event.target.value });
  };

  handleNewMessageValider = async () => {
    const { adresse } = this.props;
    const { newMessage } = this.state;
    if (newMessage.trim().length === 0) return;

    await this.props.handleValidNewMessage(adresse, newMessage);
    this.setState({
      newMessage: "",
    });
  };

  handleDisactiveMessageClick = async (message) => {
    const { adresse } = this.props;
    confirmAlert({
      title: "Suppression du message : ",
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            await this.props.handleDisactiveMessage(adresse, message);
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  render() {
    const { adresse } = this.props;
    const { newMessage } = this.state;
    if (!adresse._id) return null;
    return (
      <React.Fragment>
        <div className="pt-2 pb-2" style={{ fontSize: "14px" }}>
          <h5>Messages</h5>
          <div>
            <div className="row ml-1 mr-1 mb-2">
              <div className="col-11 row">
                <input
                  name={"newMessage" + makeId()}
                  id="newMessage"
                  type="text"
                  required
                  placeholder="Nouveau message"
                  value={newMessage}
                  onChange={this.handleNewMessageChange}
                  className="form-control mt-1"
                  autoComplete={makeId()}
                  style={{ fontSize: "14px" }}
                />
              </div>
              <div className="col-1">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleNewMessageValider()}
                  disabled={newMessage.trim().length === 0}
                >
                  Enregistrer
                </button>
              </div>
            </div>
            {adresse.tabMessage && adresse.tabMessage.length !== 0 && (
              <React.Fragment>
                <ul className="ml-1 pl-1">
                  {adresse.tabMessage.map((m) => (
                    <li key={m.messageId} className="col-12 ml-3 pl-1 mb-2">
                      <div className="col-12">
                        <div>{m.text}</div>
                        <div
                          className="float-right"
                          style={{ marginTop: "-15px" }}
                        >
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => this.handleDisactiveMessageClick(m)}
                          >
                            Supprimer
                          </button>
                        </div>
                      </div>
                      <div className="col-12 color-light-gray">
                        {m.redacteurPrenom} {m.redacteurNom} - le{" "}
                        {moment(m.dateheureAjout, "YYYYMMDDHHmm").format(
                          "DD/MM/YY à HH:mm"
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MessagesAdresse;
