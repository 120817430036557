import React, { Component } from "react";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

import {
  getActionnairesAndGerantsAllSocietes,
  deleteActionnaire,
  addActionnaire,
  updateActionnaire,
  deleteGerant,
  addGerant,
  updateGerant,
} from "../../services/pizzeriaService";
import { getAllPersonnesPhysiques } from "../../services/actionnariatService";
import { getComptesByRacine } from "../../services/saisieService";

import ListeSocieteActionnairesGerants from "./listeSocieteActionnairesGerants";
import BarreMenu from "../common/barreMenu";

import {
  menuActionnairesAndGerants,
  typeMenuParametresSocietes,
} from "../../utils/menuParametres";

class SocietesActionnairesGerants extends Component {
  state = {
    societes: [],
    tabSocietesForSelect: [],
    tabPMExclusActionnaire: [],
    tabPPForSelect: [],
    tabPPExclusActionnaire: [],
    tabCompteForSelect: [],
    tabCompteExclus: [],
    sortColumn: { path: "_id", order: "asc" },
    newActionnaire: false,
    newActionnaireSocieteId: -1,
    selectedActionnaireId: -1,
    maxTotalParticipation: 100,
    dataActionnaire: {
      isPersonneMorale: true,
      isPersonnePhysique: false,
      actionnaireId: "-1",
      typePersonne: "morale",
      isInterne: true,
      participation: 100,
      date: "",
      dateAAAAMMJJ: "",
      dateTo: "",
      dateToAAAAMMJJ: "",
      compteCCId: "-1",
    },
    newGerant: false,
    newGerantSocieteId: -1,
    dataGerant: {
      isPersonneMorale: true,
      isPersonnePhysique: false,
      gerantId: "-1",
      typePersonne: "morale",
      date: "",
      dateAAAAMMJJ: "",
      dateTo: "",
      dateToAAAAMMJJ: "",
    },
    tabPMExclusGerant: [],
    tabPPExclusGerant: [],
  };

  async componentDidMount() {
    const datasocietes = await getActionnairesAndGerantsAllSocietes();
    const tabSocieteForSelect = [];
    tabSocieteForSelect.push({
      value: "-1",
      name: "",
    });
    datasocietes.data.data.forEach((s) => {
      s.totalParticipation = 0;
      s.totalParticipationInterne = 0;
      s.tabActionnaire
        .filter((a) => a.dateToAAAAMMJJ === null)
        .forEach((a) => {
          s.totalParticipation = s.totalParticipation + a.participation;
          if (a.isInterne === 1)
            s.totalParticipationInterne =
              s.totalParticipationInterne + a.participation;
        });
      tabSocieteForSelect.push({
        value: s._id.toString(),
        name: s.nom,
      });
    });

    const tabPPForSelect = [];
    tabPPForSelect.push({
      value: "-1",
      name: "",
    });
    const dataPP = await getAllPersonnesPhysiques();
    dataPP.data.data.forEach((p) => {
      tabPPForSelect.push({
        value: p._id.toString(),
        name: p.nom + " " + p.prenom,
      });
    });

    const tabCompteForSelect = [];
    tabCompteForSelect.push({
      value: "-1",
      name: "",
    });

    const dataCompte = await getComptesByRacine("455");
    dataCompte.data.data
      .filter((c) => {
        return (
          c._id.toString().length >= 4 && !c._id.toString().startsWith("4558")
        );
      })
      .forEach((c) => {
        tabCompteForSelect.push({
          value: c._id.toString(),
          name: c._id.toString() + " - " + c.compteLibelle,
        });
      });

    this.setState({
      societes: datasocietes.data.data,
      tabSocieteForSelect,
      tabPPForSelect,
      tabCompteForSelect,
    });
  }

  handleNewActionnaire = (societeId) => {
    const { dataActionnaire, societes } = this.state;
    const tabCompteExclus = [];
    const tabPPExclusActionnaire = [];
    const tabPMExclusActionnaire = [];
    dataActionnaire.isPersonneMorale = true;
    dataActionnaire.isPersonnePhysique = false;
    dataActionnaire.typePersonne = "morale";
    dataActionnaire.isInterne = true;
    dataActionnaire.actionnaireId = "-1";
    const laSociete = societes.find((s) => s._id === societeId);
    laSociete.tabActionnaire
      .filter((a) => a.dateToAAAAMMJJ === null)
      .forEach((a) => {
        tabCompteExclus.push(a.compteCCId.toString());
        if (a.isPhysique === 1)
          tabPPExclusActionnaire.push(a.actionnaireId.toString());
        if (a.isMorale === 1)
          tabPMExclusActionnaire.push(a.actionnaireId.toString());
      });
    dataActionnaire.participation = 100 - laSociete.totalParticipation;
    if (laSociete.tabActionnaire.length === 0) {
      dataActionnaire.date = moment(
        laSociete.dateCreationAAAAMMJJ,
        "YYYYMMDD"
      ).format("YYYY-MM-DD");
      dataActionnaire.dateAAAAMMJJ = laSociete.dateCreationAAAAMMJJ;
    } else {
      dataActionnaire.date = "";
      dataActionnaire.dateAAAAMMJJ = "";
    }
    dataActionnaire.dateTo = "";
    dataActionnaire.dateToAAAAMMJJ = "";
    dataActionnaire.compteCCId = "-1";
    this.setState({
      newActionnaire: true,
      newActionnaireSocieteId: societeId,
      maxTotalParticipation: dataActionnaire.participation,
      tabCompteExclus,
      tabPPExclusActionnaire,
      tabPMExclusActionnaire,
    });
  };

  handleNewGerant = (societeId) => {
    const { dataGerant, societes } = this.state;
    const tabPMExclusGerant = [];
    const tabPPExclusGerant = [];
    dataGerant.isPersonneMorale = true;
    dataGerant.isPersonnePhysique = false;
    dataGerant.typePersonne = "morale";
    dataGerant.gerantId = "-1";
    const laSociete = societes.find((s) => s._id === societeId);
    laSociete.tabGerant
      .filter((g) => g.dateToAAAAMMJJ === null)
      .forEach((g) => {
        if (g.isPhysique === 1) tabPPExclusGerant.push(g.gerantId.toString());
        if (g.isMorale === 1) tabPMExclusGerant.push(g.gerantId.toString());
      });
    dataGerant.date = moment(laSociete.dateCreationAAAAMMJJ, "YYYYMMDD").format(
      "YYYY-MM-DD"
    );
    dataGerant.dateAAAAMMJJ = laSociete.dateCreationAAAAMMJJ;
    dataGerant.dateTo = "";
    dataGerant.dateToAAAAMMJJ = "";

    this.setState({
      newGerant: true,
      newGerantSocieteId: societeId,
      tabPMExclusGerant,
      tabPPExclusGerant,
    });
  };

  onSelectActionnaire = (a) => {
    const { dataActionnaire, societes, newActionnaireSocieteId } = this.state;
    if (newActionnaireSocieteId === -1) {
      const tabPPExclusActionnaire = [];
      const tabPMExclusActionnaire = [];
      const laSociete = societes.find((s) => s._id === a.societeId);
      const leActionnaire = laSociete.tabActionnaire.find(
        (x) => x._id === a._id
      );

      const tabCompteExclus = [];
      let maxTotalParticipation = 0;
      laSociete.tabActionnaire
        .filter(
          (x) => x._id !== a._id && x.dateFromAAAAMMJJ === a.dateFromAAAAMMJJ
        )
        .forEach((a) => {
          maxTotalParticipation = maxTotalParticipation + a.participation;
          tabCompteExclus.push(a.compteCCId.toString());
          if (a.isPhysique === 1)
            tabPPExclusActionnaire.push(a.actionnaireId.toString());
          if (a.isMorale === 1)
            tabPMExclusActionnaire.push(a.actionnaireId.toString());
        });

      maxTotalParticipation = 100 - maxTotalParticipation;

      dataActionnaire._id = leActionnaire._id;
      dataActionnaire.societeId = leActionnaire.societeId;
      dataActionnaire.isPersonneMorale = leActionnaire.isMorale === 1;
      dataActionnaire.isPersonnePhysique = leActionnaire.isPhysique === 1;
      dataActionnaire.typePersonne =
        leActionnaire.isMorale === 1 ? "morale" : "physique";
      dataActionnaire.isInterne = leActionnaire.isInterne === 1;
      dataActionnaire.actionnaireId = leActionnaire.actionnaireId.toString();

      dataActionnaire.participation = leActionnaire.participation;
      dataActionnaire.date = moment(
        leActionnaire.dateFromAAAAMMJJ,
        "YYYYMMDD"
      ).format("YYYY-MM-DD");
      dataActionnaire.dateAAAAMMJJ = leActionnaire.dateFromAAAAMMJJ;
      dataActionnaire.dateTo = moment(
        leActionnaire.dateToAAAAMMJJ,
        "YYYYMMDD"
      ).format("YYYY-MM-DD");
      dataActionnaire.dateToAAAAMMJJ = leActionnaire.dateToAAAAMMJJ;
      dataActionnaire.compteCCId = leActionnaire.compteCCId.toString();
      this.setState({
        newActionnaire: false,
        newActionnaireSocieteId: a.societeId,
        dataActionnaire,
        selectedActionnaireId: a.actionnaireId,
        maxTotalParticipation,
        tabCompteExclus,
        tabPPExclusActionnaire,
        tabPMExclusActionnaire,
      });
    }
  };

  onSelectGerant = (g) => {
    const { dataGerant, societes, newGerantSocieteId } = this.state;
    if (newGerantSocieteId === -1) {
      const tabPPExclusGerant = [];
      const tabPMExclusGerant = [];
      const laSociete = societes.find((s) => s._id === g.societeId);
      const leGerant = laSociete.tabGerant.find((x) => x._id === g._id);

      laSociete.tabGerant
        .filter((x) => x._id !== g._id)
        .forEach((g) => {
          if (g.isPhysique === 1) tabPPExclusGerant.push(g.gerantId.toString());
          if (g.isMorale === 1) tabPMExclusGerant.push(g.gerantId.toString());
        });

      dataGerant._id = leGerant._id;
      dataGerant.societeId = leGerant.societeId;
      dataGerant.isPersonneMorale = leGerant.isMorale === 1;
      dataGerant.isPersonnePhysique = leGerant.isPhysique === 1;
      dataGerant.typePersonne = leGerant.isMorale === 1 ? "morale" : "physique";
      dataGerant.gerantId = leGerant.gerantId.toString();

      dataGerant.date = moment(leGerant.dateFromAAAAMMJJ, "YYYYMMDD").format(
        "YYYY-MM-DD"
      );
      dataGerant.dateAAAAMMJJ = leGerant.dateFromAAAAMMJJ;
      dataGerant.dateTo = moment(leGerant.dateToAAAAMMJJ, "YYYYMMDD").format(
        "YYYY-MM-DD"
      );
      dataGerant.dateToAAAAMMJJ = leGerant.dateToAAAAMMJJ;

      this.setState({
        newAGerant: false,
        newGerantSocieteId: g.societeId,
        dataGerant,
        selectedGerantId: g.gerantId,
        tabPPExclusGerant,
        tabPMExclusGerant,
      });
    }
  };

  handleCancelNewActionnaire = () => {
    this.setState({
      newActionnaire: false,
      newActionnaireSocieteId: -1,
    });
  };

  handleCancelNewGerant = () => {
    this.setState({
      newGerant: false,
      newGerantSocieteId: -1,
    });
  };

  handleGetColor = (x) => {
    return x.dateToAAAAMMJJ !== null ? "text-danger" : "";
  };

  handleTypePersonneActionnaireChange = async (event) => {
    //console.log("handleTypePersoneChange", event.target.value);
    //this.setState({ typeSynthese: event.target.value });
  };

  handleTypeGerantActionnaireChange = async (event) => {
    //console.log("handleTypePersoneChange", event.target.value);
    //this.setState({ typeSynthese: event.target.value });
  };

  handleTypePersonneActionnaireClick = async (val) => {
    const { dataActionnaire } = this.state;

    dataActionnaire.typePersonne = val;
    if (dataActionnaire.typePersonne === "morale") {
      dataActionnaire.isPersonneMorale = true;
      dataActionnaire.isPersonnePhysique = false;
    } else {
      dataActionnaire.isPersonneMorale = false;
      dataActionnaire.isPersonnePhysique = true;
    }
    dataActionnaire.actionnaireId = "-1";
    this.setState({ dataActionnaire });
  };

  handleTypePersonneGerantClick = async (val) => {
    const { dataGerant } = this.state;

    dataGerant.typePersonne = val;
    if (dataGerant.typePersonne === "morale") {
      dataGerant.isPersonneMorale = true;
      dataGerant.isPersonnePhysique = false;
    } else {
      dataGerant.isPersonneMorale = false;
      dataGerant.isPersonnePhysique = true;
    }
    dataGerant.gerantId = "-1";
    this.setState({ dataGerant });
  };

  handlePersonneActionnaireSelect = (pm) => {
    const { dataActionnaire } = this.state;
    dataActionnaire.actionnaireId = pm;
    this.setState({ dataActionnaire });
  };

  handlePersonneGerantSelect = (pm) => {
    const { dataGerant } = this.state;
    dataGerant.gerantId = pm;
    this.setState({ dataGerant });
  };

  handleCCSelect = (cc) => {
    const { dataActionnaire } = this.state;
    dataActionnaire.compteCCId = cc;
    this.setState({ dataActionnaire });
  };

  handleIsInternePhysiqueChange = () => {
    const { dataActionnaire } = this.state;
    dataActionnaire.isInterne = !dataActionnaire.isInterne;
    this.setState({ dataActionnaire });
  };

  handleParticipationChange = async (event) => {
    const { dataActionnaire, maxTotalParticipation } = this.state;
    if (
      event.target.value >= 1 &&
      event.target.value <= maxTotalParticipation
    ) {
      dataActionnaire.participation = event.target.value;
      this.setState({ dataActionnaire });
    }
  };
  handleDateActionnaireChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      const { dataActionnaire } = this.state;
      dataActionnaire.date = moment(day).format("YYYY-MM-DD");
      dataActionnaire.dateAAAAMMJJ = moment(day).format("YYYYMMDD");
      this.setState({ dataActionnaire });
    }
  };
  handleDateToActionnaireToChange = async (event) => {
    const day = event.target.valueAsDate;
    const { dataActionnaire } = this.state;
    if (day !== null) {
      dataActionnaire.dateTo = moment(day).format("YYYY-MM-DD");
      dataActionnaire.dateToAAAAMMJJ = moment(day).format("YYYYMMDD");
    } else {
      dataActionnaire.dateTo = "";
      dataActionnaire.dateToAAAAMMJJ = "";
    }
    this.setState({ dataActionnaire });
  };

  handleDateGerantChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      const { dataGerant } = this.state;
      dataGerant.date = moment(day).format("YYYY-MM-DD");
      dataGerant.dateAAAAMMJJ = moment(day).format("YYYYMMDD");
      this.setState({ dataGerant });
    }
  };

  handleDateToGerantChange = async (event) => {
    const day = event.target.valueAsDate;
    const { dataGerant } = this.state;
    if (day !== null) {
      dataGerant.dateTo = moment(day).format("YYYY-MM-DD");
      dataGerant.dateToAAAAMMJJ = moment(day).format("YYYYMMDD");
    } else {
      dataGerant.dateTo = "";
      dataGerant.dateToAAAAMMJJ = "";
    }
    this.setState({ dataGerant });
  };

  handleSubmitActionnaire = async () => {
    const { newActionnaire, newActionnaireSocieteId } = this.state;
    if (newActionnaire && newActionnaireSocieteId !== -1) {
      await this.traiteNewActionnaire();
    } else {
      if (!newActionnaire && newActionnaireSocieteId !== -1) {
        await this.traiteUpdateActionnaire();
      }
    }
  };

  traiteNewActionnaire = async () => {
    const { dataActionnaire, newActionnaireSocieteId, societes } = this.state;
    const newDataSociete = await addActionnaire({
      societeId: newActionnaireSocieteId,
      dataActionnaire,
    });
    if (newDataSociete.data.success) {
      const laSociete = societes.find((s) => s._id === newActionnaireSocieteId);
      laSociete.tabActionnaire = newDataSociete.data.data;
      laSociete.totalParticipation = 0;
      laSociete.totalParticipationInterne = 0;
      laSociete.tabActionnaire
        .filter((a) => a.dateToAAAAMMJJ === null)
        .forEach((a) => {
          laSociete.totalParticipation =
            laSociete.totalParticipation + a.participation;
          if (a.isInterne === 1)
            laSociete.totalParticipationInterne =
              laSociete.totalParticipationInterne + a.participation;
        });
      this.setState({
        newActionnaire: false,
        newActionnaireSocieteId: -1,
        societes,
      });
    } else {
      toast.error(newDataSociete.data.message);
    }
  };

  traiteUpdateActionnaire = async () => {
    const { dataActionnaire, newActionnaireSocieteId, societes } = this.state;
    const newUpdateSociete = await updateActionnaire({
      id: dataActionnaire._id,
      dataActionnaire,
    });
    if (newUpdateSociete.data.success) {
      const laSociete = societes.find((s) => s._id === newActionnaireSocieteId);
      laSociete.tabActionnaire = newUpdateSociete.data.data;
      laSociete.totalParticipation = 0;
      laSociete.totalParticipationInterne = 0;
      laSociete.tabActionnaire
        .filter((a) => a.dateToAAAAMMJJ === null)
        .forEach((a) => {
          laSociete.totalParticipation =
            laSociete.totalParticipation + a.participation;
          if (a.isInterne === 1)
            laSociete.totalParticipationInterne =
              laSociete.totalParticipationInterne + a.participation;
        });
      this.setState({
        newActionnaire: false,
        newActionnaireSocieteId: -1,
        societes,
      });
    } else {
      toast.error(newUpdateSociete.data.message);
    }
  };

  handleSubmitGerant = async () => {
    const { newGerant, newGerantSocieteId } = this.state;
    if (newGerant && newGerantSocieteId !== -1) {
      await this.traiteNewGerant();
    } else {
      if (!newGerant && newGerantSocieteId !== -1) {
        await this.traiteUpdateGerant();
      }
    }
  };

  traiteNewGerant = async () => {
    const { dataGerant, newGerantSocieteId, societes } = this.state;
    const newDataGerant = await addGerant({
      societeId: newGerantSocieteId,
      dataGerant,
    });
    if (newDataGerant.data.success) {
      const laSociete = societes.find((s) => s._id === newGerantSocieteId);
      laSociete.tabGerant = newDataGerant.data.data;
      this.setState({
        newGerant: false,
        newGerantSocieteId: -1,
        societes,
      });
    } else {
      toast.error(newDataGerant.data.message);
    }
  };

  traiteUpdateGerant = async () => {
    const { dataGerant, newGerantSocieteId, societes } = this.state;
    const newUpdateSociete = await updateGerant({
      id: dataGerant._id,
      dataGerant,
    });
    if (newUpdateSociete.data.success) {
      const laSociete = societes.find((s) => s._id === newGerantSocieteId);
      laSociete.tabGerant = newUpdateSociete.data.data;
      this.setState({
        newGerant: false,
        newGerantSocieteId: -1,
        societes,
      });
    } else {
      toast.error(newUpdateSociete.data.message);
    }
  };

  handleSupprActionnaire = async (societeId, a) => {
    const { societes } = this.state;
    confirmAlert({
      title: "Suppression de l'actionnaire : " + a.actionnaireNom,
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            await deleteActionnaire(a._id);
            const laSociete = societes.find((s) => s._id === societeId);
            if (a.dateToAAAAMMJJ === null) {
              laSociete.totalParticipation =
                laSociete.totalParticipation - a.participation;
              if (a.isInterne === 1)
                laSociete.totalParticipationInterne =
                  laSociete.totalParticipationInterne - a.participation;
            }
            if (laSociete) {
              laSociete.tabActionnaire = laSociete.tabActionnaire.filter(
                (x) => x._id !== a._id
              );
            }
            this.setState({ societes });
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  handleSupprGerant = async (societeId, g) => {
    const { societes } = this.state;
    confirmAlert({
      title: "Suppression du gérant : " + g.gerantNom,
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            await deleteGerant(g._id);
            const laSociete = societes.find((s) => s._id === societeId);
            if (laSociete) {
              laSociete.tabGerant = laSociete.tabGerant.filter(
                (x) => x._id !== g._id
              );
            }
            this.setState({ societes });
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const {
      sortColumn,
      societes,
      newActionnaire,
      selectedActionnaireId,
      newActionnaireSocieteId,
      dataActionnaire,
      tabSocieteForSelect,
      tabPPForSelect,
      maxTotalParticipation,
      tabCompteForSelect,
      tabCompteExclus,
      tabPPExclusActionnaire,
      tabPMExclusActionnaire,
      newGerant,
      newGerantSocieteId,
      dataGerant,
      tabPMExclusGerant,
      tabPPExclusGerant,
    } = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <BarreMenu
            type_menu={typeMenuParametresSocietes}
            menu_actif={menuActionnairesAndGerants}
          ></BarreMenu>

          {societes && (
            <ListeSocieteActionnairesGerants
              societes={societes}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              handleSupprActionnaire={this.handleSupprActionnaire}
              handleNewActionnaire={this.handleNewActionnaire}
              newActionnaire={newActionnaire}
              selectedActionnaireId={selectedActionnaireId}
              newActionnaireSocieteId={newActionnaireSocieteId}
              dataActionnaire={dataActionnaire}
              handleTypePersonneActionnaireChange={
                this.handleTypePersonneActionnaireChange
              }
              handleTypePersonneActionnaireClick={
                this.handleTypePersonneActionnaireClick
              }
              tabSocieteForSelect={tabSocieteForSelect}
              tabPMExclusActionnaire={tabPMExclusActionnaire}
              handlePersonneActionnaireSelect={
                this.handlePersonneActionnaireSelect
              }
              tabPPForSelect={tabPPForSelect}
              tabPPExclusActionnaire={tabPPExclusActionnaire}
              handleIsInternePhysiqueChange={this.handleIsInternePhysiqueChange}
              maxTotalParticipation={maxTotalParticipation}
              handleParticipationChange={this.handleParticipationChange}
              handleDateActionnaireChange={this.handleDateActionnaireChange}
              handleDateToActionnaireToChange={
                this.handleDateToActionnaireToChange
              }
              tabCompteForSelect={tabCompteForSelect}
              tabCompteExclus={tabCompteExclus}
              handleCCSelect={this.handleCCSelect}
              handleCancelNewActionnaire={this.handleCancelNewActionnaire}
              handleSubmitActionnaire={this.handleSubmitActionnaire}
              onSelectActionnaire={this.onSelectActionnaire}
              handleSupprGerant={this.handleSupprGerant}
              handleNewGerant={this.handleNewGerant}
              newGerant={newGerant}
              newGerantSocieteId={newGerantSocieteId}
              handleCancelNewGerant={this.handleCancelNewGerant}
              dataGerant={dataGerant}
              handleTypeGerantActionnaireChange={
                this.handleTypeGerantActionnaireChange
              }
              handleTypePersonneGerantClick={this.handleTypePersonneGerantClick}
              tabPMExclusGerant={tabPMExclusGerant}
              handlePersonneGerantSelect={this.handlePersonneGerantSelect}
              tabPPExclusGerant={tabPPExclusGerant}
              handleDateGerantChange={this.handleDateGerantChange}
              handleDateToGerantChange={this.handleDateToGerantChange}
              handleSubmitGerant={this.handleSubmitGerant}
              onSelectGerant={this.onSelectGerant}
              handleGetColor={this.handleGetColor}
            />
          )}
        </div>
      </div>
    );
  }
}

export default SocietesActionnairesGerants;
