import React, { Component } from "react";

import SelectSearch from "react-select-search";

import {
  getAllPersonnesPhysiques,
  addPersonnePhysique,
  updatePersonnePhysique,
} from "../../services/actionnariatService";
import { getTiersLightWithNomPrenom } from "../../services/tiersService";
import BarreMenu from "../common/barreMenu";
import ListePersonnePhysique from "./listePersonnePhysique";
import {
  menuPersonnesPhysiques,
  typeMenuParametresSocietes,
} from "../../utils/menuParametres";

class PersonnesPhysiques extends Component {
  state = {
    personnes: [],
    sortColumn: { path: "_id", order: "asc" },
    newPersonne: false,
    dataPersonne: {
      nom: "",
      prenom: "",
      nomNaissance: "",
      sexe: "H",
    },
    tabClientForSelect: [{ value: "0", name: "sans référence client" }],
    selectedPersonneId: -1,
  };

  async componentDidMount() {
    const data = await getAllPersonnesPhysiques();
    const tabClientForSelect = [];
    const dataClient = await getTiersLightWithNomPrenom();
    tabClientForSelect.push({ value: "0", name: "sans référence client" });
    dataClient.data.data.forEach((item) => {
      tabClientForSelect.push({
        value: item._id.toString(),
        name: item._id + " - " + item.nom + " " + item.prenom,
        nom: item.nom,
        prenom: item.prenom,
        sexe: item.sexe,
      });
    });
    this.setState({ personnes: data.data.data, tabClientForSelect });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleNewPersonne = () => {
    const { newPersonne, dataPersonne } = this.state;
    dataPersonne.nom = "";
    dataPersonne.prenom = "";
    dataPersonne.sexe = "H";
    dataPersonne.clientId = "0";
    this.setState({
      newPersonne: !newPersonne,
      dataPersonne,
    });
  };

  handleCancelNewPersonne = () => {
    this.setState({
      newPersonne: false,
      selectedPersonneId: -1,
    });
  };

  handleNomChange = async (event) => {
    const { dataPersonne } = this.state;
    dataPersonne.nom = event.target.value;
    this.setState({
      dataPersonne,
    });
  };

  handlePrenomChange = async (event) => {
    const { dataPersonne } = this.state;
    dataPersonne.prenom = event.target.value;
    this.setState({
      dataPersonne,
    });
  };

  handleSexeChange = (event) => {
    const { dataPersonne } = this.state;
    dataPersonne.sexe = event.target.value;
    this.setState({ dataPersonne });
  };

  handleClientSelect = (client) => {
    const { dataPersonne, tabClientForSelect } = this.state;
    dataPersonne.clientId = client;
    if (client !== "0") {
      const leClient = tabClientForSelect.find((x) => x.value === client);
      if (leClient) {
        dataPersonne.nom = leClient.nom;
        dataPersonne.prenom = leClient.prenom;
        if (leClient.sexe === "H" || leClient.sexe === "F")
          dataPersonne.sexe = leClient.sexe;
      }
    }
    this.setState({ dataPersonne });
  };

  onSelectPersonne = (personne) => {
    const { selectedPersonneId, newPersonne } = this.state;
    if (selectedPersonneId === -1 && !newPersonne) {
      const dataPersonne = {};
      dataPersonne._id = personne._id;
      dataPersonne.nom = personne.nom;
      dataPersonne.prenom = personne.prenom;
      dataPersonne.sexe = personne.sexe;
      dataPersonne.clientId = personne.clientId.toString();
      this.setState({
        selectedPersonneId: personne._id,
        dataPersonne,
      });
    }
  };

  handleSubmitPersonne = async () => {
    const { selectedPersonneId, newPersonne } = this.state;
    if (newPersonne && selectedPersonneId === -1) {
      await this.traiteNewPersonne();
    } else {
      if (!newPersonne && selectedPersonneId !== -1) {
        await this.traiteUpdatePersonne();
      }
    }
  };

  traiteNewPersonne = async () => {
    const { newPersonne, dataPersonne } = this.state;
    await addPersonnePhysique({
      dataPersonne,
    });
    const data = await getAllPersonnesPhysiques();
    this.setState({
      newPersonne: !newPersonne,
      personnes: data.data.data,
    });
  };

  traiteUpdatePersonne = async () => {
    const { selectedPersonneId, dataPersonne } = this.state;
    await updatePersonnePhysique({
      personneId: selectedPersonneId,
      dataPersonne,
    });
    const data = await getAllPersonnesPhysiques();
    this.setState({
      newPersonne: false,
      personnes: data.data.data,
      selectedPersonneId: -1,
    });
  };

  render() {
    const {
      sortColumn,
      newPersonne,
      dataPersonne,
      selectedPersonneId,
      personnes,
      tabClientForSelect,
    } = this.state;

    const sexes = [
      { _id: "H", Label: "Homme" },
      { _id: "F", Label: "Femme" },
    ];

    return (
      <React.Fragment>
        <BarreMenu
          type_menu={typeMenuParametresSocietes}
          menu_actif={menuPersonnesPhysiques}
        ></BarreMenu>
        <div className="row">
          <div className="col-12">
            {!newPersonne && selectedPersonneId === -1 && (
              <button
                onClick={this.handleNewPersonne}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newPersonne}
              >
                Nouvelle personne physique
              </button>
            )}
            <div>
              {(newPersonne || selectedPersonneId !== -1) && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newPersonne
                      ? "Nouvelle personne"
                      : "Modification de la personne Id " + dataPersonne._id}
                  </h5>
                  <div className="ml-2 mr-2">
                    <div className="form-group">
                      <label htmlFor="client" className="font-weight-bold">
                        Client
                      </label>
                      <SelectSearch
                        options={tabClientForSelect}
                        value={dataPersonne.clientId}
                        name="Client"
                        search={true}
                        onChange={this.handleClientSelect}
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="nom">
                        Nom
                      </label>
                      <input
                        type="text"
                        value={dataPersonne.nom}
                        onChange={(event) => this.handleNomChange(event)}
                        className="form-control"
                        placeholder="Nom"
                      ></input>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="prenom">
                        Prénom
                      </label>
                      <input
                        type="text"
                        value={dataPersonne.prenom}
                        onChange={(event) => this.handlePrenomChange(event)}
                        className="form-control"
                        placeholder="Prénom"
                      ></input>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="sexe">
                        Sexe
                      </label>
                      <select
                        name="sexe"
                        id="sexe"
                        value={dataPersonne.sexe}
                        onChange={this.handleSexeChange}
                        className="form-control"
                        style={{ width: "150px" }}
                      >
                        {sexes.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.Label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitPersonne}
                      disabled={dataPersonne.nom.length === 0}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewPersonne}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>

            {personnes && (
              <ListePersonnePhysique
                personnes={personnes}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onSelectPersonne={this.onSelectPersonne}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PersonnesPhysiques;
