import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";
import {
  urlApiGeneral,
  urlApiComptabilite,
  API_CLIENT_ID,
  API_CLIENT_SECRET,
} from "../config.js";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
    toast.error("An unexpected error occurred." + error);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  if (
    config.url.indexOf(urlApiGeneral) === 0 ||
    config.url.indexOf(urlApiComptabilite) === 0
  ) {
    config.headers["client_secret"] = API_CLIENT_SECRET;
    config.headers["client_id"] = API_CLIENT_ID;
  }
  return config;
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

// eslint-disable-next-line
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
