import React, { Component } from "react";
import SelectSearch from "react-select-search";
import NumberFormat from "react-number-format";
import _ from "lodash";
import moment from "moment";

import SearchBox from "../common/searchBox";

import {
  getFullAllBanqueCompte,
  getBanqueCompteBySocieteIdJournalCode,
  getBanqueCompteBySocieteIdCompteNum,
  addBanqueCompte,
  updateBanqueCompte,
} from "../../services/paiementService";
import { getPizzerias } from "../../services/pizzeriaService";
import { getAllContactList } from "../../services/contactService";

import BarreMenu from "../common/barreMenu";
import ListeComptesBanque from "./listeComptesBanque";
import {
  typeMenuParametresBanque,
  menuComptesBanque,
} from "../../utils/menuParametres";
import { replaceAll } from "../../utils/tools";

import { getPrefixeUrlHlpBackMenu, makeId } from "../../utils/tools";

class ComptesBanque extends Component {
  state = {
    searchQuery: "",
    comptes: [],
    societesForSelect: [],
    tabContactForSelect: [],
    sortColumn: { path: "societeDenomination", order: "asc" },
    newCompte: false,
    dataCompte: {
      _id: -1,
      societeId: "-1",
      contactId: "-1",
      docRibId: null,
      isCompteCourant: true,
      banque: "",
      numCompteBancaire: "",
      format: "",
      dateMinFormat: "",
      dateMinFormatAAAAMMJJ: "",
      journalCode: "",
      compteNum: "",
    },
    selectedCompte: -1,
    formatTable: [
      { value: "", name: "" },
      { value: "CSV", name: "CSV" },
      { value: "XLSX", name: "XSLSX" },
    ],
    texteAideCodeJournal:
      "Lors de l'export FEC, les écritures liées à un compte de  banque sont affectées au code journal de la banque. Le compte de banque est celui indiqué comme n° de compte FEC.",
    urlVisuDoc: "",
    errorSocieteJournalCode: false,
    errorSocieteCompteNum: false,
  };

  async componentDidMount() {
    const dataComptes = await getFullAllBanqueCompte();
    let urlVisuDoc = await getPrefixeUrlHlpBackMenu(49);
    const result = await getPizzerias();
    const societesForSelect = [];
    societesForSelect.push({ value: "-1", name: "" });
    result.data.data.forEach((item) => {
      societesForSelect.push({
        value: item._id.toString(),
        name: item.denomination,
      });
    });
    const tabContactForSelect = [];
    tabContactForSelect.push({ value: "-1", name: "" });
    const dataContact = await getAllContactList();
    dataContact.data.data.forEach((x) => {
      tabContactForSelect.push({
        value: x._id.toString(),
        name: x._id.toString() + " " + x.nom,
      });
    });

    this.setState({
      comptes: dataComptes.data.data,
      urlVisuDoc,
      societesForSelect,
      tabContactForSelect,
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
    });
  };

  getPagedData = () => {
    const { searchQuery, comptes, sortColumn } = this.state;

    let filtered = comptes;
    if (searchQuery)
      filtered = filtered.filter(
        (c) =>
          (c.numCompteBancaire &&
            c.numCompteBancaire
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (c.banque &&
            c.banque.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (c._id && c._id.toString().includes(searchQuery))
      );
    const sorted = _.orderBy(
      filtered,
      [sortColumn.path, "banque"],
      [sortColumn.order, sortColumn.order]
    );
    return { totalCount: filtered.length, data: sorted };
  };

  handleNewCompte = () => {
    const { newCompte, dataCompte } = this.state;
    dataCompte._id = -1;
    dataCompte.societeId = "-1";
    dataCompte.contactId = "-1";
    dataCompte.docRibId = null;
    dataCompte.banque = "";
    dataCompte.numCompteBancaire = "";
    dataCompte.isCompteCourant = true;
    dataCompte.format = "";
    dataCompte.dateMinFormat = "";
    dataCompte.dateMinFormatAAAAMMJJ = "";
    dataCompte.journalCode = "";
    dataCompte.compteNum = "";
    this.setState({
      newCompte: !newCompte,
      dataCompte,
      errorSocieteJournalCode: false,
      errorSocieteCompteNum: false,
    });
  };

  handleCancelNewCompte = () => {
    const { newCompte, selectedCompte } = this.state;
    if (!newCompte && selectedCompte !== -1) {
      const releventDiv = document.getElementById("compte_" + selectedCompte);
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }

    this.setState({
      newCompte: false,
      selectedCompte: -1,
    });
  };

  handleDocRibIdChange = (event) => {
    let { dataCompte } = this.state;
    dataCompte.docRibId = event.target.value;
    this.setState({ dataCompte });
  };

  handleSocieteSelect = async (societeId) => {
    const { newCompte, dataCompte } = this.state;
    let errorSocieteJournalCode = false;
    let errorSocieteCompteNum = false;
    dataCompte.societeId = societeId;
    if (parseInt(societeId) > 0 && dataCompte.journalCode.length !== 0) {
      const dataExist = await getBanqueCompteBySocieteIdJournalCode(
        societeId,
        dataCompte.journalCode
      );
      if (newCompte && dataExist.data.success) errorSocieteJournalCode = true;
      if (
        !newCompte &&
        dataExist.data.success &&
        dataExist.data.data._id !== dataCompte._id
      )
        errorSocieteJournalCode = true;
    }
    if (
      parseInt(societeId) > 0 &&
      dataCompte.compteNum.toString().length !== 0
    ) {
      const dataExist = await getBanqueCompteBySocieteIdCompteNum(
        societeId,
        dataCompte.compteNum
      );
      if (newCompte && dataExist.data.success) errorSocieteCompteNum = true;
      if (
        !newCompte &&
        dataExist.data.success &&
        dataExist.data.data._id !== dataCompte._id
      )
        errorSocieteCompteNum = true;
    }
    this.setState({
      dataCompte,
      errorSocieteJournalCode,
      errorSocieteCompteNum,
    });
  };

  handleContactSelect = (methode) => {
    const { dataCompte } = this.state;
    dataCompte.contactId = methode;
    this.setState({ dataCompte });
  };

  handleBanqueChange = async (event) => {
    const { dataCompte } = this.state;
    dataCompte.banque = event.target.value.trim().toUpperCase();
    this.setState({
      dataCompte,
    });
  };

  handleNumCompteBancaireChange = async (event) => {
    const { dataCompte } = this.state;
    dataCompte.numCompteBancaire = event.target.value.trim();
    this.setState({
      dataCompte,
    });
  };

  handleJournalCodeChange = async (event) => {
    const { newCompte, dataCompte } = this.state;
    dataCompte.journalCode = event.target.value.trim().toUpperCase();
    let errorSocieteJournalCode = false;
    if (
      parseInt(dataCompte.societeId) > 0 &&
      dataCompte.journalCode.length !== 0
    ) {
      const dataExist = await getBanqueCompteBySocieteIdJournalCode(
        dataCompte.societeId,
        dataCompte.journalCode
      );
      if (newCompte && dataExist.data.success) errorSocieteJournalCode = true;
      if (
        !newCompte &&
        dataExist.data.success &&
        dataExist.data.data._id !== dataCompte._id
      )
        errorSocieteJournalCode = true;
    }
    this.setState({
      dataCompte,
      errorSocieteJournalCode,
    });
  };

  handleIsCompteCourantChange = () => {
    const { dataCompte } = this.state;
    dataCompte.isCompteCourant = !dataCompte.isCompteCourant;
    this.setState({ dataCompte });
  };

  handleFormatTableSelect = (format) => {
    const { dataCompte } = this.state;
    dataCompte.format = format;
    this.setState({ dataCompte });
  };

  handleDateMinFormatChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      const { dataCompte } = this.state;
      dataCompte.dateMinFormat = moment(day).format("YYYY-MM-DD");
      dataCompte.dateMinFormatAAAAMMJJ = moment(day).format("YYYYMMDD");
      this.setState({ dataCompte });
    }
  };

  handlecompteNumChange = async (event) => {
    const { newCompte, dataCompte } = this.state;
    let errorSocieteCompteNum = false;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    const stringValue = formattedValue.toString();
    if (stringValue.length !== 0 && stringValue.length < 9) {
      dataCompte.compteNum = Number(formattedValue).toString();
      if (
        parseInt(dataCompte.societeId) > 0 &&
        dataCompte.compteNum.toString().length !== 0
      ) {
        const dataExist = await getBanqueCompteBySocieteIdCompteNum(
          parseInt(dataCompte.societeId),
          dataCompte.compteNum
        );
        if (newCompte && dataExist.data.success) errorSocieteCompteNum = true;
        if (
          !newCompte &&
          dataExist.data.success &&
          dataExist.data.data._id !== dataCompte._id
        )
          errorSocieteCompteNum = true;
      }
    } else {
      dataCompte.compteNum = "";
    }
    this.setState({ dataCompte, errorSocieteCompteNum });
  };

  onSelectCompte = (compte) => {
    const { selectedCompte, newCompte } = this.state;
    if (selectedCompte === -1 && !newCompte) {
      const dataCompte = {};
      dataCompte._id = compte._id;
      dataCompte.societeId = compte.societeId.toString();
      dataCompte.contactId = compte.contactId.toString();
      dataCompte.docRibId = compte.docRibId;
      dataCompte.banque = compte.banque;
      dataCompte.numCompteBancaire = compte.numCompteBancaire;
      dataCompte.isCompteCourant = compte.isCompteCourant;
      dataCompte.format = compte.format;
      dataCompte.dateMinFormat = moment(
        compte.dateMinFormatAAAAMMJJ,
        "YYYYMMDD"
      ).format("YYYY-MM-DD");
      dataCompte.dateMinFormatAAAAMMJJ = compte.dateMinFormatAAAAMMJJ;
      dataCompte.journalCode = compte.journalCode;
      dataCompte.compteNum = compte.compteNum;
      this.setState({
        selectedCompte: compte._id,
        dataCompte,
        errorSocieteJournalCode: false,
        errorSocieteCompteNum: false,
      });

      const releventDiv = document.getElementById("top-id");
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
  };

  handleSubmitCompte = async () => {
    const { selectedCompte, newCompte } = this.state;
    if (newCompte && selectedCompte === -1) {
      await this.traiteNewCompte();
    } else {
      if (!newCompte && selectedCompte !== -1) {
        await this.traiteUpdateCompte();
      }
    }
  };

  traiteNewCompte = async () => {
    const { dataCompte } = this.state;
    const retAdd = await addBanqueCompte(dataCompte);
    const dataComptes = await getFullAllBanqueCompte();
    this.setState({
      comptes: dataComptes.data.data,
      newCompte: false,
    });
    if (
      retAdd.data.success &&
      retAdd.data.data.insertId &&
      retAdd.data.data.insertId !== 0
    ) {
      const releventDiv = document.getElementById(
        "compte_" + retAdd.data.data.insertId
      );
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
  };

  traiteUpdateCompte = async () => {
    const { selectedCompte, dataCompte } = this.state;
    await updateBanqueCompte(selectedCompte, dataCompte);
    const dataComptes = await getFullAllBanqueCompte();

    const releventDiv = document.getElementById("compte_" + selectedCompte);
    if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    this.setState({
      comptes: dataComptes.data.data,
      newCompte: false,
      selectedCompte: -1,
    });
  };

  render() {
    const {
      sortColumn,
      newCompte,
      dataCompte,
      selectedCompte,
      formatTable,
      searchQuery,
      texteAideCodeJournal,
      urlVisuDoc,
      societesForSelect,
      tabContactForSelect,
      errorSocieteJournalCode,
      errorSocieteCompteNum,
    } = this.state;

    const { totalCount, data } = this.getPagedData();

    return (
      <React.Fragment>
        <span id="top-id"></span>
        <BarreMenu
          type_menu={typeMenuParametresBanque}
          menu_actif={menuComptesBanque}
        ></BarreMenu>
        <div className="row">
          <div className="col-12">
            {!newCompte && selectedCompte === -1 && (
              <button
                onClick={this.handleNewCompte}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newCompte}
              >
                Nouveau compte
              </button>
            )}
            <div>
              {(newCompte || selectedCompte !== -1) && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newCompte
                      ? "Nouveau compte"
                      : "Modification du compte Id " + dataCompte._id}
                  </h5>
                  <div className="ml-2 mr-2" style={{ fontSize: "14px" }}>
                    <div className="form-group row mb-0">
                      <div className="col-3">
                        <label className="font-weight-bold">Document RIB</label>
                        <div className="input-group mb-3">
                          <NumberFormat
                            className="form-control"
                            id="Document"
                            autoComplete={makeId()}
                            name={"Document" + makeId()}
                            value={dataCompte.docRibId}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=""
                            allowNegative={false}
                            onChange={(e) => {
                              this.handleDocRibIdChange(e);
                            }}
                            placeholder="Document RIB"
                            style={{ fontSize: "14px" }}
                          />
                          <div className="input-group-append">
                            {urlVisuDoc &&
                              urlVisuDoc.length !== 0 &&
                              dataCompte.docRibId &&
                              dataCompte.docRibId !== 0 && (
                                <a
                                  className="input-group-text"
                                  href={
                                    urlVisuDoc +
                                    "documentfile/" +
                                    dataCompte.docRibId
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "14px",
                                  }}
                                >
                                  Voir
                                </a>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <label className="font-weight-bold" htmlFor="societeId">
                          Société
                        </label>
                        <SelectSearch
                          options={societesForSelect}
                          value={dataCompte.societeId}
                          name="societeId"
                          search={true}
                          onChange={this.handleSocieteSelect}
                          style={{ fontSize: "14px" }}
                        />
                        {errorSocieteJournalCode && (
                          <div className="alert alert-danger mt-2">
                            Le code journal est déjà affecté à la société
                          </div>
                        )}
                        {errorSocieteCompteNum && (
                          <div className="alert alert-danger mt-2">
                            Le n° de compte FEC est déjà affecté à la société
                          </div>
                        )}
                      </div>
                      <div className="col-3">
                        <label className="font-weight-bold" htmlFor="contactId">
                          Contact
                        </label>
                        <SelectSearch
                          options={tabContactForSelect}
                          value={dataCompte.contactId}
                          name="contactId"
                          search={true}
                          onChange={this.handleContactSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                      <div className="col-3">
                        <label className="font-weight-bold" htmlFor="banque">
                          Id Banque
                        </label>
                        <input
                          type="text"
                          value={dataCompte.banque}
                          onChange={(event) => this.handleBanqueChange(event)}
                          className="form-control"
                          placeholder="Banque"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row mt-0">
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="numCompteBancaire"
                        >
                          Compte Bancaire
                        </label>
                        <input
                          type="text"
                          value={dataCompte.numCompteBancaire}
                          onChange={(event) =>
                            this.handleNumCompteBancaireChange(event)
                          }
                          className="form-control"
                          placeholder="N° de compte"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isCompteCourant"
                            checked={dataCompte.isCompteCourant}
                            onChange={this.handleIsCompteCourantChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isCompteCourant"
                          >
                            Compte courant
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="formatTable"
                        >
                          Format des relevés de compte
                        </label>
                        <SelectSearch
                          options={formatTable}
                          value={dataCompte.format}
                          name="formatTable"
                          search={false}
                          onChange={this.handleFormatTableSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                      <div className="col-3">
                        <label className="font-weight-bold" htmlFor="nom">
                          Date d'application du format
                        </label>
                        <input
                          type="date"
                          value={dataCompte.dateMinFormat}
                          onChange={(date) =>
                            this.handleDateMinFormatChange(date)
                          }
                          className="form-control"
                          style={{ fontSize: "14px", width: "150px" }}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="journalCode"
                        >
                          Code journal FEC
                          <i className="fas fa-info-circle ml-1"></i>
                        </label>
                        <input
                          type="text"
                          value={dataCompte.journalCode}
                          onChange={(event) =>
                            this.handleJournalCodeChange(event)
                          }
                          className="form-control"
                          placeholder="Code journal"
                          style={{ fontSize: "14px" }}
                        ></input>
                        {errorSocieteJournalCode && (
                          <div className="alert alert-danger mt-2">
                            Le code journal est déjà affecté à la société
                          </div>
                        )}
                      </div>
                      <div className="col-3">
                        <label className="font-weight-bold" htmlFor="compteNum">
                          Numéro de compte FEC
                          <i className="fas fa-info-circle ml-1"></i>
                        </label>
                        <NumberFormat
                          className="form-control text-right"
                          value={dataCompte.compteNum}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator={false}
                          allowNegative={false}
                          onChange={(e, value) => {
                            this.handlecompteNumChange(e, value);
                          }}
                          style={{ width: "150px", fontSize: "14px" }}
                          placeholder={"N° de compte FEC"}
                        />
                        {errorSocieteCompteNum && (
                          <div className="alert alert-danger mt-2">
                            Le n° de compte FEC est déjà affecté à la société
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="alert alert-warning">
                      <i className="fas fa-info-circle mr-2"></i>
                      {texteAideCodeJournal}
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitCompte}
                      disabled={
                        dataCompte.societeId === "-1" ||
                        dataCompte.contactId === "-1" ||
                        dataCompte.banque.length === 0 ||
                        dataCompte.numCompteBancaire.length === 0 ||
                        dataCompte.journalCode.length === 0 ||
                        dataCompte.compteNum.length !== 8 ||
                        errorSocieteJournalCode ||
                        errorSocieteCompteNum
                      }
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewCompte}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>

            <p className="w-100">{totalCount} comptes de banque</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />

            {!(newCompte || selectedCompte !== -1) && (
              <div className="alert alert-warning" style={{ fontSize: "14px" }}>
                {"(*) " + texteAideCodeJournal}
              </div>
            )}

            {data && (
              <ListeComptesBanque
                comptes={data}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onSelectCompte={this.onSelectCompte}
                texteAideCodeJournal={texteAideCodeJournal}
                urlVisuDoc={urlVisuDoc}
              />
            )}
            <span id="bottom-id"></span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ComptesBanque;
