import React, { Component } from "react";
import SelectSearch from "react-select-search";
import NumberFormat from "react-number-format";

import { calculeValeurEncaissement } from "../services/paiementService";

class ValeurEncaissement extends Component {
  state = {
    annees: [],
    selectedAnnee: null,
    dataSociete: {},
    traitEnCours: false,

    calculFait: false,
    traitFait: false,
    tabTrait: [],
  };
  async componentDidMount() {
    let annees = [];
    const annee = new Date().getFullYear();
    for (let index = annee; index >= 2019; index--) {
      annees.push({
        value: index.toString(),
        name: index.toString(),
      });
    }

    this.setState({
      annees,
      selectedAnnee: annees[0].value,
    });
  }

  handleAnneeSelect = (annee) => {
    this.setState({
      selectedAnnee: annee,
      calculFait: false,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    const { traitEnCours, selectedAnnee } = this.state;
    if (!traitEnCours) {
      this.setState({ traitEnCours: true });

      const data = await calculeValeurEncaissement(selectedAnnee);

      data.data.data.map((x) => {
        x.modesPaiement.map((m) => {
          m.collapse = false;
          return true;
        });
        return true;
      });
      console.log("data.data.data", data.data.data);

      this.setState({
        traitEnCours: false,
        dataSociete: data.data.data,
        calculFait: true,
      });
    }
  };

  handleClickCollapse = (i, j) => {
    let { dataSociete } = this.state;
    const laSociete = dataSociete[i];
    const leMode = laSociete.modesPaiement[j];
    leMode.collapse = !leMode.collapse;
    this.setState({ dataSociete });
  };

  render() {
    const { annees, selectedAnnee, dataSociete, traitEnCours, calculFait } =
      this.state;

    const anneeN_1 = parseInt(selectedAnnee) + 1;
    return (
      <React.Fragment>
        <h2>Valeurs à l'encaissement</h2>
        <div className="row">
          <div className="col-2">
            <div>
              <div>
                <label className="font-weight-bold" htmlFor="annee">
                  Année
                </label>
              </div>
              <SelectSearch
                options={annees}
                value={selectedAnnee}
                name="annee"
                onChange={this.handleAnneeSelect}
              />
            </div>

            <div className="mt-4">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderClick}
              >
                Calculer
              </button>
              {traitEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {calculFait && !traitEnCours && (
            <div className="col-10">
              <table
                className="table table-bordered table-striped mt-4"
                style={{ fontSize: "12px" }}
              >
                <tbody>
                  {dataSociete.map((societe, index) => (
                    <tr className="mr-0 ml-0" key={societe.societeId}>
                      <td
                        className="font-weight-bold text-nowrap"
                        style={{ width: "200px" }}
                      >
                        <div>{societe.societeDenomination}</div>
                        {societe.charges !== 0 && (
                          <div className="text-danger">
                            IL MANQUE{" "}
                            <NumberFormat
                              value={societe.charges}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                              suffix=" €"
                            />
                          </div>
                        )}
                        {societe.produits !== 0 && (
                          <div className="text-warning">
                            IL Y A EN TROP{" "}
                            <NumberFormat
                              value={societe.produits}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                              suffix=" €"
                            />
                          </div>
                        )}
                      </td>
                      {societe.modesPaiement.map((p, indexMode) => (
                        <td className="w-auto">
                          <div
                            className={
                              "font-weight-bold text-center " +
                              (p.caisseDeposeeN_1 !== p.soldeCpta
                                ? "text-danger"
                                : "")
                            }
                            style={{ fontSize: "14px" }}
                          >
                            {p.abreviation} - Compte {p.compteId}
                          </div>
                          <div>
                            <span>Somme déposée en {anneeN_1}</span>
                            <span className="float-right">
                              <NumberFormat
                                value={p.caisseDeposeeN_1}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                                suffix=" €"
                              />
                            </span>
                          </div>
                          <div>
                            <span>Solde en compta</span>
                            <span className="float-right">
                              <NumberFormat
                                value={p.soldeCpta}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                                suffix=" €"
                              />
                            </span>
                          </div>
                          {p.regul && p.regul.mt && (
                            <div
                              className={
                                p.regul.type === "-"
                                  ? "text-danger font-weight-bold"
                                  : "text-warning font-weight-bold"
                              }
                            >
                              <span>{p.regul.compte}</span>
                              <span className="float-right">
                                <NumberFormat
                                  value={p.regul.mt}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator=" "
                                  displayType="text"
                                  suffix=" €"
                                />
                              </span>
                            </div>
                          )}
                          <div className="text-right mt-2">
                            <button
                              className="btn btn-outline-primary mb-2"
                              onClick={() =>
                                this.handleClickCollapse(index, indexMode)
                              }
                              style={{ fontSize: "14px" }}
                            >
                              <i
                                className={
                                  "mr-2 fa text-primary " +
                                  (!p.collapse ? "fa-plus" : "fa-minus")
                                }
                                style={{ fontSize: "14px" }}
                                aria-hidden="true"
                              ></i>
                              Détail
                            </button>
                          </div>

                          {p.collapse && (
                            <div
                              className="mt-2"
                              style={{
                                maxHeight: "500px",
                                overflowY: "auto",
                              }}
                            >
                              <table
                                className="table table-bordered  mt-2"
                                style={{ fontSize: "12px" }}
                              >
                                <thead>
                                  <tr>
                                    <td>Caisse du</td>
                                    <td>Dépôt</td>
                                    <td>Le</td>
                                    <td>Montant</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {p.listeCaisse.map((l, indexL) => (
                                    // <tr>{JSON.stringify(l)}
                                    <tr key={indexL}>
                                      <td>{l.dateJJMMAAAA}</td>
                                      <td>{l.rapprochement}</td>
                                      <td>{l.dateBanqueCompta}</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={l.mt}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          decimalSeparator=","
                                          thousandSeparator=" "
                                          displayType="text"
                                          suffix=" €"
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ValeurEncaissement;
