import React, { Component } from "react";
import SelectSearch from "react-select-search";

import { makeId } from "../../utils/tools";
import {
  getAbreviationsRueFantoir,
  getRuesFantoir,
  getVilleAllRuesFantoir,
} from "../../services/pizzeriaService";

const patternNumero = /^[0-9]*$/;

class ModificationAdresse extends Component {
  state = {
    adresse: {},
    adresseLibre: "",
    villeId: "-1",
    oldSaisieViaFantoir: false,
    saisieViaFantoir: false,
    modifierSaisieLibre: false,
    num: "",
    repetitionLettre: "",
    repetitionAdverbe: "",
    abrFantoir: "",
    fantoirId: "",
    villesForSelect: [],
    tabAbreviationsRueFantoir: [],
    tabRueFantoir: [],
    tabAllVilleRueFantoir: [],
    shouldShowBox: false,
    traitEncours: false,
  };

  async componentDidMount() {
    const {
      adresse,
      villesForSelect,
      tabIndiceRepetitionLettreForSelect,
      tabIndiceRepetitionAdverbeForSelect,
    } = this.props;
    let tabAbreviationsRueFantoir = [];
    let tabRueFantoir = [];
    let tabAllVilleRueFantoir = [];
    if (adresse && adresse._id) {
      const laVille = villesForSelect
        .filter((v) => v.value !== "-1")
        .find((v) => v.value === adresse.villeId.toString());
      const resultAbreviationsRueFantoir = await getAbreviationsRueFantoir(
        laVille.villeIdInsee
      );
      tabAbreviationsRueFantoir = resultAbreviationsRueFantoir.data.data;

      if (adresse.saisieViaFantoir === 1) {
        const resultRuesFantoir = await getRuesFantoir({
          villeIdInsee: laVille.villeIdInsee,
          abreviationId: adresse.abrFantoir,
        });
        resultRuesFantoir.data.data.forEach((item) => {
          tabRueFantoir.push({
            value: item.fantoirId,
            name: item.nomComplet,
          });
        });
      }
      const resultVilleRuesFantoir = await getVilleAllRuesFantoir({
        villeIdInsee: laVille.villeIdInsee,
      });
      resultVilleRuesFantoir.data.data.forEach((item) => {
        tabAllVilleRueFantoir.push({
          value: item.fantoirId,
          name: item.nomComplet,
          abrFantoir: item.abrFantoir,
        });
      });
    }
    let num = adresse.num;
    let repetitionLettre = adresse.repetitionLettre;
    let repetitionAdverbe = adresse.repetitionAdverbe;
    if (adresse.saisieViaFantoir === 0) {
      const decompose = adresse.adresse.split(" ");
      const recupNum = parseInt(decompose[0]);
      if (recupNum && recupNum > 0) num = recupNum;
      const recupRepetition = decompose[1];
      if (recupRepetition && recupRepetition.length !== 0) {
        const existRepetitionAdverbe = tabIndiceRepetitionAdverbeForSelect.find(
          (r) => {
            return r.value.toUpperCase() === recupRepetition.toUpperCase();
          }
        );
        if (existRepetitionAdverbe) {
          repetitionAdverbe = existRepetitionAdverbe.value;
        } else {
          const existRepetitionLettre = tabIndiceRepetitionLettreForSelect.find(
            (r) => {
              return r.value.toUpperCase() === recupRepetition.toUpperCase();
            }
          );
          if (existRepetitionLettre)
            repetitionLettre = existRepetitionLettre.value;
        }
      }
    }
    this.setState({
      adresse,
      adresseLibre: adresse.adresse,
      saisieViaFantoir: adresse.saisieViaFantoir === 1,
      modifierSaisieLibre: adresse.saisieViaFantoir === 0,
      oldSaisieViaFantoir: adresse.saisieViaFantoir === 1,
      num,
      repetitionLettre,
      repetitionAdverbe,
      abrFantoir: adresse.abrFantoir,
      villeId: adresse.villeId.toString(),
      villesForSelect: villesForSelect.filter((v) => v.value !== "-1"),
      tabAbreviationsRueFantoir,
      tabRueFantoir,
      tabAllVilleRueFantoir,
      fantoirId: adresse.fantoirId,
      shouldShowBox: true,
    });
  }

  handleVilleSelect = async (ville) => {
    const { villesForSelect, saisieViaFantoir } = this.state;
    const laVille = villesForSelect.find((v) => v.value === ville);
    const resultAbreviationsRueFantoir = await getAbreviationsRueFantoir(
      laVille.villeIdInsee
    );
    let tabAbreviationsRueFantoir = resultAbreviationsRueFantoir.data.data;
    let tabRueFantoir = [];
    let tabAllVilleRueFantoir = [];
    if (saisieViaFantoir) {
      const resultRuesFantoir = await getRuesFantoir({
        villeIdInsee: laVille.villeIdInsee,
        abreviationId: tabAbreviationsRueFantoir[0]
          ? tabAbreviationsRueFantoir[0]._id
          : "",
      });
      resultRuesFantoir.data.data.forEach((item) => {
        tabRueFantoir.push({
          value: item.fantoirId,
          name: item.nomComplet,
        });
      });
    }
    const resultVilleRuesFantoir = await getVilleAllRuesFantoir({
      villeIdInsee: laVille.villeIdInsee,
    });
    resultVilleRuesFantoir.data.data.forEach((item) => {
      tabAllVilleRueFantoir.push({
        value: item.fantoirId,
        name: item.nomComplet,
        abrFantoir: item.abrFantoir,
      });
    });
    this.setState({
      villeId: ville,
      tabAbreviationsRueFantoir,
      abrFantoir: tabAbreviationsRueFantoir[0]
        ? tabAbreviationsRueFantoir[0]._id
        : "",
      tabRueFantoir,
      tabAllVilleRueFantoir,
      fantoirId: tabRueFantoir[0] ? tabRueFantoir[0].value : "",
    });
  };

  handleNumeroChange = (event) => {
    if (patternNumero.test(event.target.value))
      this.setState({ num: event.target.value });
  };

  handleSaisieViaFantoirChange = () => {
    const { saisieViaFantoir } = this.state;
    this.setState({
      saisieViaFantoir: !saisieViaFantoir,
      modifierSaisieLibre: saisieViaFantoir,
    });
  };

  handleModifierSaisieLibreChange = () => {
    const { modifierSaisieLibre, tabAllVilleRueFantoir } = this.state;
    let { fantoirId } = this.state;
    if (modifierSaisieLibre) {
      fantoirId = tabAllVilleRueFantoir[0].value;
    }
    this.setState({
      modifierSaisieLibre: !modifierSaisieLibre,
      fantoirId,
    });
  };

  handleClickAbreviation = async (abreviation) => {
    const { saisieViaFantoir, villesForSelect, villeId } = this.state;
    let tabRueFantoir = [];
    if (saisieViaFantoir) {
      const laVille = villesForSelect.find((v) => v.value === villeId);
      const resultRuesFantoir = await getRuesFantoir({
        villeIdInsee: laVille.villeIdInsee,
        abreviationId: abreviation._id,
      });
      resultRuesFantoir.data.data.forEach((item) => {
        tabRueFantoir.push({
          value: item.fantoirId,
          name: item.nomComplet,
        });
      });
    }
    this.setState({
      abrFantoir: abreviation._id,
      tabRueFantoir,
      fantoirId: tabRueFantoir[0].value,
    });
  };

  handleRepetitionLettreSelect = (repetitionLettre) => {
    this.setState({ repetitionLettre });
  };

  handleRepetitionAdverbeSelect = (repetitionAdverbe) => {
    this.setState({ repetitionAdverbe });
  };

  handleRueFantoirSelect = async (rue) => {
    this.setState({ fantoirId: rue });
  };

  handleAdresseLibreChange = (event) => {
    this.setState({ adresseLibre: event.target.value });
  };

  handleCancelModifAdresse = async () => {
    await this.props.handleCollapseMajOnClick(this.state.adresse, true);
  };

  handleValidModifAdresse = async () => {
    const {
      adresse,
      villeId,
      saisieViaFantoir,
      modifierSaisieLibre,
      adresseLibre,
      num,
      repetitionLettre,
      repetitionAdverbe,
      fantoirId,
      traitEncours,
    } = this.state;

    if (!traitEncours) {
      if (villeId !== "-1") {
        this.setState({ traitEncours: true });
        const newAdresse = {};
        let parSaisieLibre = false;
        newAdresse.villeId = parseInt(villeId);
        if (!saisieViaFantoir) {
          if (modifierSaisieLibre) {
            newAdresse.adresseLibre = adresseLibre;
            parSaisieLibre = true;
          }
        }
        if (!parSaisieLibre) {
          newAdresse.num = num;
          newAdresse.repetitionLettre = repetitionLettre;
          newAdresse.repetitionAdverbe = repetitionAdverbe;
          newAdresse.fantoirId = fantoirId;
        }
        await this.props.handleValidModifAdresse(adresse._id, newAdresse);
        this.setState({ traitEncours: false });
      }
    }
  };

  render() {
    const {
      adresse,
      adresseLibre,
      shouldShowBox,
      villesForSelect,
      villeId,
      num,
      repetitionLettre,
      repetitionAdverbe,
      tabAbreviationsRueFantoir,
      tabRueFantoir,
      saisieViaFantoir,
      oldSaisieViaFantoir,
      modifierSaisieLibre,
      fantoirId,
      abrFantoir,
      tabAllVilleRueFantoir,
      traitEncours,
    } = this.state;

    const {
      tabIndiceRepetitionLettreForSelect,
      tabIndiceRepetitionAdverbeForSelect,
    } = this.props;
    if (!adresse._id) return null;
    return (
      <React.Fragment>
        {!shouldShowBox && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {shouldShowBox && (
          <div className="pt-2 pb-2">
            <h5>Modification de l'adresse</h5>
            <div className="row maxhauteur300">
              <div className="form-group col-12">
                <div>
                  <SelectSearch
                    options={villesForSelect}
                    value={villeId}
                    name="ville"
                    search
                    placeholder="Ville"
                    onChange={this.handleVilleSelect}
                  />
                </div>
              </div>
            </div>
            {oldSaisieViaFantoir && (
              <div className="row ml-1 mb-4">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="saisieViaFantoir"
                    checked={!saisieViaFantoir}
                    onChange={this.handleSaisieViaFantoirChange}
                  ></input>
                  <label
                    className="form-check-label"
                    htmlFor="saisieViaFantoir"
                  >
                    Saisie libre de la voie
                  </label>
                </div>
              </div>
            )}
            {!oldSaisieViaFantoir && !saisieViaFantoir && (
              <React.Fragment>
                <div className="row ml-3 mb-4">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="modifierSaisieLibre"
                      checked={modifierSaisieLibre}
                      onChange={this.handleModifierSaisieLibreChange}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="modifierSaisieLibre"
                    >
                      Saisie libre de la voie
                    </label>
                  </div>
                </div>
              </React.Fragment>
            )}
            {modifierSaisieLibre && (
              <div className="row mt-2 ml-3">
                <div className="form-group col-12">
                  <input
                    type="text"
                    name={"AdresseLibre" + makeId()}
                    id="adresseLibre"
                    placeholder="Saisie libre"
                    value={adresseLibre}
                    className="form-control"
                    autoComplete={makeId()}
                    onChange={(e) => {
                      this.handleAdresseLibreChange(e);
                    }}
                  />
                </div>
              </div>
            )}
            {(saisieViaFantoir || !modifierSaisieLibre) && (
              <React.Fragment>
                <div className="row ml-3">
                  <div className="form-group col-3">
                    <div className="form-group">
                      <input
                        name="Numero"
                        id="Numero"
                        type="text"
                        placeholder="N°"
                        value={num}
                        onChange={this.handleNumeroChange}
                        className="form-control"
                        maxLength="15"
                        autoComplete={makeId()}
                      />
                    </div>
                  </div>
                  <div className="form-group col-3 maxhauteur100">
                    <div className="form-group">
                      <SelectSearch
                        options={tabIndiceRepetitionLettreForSelect}
                        value={repetitionLettre}
                        name="repetitionLettre"
                        onChange={this.handleRepetitionLettreSelect}
                        disabled={repetitionAdverbe.trim().length !== 0}
                      />
                    </div>
                  </div>
                  <div className="form-group col-3 maxhauteur100">
                    <div className="form-group">
                      <SelectSearch
                        options={tabIndiceRepetitionAdverbeForSelect}
                        value={repetitionAdverbe}
                        name="repetitionAdverbe"
                        onChange={this.handleRepetitionAdverbeSelect}
                        disabled={repetitionLettre.trim().length !== 0}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {!saisieViaFantoir && !modifierSaisieLibre && (
              <div className="row ml-3 maxhauteur300">
                <div className="form-group col-12">
                  <SelectSearch
                    options={tabAllVilleRueFantoir}
                    value={fantoirId}
                    name="ville"
                    search
                    onChange={this.handleRueFantoirSelect}
                  />
                </div>
              </div>
            )}
            {saisieViaFantoir && (
              <React.Fragment>
                <div className="row ml-3">
                  {tabAbreviationsRueFantoir.map((r) => {
                    return (
                      <React.Fragment key={r._id}>
                        <button
                          className={
                            abrFantoir === r._id
                              ? "btn btn-primary col-2 ml-2 mb-2"
                              : "btn btn-outline-secondary col-2 ml-2 mb-2"
                          }
                          style={{ fontSize: "14px" }}
                          onClick={() => this.handleClickAbreviation(r)}
                        >
                          {r.nom}
                        </button>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="row mt-2 maxhauteur300">
                  <div className="form-group col-12">
                    <SelectSearch
                      options={tabRueFantoir}
                      value={fantoirId}
                      name="ville"
                      search
                      onChange={this.handleRueFantoirSelect}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}

            <div className="ml-2 mr-2">
              <button
                className="btn btn-primary mr-2"
                onClick={this.handleValidModifAdresse}
                disabled={traitEncours}
              >
                Enregistrer
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={this.handleCancelModifAdresse}
              >
                Annuler
              </button>
              {traitEncours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ModificationAdresse;
