import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function getTypesEcritures() {
  return await http.get(urlApiComptabilite + "/saisie/getTypesEcritures/");
}

export async function getFullTypesEcriture() {
  return await http.get(urlApiComptabilite + "/saisie/getFullTypesEcriture/");
}

export async function addTypeEcriture(dataType) {
  return await http.post(urlApiComptabilite + "/saisie/addTypeEcriture/", {
    dataType,
  });
}
export async function updateTypeEcriture(id, dataType) {
  return await http.post(
    urlApiComptabilite + "/saisie/updateTypeEcriture/" + id,
    {
      dataType,
    }
  );
}

export async function getListGroupeEcriturePeriode(param) {
  return await http.post(
    urlApiComptabilite + "/saisie/getListGroupeEcriturePeriode",
    param
  );
}

export async function getGroupeEcriture(id) {
  return await http.get(
    urlApiComptabilite + "/saisie/getGroupeEcriture/" + id.toString()
  );
}

export async function getEcrituresGroupe(id) {
  return await http.get(
    urlApiComptabilite + "/saisie/getEcrituresGroupe/" + id.toString()
  );
}

export async function getComptesAvecNumeroEC() {
  return await http.get(urlApiComptabilite + "/saisie/getComptesAvecNumeroEC/");
}

export async function getAllEmployes() {
  return await http.get(urlApiComptabilite + "/saisie/getAllEmployes/");
}

export async function addGroupeEcriture(data) {
  return await http.post(
    urlApiComptabilite + "/saisie/addGroupeEcriture/",
    data
  );
}

export async function deleteGroupeEcriture(id) {
  return await http.delete(
    urlApiComptabilite + "/saisie/deleteGroupeEcriture/" + id
  );
}

export async function updateGroupeEcriture(id, data) {
  return await http.put(
    urlApiComptabilite + "/saisie/updateGroupeEcriture/" + id,
    data
  );
}

export async function extourneGroupeEcriture(id, data) {
  return await http.put(
    urlApiComptabilite + "/saisie/extourneGroupeEcriture/" + id,
    data
  );
}

export async function getRapprochementListGroupeEcriture(param) {
  return await http.post(
    urlApiComptabilite + "/saisie/getRapprochementListGroupeEcriture",
    param
  );
}

export async function soldeCptResultat({ allSocietes, societeIdTab, userId }) {
  return await http.post(urlApiComptabilite + "/saisie/soldeCptResultat/", {
    allSocietes,
    societeIdTab,
    userId,
  });
}

export async function estExerciceCloture(societeId, annee) {
  return await http.get(
    urlApiComptabilite + "/saisie/estExerciceCloture/" + societeId + "/" + annee
  );
}

export async function getMaxDateEcritureSociete() {
  return await http.get(
    urlApiComptabilite + "/saisie/getMaxDateEcritureSociete/"
  );
}

export async function getComptesByRacine(racine) {
  return await http.get(
    urlApiComptabilite + "/saisie/getComptesByRacine/" + racine
  );
}

// eslint-disable-next-line
export default {
  getTypesEcritures,
};
