import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export function getListAdresses(param) {
  return http.post(urlApiComptabilite + "/adresses/getListAdresses", param);
}

export function getTypeLocalisation() {
  return http.get(urlApiComptabilite + "/adresses/getTypeLocalisation");
}

export function disactiveAdresse(adresseId) {
  return http.post(
    urlApiComptabilite + "/adresses/disactiveAdresse/" + adresseId
  );
}

export function callApiGps(adresseId) {
  return http.get(urlApiComptabilite + "/adresses/callApiGps/" + adresseId);
}

export function setCoordonneesGPS(adresseId, data) {
  return http.post(
    urlApiComptabilite + "/adresses/setCoordonneesGPS/" + adresseId,
    data
  );
}

export function deleteCoordonneesGPS(adresseId) {
  return http.post(
    urlApiComptabilite + "/adresses/deleteCoordonneesGPS/" + adresseId
  );
}

export function updateAdresse(adresseId, data) {
  return http.post(
    urlApiComptabilite + "/adresses/updateAdresse/" + adresseId,
    data
  );
}

export function addConversationAdresse(data) {
  return http.post(
    urlApiComptabilite + "/adresses/addConversationAdresse",
    data
  );
}

export function addMessageConversationAdresse(data) {
  return http.post(
    urlApiComptabilite + "/adresses/addMessageConversationAdresse",
    data
  );
}

export function setMessagesConversationLu(data) {
  return http.post(
    urlApiComptabilite + "/adresses/setMessagesConversationLu",
    data
  );
}

export function disactiveMessageConversationAdresse(data) {
  return http.post(
    urlApiComptabilite + "/adresses/disactiveMessageConversationAdresse",
    data
  );
}
