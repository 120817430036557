import React, { Component } from "react";
import Table from "../common/table";

class ListeCodeAPE extends Component {
  columns = [
    {
      path: "_id",
      label: "Code",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{
              fontSize: "14px",
            }}
          >
            <a
              className="btn btn-link"
              role="button"
              href="#/"
              onClick={() => this.props.onSelectCodeAPE(c)}
            >
              {c._id}
            </a>
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "designation",
      label: "Désignation de la nomenclature",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.designation}</div>
        </React.Fragment>
      ),
    },
    {
      path: "nomInterne",
      label: "Nom interne",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.nomInterne}</div>
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { tabAPE, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={tabAPE}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeCodeAPE;
