import React, { Component } from "react";
import { callApiGps } from "../../services/adressesService";

class AdresseGPS extends Component {
  state = {
    adresse: {},
    tabCoordGps: [],
    selectedCoordGps: null,
    droitChoixCoordonnees: true,
    shouldShowBox: true,
  };
  async componentDidMount() {
    const { adresse } = this.props;
    const tabCoordGps = [];
    let selectedCoordGps = null;
    if (adresse.typeLocalisationId !== -1) {
      tabCoordGps.push({
        _id: -1,
        typeLocalisationId: adresse.typeLocalisationId,
        typeLocalisationLib: adresse.typeLocalisationLib,
        latLon: adresse.latLon,
        nom: adresse.adresseComplete,
        queryScore: -1,
        confidence: "",
      });
      selectedCoordGps = tabCoordGps[0];
    }
    // Pas le client a repairé son adresse et Rue et N° de rue
    const droitChoixCoordonnees =
      adresse.typeLocalisationId !== 1 && adresse.typeLocalisationId !== 3;
    this.setState({
      adresse,
      tabCoordGps,
      selectedCoordGps,
      droitChoixCoordonnees,
    });
  }

  handleSearchClick = async () => {
    const { adresse } = this.state;
    this.setState({ shouldShowBox: false });
    let { tabCoordGps } = this.state;
    tabCoordGps = tabCoordGps.filter((t) => t._id === -1);
    const result = await callApiGps(adresse._id);
    result.data.data.forEach((x) => {
      tabCoordGps.push({
        _id: tabCoordGps.length + 1,
        typeLocalisationId: 2,
        typeLocalisationLib: x.algo,
        latLon: x.latitude + "," + x.longitude,
        nom: x.nom,
        queryScore: x.queryScore,
        confidence: x.confidence,
      });
    });

    this.setState({
      tabCoordGps: JSON.parse(JSON.stringify(tabCoordGps)),
      selectedCoordGps: tabCoordGps.length > 0 ? tabCoordGps[0] : null,
      shouldShowBox: true,
    });
  };

  onItemSelect = (c) => {
    this.setState({
      selectedCoordGps: c,
    });
  };
  handleValiderClick = async (c) => {
    await this.props.handleChoixCoordonnees(this.state.adresse, c);
  };

  render() {
    const {
      adresse,
      tabCoordGps,
      selectedCoordGps,
      droitChoixCoordonnees,
      shouldShowBox,
    } = this.state;
    const { APIKEY_GOOGLEMAPS } = this.props;
    if (!adresse._id) return null;
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-secondary btn-sm mt-2"
          onClick={this.handleSearchClick}
        >
          Rechercher les coordonnées GPS de l'adresse
        </button>
        {!shouldShowBox && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {shouldShowBox && (
          <ul className="list-group mt-2 mb-3">
            {tabCoordGps.map((c) => (
              <li
                key={c._id}
                className={
                  "list-group-item d-flex justify-content-between align-items-start pt-1 pb-1 " +
                  (c._id === selectedCoordGps._id ? "active" : "")
                }
                style={{ fontSize: "12px", cursor: "pointer" }}
              >
                <div
                  className="ms-2 me-auto"
                  onClick={() => this.onItemSelect(c)}
                >
                  <div className="font-weight-bold">
                    {c.typeLocalisationLib} - {c.nom}
                    {c.queryScore > 0
                      ? " - Score : " + c.queryScore.toString() + " / 1"
                      : ""}
                    {c.confidence.length !== 0
                      ? " - Confiance : " + c.confidence
                      : ""}
                  </div>
                  {c.latLon}
                </div>
                {c._id === selectedCoordGps._id && droitChoixCoordonnees && (
                  <button
                    className="btn btn-outline-secondary btn-sm mt-2"
                    onClick={() => this.handleValiderClick(c)}
                  >
                    Valider ces coordonnées GPS
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}

        {selectedCoordGps !== null &&
          selectedCoordGps.latLon &&
          selectedCoordGps.latLon.length !== 0 &&
          shouldShowBox && (
            <React.Fragment>
              <div style={{ fontSize: "14px" }}>
                La carte ci-dessous affiche la carte Google Maps des coordonnées
                GPS {selectedCoordGps.latLon}
              </div>
              <iframe
                title="Carte google maps"
                id="carte"
                width="100%"
                height="400"
                src={`https://www.google.com/maps/embed/v1/place?q=${selectedCoordGps.latLon}&key=${APIKEY_GOOGLEMAPS}`}
              />
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }
}

export default AdresseGPS;
