import React from "react";
import Form from "./common/form";
import { toast } from "react-toastify";

import {
  getGroupeEcriture,
  extourneGroupeEcriture,
  estExerciceCloture,
} from "../services/saisieService";
import auth from "../services/authService";
import { makeId } from "../utils/tools";

class ExtourneForm extends Form {
  state = {
    groupeId: -1,
    societeId: -1,
    nouvelleDate: new Date(),
    commentaire: "",
    tabErreurs: [],
    shouldShowBox: false,
  };

  async componentDidMount() {
    const groupeId = parseInt(this.props.match.params.groupeId);
    const dataGroupe = await getGroupeEcriture(groupeId);
    const groupe = dataGroupe.data.data;
    const dc = new Date(
      groupe.dateComptableAAAAMMJJ.substring(0, 4),
      groupe.dateComptableAAAAMMJJ.substring(4, 6) - 1,
      groupe.dateComptableAAAAMMJJ.substring(6, 8)
    );

    this.setState({
      groupeId: groupe._id,
      societeId: groupe.societeId,
      commentaire: groupe.commentaire,
      nouvelleDate: dc,
      shouldShowBox: true,
    });
  }

  handleDateChange = (event) => {
    let { nouvelleDate } = this.state;
    const day = event.target.valueAsDate;
    if (day != null) {
      nouvelleDate = day;
      this.setState({ nouvelleDate });
    }
  };

  handleCommentaireChange = (event) => {
    this.setState({ commentaire: event.target.value });
  };

  getPageRetour = () => {
    return "/saisie/1";
  };

  handleEnregistrerClick = async (event) => {
    event.preventDefault();
    let {
      traitEncours,
      nouvelleDate,
      groupeId,
      societeId,
      commentaire,
    } = this.state;
    if (!traitEncours) {
      let tabErreurs = [];
      const dateGroupe =
        nouvelleDate.getFullYear() +
        "-" +
        (nouvelleDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        nouvelleDate.getDate().toString().padStart(2, "0");
      if (!dateGroupe) {
        tabErreurs.push("Date incorrecte");
      }
      if (parseInt(dateGroupe.substring(0, 4)) < 2014) {
        tabErreurs.push("Date incorrecte");
      }
      if (parseInt(dateGroupe.substring(0, 4)) > 2100) {
        tabErreurs.push("Date incorrecte");
      }
      const retEstExerciceCloture = await estExerciceCloture(
        societeId,
        nouvelleDate.getFullYear()
      );
      if (retEstExerciceCloture.data.data === true) {
        tabErreurs.push(
          "L'année " + nouvelleDate.getFullYear() + " est clôturée"
        );
      }
      if (tabErreurs.length !== 0) {
        this.setState({ tabErreurs, traitEncours: false });
        return;
      }

      this.setState({ traitEncours: true });

      const data = {};
      data.nouvelleDate = dateGroupe;
      data.commentaire = commentaire;
      data.userId = auth.getCurrentUser()._id;

      const result = await extourneGroupeEcriture(groupeId, data);

      if (!result.data.success) {
        toast.error(
          "Erreur à l'enregistrement de l'extourne du groupe : " +
            result.data.message
        );
      } else {
        if (
          result.data.success &&
          result.data.data &&
          result.data.data.nouveauGroupeId
        )
          toast.success(
            "Le groupe " + result.data.data.nouveauGroupeId + " a été généré"
          );
      }

      this.props.history.push(this.getPageRetour());
    }
  };

  handleCancelClick = (event) => {
    event.preventDefault();
    this.props.history.push(this.getPageRetour());
  };

  render() {
    const {
      groupeId,
      nouvelleDate,
      commentaire,
      tabErreurs,
      shouldShowBox,
      traitEncours,
    } = this.state;

    const now = new Date();
    const dateGroupeAAAAMMJJ =
      nouvelleDate instanceof Date
        ? nouvelleDate.getFullYear() +
          "-" +
          (nouvelleDate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          nouvelleDate.getDate().toString().padStart(2, "0")
        : now.getFullYear() +
          "-" +
          (now.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          now.getDate().toString().padStart(2, "0");

    const textH2 =
      "Extourne / Contrepassation du groupe d'écriture " + groupeId;

    return (
      <div>
        {!shouldShowBox && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {shouldShowBox && (
          <React.Fragment>
            <h2>{textH2}</h2>
            {tabErreurs.length > 0 && (
              <div className="row alert alert-danger">
                <div className="col-12">Erreurs :</div>
                <ul className="col-12">
                  {tabErreurs.map((x, index) => (
                    <li key={index}>
                      <div>{x}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <div>
                <label className="w-100 font-weight-bold mt-2">
                  Date du nouveau groupe
                </label>
                <input
                  type="date"
                  value={dateGroupeAAAAMMJJ}
                  onChange={(date) => this.handleDateChange(date)}
                ></input>
              </div>
              <div>
                <label className="w-100 font-weight-bold mt-2">
                  Commentaire
                </label>
                <input
                  type="text"
                  name={"Commentaire" + makeId()}
                  id="Commentaire"
                  placeholder="Commentaire"
                  value={commentaire}
                  className="form-control"
                  autoComplete={makeId()}
                  onChange={(e) => {
                    this.handleCommentaireChange(e);
                  }}
                />
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-primary mr-2"
                  onClick={this.handleEnregistrerClick}
                >
                  Enregistrer
                </button>
                <button
                  className="btn btn-outline-secondary ml-2"
                  onClick={this.handleCancelClick}
                >
                  Annuler
                </button>
              </div>

              {traitEncours && (
                <div className="mt-3 mb-3">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ExtourneForm;
