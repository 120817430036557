import React, { Component } from "react";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";
import moment from "moment";
import localization from "moment/locale/fr";
import NumberFormat from "react-number-format";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";

import { getPizzerias } from "../services/pizzeriaService";
import { exportImmos } from "../services/immosService";
import { paginate } from "../utils/paginate";
import { getPrefixeUrlHlpBackMenu, roundDecimal } from "../utils/tools";

moment.locale("fr", localization);

const headersCSV = [
  { label: "Code", key: "code" },
  { label: "Libellé", key: "libelle" },
  { label: "Libellé 2", key: "libelle2" },
  { label: "Code fournisseur", key: "fournisseurCode" },
  { label: "Raison sociale fournisseur", key: "fournisseurRaisonSociale" },
  { label: "Référence facture", key: "factureReference" },
  { label: "Date d'achat", key: "dateAchatJJMMAAAA" },
  { label: "Date de mise en service", key: "dateMiseEnServiceJJMMAAAA" },
  { label: "Montant HT", key: "montantHT_comma" },
  { label: "Quantité", key: "quantite_comma" },
  { label: "Montant de TVA", key: "nonGere" },
  { label: "Montant de TVA récupérée", key: "nonGere" },
  { label: "Valeur vénale", key: "nonGere" },
  { label: "Méthode d'amortissement", key: "methode" },
  { label: "Début d'amortissement", key: "dateMiseEnServiceJJMMAAAA" },
  { label: "Durée en mois", key: "nonGere" },
  { label: "Durée en année", key: "duree" },
  { label: "Valeur nette de reprise", key: "nonGere" },
  { label: "Limite amortissable", key: "nonGere" },
  { label: "Quote-part personnelle", key: "nonGere" },
  { label: "Amortissement fiscal activé", key: "nonGere" },
  { label: "Méthode d'amortissement fiscal", key: "nonGere" },
  { label: "Durée en mois de l'amortissement fiscal", key: "nonGere" },
  { label: "Durée en année de l'amortissement fiscal", key: "nonGere" },
  { label: "Date de début  de l'amortissement fiscal", key: "nonGere" },
  { label: "Base d'amortissement fiscal", key: "nonGere" },
  { label: "Base manuelle d'amortissement fiscal", key: "nonGere" },
  { label: "Commentaire", key: "nonGere" },
  { label: "Compte d'immobilisation", key: "compteImmo" },
  { label: "Compte d'amortissement", key: "compteAmort" },
  { label: "Compte de dotation", key: "compteDotation" },
  { label: "Compte d'immobilisation dérogatoire", key: "nonGere" },
  { label: "Compte d'amortissement dérogatoire", key: "nonGere" },
  { label: "Compte de dotation dérogatoire", key: "nonGere" },
  { label: "Axe analytique", key: "nonGere" },
  { label: "Section analytique", key: "nonGere" },
  { label: "Localisation", key: "nonGere" },
  { label: "Famille", key: "nonGere" },
];

class ExportImmos extends Component {
  state = {
    controleEnCours: false,
    traitEnCours: false,
    societesForSelect: [],
    selectedSociete: 0,
    date: new Date(),
    dateFin: new Date(),
    traitFait: false,
    tabTrait: [],
    dataImmos: [],
    dataImmosEnErreur: [],
    cptImmoTab: [],
    detailImmosEnErreur: false,
    detailImmos: false,
    currentPage: 1,
    pageSize: 100,
    totalCount: 0,
    urlVisuImmo: "",
  };

  async componentDidMount() {
    const result = await getPizzerias();

    const urlVisuImmo =
      (await getPrefixeUrlHlpBackMenu(85)) +
      "admin_index.php?cat=comptabilite&scat=immobilisation&id=@id&annee=@annee";

    let societesForSelect = [];
    result.data.data.map((item) => {
      societesForSelect.push({
        value: item._id.toString(),
        name: item.denomination,
        siren: item.siret.replaceAll(" ", "").substring(0, 9),
      });
      return true;
    });

    const now = moment();

    const dateFin = moment()
      .set("year", now.year() - 1)
      .set("month", 11)
      .set("date", 31)
      .format("YYYY-MM-DD");
    let date = moment()
      .set("year", now.year() - 1)
      .set("month", 11)
      .set("date", 31);

    this.setState({
      societesForSelect,
      selectedSociete: societesForSelect[0].value,
      date: date.toDate(),
      dateFin,
      shouldShowBox: true,
      urlVisuImmo,
    });
  }

  handleSocieteSelect = (societe) => {
    this.setState({
      selectedSociete: societe,
      traitFait: false,
    });
  };

  handleDateChange = (day) => {
    let oldDate = this.state.date;
    const laDate = day;
    if (laDate != null) {
      oldDate = day;
      const currentDate = new Date(
        oldDate.getFullYear(),
        oldDate.getMonth(),
        2
      );
      const dateFinMois = moment(currentDate).endOf("month");
      const dateFin = moment()
        .set("year", dateFinMois.year())
        .set("month", dateFinMois.month())
        .set("date", dateFinMois.date())
        .format("YYYY-MM-DD");
      this.setState({
        date: oldDate,
        dateFin,
        traitFait: false,
      });
    }
  };

  handleValiderClick = async (event) => {
    event.preventDefault();
    const { traitEnCours } = this.state;
    if (!traitEnCours) {
      this.setState({ traitEnCours: true });
      const { selectedSociete, date } = this.state;
      const mois = date.getMonth();
      const annee = date.getFullYear();
      const ret = await exportImmos(parseInt(selectedSociete), mois + 1, annee);

      this.setState({
        traitEnCours: false,
        traitFait: true,
        dataImmos: ret.data.data.immos,
        dataImmosEnErreur: ret.data.data.immosEnErreur,
        cptImmoTab: ret.data.data.cptImmoTab,
        detailImmosEnErreur: false,
        detailImmos: false,
        totalCount: ret.data.data.immos.length,
        currentPage: 1,
      });
    }
  };

  handleClickCollapseImmos = () => {
    const { detailImmos } = this.state;
    this.setState({ detailImmos: !detailImmos });
  };
  handleClickCollapseImmosEnErreur = () => {
    const { detailImmosEnErreur } = this.state;
    this.setState({ detailImmosEnErreur: !detailImmosEnErreur });
  };

  handleGenereUrlImmo = (_id) => {
    const { urlVisuImmo, date } = this.state;
    return urlVisuImmo
      .replace("@id", _id)
      .replace("@annee", date.getFullYear().toString());
  };

  handlePageChange = async (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const {
      societesForSelect,
      selectedSociete,
      date,
      dataImmos,
      dataImmosEnErreur,
      cptImmoTab,
      traitEnCours,
      traitFait,
      detailImmosEnErreur,
      detailImmos,
      currentPage,
      pageSize,
      totalCount,
    } = this.state;

    const immosToShow = paginate(dataImmos, currentPage, pageSize);

    let textH5 = "";
    if (selectedSociete) {
      textH5 =
        societesForSelect.find((x) => x.value === selectedSociete).name +
        " à " +
        moment(date).format("MMMM YYYY");
    }
    let nomFichier = "immo.txt";
    if (selectedSociete) {
      nomFichier =
        societesForSelect.find((x) => x.value === selectedSociete).name +
        "_" +
        societesForSelect.find((x) => x.value === selectedSociete).siren +
        "IMM" +
        moment(date).format("YYYYMM") +
        ".txt";
    }
    return (
      <React.Fragment>
        <h2 className="mb-4">Export des immobilisations</h2>
        <div className="row">
          <div className="col-2">
            <div>
              <div>
                <label className="font-weight-bold" htmlFor="societes">
                  Société
                </label>
              </div>
              <SelectSearch
                options={societesForSelect}
                value={selectedSociete}
                name="societe"
                search
                onChange={this.handleSocieteSelect}
              />
            </div>
            <div className="mt-2">
              <div>
                <label className="font-weight-bold" htmlFor="mois">
                  Mois{" "}
                </label>
              </div>
              <DatePicker
                className="form-control"
                onChange={(date) => this.handleDateChange(date)}
                placeholder="Sélectionner une date"
                dateFormat="MM/yyyy"
                value={date}
                selected={date instanceof Date ? date : null}
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
            <div className="mt-4">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderClick}
                disabled={selectedSociete === 0}
              >
                Valider
              </button>
              {traitEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-10">
            <h5 className="mb-3">{textH5}</h5>
            {!traitEnCours && traitFait && (
              <React.Fragment>
                <div>
                  <div className="font-weight-bold text-uppercase mb-2">
                    Comparaison Immobilisations à exporter / Comptabilité
                  </div>
                  <table
                    className="table table-striped table-bordered"
                    style={{ fontSize: "12px" }}
                  >
                    <thead>
                      <tr className="row mr-0 ml-0">
                        <th className="col-3">Compte d'immobilisation</th>
                        <th className="col-1 text-right">Montant HT</th>
                        <th className="col-1 text-right">Solde comptable</th>
                        <th className="col-1 text-right">Différence</th>
                        <th className="col-3">Compte d'amortissement</th>
                        <th className="col-1 text-right">Amortissement</th>
                        <th className="col-1 text-right">Solde comptable</th>
                        <th className="col-1 text-right">Différence</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cptImmoTab.map((x) => (
                        <tr
                          className={
                            "row mr-0 ml-0 " +
                            (x.hasErreur ? "font-weight-bold" : "")
                          }
                          key={x._id}
                        >
                          <td
                            className={
                              "col-3 font-weight-bold " +
                              (x.hasErreur ? "text-danger" : "")
                            }
                          >
                            {x.hasErreur && (
                              <i
                                className="fas fa-exclamation-triangle mr-2 text-danger"
                                fontSize="16px"
                              ></i>
                            )}
                            <span>{x._id}</span>
                          </td>
                          <td
                            className={
                              "col-1 text-right text-nowrap " +
                              (x.montantHT < 0 ? "text-danger" : "")
                            }
                          >
                            <NumberFormat
                              value={x.montantHT}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                            />
                          </td>
                          <td
                            className={
                              "col-1 text-right text-nowrap " +
                              (x.soldeCptImmo < 0 ? "text-danger" : "")
                            }
                          >
                            <NumberFormat
                              value={x.soldeCptImmo}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                            />
                          </td>
                          <td className={"col-1 text-right text-nowrap "}>
                            {x.montantHT !== x.soldeCptImmo && (
                              <NumberFormat
                                value={roundDecimal(
                                  x.montantHT - x.soldeCptImmo
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                              />
                            )}
                          </td>
                          <td className="col-3">
                            <div>
                              {x.isAmortissable === 1 ? x.compteAmortId : ""}
                            </div>
                          </td>
                          <td
                            className={
                              "col-1 text-right text-nowrap " +
                              (x.totalAmorti < 0 ? "text-danger" : "")
                            }
                          >
                            {x.isAmortissable === 1 && (
                              <NumberFormat
                                value={x.totalAmorti}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                              />
                            )}
                          </td>
                          <td
                            className={
                              "col-1 text-right text-nowrap " +
                              (x.soldeCptAmort < 0 ? "text-danger" : "")
                            }
                          >
                            {x.isAmortissable === 1 && (
                              <NumberFormat
                                value={x.soldeCptAmort}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                              />
                            )}
                          </td>
                          <td className={"col-1 text-right text-nowrap "}>
                            {x.isAmortissable === 1 &&
                              x.totalAmorti !== x.soldeCptAmort && (
                                <NumberFormat
                                  value={roundDecimal(
                                    x.totalAmorti - x.soldeCptAmort
                                  )}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator=" "
                                  displayType="text"
                                />
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="font-weight-bold text-uppercase mb-2">
                  Immobilisations
                </div>
                {dataImmosEnErreur.length !== 0 && (
                  <React.Fragment>
                    <div className="row">
                      <div
                        className="col-12 font-weight-bold text-danger mb-4"
                        onClick={() => this.handleClickCollapseImmosEnErreur()}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fas fa-play mr-2"></i>
                        {dataImmosEnErreur.length} immobilisations en erreur
                      </div>
                    </div>
                    {detailImmosEnErreur && (
                      <table
                        className="table table-striped table-bordered"
                        style={{ fontSize: "12px" }}
                      >
                        <thead>
                          <tr className="mr-0 ml-0">
                            <th>Immo</th>
                            <th>Erreur</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataImmosEnErreur.map((x, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  href={this.handleGenereUrlImmo(x.immo._id)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {x.immo._id}
                                </a>
                              </td>
                              <td>{x.message}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </React.Fragment>
                )}
                {dataImmos.length === 0 && traitFait && (
                  <div>Aucune immobilisation</div>
                )}
                {dataImmos.length !== 0 && (
                  <React.Fragment>
                    <div className="row mb-3">
                      <div
                        className="col-12 font-weight-bold"
                        onClick={() => this.handleClickCollapseImmos()}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fas fa-play mr-2"></i>
                        {dataImmos.length} immobilisations à exporter
                      </div>
                    </div>

                    <CSVLink
                      data={dataImmos}
                      headers={headersCSV}
                      separator={"\t"}
                      enclosingCharacter={``}
                      filename={nomFichier}
                      className="btn btn-primary mb-4"
                    >
                      <i
                        className="fas fa-cloud-download-alt mr-2"
                        style={{ fontSize: "20px" }}
                        aria-hidden="true"
                      ></i>
                      Télécharger le fichier
                    </CSVLink>

                    {detailImmos && (
                      <React.Fragment>
                        <div className="font-weight-bold text-uppercase mb-2">
                          Liste des immobilisations
                        </div>
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={pageSize}
                          totalItemsCount={totalCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                          prevPageText="Précédent"
                          nextPageText="Suivant"
                          firstPageText="Premier"
                          lastPageText="Dernier"
                        />
                        <div className="overflow-auto mt-4">
                          <table
                            className="table table-striped table-bordered"
                            style={{ fontSize: "12px" }}
                          >
                            <thead>
                              <tr className="mr-0 ml-0">
                                <th>Code</th>
                                <th>Libellé</th>
                                <th>Fournisseur</th>
                                <th>Facture</th>
                                <th>Achat - Mise en service</th>
                                <th>Montant HT</th>
                                <th>Quantité</th>
                                <th>Méthode</th>
                                <th>Début d'amortissement</th>
                                <th>Durée (année)</th>
                                <th>Amortissement</th>
                                <th>Valeur nette de reprise</th>
                                <th>Compte d'immobilisation</th>
                                <th>Compte d'amortissement</th>
                                <th>Compte de dotation</th>
                              </tr>
                            </thead>
                            <tbody>
                              {immosToShow.map((x) => (
                                <tr key={x.code}>
                                  <td>
                                    <a
                                      href={this.handleGenereUrlImmo(x._id)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {x.code}
                                    </a>
                                  </td>
                                  <td className="text-nowrap">{x.libelle}</td>
                                  <td className="text-nowrap">
                                    {x.fournisseurCode} -{" "}
                                    {x.fournisseurRaisonSociale}
                                  </td>
                                  <td>{x.factureReference}</td>
                                  <td>
                                    {x.dateAchatJJMMAAAA}{" "}
                                    {x.dateMiseEnServiceJJMMAAAA}
                                  </td>
                                  <td className="text-right text-nowrap">
                                    <NumberFormat
                                      value={x.montantHT}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      decimalSeparator=","
                                      thousandSeparator=" "
                                      displayType="text"
                                      suffix=" €"
                                    />
                                  </td>
                                  <td className="text-right">
                                    <NumberFormat
                                      value={x.quantite}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      decimalSeparator=","
                                      thousandSeparator=" "
                                      displayType="text"
                                    />
                                  </td>
                                  <td className="text-nowrap">{x.methode}</td>
                                  <td>{x.dateMiseEnServiceJJMMAAAA}</td>
                                  <td>{x.duree !== 0 ? x.duree : ""}</td>
                                  <td className="text-nowrap text-right">
                                    {x.duree !== 0 && (
                                      <NumberFormat
                                        value={x.valeurNette}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        decimalSeparator=","
                                        thousandSeparator=" "
                                        displayType="text"
                                        suffix=" €"
                                      />
                                    )}
                                  </td>
                                  <td className="text-right text-nowrap">
                                    <NumberFormat
                                      value={x.totalAmorti}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      decimalSeparator=","
                                      thousandSeparator=" "
                                      displayType="text"
                                      suffix=" €"
                                    />
                                  </td>
                                  <td>{x.compteImmo}</td>
                                  <td>{x.compteAmort}</td>
                                  <td>{x.compteDotation}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ExportImmos;
