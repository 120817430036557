import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function getAllCodesJournal() {
  return await http.get(urlApiComptabilite + "/parametres/getAllCodesJournal");
}

export async function getCodeJournal(code) {
  return await http.get(
    urlApiComptabilite + "/parametres/getCodeJournal/" + code
  );
}

export async function addCodeJournal(dataCodeJournal) {
  return await http.post(
    urlApiComptabilite + "/parametres/addCodeJournal",
    dataCodeJournal
  );
}

export async function updateCodeJournal(code, dataCodeJournal) {
  return await http.post(
    urlApiComptabilite + "/parametres/updateCodeJournal/" + code,
    dataCodeJournal
  );
}

export async function deleteCodeJournal(code) {
  return await http.post(
    urlApiComptabilite + "/parametres/deleteCodeJournal/" + code
  );
}

export async function getAllTauxInteret() {
  return await http.get(urlApiComptabilite + "/parametres/getAllTauxInteret");
}

export async function addTauxInteret(dataTaux) {
  return await http.post(
    urlApiComptabilite + "/parametres/addTauxInteret",
    dataTaux
  );
}

export async function getTauxInteret(date) {
  return await http.get(
    urlApiComptabilite + "/parametres/getTauxInteret/" + date
  );
}

export async function updateTauxInteret(date, dataTaux) {
  return await http.post(
    urlApiComptabilite + "/parametres/updateTauxInteret/" + date,
    dataTaux
  );
}

export async function deleteTauxInteret(date) {
  return await http.post(
    urlApiComptabilite + "/parametres/deleteTauxInteret/" + date
  );
}

export async function getFullComptes() {
  return await http.get(urlApiComptabilite + "/parametres/getFullComptes");
}

export async function getRecursiveCategorieBilanCR() {
  return await http.get(
    urlApiComptabilite + "/parametres/getRecursiveCategorieBilanCR"
  );
}

export async function getFullCompte(id) {
  return await http.get(urlApiComptabilite + "/parametres/getFullCompte/" + id);
}

export async function getFullCompteByNumCompteFEC(id) {
  return await http.get(
    urlApiComptabilite + "/parametres/getFullCompteByNumCompteFEC/" + id
  );
}

export async function addCompte(dataCompte) {
  return await http.post(
    urlApiComptabilite + "/parametres/addCompte",
    dataCompte
  );
}

export async function updateCompte(id, dataCompte) {
  return await http.post(
    urlApiComptabilite + "/parametres/updateCompte/" + id,
    dataCompte
  );
}

export async function getAllScanRelevesCompteBanque() {
  return await http.get(
    urlApiComptabilite + "/parametres/getAllScanRelevesCompteBanque"
  );
}

export async function addScanReleveCompte(dataScan) {
  return await http.post(
    urlApiComptabilite + "/parametres/addScanReleveCompte",
    { dataScan }
  );
}

export async function updateScanReleveCompte(id, dataScan) {
  return await http.post(
    urlApiComptabilite + "/parametres/updateScanReleveCompte/" + id,
    { dataScan }
  );
}

export async function PostesList() {
  return await http.get(urlApiComptabilite + "/parametres/PostesList/");
}
