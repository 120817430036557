import React, { Component } from "react";
import SelectSearch from "react-select-search";

import {
  fullAllModePaiement,
  addMethode,
  updateMethode,
} from "../../services/paiementService";
import { getComptesByRacine } from "../../services/saisieService";
import BarreMenu from "../common/barreMenu";
import ListeMethodesPaiement from "./listeMethodePaiement";
import {
  typeMenuParametresComptabilite,
  menuMethodesPaiement,
} from "../../utils/menuParametres";

class MethodesPaiement extends Component {
  state = {
    tabMethodeCompteInexistant: [],
    methodesPaiement: [],
    tabMethodeForSelect: [],
    tabCompteForSelect: [],
    sortColumn: { path: "_id", order: "asc" },
    newMethode: false,
    dataMethode: {
      _id: -1,
      designation: "",
      abreviation: "",
      parentId: 0,
      isCaisseActif: false,
      texteReleveBanque: "",
      compteAEncaisserId: 0,
      compteErreurId: 0,
    },
    selectedMethode: -1,
  };

  async componentDidMount() {
    const dataComptes = await getComptesByRacine("5");
    const tabCompteForSelect = [];
    tabCompteForSelect.push({ value: "0", name: "" });
    dataComptes.data.data.forEach((x) => {
      tabCompteForSelect.push({
        value: x._id.toString(),
        name: x._id.toString() + " - " + x.compteLibelle,
      });
    });
    this.setState({
      tabCompteForSelect,
    });
    await this.chargeMethodes();
  }

  chargeMethodes = async () => {
    const data = await fullAllModePaiement();
    const tabMethodeForSelect = [];
    const tabMethodeCompteInexistant = [];
    tabMethodeForSelect.push({ value: "0", name: "" });
    data.data.data.forEach((x) => {
      tabMethodeForSelect.push({
        value: x._id.toString(),
        name: x._id.toString() + " - " + x.designation,
      });
      if (
        (x.compteAEncaisserId &&
          parseInt(x.compteAEncaisserId) !== 0 &&
          x.compteAEncaisserLibelle === null) ||
        (x.compteErreurId &&
          parseInt(x.compteErreurId) !== 0 &&
          x.compteErreurLibelle === null)
      ) {
        tabMethodeCompteInexistant.push(x._id);
      }
    });

    this.setState({
      methodesPaiement: data.data.data,
      tabMethodeForSelect,
      tabMethodeCompteInexistant,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleNewMethode = () => {
    const { newMethode, dataMethode } = this.state;
    dataMethode._id = -1;
    dataMethode.designation = "";
    dataMethode.abreviation = "";
    dataMethode.parentId = 0;
    dataMethode.isCaisseActif = false;
    dataMethode.texteReleveBanque = "";
    dataMethode.compteAEncaisserId = 0;
    dataMethode.compteErreurId = 0;
    this.setState({
      newMethode: !newMethode,
      dataMethode,
    });
  };

  handleCancelNewMethode = () => {
    this.setState({
      newMethode: false,
      selectedMethode: -1,
    });
  };

  handleDesignationChange = async (event) => {
    const { dataMethode } = this.state;
    dataMethode.designation = event.target.value;
    this.setState({
      dataMethode,
    });
  };

  handleAbreviationChange = async (event) => {
    const { dataMethode } = this.state;
    if (event.target.value.trim().length <= 5) {
      dataMethode.abreviation = event.target.value.trim();
      this.setState({
        dataMethode,
      });
    }
  };

  handleParentSelect = (parent) => {
    const { dataMethode } = this.state;
    dataMethode.parentId = parent;
    this.setState({ dataMethode });
  };

  handleIsCaisseActifChange = () => {
    const { dataMethode } = this.state;
    dataMethode.isCaisseActif = !dataMethode.isCaisseActif;
    this.setState({ dataMethode });
  };

  handleTexteReleveBanqueChange = async (event) => {
    const { dataMethode } = this.state;
    dataMethode.texteReleveBanque = event.target.value.trim();
    this.setState({
      dataMethode,
    });
  };

  handleCompteAEncaisserSelect = (cpt) => {
    const { dataMethode } = this.state;
    dataMethode.compteAEncaisserId = cpt;
    this.setState({ dataMethode });
  };

  handleCompteErreurSelect = (cpt) => {
    const { dataMethode } = this.state;
    dataMethode.compteErreurId = cpt;
    this.setState({ dataMethode });
  };

  onSelectCodeJournal = (methode) => {
    const { selectedMethode, newMethode } = this.state;
    if (selectedMethode === -1 && !newMethode) {
      const dataMethode = {};
      dataMethode._id = methode._id;
      dataMethode.designation = methode.designation;
      dataMethode.abreviation = methode.abreviation;
      dataMethode.parentId = methode.parentId.toString();
      dataMethode.isCaisseActif = methode.isCaisseActif;
      dataMethode.texteReleveBanque = methode.texteReleveBanque;
      dataMethode.compteAEncaisserId = methode.compteAEncaisserId.toString();

      dataMethode.compteErreurId =
        methode.compteErreurId === null
          ? "0"
          : methode.compteErreurId.toString();
      this.setState({
        selectedMethode: methode._id,
        dataMethode,
      });
    }
  };

  handleSubmitMethode = async () => {
    const { selectedMethode, newMethode } = this.state;
    if (newMethode && selectedMethode === -1) {
      await this.traiteNewMethode();
    } else {
      if (!newMethode && selectedMethode !== -1) {
        await this.traiteUpdateMethode();
      }
    }
  };

  traiteNewMethode = async () => {
    const { dataMethode } = this.state;
    await addMethode({
      dataMethode,
    });
    await this.chargeMethodes();
    this.setState({
      newMethode: false,
    });
  };

  traiteUpdateMethode = async () => {
    const { selectedMethode, dataMethode } = this.state;
    await updateMethode(selectedMethode, { dataMethode });
    await this.chargeMethodes();
    this.setState({
      newMethode: false,
      selectedMethode: -1,
    });
  };

  render() {
    const {
      sortColumn,
      newMethode,
      dataMethode,
      selectedMethode,
      methodesPaiement,
      tabMethodeForSelect,
      tabCompteForSelect,
      tabMethodeCompteInexistant,
    } = this.state;

    return (
      <React.Fragment>
        <BarreMenu
          type_menu={typeMenuParametresComptabilite}
          menu_actif={menuMethodesPaiement}
        ></BarreMenu>
        {tabMethodeCompteInexistant.length !== 0 && (
          <div className="alert alert-warning">
            Méthode de paiement avec compte(s) inexistant(s) :{" "}
            {tabMethodeCompteInexistant.map((r, index) => (
              <span key={r}>{index !== 0 ? ", " + r : r}</span>
            ))}
          </div>
        )}
        <div className="row">
          <div className="col-12">
            {!newMethode && selectedMethode === -1 && (
              <button
                onClick={this.handleNewMethode}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newMethode}
              >
                Nouvelle méthode de paiement
              </button>
            )}
            <div>
              {(newMethode || selectedMethode !== -1) && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newMethode
                      ? "Nouvelle méthode de paiement"
                      : "Modification de la méthode de paiement Id " +
                        dataMethode._id}
                  </h5>
                  <div className="ml-2 mr-2" style={{ fontSize: "14px" }}>
                    <div className="form-group row">
                      <div className="col-9">
                        <label
                          className="font-weight-bold"
                          htmlFor="designation"
                        >
                          Désignation
                        </label>
                        <input
                          type="text"
                          value={dataMethode.designation}
                          onChange={(event) =>
                            this.handleDesignationChange(event)
                          }
                          className="form-control"
                          placeholder="Désignation"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>

                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="abreviation"
                        >
                          Abréviation
                        </label>
                        <input
                          type="text"
                          value={dataMethode.abreviation}
                          onChange={(event) =>
                            this.handleAbreviationChange(event)
                          }
                          className="form-control"
                          placeholder="Abréviation"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-3">
                        <label className="font-weight-bold" htmlFor="parent">
                          Méthode parente
                        </label>
                        <SelectSearch
                          options={tabMethodeForSelect}
                          value={dataMethode.parentId}
                          name="parent"
                          search={true}
                          onChange={this.handleParentSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isCaisseActif"
                            checked={dataMethode.isCaisseActif}
                            onChange={this.handleIsCaisseActifChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isCaisseActif"
                          >
                            Utilisable dans la Caisse
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <label
                          className="font-weight-bold"
                          htmlFor="texteReleveBanque"
                        >
                          Texte à rechercher / import des relevés de banque
                        </label>
                        <input
                          type="text"
                          value={dataMethode.texteReleveBanque}
                          onChange={(event) =>
                            this.handleTexteReleveBanqueChange(event)
                          }
                          className="form-control"
                          placeholder="Texte à rechercher / import des relevés de banque"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-6">
                        <label className="font-weight-bold" htmlFor="parent">
                          Compte d'encaissement
                        </label>
                        <SelectSearch
                          options={tabCompteForSelect}
                          value={dataMethode.compteAEncaisserId}
                          name="parent"
                          search={true}
                          onChange={this.handleCompteAEncaisserSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                      <div className="col-6">
                        <label className="font-weight-bold" htmlFor="parent">
                          Compte à imputer en cas d'erreur
                        </label>
                        <SelectSearch
                          options={tabCompteForSelect}
                          value={dataMethode.compteErreurId}
                          name="parent"
                          search={true}
                          onChange={this.handleCompteErreurSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitMethode}
                      disabled={dataMethode.designation.length === 0}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewMethode}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>

            {methodesPaiement && (
              <ListeMethodesPaiement
                methodesPaiement={methodesPaiement}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onSelectCodeJournal={this.onSelectCodeJournal}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MethodesPaiement;
