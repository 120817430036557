import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import TableBloc from "./tableBloc";

const Table = ({ columns, sortColumn, onSort, data, blocs, noHeader }) => {
  const avecHeader = noHeader === undefined || !noHeader;
  return (
    <React.Fragment>
      {blocs && blocs.length !== 0 && (
        <div>
          <TableBloc blocs={blocs} data={data} />
        </div>
      )}
      {!blocs && (
        <table
          className="table table-bordered table-striped"
          style={{ fontSize: "0.75em" }}
        >
          {avecHeader && (
            <TableHeader
              columns={columns}
              sortColumn={sortColumn}
              onSort={onSort}
            />
          )}
          <TableBody columns={columns} data={data} />
        </table>
      )}
    </React.Fragment>
  );
};

export default Table;
