import http from "./httpService";
import { urlApiGeneral, urlApiComptabilite } from "../config.js";

export async function getVentes(param) {
  return await http.post(urlApiGeneral + "/stats/getVentes", param);
}

export async function getTauxTVAVentes() {
  return await http.get(urlApiGeneral + "/stats/getTauxTVAVentes");
}

export async function getCAMoyenAndSaisonnalite(param) {
  return await http.post(
    urlApiComptabilite + "/stats/getCAMoyenAndSaisonnalite",
    param
  );
}

export async function getStatsProduit(param) {
  return await http.post(urlApiGeneral + "/stats/getStatsProduit", param);
}

export async function getStatsTypeCommande(param) {
  return await http.post(
    urlApiComptabilite + "/stats/getStatsTypeCommande",
    param
  );
}

export async function getStatsAnciennete(param) {
  return await http.post(
    urlApiComptabilite + "/stats/getStatsAnciennete",
    param
  );
}

// eslint-disable-next-line
export default {
  getVentes,
};
