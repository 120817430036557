export const typeMenuParametresSocietes = 206;
export const typeMenuParametresComptabilite = 209;
export const typeMenuParametresBanque = 210;

export const menuPersonnesPhysiques = 1;
export const menuSocietes = 2;
export const menuCodesAPE = 3;
export const menuActionnairesAndGerants = 4;

export const menuCodesJournal = 1;
export const menuMethodesPaiement = 2;
export const menuTypesEcriture = 3;
export const menuTauxInteretCreances = 4;
export const menuComptes = 5;

export const menuComptesBanque = 1;
export const menuScanRelevesComptesBanque = 2;
