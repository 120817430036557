import React, { Component } from "react";
import Table from "../common/table";

class ListePersonnePhysique extends Component {
  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (p) => (
        <React.Fragment>
          <div
            className="w-100 text-nowrap"
            style={{
              fontSize: "14px",
            }}
          >
            <a
              className="btn btn-link"
              role="button"
              href="#/"
              onClick={() => this.props.onSelectPersonne(p)}
            >
              <i className="fas fa-edit mr-1" style={{ fontSize: "18px" }}></i>
              {p._id}
            </a>
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "nom",
      label: "Nom",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (p) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{p.nom}</div>
        </React.Fragment>
      ),
    },
    {
      path: "prenom",
      label: "Prénom",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (p) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{p.prenom}</div>
        </React.Fragment>
      ),
    },
    {
      path: "sexe",
      label: "Sexe",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (p) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{p.sexe}</div>
        </React.Fragment>
      ),
    },
    {
      path: "clientId",
      label: "Référence Client",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (p) => (
        <React.Fragment>
          {p.clientId !== 0 && (
            <div style={{ fontSize: "14px" }}>
              {p.clientId} - {p.clientNom} {p.clientPrenom}{" "}
              {p.clientNomNaissance}
            </div>
          )}
          {p.clientId === 0 && <div style={{ fontSize: "14px" }}>Aucun</div>}
        </React.Fragment>
      ),
    },
    {
      path: "empId",
      label: "Référence Employé",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (p) => (
        <React.Fragment>
          {p.employeId !== 0 && (
            <div style={{ fontSize: "14px" }}>
              {p.employeId} - {p.employeNom} {p.employePrenom}{" "}
              {p.employeNomNaissance}
            </div>
          )}
          {p.employeId === 0 && <div style={{ fontSize: "14px" }}>Aucun</div>}
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { personnes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={personnes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListePersonnePhysique;
