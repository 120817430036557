import React, { Component } from "react";
import Table from "../common/table";
import BlocIntroSociete from "./blocIntroSociete";
import BlocActionnaires from "./blocActionnaires";
import BlocGerants from "./blocGerants";

class ListeSocieteActionnairesGerants extends Component {
  blocs = [
    {
      path: "blocIntro",
      content: (societe) => <BlocIntroSociete societe={societe} />,
      className: "col-2 bg-light border rounded mr-1 pt-1 pb-1",
    },
    {
      path: "blocActionnaires",
      content: (societe) => (
        <BlocActionnaires
          societe={societe}
          handleSupprActionnaire={this.props.handleSupprActionnaire}
          handleNewActionnaire={this.props.handleNewActionnaire}
          newActionnaire={this.props.newActionnaire}
          selectedActionnaireId={this.props.selectedActionnaireId}
          newActionnaireSocieteId={this.props.newActionnaireSocieteId}
          dataActionnaire={this.props.dataActionnaire}
          handleTypePersonneActionnaireChange={
            this.props.handleTypePersonneActionnaireChange
          }
          handleTypePersonneActionnaireClick={
            this.props.handleTypePersonneActionnaireClick
          }
          tabSocieteForSelect={this.props.tabSocieteForSelect}
          tabPMExclusActionnaire={this.props.tabPMExclusActionnaire}
          handlePersonneActionnaireSelect={
            this.props.handlePersonneActionnaireSelect
          }
          tabPPForSelect={this.props.tabPPForSelect}
          tabPPExclusActionnaire={this.props.tabPPExclusActionnaire}
          handleIsInternePhysiqueChange={
            this.props.handleIsInternePhysiqueChange
          }
          maxTotalParticipation={this.props.maxTotalParticipation}
          handleParticipationChange={this.props.handleParticipationChange}
          handleDateActionnaireChange={this.props.handleDateActionnaireChange}
          handleDateToActionnaireToChange={
            this.props.handleDateToActionnaireToChange
          }
          tabCompteForSelect={this.props.tabCompteForSelect}
          tabCompteExclus={this.props.tabCompteExclus}
          handleCCSelect={this.props.handleCCSelect}
          handleCancelNewActionnaire={this.props.handleCancelNewActionnaire}
          handleSubmitActionnaire={this.props.handleSubmitActionnaire}
          onSelectActionnaire={this.props.onSelectActionnaire}
          handleGetColor={this.props.handleGetColor}
        />
      ),
      className: "col-5 bg-light border rounded mr-1 pt-1 pb-1",
    },
    {
      path: "blocGerants",
      content: (societe) => (
        <BlocGerants
          tabSocieteForSelect={this.props.tabSocieteForSelect}
          tabPPForSelect={this.props.tabPPForSelect}
          societe={societe}
          dataGerant={this.props.dataGerant}
          handleSupprGerant={this.props.handleSupprGerant}
          handleNewGerant={this.props.handleNewGerant}
          newGerant={this.props.newGerant}
          newGerantSocieteId={this.props.newGerantSocieteId}
          handleCancelNewGerant={this.props.handleCancelNewGerant}
          handleTypeGerantActionnaireChange={
            this.props.handleTypeGerantActionnaireChange
          }
          handleTypePersonneGerantClick={
            this.props.handleTypePersonneGerantClick
          }
          tabPMExclusGerant={this.props.tabPMExclusGerant}
          handlePersonneGerantSelect={this.props.handlePersonneGerantSelect}
          tabPPExclusGerant={this.props.tabPPExclusGerant}
          handleDateGerantChange={this.props.handleDateGerantChange}
          handleDateToGerantChange={this.props.handleDateToGerantChange}
          handleSubmitGerant={this.props.handleSubmitGerant}
          onSelectGerant={this.props.onSelectGerant}
          handleGetColor={this.props.handleGetColor}
        />
      ),
      className: "col-4 bg-light border rounded mr-1 pt-1 pb-1",
    },
  ];

  render() {
    const { societes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={societes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeSocieteActionnairesGerants;
