import React, { Component } from "react";
import { Link } from "react-router-dom";
import SelectSearch from "react-select-search";
import { confirmAlert } from "react-confirm-alert";
import NumberFormat from "react-number-format";
import _ from "lodash";
import moment from "moment";
import {
  getExportFECList,
  getContentFEC,
  deleteExportFEC,
  updateFECDataImport,
} from "../services/fecService";
import auth from "../services/authService";

import { minAnneeFec, dateTimeTransform } from "../utils/tools";

class ExportFec extends Component {
  state = {
    societes: [],
    annees: [],
    selectedAnnee: null,
    traitEnCours: false,
  };

  async componentDidMount() {
    let annees = [];
    const annee = new Date().getFullYear();
    const laMinAnneeFec = minAnneeFec();
    for (let index = annee; index >= 2014; index--) {
      annees.push({
        value: index.toString(),
        name: index.toString(),
      });
    }
    const defaultAnnee = annees.find(
      (a) => parseInt(a.value) === laMinAnneeFec
    );
    const result = await this.traitExport(defaultAnnee.value);

    this.setState({
      societes: result.dataSocietes,
      exportsSupprimables: result.exportsSupprimables,
      annees,
      selectedAnnee: defaultAnnee.value,
    });
  }

  traitExport = async (annee) => {
    const resultFEC = await getExportFECList(annee);
    const dataSocietes = resultFEC.data.data;
    let exportsSupprimables = [];
    dataSocietes.map((item) => {
      item.SIREN = item.siret.replaceAll(" ", "").substring(0, 9);
      const distinctAnnees = [
        ...new Set(item.tabExport.map((x) => x.dateDebut)),
      ];
      distinctAnnees.map((d) => {
        let exportAnnees = item.tabExport.filter((x) => x.dateDebut === d);
        exportAnnees = _.sortBy(exportAnnees, "dateAjout").reverse();
        exportsSupprimables.push(exportAnnees[0]._id);
        return true;
      });
      return true;
    });
    return { dataSocietes, exportsSupprimables };
  };

  handleAnneeSelect = (annee) => {
    this.setState({
      selectedAnnee: annee,
    });
  };

  handleValiderClick = async (event) => {
    event.preventDefault();
    const { traitEnCours, selectedAnnee } = this.state;
    if (!traitEnCours) {
      this.setState({ traitEnCours: true });
      const result = await this.traitExport(selectedAnnee);

      this.setState({
        societes: result.dataSocietes,
        exportsSupprimables: result.exportsSupprimables,
        traitEnCours: false,
        traitFait: true,
      });
    }
  };

  handleDownLoadOnClick = async (soc, exp) => {
    const result = await getContentFEC(exp._id);
    const file = new Blob([result.data], { type: "text/plain" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    //window.open(fileURL);
    let a = document.createElement("a");
    a.href = fileURL;
    a.download =
      soc.SIREN + "HOP" + exp.dateFinAAAAMMJJ + "_" + exp._id + ".txt";
    a.click();
  };

  handleImportOnClick = async (item, exp) => {
    let { societes } = this.state;
    const ret = await updateFECDataImport({
      id: exp._id,
      isImporte: exp.isImporte === 0 ? 1 : 0,
      userId: auth.getCurrentUser()._id,
    });

    societes.map((x) => {
      if (x._id === item._id) {
        x.tabExport.map((y) => {
          if (y._id === exp._id) {
            y.isImporte = ret.data.data.isImporte;
            y.dateImport = ret.data.data.dateImport;
          }
          return true;
        });
      }
      return true;
    });
    this.setState({ societes: societes });
  };

  handleDeleteOnClick = async (exp) => {
    confirmAlert({
      title: "Suppression de l'export : ",
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            await deleteExportFEC(exp._id);
            window.location.reload(false);
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  render() {
    const {
      societes,
      exportsSupprimables,
      annees,
      selectedAnnee,
      traitEnCours,
    } = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <h2>Liste des exports Fec</h2>
          <Link
            role="button"
            to="/generefec"
            className="btn btn-primary text-white mt-2 mb-2"
          >
            Nouvelle génération
          </Link>
          <div className="row">
            <label className="ml-3 mr-2 mt-1">
              Uniquement les export concernant les exercices depuis
            </label>
            <div>
              <SelectSearch
                options={annees}
                value={selectedAnnee}
                name="annee"
                onChange={this.handleAnneeSelect}
              />
            </div>
            <div className="ml-2">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderClick}
              >
                Valider
              </button>
              {traitEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <table
            className="table table-bordered table-striped mt-4"
            style={{ fontSize: "12px" }}
          >
            <tbody>
              {societes.map((item) => (
                <tr className="row mr-0 ml-0" key={item._id}>
                  <td className="col-1" style={{ fontSize: "12px" }}>
                    <div className="font-weight-bold">{item.denomination}</div>
                    <div className="font-weight-bold">
                      Fin 1er exercice{" "}
                      {moment(item.dateFinExoAAAAMMJJ_1, "YYYYMMDD").format(
                        "YYYY"
                      )}
                    </div>
                    <div>SIREN {item.SIREN}</div>
                  </td>
                  <td className="col-11">
                    <table
                      className="table table-striped"
                      style={{ fontSize: "12px" }}
                    >
                      <thead>
                        <tr className="row mr-0 ml-0">
                          <th className="col-2">Exécution</th>
                          <th className="col-2">Période</th>
                          <th className="col-1 text-right">Nb Ligne</th>
                          <th className="col-1 text-right">Débit</th>
                          <th className="col-1 text-right">Crédit</th>
                          <th className="col-2">Infos import</th>
                          <th className="col-3"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.tabExport.map((exp, index) => (
                          <tr className="row mr-0 ml-0" key={exp._id}>
                            <td className="col-2">
                              <span
                                className={
                                  "badge " +
                                  (exp.isOk === 1
                                    ? "badge-secondary"
                                    : "badge-danger")
                                }
                              >
                                {index + 1}
                              </span>
                              <span className="ml-2 mr-2">
                                {dateTimeTransform(exp.dateAjout)}
                              </span>
                              <span>{exp.userPrenom}</span>
                            </td>
                            <td className="col-2">
                              du {exp.dateDebut} au {exp.dateFin}
                            </td>
                            <td className="col-1 text-right">
                              <NumberFormat
                                value={exp.nbLigne}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                              />
                            </td>
                            <td className="col-1 text-right text-nowrap">
                              {exp.isOk === 1 && (
                                <NumberFormat
                                  value={exp.totalDebit}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator=" "
                                  displayType="text"
                                />
                              )}
                            </td>
                            <td className="col-1 text-right text-nowrap">
                              {exp.isOk === 1 && (
                                <NumberFormat
                                  value={exp.totalCredit}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator=" "
                                  displayType="text"
                                />
                              )}
                            </td>
                            <td className="col-2">
                              <button
                                className="btn btn-outline-secondary btn-sq-xs mr-2"
                                fontSize="20px"
                                onClick={() =>
                                  this.handleImportOnClick(item, exp)
                                }
                              ></button>
                              {exp.isImporte === 1 && (
                                <span>Effectué le {exp.dateImport}</span>
                              )}
                            </td>
                            <td className="col-3">
                              {exp.isOk === 1 && (
                                <button
                                  className="btn btn-outline-secondary"
                                  onClick={() =>
                                    this.handleDownLoadOnClick(item, exp)
                                  }
                                >
                                  <i
                                    className="fas fa-cloud-download-alt"
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                              {exp.isOk !== 1 && (
                                <Link
                                  role="button"
                                  to={`/detailerreursfec/${exp._id}`}
                                  className="btn btn-outline-secondary"
                                >
                                  <i
                                    className="fa fa-eye text-danger"
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              )}
                              {exportsSupprimables.includes(exp._id) && (
                                <button
                                  className="btn btn-outline-secondary ml-2"
                                  onClick={() => this.handleDeleteOnClick(exp)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ExportFec;
