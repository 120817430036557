import React, { Component } from "react";
import Table from "../common/table";

class ListeScanRelevesCompte extends Component {
  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <div
          className="w-100 text-nowrap"
          style={{
            fontSize: "14px",
          }}
          id={"param_" + s._id}
        >
          <a
            className="btn btn-link"
            role="button"
            href="#/"
            onClick={() => this.props.onSelectParam(s)}
          >
            <i className="fas fa-edit mr-1" style={{ fontSize: "18px" }}></i>
            {s._id}
          </a>
        </div>
      ),
    },

    {
      path: "commentaire",
      label: "Commentaire",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{s.commentaire}</div>
        </React.Fragment>
      ),
    },
    {
      path: "typeEcritureNom",
      label: "Type d'écriture",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>
            {s.typeEcritureId} - {s.typeEcritureNom}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "contactDefautNom",
      label: "Contact",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          {s.contactDefautId &&
            s.contactDefautNom &&
            s.contactDefautNom.length !== 0 && (
              <div style={{ fontSize: "14px" }}>
                {s.contactDefautId} - {s.contactDefautNom}
              </div>
            )}
          {s.contactDefautId && s.contactDefautNom.length === 0 && (
            <div style={{ fontSize: "14px" }}>{s.contactDefautId}</div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "string1",
      label: "Texte recherché n°1",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{s.string1}</div>
        </React.Fragment>
      ),
    },
    {
      path: "sousString1",
      label: "Présence du texte n°1 / position",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          {s.sousString1.length !== 0 && (
            <div style={{ fontSize: "14px" }}>
              {s.sousString1} / {s.positionString1}
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "string2",
      label: "Texte recherché n°2",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{s.string2}</div>
        </React.Fragment>
      ),
    },
    {
      path: "sousString2",
      label: "Présence du texte n°2 / position",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          {s.sousString2.length !== 0 && (
            <div style={{ fontSize: "14px" }}>
              {s.sousString2} / {s.positionString2}
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "isAutoConfirme",
      label: "Confirmation automatique",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (s) => (
        <React.Fragment>
          {s.isAutoConfirme === 1 && (
            <div className={"text-center"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check "
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { scans, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={scans}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeScanRelevesCompte;
