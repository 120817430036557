import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";
import { isMenuAuthorized } from "../../services/mapsService";

const ProtectedRoute = ({
  droit,
  path,
  component: Component,
  render,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.getCurrentUser())
          return (
            <React.Fragment>
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.match.url },
                }}
              />
            </React.Fragment>
          );
        if (auth.isTokenExpired())
          return (
            <React.Fragment>
              {/* <Redirect
            to={{
              pathname: "/logout/"+droit              
            }}
          /> */}
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.match.url },
                }}
              />
            </React.Fragment>
          );

        if (!isMenuAuthorized(droit) && droit !== "mobilemonprofil") {
          return <Redirect to="/not-authorized" />;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
