import React, { Component } from "react";
import LigneGroupeEcriture from "./ligneGroupeEcriture";
//import MyAutosuggest from "./common/myAutoSuggest";
//import { makeId } from "../utils/tools";

class ListeLigneGroupe extends Component {
  render() {
    const {
      lignes,
      nbLigne,
      comptes,
      comptesForSelect,
      enteteGroupe,
      groupeModifiable,
    } = this.props;

    return (
      <tbody>
        {lignes.map((x, index) => (
          <tr className="row mr-0 ml-0" key={x._id}>
            <LigneGroupeEcriture
              key={index}
              enteteGroupe={enteteGroupe}
              groupeModifiable={groupeModifiable}
              ligne={x}
              index={index}
              nbLigne={nbLigne}
              handleChangeCompte={this.props.handleChangeCompte}
              handleDebitChange={this.props.handleDebitChange}
              handleCreditChange={this.props.handleCreditChange}
              handleClickNouvelleLigne={this.props.handleClickNouvelleLigne}
              handleSupprLigne={this.props.handleSupprLigne}
              comptesFull={comptes}
              comptesForSelect={comptesForSelect}
              isLastLigne={index === lignes.length - 1}
            ></LigneGroupeEcriture>
          </tr>
        ))}
      </tbody>
    );
  }
}

export default ListeLigneGroupe;
