import React, { Component } from "react";

import { fantoirImport } from "../../services/fantoirService";
import { updateBanoData } from "../../services/latLonService";

class ImportFantoir extends Component {
  state = {
    traitEnCoursFantoir: false,
    traitEnCoursBano: false,
    fichiers: null,
    shouldShowBox: false,
    erreurFantoir: "",
    nblignesTraitees: -1,
    successBano: null,
  };
  async componentDidMount() {
    this.setState({
      shouldShowBox: true,
    });
  }

  handleValiderClick = async (event) => {
    event.preventDefault();
    let erreurFantoir = "";
    const { traitEnCoursFantoir, traitEnCoursBano } = this.state;
    if (!traitEnCoursFantoir && !traitEnCoursBano) {
      this.setState({ traitEnCoursFantoir: true });
      const data = new FormData();
      Array.from(this.state.fichiers).forEach((file) => {
        data.append("file", file);
      });
      const result = await fantoirImport(data);
      let nblignesTraitees = 0;
      if (result.data.success) {
        nblignesTraitees = result.data.data.nbLignesTraitees;
      } else {
        erreurFantoir = result.data.message;
      }
      this.setState({
        traitEnCoursFantoir: false,
        nblignesTraitees,
        erreurFantoir,
      });
    }
  };

  handleValiderBanoClick = async (event) => {
    event.preventDefault();
    const { traitEnCoursFantoir, traitEnCoursBano, nblignesTraitees } =
      this.state;
    if (!traitEnCoursFantoir && !traitEnCoursBano) {
      this.setState({ traitEnCoursBano: true });
      const result = await updateBanoData();
      const successBano = result.data.success;

      this.setState({
        traitEnCoursBano: false,
        nblignesTraitees,
        successBano,
      });
    }
  };

  handleSetFile = async (e) => {
    this.setState({ fichiers: e });
  };

  render() {
    const {
      traitEnCoursFantoir,
      traitEnCoursBano,
      fichiers,
      nblignesTraitees,
      erreurFantoir,
      successBano,
    } = this.state;

    return (
      <React.Fragment>
        <h2 className="mb-2">Import Fantoir</h2>
        <div className="alert alert-info">
          Actualise les rues des villes
          <br />
          Procédure :
          <br />
          Importer le fichier compressé depuis
          <a
            className="ml-1"
            href="https://www.collectivites-locales.gouv.fr/competences/la-mise-disposition-gratuite-du-fichier-des-voies-et-des-lieux-dits-fantoir"
            target="_blank"
            rel="noreferrer"
          >
            https://www.collectivites-locales.gouv.fr/competences/la-mise-disposition-gratuite-du-fichier-des-voies-et-des-lieux-dits-fantoir
          </a>
          <br />
          Décompresser le fichier compressé pour obtenir un fichier par
          département (ex : 450.txt)
        </div>
        <div className="row">
          <div className="col-4">
            <div className="mt-1">
              <div className="form-group upload">
                <label className="font-weight-bold" htmlFor="file">
                  Fichier FANTOIR
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  name="file"
                  accept=".txt"
                  onChange={(e) => this.handleSetFile(e.target.files)}
                />
              </div>
              {(fichiers === null || Array.from(fichiers).length === 0) && (
                <div className="alert alert-danger mt-2">
                  Fichier obligatoire
                </div>
              )}
            </div>

            <div className="mt-2">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderClick}
                disabled={
                  traitEnCoursBano ||
                  fichiers === null ||
                  Array.from(fichiers).length === 0
                }
              >
                Valider
              </button>
              {traitEnCoursFantoir && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-6">
            {!traitEnCoursFantoir && nblignesTraitees > 0 && (
              <div className="text-success font-weight-bold">
                Compte-rendu : {nblignesTraitees} lignes traitées
              </div>
            )}
            {!traitEnCoursFantoir && erreurFantoir.length !== 0 && (
              <div className="text-danger font-weight-bold">
                Compte-rendu : {erreurFantoir}
              </div>
            )}
          </div>
        </div>
        <h2 className="mt-2 mb-2">
          Mise à jour Base d'Adresses Nationale Ouverte
        </h2>
        <div className="alert alert-info">
          Actualise les coordonnées GPS des adresses (hors affectation manuelle
          et affectation par le client)
        </div>
        <div className="row">
          <div className="col-4">
            <div className="mt-2">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderBanoClick}
                disabled={traitEnCoursFantoir}
              >
                Valider
              </button>
              {traitEnCoursBano && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-6">
            {!traitEnCoursBano && successBano && (
              <div className="text-success font-weight-bold">
                Traitement terminé
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ImportFantoir;
