import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";

import { getAllFournisseursPizztList } from "../../services/contactService";
import {
  getFlatListeCommandes,
  getAllCaisseCategorieProduit,
  getMaxDateInventaire,
} from "../../services/produitsFournisseursService";
import { getPizzerias } from "../../services/pizzeriaService";
import SelectSearch from "react-select-search";
import Table from "../common/table";

class FlatListeCommande extends Component {
  state = {
    searchQuery: "",
    date: "",
    newFournisseur: false,
    tabFournisseursForSelect: [],
    selectedFournisseur: "-1",
    tabPizzeriaId: [],
    tabFournisseurId: [],
    tabCommande: [],
    tabSynthese: [],
    sortColumnSynthese: { path: "categoriePos", order: "asc" },
    tabProduitSansFournisseur: [],
    tabProduitSansCondFournisseur: [],
    categoriesForSelect: [],
    selectedCategories: [],
    allPizzeriasForSelect: [],
    pizzsForSelect: [],
    traitEnCours: false,
    showSynthese: true,
    showDetail: true,
    shouldShowBox: false,
  };

  columnsSynthese = [
    {
      path: "qteASaisir",
      label: "Qte A saisir",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.qteASaisir}</span>,
    },
    {
      path: "qteACommander",
      label: "A commander",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.qteACommander}</span>
      ),
    },
    {
      path: "constX",
      label: "",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.constX}</span>,
    },
    {
      path: "produitNomCommande",
      label: "Nom Commande",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.produitNomCommande}</span>
      ),
    },
    {
      path: "prefixeRefF",
      label: "",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.prefixeRefF}</span>,
    },
    {
      path: "produitRF",
      label: "Ref. F",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.produitRF}</span>,
    },
    {
      path: "suffixeRefF",
      label: "",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.suffixeRefF}</span>,
    },
    {
      path: "produitDesignation",
      label: "Designation",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.produitDesignation}</span>
      ),
    },
    {
      path: "produitId",
      label: "Id Produit",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.produitId}</span>,
    },
    {
      path: "categoriePos",
      label: "Catégorie",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.categorieNom}</span>
      ),
    },
    {
      path: "produitUniteMesureAbr",
      label: "Unité",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.produitUniteMesureAbr}</span>
      ),
    },
    {
      path: "fournisseurNom",
      label: "Fournisseur",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>
          {x.fournisseurId} - {x.fournisseurNom}
        </span>
      ),
    },
    {
      path: "fournisseurCond",
      label: "Four. Cond.",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.fournisseurCond}</span>
      ),
    },
    {
      path: "inventaireCond",
      label: "Courses Cond.",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireCond}</span>
      ),
    },
    {
      path: "inventaireNb",
      label: "Courses Nb",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireNb}</span>
      ),
    },
    {
      path: "inventaireBesoin",
      label: "Courses Besoin",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireBesoin}</span>
      ),
    },
    {
      path: "inventaireALivrer",
      label: "Courses A livrer",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireALivrer}</span>
      ),
    },
  ];

  async componentDidMount() {
    const resultDateInventaire = await getMaxDateInventaire();
    let date = resultDateInventaire.data.data.dateInventaireAAAAMMJJ;
    const resultFourn = await getAllFournisseursPizztList();
    const tabFournisseurId = [];
    let tabFournisseursForSelect = [];

    resultFourn.data.data
      .filter((f) => f.email && f.email.length !== 0)
      .forEach((item) => {
        tabFournisseursForSelect.push({
          value: item._id.toString(),
          name: item.nom,
        });
        tabFournisseurId.push(item._id);
      });
    tabFournisseursForSelect = _.orderBy(
      tabFournisseursForSelect,
      ["name"],
      ["asc"]
    );

    tabFournisseursForSelect.unshift({
      value: "-1",
      name: "Tous",
    });

    const resultPizzerias = await getPizzerias();
    const tabPizzeriaId = [];
    const pizzsForSelect = [];
    let allPizzeriasForSelect = [];
    resultPizzerias.data.data
      .filter((item) => item.hasBesoinPlanning === 1)
      .forEach((item) => {
        allPizzeriasForSelect.push({
          value: item._id.toString(),
          name: item.denomination,
        });
        tabPizzeriaId.push(item._id);
        pizzsForSelect.push(item._id.toString());
      });
    allPizzeriasForSelect = _.orderBy(allPizzeriasForSelect, ["name"], ["asc"]);

    const tabCategorieId = [];
    const resultCategorie = await getAllCaisseCategorieProduit();
    let categoriesForSelect = [];
    let selectedCategories = [];
    resultCategorie.data.data.map((item, index) => {
      categoriesForSelect.push({
        value: item._id.toString(),
        name: item.nom,
      });
      tabCategorieId.push(item._id);
      selectedCategories.push(item._id.toString());
      return true;
    });

    const resultCommande = await getFlatListeCommandes({
      tabSocieteId: tabPizzeriaId,
      date,
      tabFournisseurId,
      tabCategorieId,
    });

    this.setState({
      date,
      tabPizzeriaId,
      tabFournisseurId,
      tabFournisseursForSelect,
      tabCommande: resultCommande.data.data.liste,
      tabSynthese: resultCommande.data.data.synthese,
      tabProduitSansFournisseur:
        resultCommande.data.data.tabProduitSansFournisseur,
      tabProduitSansCondFournisseur:
        resultCommande.data.data.tabProduitSansCondFournisseur,
      categoriesForSelect,
      selectedCategories,
      allPizzeriasForSelect,
      pizzsForSelect,
      shouldShowBox: true,
    });
  }

  handleFournisseurSelect = (event) => {
    this.setState({
      selectedFournisseur: event.target.value,
    });
  };

  handleSelectAllCategorieClick = (event) => {
    event.preventDefault();
    const { categoriesForSelect } = this.state;
    let selectedCategories = [];
    categoriesForSelect.map((item) => {
      selectedCategories.push(item.value);
      return true;
    });
    this.setState({
      selectedCategories,
    });
  };

  handleUnSelectAllCategorieClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedCategories: [],
    });
  };

  handleCategoriesSelect = (categories) => {
    this.setState({
      selectedCategories: categories,
    });
  };

  handleSelectAllPizzClick = (event) => {
    event.preventDefault();
    const { allPizzeriasForSelect } = this.state;
    let pizzsForSelect = [];
    allPizzeriasForSelect.map((item) => {
      pizzsForSelect.push(item.value);
      return true;
    });
    this.setState({
      pizzsForSelect,
    });
  };

  handleUnSelectAllPizzClick = (event) => {
    event.preventDefault();
    this.setState({
      pizzsForSelect: [],
    });
  };

  handlePizzsSelect = (pizzs) => {
    this.setState({
      pizzsForSelect: pizzs,
    });
  };

  getPagedDataSynthese = () => {
    const { tabSynthese, sortColumnSynthese, shouldShowBox } = this.state;
    if (shouldShowBox) {
      let filtered = tabSynthese;

      let sorted = [];
      if (sortColumnSynthese.path === "categoriePos") {
        sorted = _.orderBy(
          filtered,
          [sortColumnSynthese.path, "produitDesignation"],
          [sortColumnSynthese.order, "asc"]
        );
      } else {
        sorted = _.orderBy(
          filtered,
          [sortColumnSynthese.path, "categoriePos", "produitDesignation"],
          [sortColumnSynthese.order, "asc", "asc"]
        );
      }

      return { dataSynthese: sorted };
    }
    return { dataSynthese: [] };
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSortSynthese = (sortColumnSynthese) => {
    this.setState({ sortColumnSynthese });
  };

  handleDateChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      this.setState({ date: moment(day).format("YYYYMMDD") });
    }
  };

  handleValiderClick = async () => {
    const {
      date,
      pizzsForSelect,
      selectedFournisseur,
      tabFournisseurId,
      selectedCategories,
    } = this.state;
    await this.handleExecuteValider(
      date,
      pizzsForSelect,
      selectedFournisseur,
      tabFournisseurId,
      selectedCategories
    );
  };

  handleExecuteValider = async (
    date,
    pizzsForSelect,
    selectedFournisseur,
    tabFournisseurId,
    selectedCategories
  ) => {
    if (!this.state.loading) this.setState({ loading: true });
    let tabId = [];
    if (selectedFournisseur === "-1") {
      tabId = tabFournisseurId;
    } else {
      tabId = [parseInt(selectedFournisseur)];
    }
    const tabSocieteId = [];
    pizzsForSelect.forEach((x) => tabSocieteId.push(parseInt(x)));
    const tabCategorieId = [];
    selectedCategories.forEach((x) => tabCategorieId.push(parseInt(x)));
    const resultCommande = await getFlatListeCommandes({
      tabSocieteId,
      date: date,
      tabFournisseurId: tabId,
      tabCategorieId,
    });
    if (resultCommande.data.success) {
      this.setState({
        tabCommande: resultCommande.data.data.liste,
        tabSynthese: resultCommande.data.data.synthese,
        tabProduitSansFournisseur:
          resultCommande.data.data.tabProduitSansFournisseur,
        tabProduitSansCondFournisseur:
          resultCommande.data.data.tabProduitSansCondFournisseur,
      });
    }
    this.setState({ loading: false });
  };

  handleClickCollapseSynthese = () => {
    const { showSynthese } = this.state;
    this.setState({ showSynthese: !showSynthese });
  };

  handleClickCollapseDetail = () => {
    const { showDetail } = this.state;
    this.setState({ showDetail: !showDetail });
  };

  render() {
    const {
      date,
      tabFournisseursForSelect,
      selectedFournisseur,
      sortColumnSynthese,
      tabProduitSansFournisseur,
      tabProduitSansCondFournisseur,
      categoriesForSelect,
      selectedCategories,
      allPizzeriasForSelect,
      pizzsForSelect,
      shouldShowBox,
      showSynthese,
      showDetail,
      tabCommande,
    } = this.state;

    const { dataSynthese } = this.getPagedDataSynthese();
    const nbLigne = tabCommande.filter((x) => x.type === 3).length;

    return (
      <div className="row">
        <div className="col-12">
          <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
            <h2>Liste des produits à commander</h2>

            {!shouldShowBox && (
              <div className="mt-5 mb-5">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {shouldShowBox && (
              <div className="row">
                <div className="d-none d-md-block col-2">
                  <div className="mt-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handleValiderClick()}
                      disabled={
                        selectedCategories.length === 0 ||
                        pizzsForSelect.length === 0
                      }
                    >
                      Valider la sélection
                    </button>
                  </div>
                  <div className="mt-2">
                    <label htmlFor="date" className="font-weight-bold mr-1">
                      Date :
                    </label>
                    <input
                      type="date"
                      value={moment(date).format("YYYY-MM-DD")}
                      onChange={(date) => this.handleDateChange(date)}
                    ></input>
                  </div>
                  <div className="mt-2">
                    <label htmlFor="fournisseurs" className="font-weight-bold">
                      Fournisseur :
                    </label>
                    <select
                      name="fournisseurs"
                      id="fournisseurs"
                      value={selectedFournisseur}
                      onChange={this.handleFournisseurSelect}
                      className="form-control"
                    >
                      {tabFournisseursForSelect.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    className="mt-3"
                    style={{ height: "500px", marginBottom: 0 }}
                  >
                    <div>
                      <label htmlFor="societes" className="font-weight-bold">
                        Pizzerias sélectionnées :
                      </label>
                    </div>
                    <div className="btn-group">
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "12px" }}
                        onClick={this.handleSelectAllPizzClick}
                      >
                        Tout sélectionner
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "12px" }}
                        onClick={this.handleUnSelectAllPizzClick}
                      >
                        Tout désélectionner
                      </button>
                    </div>
                    <div style={{ marginTop: "-30px" }}>
                      <SelectSearch
                        options={allPizzeriasForSelect}
                        value={pizzsForSelect}
                        name="pizzeria"
                        multiple
                        onChange={this.handlePizzsSelect}
                      />
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{ height: "450px", marginBottom: 0 }}
                  >
                    <div>
                      <label htmlFor="societes" className="font-weight-bold">
                        Catégories sélectionnées :
                      </label>
                    </div>
                    <div className="btn-group">
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "12px" }}
                        onClick={this.handleSelectAllCategorieClick}
                      >
                        Tout sélectionner
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "12px" }}
                        onClick={this.handleUnSelectAllCategorieClick}
                      >
                        Tout désélectionner
                      </button>
                    </div>
                    <div style={{ marginTop: "-30px" }}>
                      <SelectSearch
                        options={categoriesForSelect}
                        value={selectedCategories}
                        name="categorie"
                        multiple
                        onChange={this.handleCategoriesSelect}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-10">
                  {tabProduitSansFournisseur.length > 0 && (
                    <div
                      className="row alert alert-warning"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="col-12">
                        Produit(s) sans fournisseur :
                      </div>
                      <ul className="col-12">
                        {tabProduitSansFournisseur.map((p) => (
                          <li key={p._id}>
                            {p._id} {p.designation}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {tabProduitSansCondFournisseur.length > 0 && (
                    <div
                      className="row alert alert-warning"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="col-12">
                        Produit(s) avec un conditionnement fournisseur à 0 :
                      </div>
                      <ul className="col-12">
                        {tabProduitSansCondFournisseur.map((p) => (
                          <li key={p._id}>
                            {p._id} {p.designation}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div
                    className="col-12 font-weight-bold mb-2"
                    onClick={() => this.handleClickCollapseSynthese()}
                    style={{ cursor: "pointer", color: "#ffc000" }}
                  >
                    <i className="fas fa-play mr-2"></i>
                    Total par produit ({dataSynthese.length} produits)
                  </div>
                  {showSynthese && (
                    <Table
                      columns={this.columnsSynthese}
                      data={dataSynthese}
                      sortColumn={sortColumnSynthese}
                      onSort={this.handleSortSynthese}
                    />
                  )}
                  <div
                    className="col-12 font-weight-bold mb-2"
                    onClick={() => this.handleClickCollapseDetail()}
                    style={{ cursor: "pointer", color: "#ffc000" }}
                  >
                    <i className="fas fa-play mr-2"></i>
                    Détail des {nbLigne} produits
                  </div>
                  {showDetail && (
                    <React.Fragment>
                      <table
                        className="table table-bordered"
                        style={{ fontSize: "12px" }}
                      >
                        <tbody>
                          {tabCommande.map((x) => (
                            <tr className="mr-0 ml-0" key={x._id}>
                              {x.type < 3 && (
                                <td
                                  className="font-weight-bold"
                                  colSpan={19}
                                  style={{ height: "30px" }}
                                >
                                  {x.type === 1 && <span>&nbsp;</span>}
                                  {x.type === 2 && (
                                    <span>{x.societeDenomination}</span>
                                  )}
                                </td>
                              )}
                              {x.type === 3 && (
                                <React.Fragment>
                                  <td>{x.qteASaisir}</td>
                                  <td>{x.qteACommander}</td>
                                  <td>{x.constX}</td>
                                  <td>{x.produitNomCommande}</td>
                                  <td>{x.prefixeRefF}</td>
                                  <td>{x.produitRF}</td>
                                  <td>{x.suffixeRefF}</td>
                                  <td>{x.produitDesignation}</td>
                                  <td>{x.produitId}</td>
                                  <td>{x.societeDenomination}</td>
                                  <td>{x.categorieNom}</td>
                                  <td>{x.produitUniteMesureAbr}</td>
                                  <td>
                                    {x.fournisseurId} - {x.fournisseurNom}
                                  </td>
                                  <td>{x.fournisseurCond}</td>
                                  <td>{x.societeId}</td>
                                  <td>{x.inventaireCond}</td>
                                  <td>{x.inventaireNb}</td>
                                  <td>{x.inventaireBesoin}</td>
                                  <td>{x.inventaireALivrer}</td>
                                </React.Fragment>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FlatListeCommande;
