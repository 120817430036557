import React from "react";
import Form from "./common/form";
import NumberFormat from "react-number-format";
import SelectSearch from "react-select-search";
import { toast } from "react-toastify";
import ListeLigneGroupe from "./listeLigneGroupe";
import DetailRapprochementListe from "./detailRapprochementListe";

import {
  getAllEmployes,
  getGroupeEcriture,
  getTypesEcritures,
  getEcrituresGroupe,
  getComptesAvecNumeroEC,
  addGroupeEcriture,
  updateGroupeEcriture,
  getRapprochementListGroupeEcriture,
  getMaxDateEcritureSociete,
  estExerciceCloture,
} from "../services/saisieService";
import { getPizzerias } from "../services/pizzeriaService";
import { getAllContactList } from "../services/contactService";
import auth from "../services/authService";
import { getAdminFlat } from "../services/mapsService";
import {
  makeId,
  makeLongId,
  replaceAll,
  roundDecimal,
  getPrefixeUrlHlpBackMenu,
} from "../utils/tools";

class SaisieGroupeForm extends Form {
  state = {
    societesForSelect: [],
    typesEcriture: [],
    typeEcrituresForSelect: [],
    employesForSelect: [],
    achatFournisseurForSelect: [],
    comptes: [],
    comptesForSelect: [],
    laSociete: {},
    leTypeEcriture: {},
    nouveauGroupe: false,
    enteteGroupe: {
      societeId: 0,
      typeEcritureId: -1,
      date: new Date(),
      _id: -1,
      rapprochement: "",
      documentId: "",
      contactId: "-1",
      urlId: "",
      commentaire: "",
      isSaisieManuelle: 0,
    },
    lignes: [],
    totalDebit: 0.0,
    totalCredit: 0.0,
    value: "",
    suggestions: [],
    clickSee: false,
    rapprochementSee: "",
    groupesRapprochement: [],
    tabErreurs: [],
    urlVisuDoc: "",
    adminMaps: [],
    shouldShowBox: false,
  };

  async componentDidMount() {
    const mode = this.props.match.params.mode;
    let mois = null;
    let annee = null;
    let groupeId = null;
    let dc = new Date();
    let nouveauGroupe = false;
    const enteteGroupe = {};
    let lignes = [];
    let totalDebit = 0.0;
    let totalCredit = 0.0;
    let employesForSelect = [];
    const dataComptes = await getComptesAvecNumeroEC();
    const comptes = dataComptes.data.data;
    let comptesForSelect = [];
    comptesForSelect.push({ value: "-1", name: "", origine: {} });
    comptes.map((item) => {
      comptesForSelect.push({
        value: item._id.toString(),
        name: item._id.toString() + " " + item.compteLibelle,
        origine: item,
      });
      return true;
    });

    const retMinDateEcritureSociete = await getMaxDateEcritureSociete();

    const MinDateEcritureSociete = retMinDateEcritureSociete.data.data;

    if (mode === "create") {
      // Nouvelle saisie
      nouveauGroupe = true;
      const now = new Date();
      annee = now.getFullYear();
      mois = now.getMonth();
      dc = new Date(annee, mois, 1);
      enteteGroupe.societeId = -1;
      enteteGroupe.typeEcritureId = -1;
      enteteGroupe.documentId = "";
      enteteGroupe.urlId = "";
      enteteGroupe.commentaire = "";
      enteteGroupe.isSaisieManuelle = 1;
      enteteGroupe.contactId = "-1";

      const newLigne = this.insereNouvelleLigne(comptesForSelect);
      lignes = lignes.concat(newLigne);
    } else {
      // Modification
      groupeId = parseInt(this.props.match.params.groupeId);
      const dataGroupe = await getGroupeEcriture(groupeId);
      const groupe = dataGroupe.data.data;
      dc = new Date(
        groupe.dateComptableAAAAMMJJ.substring(0, 4),
        groupe.dateComptableAAAAMMJJ.substring(4, 6) - 1,
        groupe.dateComptableAAAAMMJJ.substring(6, 8)
      );

      enteteGroupe.societeId = groupe.societeId;
      enteteGroupe.typeEcritureId = groupe.typeEcritureId;
      enteteGroupe._id = groupe._id;
      enteteGroupe.rapprochement = groupe.rapprochement;
      if (groupe.contactId) {
        enteteGroupe.contactId = groupe.contactId.toString();
      } else {
        enteteGroupe.contactId = "-1";
      }
      enteteGroupe.documentId = groupe.documentId;
      enteteGroupe.urlId = groupe.urlId;
      enteteGroupe.commentaire = groupe.commentaire;
      enteteGroupe.isSaisieManuelle = groupe.isSaisieManuelle;

      const dataEcritures = await getEcrituresGroupe(enteteGroupe._id);
      dataEcritures.data.data.map((l) => {
        const item = {};
        item._id = l._id;
        item.compteId = l.compteId.toString();
        item.debit = l.debit;
        item.credit = l.credit;
        const leCompte = comptes.find((x) => x._id === l.compteId);
        item.leCompte = leCompte;
        item.isNouvelleLigne = false;
        lignes.push(item);
        totalDebit = roundDecimal(Number(totalDebit) + Number(l.debit));
        totalCredit = roundDecimal(Number(totalCredit) + Number(l.credit));
        return true;
      });
    }
    enteteGroupe.date = dc;

    const societes = await getPizzerias();
    const laSociete = societes.data.data.find(
      (x) => x._id === enteteGroupe.societeId
    );
    let societesForSelect = [];
    societesForSelect.push({ value: "-1", name: "", dateComptableMin: null });
    societes.data.data.map((item) => {
      const dateMin = MinDateEcritureSociete.find(
        (x) => x.societeId === item._id
      );
      societesForSelect.push({
        value: item._id.toString(),
        name: item._id.toString() + " - " + item.denomination,
        dateComptableMin: dateMin
          ? dateMin.dateComptableAAAAMMJJ.substring(0, 4) +
            "-" +
            dateMin.dateComptableAAAAMMJJ.substring(4, 6) +
            "-" +
            dateMin.dateComptableAAAAMMJJ.substring(6, 8)
          : null,
      });
      return true;
    });
    const dataEmployes = await getAllEmployes();
    employesForSelect.push({ value: "-1", name: "" });
    dataEmployes.data.data.map((item) => {
      employesForSelect.push({
        value: item._id.toString(),
        name: item._id.toString() + " -" + item.prenom + " " + item.nom,
      });
      return true;
    });

    let achatFournisseurForSelect = [];
    const dataAchatFournisseur = await getAllContactList();
    achatFournisseurForSelect.push({ value: "-1", name: "" });
    dataAchatFournisseur.data.data.map((item) => {
      achatFournisseurForSelect.push({
        value: item._id.toString(),
        name: item._id.toString() + " - " + item.nom,
      });
      return true;
    });

    const typesEcriture = await getTypesEcritures();
    const leTypeEcriture = typesEcriture.data.data.find(
      (x) => x._id === enteteGroupe.typeEcritureId
    );
    let typeEcrituresForSelect = [];
    typeEcrituresForSelect.push({ value: "-1", name: "" });
    typesEcriture.data.data
      .filter((item) => item.isOuvertSaisie)
      .map((item) => {
        typeEcrituresForSelect.push({
          value: item._id.toString(),
          name: item._id.toString() + " - " + item.nom,
          journalCode: item.journalCode,
          contactNomTable: item.contactNomTable,
          adminMapsId: item.adminMapsId,
        });

        return true;
      });

    let tableForSelect = [];
    if (
      leTypeEcriture &&
      leTypeEcriture.contactNomTable &&
      leTypeEcriture.contactNomTable.length !== 0
    ) {
      if (leTypeEcriture.contactNomTable === "ACHAT_FOURNISSEUR")
        tableForSelect = achatFournisseurForSelect;
      if (leTypeEcriture.contactNomTable === "EMPLOYE")
        tableForSelect = employesForSelect;
      if (leTypeEcriture.contactNomTable === "PIZZERIA_SOCIETE")
        tableForSelect = societesForSelect;
    }
    if (
      leTypeEcriture &&
      leTypeEcriture.contactNomTable &&
      leTypeEcriture.contactNomTable.length !== 0 &&
      mode !== "create"
    ) {
      const leContact = tableForSelect.find((x) => {
        return x.value === enteteGroupe.contactId.toString();
      });

      if (!leContact) enteteGroupe.contactId = "-1";
    }

    if (
      mode === "create" &&
      (!leTypeEcriture ||
        !leTypeEcriture.contactNomTable ||
        leTypeEcriture.contactNomTable.length === 0)
    ) {
      enteteGroupe.contactId = "";
    }
    if (
      mode !== "create" &&
      (!leTypeEcriture ||
        !leTypeEcriture.contactNomTable ||
        leTypeEcriture.contactNomTable.length === 0) &&
      enteteGroupe.contactId === "-1"
    ) {
      enteteGroupe.contactId = "";
    }

    const adminMaps = await getAdminFlat();
    let urlVisuDoc = await getPrefixeUrlHlpBackMenu(49);

    this.setState({
      typesEcriture: typesEcriture.data.data,
      societesForSelect,
      employesForSelect,
      achatFournisseurForSelect,
      typeEcrituresForSelect,
      comptes,
      comptesForSelect,
      enteteGroupe,
      laSociete,
      leTypeEcriture,
      nouveauGroupe,
      lignes: lignes,
      totalDebit,
      totalCredit,
      urlVisuDoc,
      adminMaps,
      shouldShowBox: true,
    });
  }

  handleSocieteSelect = async (societe) => {
    let { enteteGroupe, societesForSelect } = this.state;
    const mode = this.props.match.params.mode;
    enteteGroupe.societeId = parseInt(societe);
    const laSociete = societesForSelect.find((x) => x.value === societe);
    if (laSociete && mode === "create") {
      if (
        laSociete.dateComptableMin &&
        laSociete.dateComptableMin.length !== 0
      ) {
        const annee = laSociete.dateComptableMin.substring(0, 4);
        enteteGroupe.date = new Date(parseInt(annee) + 1, 11, 31);
      }
    }
    this.setState({ enteteGroupe });
  };

  handleTypeEcritureSelect = async (type) => {
    let { enteteGroupe } = this.state;
    enteteGroupe.typeEcritureId = parseInt(type);
    enteteGroupe.contactId = "";
    this.setState({ enteteGroupe });
  };

  handleDateChange = (event) => {
    const { enteteGroupe } = this.state;
    const day = event.target.valueAsDate;
    if (day != null) {
      enteteGroupe.date = day;
      this.setState({ enteteGroupe });
    }
  };

  handleDocumentIdChange = (event) => {
    let { enteteGroupe } = this.state;
    enteteGroupe.documentId = event.target.value;
    this.setState({ enteteGroupe });
  };

  handleRapprochementChange = (event) => {
    let { enteteGroupe } = this.state;
    enteteGroupe.rapprochement = event.target.value;
    this.setState({ enteteGroupe });
  };

  handleRapprochementSee = async () => {
    let { enteteGroupe } = this.state;
    if (enteteGroupe.rapprochement && enteteGroupe.rapprochement.length !== 0) {
      const param = {};
      param.rapprochement = enteteGroupe.rapprochement;
      const dataRapprochement = await getRapprochementListGroupeEcriture(param);
      this.setState({
        groupesRapprochement: dataRapprochement.data.data,
        clickSee: true,
        rapprochementSee: enteteGroupe.rapprochement,
      });
    }
  };

  handleUrlIdChange = (event) => {
    let { enteteGroupe } = this.state;
    enteteGroupe.urlId = event.target.value;
    this.setState({ enteteGroupe });
  };

  handleContactIdChange = (event) => {
    let { enteteGroupe } = this.state;
    enteteGroupe.contactId = event.target.value;
    this.setState({ enteteGroupe });
  };

  handleContactSelect = async (contact) => {
    let { enteteGroupe } = this.state;
    enteteGroupe.contactId = contact;
    this.setState({ enteteGroupe });
  };

  handleCommentaireChange = (event) => {
    let { enteteGroupe } = this.state;
    enteteGroupe.commentaire = event.target.value;
    this.setState({ enteteGroupe });
  };

  insereNouvelleLigne = () => {
    const item = {};
    item._id = "NEW|" + makeLongId();
    item.compteId = "-1";
    item.debit = 0.0;
    item.credit = 0.0;
    item.leCompte = {};
    item.leCompte._id = "-1";
    item.isNouvelleLigne = true;
    return item;
  };

  handleClickNouvelleLigne = () => {
    let { lignes } = this.state;
    const newLigne = this.insereNouvelleLigne();
    this.setState({
      lignes: lignes.concat(newLigne),
    });
  };

  handleSupprLigne = (x) => {
    let newArray = [...this.state.lignes];
    let newList = newArray.filter(
      (item) => item._id.toString() !== x._id.toString()
    );
    let totalDebit = 0.0;
    let totalCredit = 0.0;
    newList.map((x) => {
      totalDebit = roundDecimal(totalDebit + x.debit);
      totalCredit = roundDecimal(totalCredit + x.credit);
      return true;
    });
    this.setState({
      lignes: newList,
      totalDebit,
      totalCredit,
    });
  };

  handleChangeCompte = (id, newValue) => {
    let elementsIndex = this.state.lignes.findIndex((x) => x._id === id);
    let newArray = [...this.state.lignes];
    let leCpt = {};
    if (newValue.length !== 0 && newValue !== "-1") {
      leCpt = this.state.comptes.find((x) => x._id === parseInt(newValue));
    }
    if (newValue === "-1") {
      leCpt._id = "-1";
    }
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      leCompte: leCpt,
      compteId: leCpt._id.toString(),
    };
    this.setState({ lignes: newArray });
  };

  handleDebitChange = (event) => {
    let { lignes, totalDebit } = this.state;
    let formattedValue = event.target.value;
    const id = event.target.id;
    let elementsIndex = id.split("_")[1];
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    const oldValue = lignes[elementsIndex].debit;
    let newArray = [...this.state.lignes];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      debit: Number(formattedValue),
    };

    totalDebit = roundDecimal(totalDebit - oldValue + Number(formattedValue));
    this.setState({ lignes: newArray, totalDebit });
  };

  handleCreditChange = (event) => {
    let { lignes, totalCredit } = this.state;
    let formattedValue = event.target.value;
    const id = event.target.id;
    let elementsIndex = id.split("_")[1];
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    const oldValue = lignes[elementsIndex].credit;
    let newArray = [...this.state.lignes];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      credit: Number(formattedValue),
    };
    totalCredit = roundDecimal(totalCredit - oldValue + Number(formattedValue));
    this.setState({ lignes: newArray, totalCredit });
  };

  getPageRetour = () => {
    if (this.props.match.params.call && this.props.match.params.paramCall) {
      return (
        "/" +
        this.props.match.params.call +
        "/" +
        this.props.match.params.paramCall
      );
    }
    return "/saisie/1";
  };

  handleEnregistrerClick = async (event) => {
    const mode = this.props.match.params.mode;
    event.preventDefault();
    let { traitEncours } = this.state;
    if (!traitEncours) {
      this.setState({ traitEncours: true });
      let {
        typesEcriture,
        lignes,
        totalDebit,
        totalCredit,
        enteteGroupe,
        comptes,
      } = this.state;
      let tabErreurs = [];
      const dateGroupe =
        enteteGroupe.date.getFullYear() +
        "-" +
        (enteteGroupe.date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        enteteGroupe.date.getDate().toString().padStart(2, "0");
      if (!dateGroupe) {
        tabErreurs.push("Date incorrecte");
      }
      if (parseInt(dateGroupe.substring(0, 4)) < 2014) {
        tabErreurs.push("Date incorrecte");
      }
      if (parseInt(dateGroupe.substring(0, 4)) > 2100) {
        tabErreurs.push("Date incorrecte");
      }

      if (parseInt(enteteGroupe.societeId) <= 0) {
        tabErreurs.push("Société obligatoire");
      }
      const retEstExerciceCloture = await estExerciceCloture(
        enteteGroupe.societeId,
        enteteGroupe.date.getFullYear()
      );
      if (retEstExerciceCloture.data.data === true) {
        tabErreurs.push(
          "L'année " + enteteGroupe.date.getFullYear() + " est clôturée"
        );
      }

      if (parseInt(enteteGroupe.typeEcritureId) <= 0) {
        tabErreurs.push("Type obligatoire");
      }
      if (Number(totalDebit) !== Number(totalCredit)) {
        tabErreurs.push("Total débit différent de Total Credit");
      }
      if (lignes.length === 0) {
        tabErreurs.push("Aucune ligne a été saisie");
      }
      lignes.map((ligne) => {
        if (ligne.compteId === "-1") {
          tabErreurs.push("Une ligne n'a pas de numéro de compte");
        }
        if (ligne.debit === 0 && ligne.credit === 0) {
          tabErreurs.push("Une ligne a ni débit ni credit");
        }
        return true;
      });

      const leTypeEcriture = typesEcriture.find(
        (x) => x._id === parseInt(enteteGroupe.typeEcritureId)
      );
      if (
        leTypeEcriture &&
        leTypeEcriture.contactNomTable &&
        leTypeEcriture.contactNomTable.length !== 0 &&
        (enteteGroupe.contactId.toString() === "-1" ||
          enteteGroupe.contactId.toString() === "")
      ) {
        tabErreurs.push("Le contact est obligatoire");
      }

      let cptAvecNature = [];
      lignes.map((ligne) => {
        const leCpt = comptes.find((c) => c._id === parseInt(ligne.compteId));
        if (leCpt && leCpt.compteNature) cptAvecNature.push(leCpt);
        return true;
      });
      if (
        leTypeEcriture &&
        leTypeEcriture.contactNomTable === "ACHAT_FOURNISSEUR"
      ) {
        const existCptErreur = cptAvecNature.find(
          (c) => c.compteNature === "SAL" || c.compteNature === "GRP"
        );
        if (existCptErreur)
          tabErreurs.push(
            "Les comptes de nature Salarié et/ou Groupe sont interdits sur ce type d'écriture"
          );
      }
      if (
        leTypeEcriture &&
        leTypeEcriture.contactNomTable === "PIZZERIA_SOCIETE"
      ) {
        const existCptErreur = cptAvecNature.find(
          (c) =>
            c.compteNature === "SAL" ||
            c.compteNature === "CLI" ||
            c.compteNature === "FRN"
        );
        if (existCptErreur)
          tabErreurs.push(
            "Les comptes de nature Salarié et/ou Client et/ou Fournisseur sont interdits sur ce type d'écriture"
          );
      }
      if (leTypeEcriture && leTypeEcriture.contactNomTable === "EMPLOYE") {
        const existCptErreur = cptAvecNature.find(
          (c) =>
            c.compteNature === "CLI" ||
            c.compteNature === "FRN" ||
            c.compteNature === "GRP"
        );
        if (existCptErreur)
          tabErreurs.push(
            "Les comptes de nature Client et/ou Fournisseur et/ou Groupe sont interdits sur ce type d'écriture"
          );
      }
      const existCptBq = cptAvecNature.find((c) => c.compteNature === "BQ");
      if (
        existCptBq &&
        (!enteteGroupe.rapprochement || enteteGroupe.rapprochement.length === 0)
      ) {
        tabErreurs.push(
          "Rapprochement obligatoire pour une écriture sur le " + existCptBq._id
        );
      }

      if (tabErreurs.length !== 0) {
        this.setState({ tabErreurs, traitEncours: false });
        return;
      }
      const data = {};
      data.enteteGroupe = enteteGroupe;
      data.enteteGroupe.dateAAAAMMJJ = dateGroupe;
      data.lignes = [];
      data.userId = auth.getCurrentUser()._id;
      lignes.map((ligne) => {
        const item = {};
        item._id = ligne._id;
        item.compteId = parseInt(ligne.compteId);
        item.debit = ligne.debit;
        item.credit = ligne.credit;
        data.lignes.push(item);
        return true;
      });
      let result = null;
      if (mode === "create") {
        result = await addGroupeEcriture(data);
      } else {
        result = await updateGroupeEcriture(enteteGroupe._id, data);
      }
      if (!result.data.success) {
        toast.error(
          "Erreur à l'enregistrement du groupe d'écritures : " +
            result.data.message
        );
      }
      //console.log("nouveauGroupe", result);

      this.props.history.push(this.getPageRetour());
    }
  };

  handleCancelClick = (event) => {
    event.preventDefault();
    this.props.history.push(this.getPageRetour());
  };

  render() {
    const {
      societesForSelect,
      employesForSelect,
      achatFournisseurForSelect,
      typesEcriture,
      typeEcrituresForSelect,
      nouveauGroupe,
      enteteGroupe,
      lignes,
      totalDebit,
      totalCredit,
      traitEncours,
      tabErreurs,
      groupesRapprochement,
      clickSee,
      rapprochementSee,
      urlVisuDoc,
      adminMaps,
      shouldShowBox,
    } = this.state;
    const mode = this.props.match.params.mode;

    const laSociete = societesForSelect.find(
      (x) => x.value === enteteGroupe.societeId.toString()
    );
    const leTypeEcriture = typesEcriture.find(
      (x) => x._id === enteteGroupe.typeEcritureId
    );
    let urlVisuUrl = null;
    if (
      leTypeEcriture &&
      leTypeEcriture.adminMapsId &&
      leTypeEcriture.adminMapsId !== 0
    ) {
      const menuAdmin = adminMaps.filter(
        (item) => item._id === leTypeEcriture.adminMapsId
      );
      if (
        menuAdmin &&
        menuAdmin[0] &&
        menuAdmin[0].prefixeUrl &&
        menuAdmin[0].suffixeUrl
      )
        urlVisuUrl = menuAdmin[0].prefixeUrl + menuAdmin[0].suffixeUrl;
    }

    let tableForSelect = [];
    if (
      leTypeEcriture &&
      leTypeEcriture.contactNomTable &&
      leTypeEcriture.contactNomTable.length !== 0
    ) {
      if (leTypeEcriture.contactNomTable === "ACHAT_FOURNISSEUR")
        tableForSelect = achatFournisseurForSelect;
      if (leTypeEcriture.contactNomTable === "EMPLOYE")
        tableForSelect = employesForSelect;
      if (leTypeEcriture.contactNomTable === "PIZZERIA_SOCIETE")
        tableForSelect = societesForSelect;
    }

    const now = new Date();
    const dateGroupeAAAAMMJJ =
      enteteGroupe.date instanceof Date
        ? enteteGroupe.date.getFullYear() +
          "-" +
          (enteteGroupe.date.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          enteteGroupe.date.getDate().toString().padStart(2, "0")
        : now.getFullYear() +
          "-" +
          (now.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          now.getDate().toString().padStart(2, "0");

    let minDateGroupe = "1970-01-01";
    if (laSociete && laSociete.dateComptableMin)
      minDateGroupe = laSociete.dateComptableMin;

    let groupeModifiable = enteteGroupe.isSaisieManuelle === 1;
    if (dateGroupeAAAAMMJJ <= minDateGroupe) groupeModifiable = false;

    const textH2 = nouveauGroupe
      ? "Nouveau groupe d'écriture"
      : groupeModifiable
      ? "Groupe d'écriture " + enteteGroupe._id
      : "Consultation du groupe " + enteteGroupe._id;

    const isEquilibre =
      Number(totalDebit) === Number(totalCredit) ? true : false;
    const difference = roundDecimal(Number(totalDebit) - Number(totalCredit));

    const nbLigne = lignes.length;

    return (
      <div>
        {!shouldShowBox && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {shouldShowBox && (
          <React.Fragment>
            <h2>{textH2}</h2>

            <div className="row mb-4">
              <div className="col-3">
                <div>
                  <label className="w-100 font-weight-bold">Société </label>
                  {!groupeModifiable && <div>{laSociete.name}</div>}
                  {groupeModifiable && (
                    <SelectSearch
                      options={societesForSelect}
                      value={enteteGroupe.societeId.toString()}
                      name="societe"
                      search
                      onChange={this.handleSocieteSelect}
                    />
                  )}
                  {mode === "create" &&
                    (enteteGroupe.societeId.toString() === "" ||
                      enteteGroupe.societeId.toString() === "-1") && (
                      <div className="alert alert-danger mt-2">
                        Société obligatoire
                      </div>
                    )}
                </div>
                <div>
                  <label className="w-100 font-weight-bold mt-2">Type </label>
                  {!groupeModifiable && (
                    <div>
                      {leTypeEcriture._id} - {leTypeEcriture.nom}
                    </div>
                  )}
                  {groupeModifiable && (
                    <SelectSearch
                      options={typeEcrituresForSelect}
                      value={enteteGroupe.typeEcritureId.toString()}
                      name="typeEcriture"
                      search
                      onChange={this.handleTypeEcritureSelect}
                    />
                  )}
                  {mode === "create" &&
                    (enteteGroupe.typeEcritureId.toString() === "" ||
                      enteteGroupe.typeEcritureId.toString() === "-1") && (
                      <div className="alert alert-danger mt-2">
                        Type obligatoire
                      </div>
                    )}
                  {leTypeEcriture &&
                    leTypeEcriture.journalCode &&
                    leTypeEcriture.journalCode.length !== 0 && (
                      <div
                        className="w-100 mt-1"
                        style={{ fontStyle: "italic" }}
                      >
                        <div>
                          Code journal connu par l'EC :{" "}
                          {leTypeEcriture.journalCode}
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  <label className="w-100 font-weight-bold mt-2">Date</label>
                  <input
                    type="date"
                    value={dateGroupeAAAAMMJJ}
                    min={minDateGroupe}
                    onChange={(date) => this.handleDateChange(date)}
                    disabled={!groupeModifiable}
                  ></input>
                </div>
                <div>
                  <label className="w-100 font-weight-bold mt-2">
                    Document
                  </label>
                  <div className="input-group mb-3">
                    <NumberFormat
                      className="form-control"
                      id="Document"
                      autoComplete={makeId()}
                      name={"Document" + makeId()}
                      value={enteteGroupe.documentId}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      decimalSeparator=","
                      thousandSeparator=""
                      allowNegative={false}
                      onChange={(e) => {
                        this.handleDocumentIdChange(e);
                      }}
                      placeholder="Document"
                      disabled={!groupeModifiable}
                    />
                    <div className="input-group-append">
                      {urlVisuDoc &&
                        urlVisuDoc.length !== 0 &&
                        enteteGroupe.documentId &&
                        enteteGroupe.documentId !== 0 && (
                          <a
                            className="input-group-text"
                            href={
                              urlVisuDoc +
                              "documentfile/" +
                              enteteGroupe.documentId
                            }
                            target="_blank"
                            rel="noreferrer"
                            style={{ cursor: "pointer" }}
                          >
                            Voir
                          </a>
                        )}
                    </div>
                  </div>
                </div>
                <div>
                  <label className="font-weight-bold mt-2">Rapprochement</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      name={"Rapprochement" + makeId()}
                      id="Rapprochement"
                      placeholder="Rapprochement"
                      value={enteteGroupe.rapprochement}
                      className="form-control"
                      autoComplete={makeId()}
                      onChange={(e) => {
                        this.handleRapprochementChange(e);
                      }}
                      disabled={!groupeModifiable}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        onClick={this.handleRapprochementSee}
                        style={{ cursor: "pointer" }}
                      >
                        Voir
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="w-100 font-weight-bold mt-2">Url</label>
                  <div className="input-group mb-3">
                    <NumberFormat
                      className="form-control"
                      id="Url"
                      autoComplete={makeId()}
                      name={"Url" + makeId()}
                      value={enteteGroupe.urlId}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      decimalSeparator=","
                      thousandSeparator=""
                      allowNegative={false}
                      onChange={(e) => {
                        this.handleUrlIdChange(e);
                      }}
                      placeholder="Url"
                      disabled={!groupeModifiable}
                    />
                    <div className="input-group-append">
                      {urlVisuUrl &&
                        urlVisuUrl.length !== 0 &&
                        enteteGroupe.urlId &&
                        enteteGroupe.urlId !== 0 && (
                          <a
                            className="input-group-text"
                            href={urlVisuUrl + "&id=" + enteteGroupe.urlId}
                            target="_blank"
                            rel="noreferrer"
                            style={{ cursor: "pointer" }}
                          >
                            Voir
                          </a>
                        )}
                    </div>
                  </div>
                </div>
                {(!leTypeEcriture ||
                  !leTypeEcriture.contactNomTable ||
                  leTypeEcriture.contactNomTable.length === 0) && (
                  <div>
                    <label className="w-100 font-weight-bold mt-2">
                      Contact
                    </label>
                    <NumberFormat
                      className="form-control"
                      id="Contact"
                      autoComplete={makeId()}
                      name={"Contactng" + makeId()}
                      value={enteteGroupe.contactId}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      decimalSeparator=","
                      thousandSeparator=""
                      allowNegative={false}
                      onChange={(e) => {
                        this.handleContactIdChange(e);
                      }}
                      placeholder="Contact"
                      disabled={!groupeModifiable}
                    />
                  </div>
                )}

                {leTypeEcriture &&
                  leTypeEcriture.contactNomTable &&
                  leTypeEcriture.contactNomTable.length !== 0 && (
                    <div>
                      <label
                        htmlFor="contact"
                        className="w-100 font-weight-bold mt-2"
                      >
                        Contact
                      </label>
                      <SelectSearch
                        options={tableForSelect}
                        value={enteteGroupe.contactId}
                        name="contact"
                        search
                        onChange={this.handleContactSelect}
                        disabled={!groupeModifiable}
                      />
                    </div>
                  )}
                {leTypeEcriture &&
                  leTypeEcriture.contactNomTable &&
                  leTypeEcriture.contactNomTable.length !== 0 &&
                  (enteteGroupe.contactId.toString() === "" ||
                    enteteGroupe.contactId.toString() === "-1") && (
                    <div className="alert alert-danger mt-2">
                      Contact obligatoire
                    </div>
                  )}
                <div>
                  <label className="w-100 font-weight-bold mt-2">
                    Commentaire
                  </label>
                  <input
                    type="text"
                    name={"Commentaire" + makeId()}
                    id="Commentaire"
                    placeholder="Commentaire"
                    value={enteteGroupe.commentaire}
                    className="form-control"
                    autoComplete={makeId()}
                    onChange={(e) => {
                      this.handleCommentaireChange(e);
                    }}
                    disabled={!groupeModifiable}
                  />
                </div>

                {groupeModifiable && (
                  <div className="mt-4">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleEnregistrerClick}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary ml-2"
                      onClick={this.handleCancelClick}
                    >
                      Annuler
                    </button>
                  </div>
                )}
                {!groupeModifiable && (
                  <div className="mt-4">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelClick}
                    >
                      <i className="fa fa-arrow-left mr-2"></i>
                      Retour
                    </button>
                  </div>
                )}
                {traitEncours && (
                  <div className="mt-3 mb-3">
                    <div className="spinner-border text-info" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-9">
                {tabErreurs.length > 0 && (
                  <div className="row alert alert-danger">
                    <div className="col-12">Erreurs :</div>
                    <ul className="col-12">
                      {tabErreurs.map((x, index) => (
                        <li key={index}>
                          <div>{x}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {groupeModifiable && (
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => this.handleClickNouvelleLigne()}
                    style={{ marginBottom: 20 }}
                  >
                    <i
                      className="fa fa-plus mr-2"
                      style={{ fontSize: "20px" }}
                      aria-hidden="true"
                    ></i>
                    Nouvelle ligne
                  </button>
                )}
                <table
                  className="table table-striped table-bordered"
                  style={{ fontSize: "12px" }}
                >
                  <thead>
                    <tr className="row mr-0 ml-0">
                      <th className="col-4">Compte</th>
                      <th className="col-2"></th>
                      <th className="col-2 text-right">Débit</th>
                      <th className="col-2 text-right">Crédit</th>
                      <th className="col-2"></th>
                    </tr>
                    <tr className="row mr-0 ml-0">
                      <th className="col-4"></th>
                      <th className="col-2">Total</th>
                      <th className="col-2 text-right">
                        <NumberFormat
                          className={
                            "col-12 text-right " +
                            (isEquilibre ? "" : "text-danger")
                          }
                          value={totalDebit}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </th>
                      <th
                        className={
                          "col-2 text-right " +
                          (isEquilibre ? "" : "text-danger")
                        }
                      >
                        <NumberFormat
                          className="col-12 text-right"
                          value={totalCredit}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </th>
                      <th
                        className={
                          "col-2 text-right " +
                          (isEquilibre ? "" : "text-danger")
                        }
                      >
                        <NumberFormat
                          className="col-12 text-right"
                          value={difference}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </th>
                    </tr>
                  </thead>
                  <ListeLigneGroupe
                    key={nbLigne}
                    nbLigne={nbLigne}
                    lignes={lignes}
                    comptes={this.state.comptes}
                    comptesForSelect={this.state.comptesForSelect}
                    enteteGroupe={enteteGroupe}
                    groupeModifiable={groupeModifiable}
                    handleChangeCompte={this.handleChangeCompte}
                    handleDebitChange={this.handleDebitChange}
                    handleCreditChange={this.handleCreditChange}
                    handleSupprLigne={this.handleSupprLigne}
                    handleClickNouvelleLigne={this.handleClickNouvelleLigne}
                  ></ListeLigneGroupe>
                </table>
                {/* <div>{JSON.stringify(lignes)}</div> */}
                {clickSee && (
                  <React.Fragment>
                    <h5 className="mt-5">
                      Détail du rapprochement {rapprochementSee}
                    </h5>
                    {groupesRapprochement.length === 0 && (
                      <div>Aucun groupe avec ce rapprochement</div>
                    )}
                    <DetailRapprochementListe
                      groupes={groupesRapprochement}
                    ></DetailRapprochementListe>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default SaisieGroupeForm;
