import React, { Component } from "react";
import Pagination from "react-js-pagination";
import SelectSearch from "react-select-search";
import { confirmAlert } from "react-confirm-alert";
import {
  getListAdresses,
  getTypeLocalisation,
  disactiveAdresse,
  setCoordonneesGPS,
  deleteCoordonneesGPS,
  updateAdresse,
  addConversationAdresse,
  addMessageConversationAdresse,
  disactiveMessageConversationAdresse,
} from "../../services/adressesService";
import {
  getDistinctVillesLivrees,
  getAllIndiceRepetitionLettreNumRue,
  getAllIndiceRepetitionAdverbeNumRue,
} from "../../services/pizzeriaService";
import auth from "../../services/authService";

import ListGroup from "./../common/listGroup";
import ListeAdresse from "./listeAdresse";

class Adresses extends Component {
  state = {
    adresses: [],
    sortColumn: { path: "_id", order: "desc" },
    totalCount: 0,
    sansCdeCount: 0,
    pageCount: 0,
    currentPage: 1,
    pageSize: 50,
    precisionsForSelect: [],
    selectedPrecisions: [],
    villesForSelect: [],
    selectedVille: "",
    fantoirForSelect: [],
    selectedFantoir: "",
    APIKEY_GOOGLEMAPS: "",
    villesUpdateForSelect: [],
    tabIndiceRepetitionLettreForSelect: [],
    tabIndiceRepetitionAdverveForSelect: [],
    uniquementAvecMessage: false,
    uniquementRuesNonLivrees: false,
    uniquementRuesExceptLivrees: false,
    searchEnCours: false,
  };

  async componentDidMount() {
    const dataTypes = await getTypeLocalisation();
    const resultVilles = await getDistinctVillesLivrees();
    const resultIndiceRepetitionLettre =
      await getAllIndiceRepetitionLettreNumRue();
    const resultIndiceRepetitionAdverbe =
      await getAllIndiceRepetitionAdverbeNumRue();

    let precisionsForSelect = [];
    let selectedPrecisions = [];
    precisionsForSelect.push({ value: "-1", name: "Sans coordonnées GPS" });
    selectedPrecisions.push("-1");
    dataTypes.data.data.forEach((item) => {
      precisionsForSelect.push({
        value: item._id.toString(),
        name: item.libelle,
      });
    });
    let villesForSelect = [];
    villesForSelect.push({ value: "-1", name: "Toutes les villes" });
    resultVilles.data.data.forEach((item) => {
      villesForSelect.push({
        value: item.villeId.toString(),
        name: item.villeNom + " " + item.villeCP,
        villeIdInsee: item.villeIdInsee,
      });
    });

    let tabIndiceRepetitionLettreForSelect = [];
    resultIndiceRepetitionLettre.data.data.forEach((item) => {
      tabIndiceRepetitionLettreForSelect.push({
        value: item._id.length === 0 ? " " : item._id,
        name: item._id,
      });
    });
    let tabIndiceRepetitionAdverbeForSelect = [];
    resultIndiceRepetitionAdverbe.data.data.forEach((item) => {
      tabIndiceRepetitionAdverbeForSelect.push({
        value: item._id.length === 0 ? " " : item._id,
        name: item._id,
      });
    });
    const fantoirForSelect = [
      { _id: -1, denomination: "Toutes les adresses" },
      { _id: 0, denomination: "Adresses sans Fantoir" },
      { _id: 1, denomination: "Adresses avec Fantoir" },
    ];
    this.setState({
      precisionsForSelect,
      selectedPrecisions,
      villesForSelect,
      selectedVille: villesForSelect[0].value,
      fantoirForSelect,
      selectedFantoir: fantoirForSelect[0],
      tabIndiceRepetitionLettreForSelect,
      tabIndiceRepetitionAdverbeForSelect,
    });
    await this.traitValiderClick({
      page: 1,
      sortColumn: this.state.sortColumn,
    });
  }

  handleTypeFantoirSelect = (typeFantoir) => {
    this.setState({
      selectedFantoir: typeFantoir,
    });
  };

  handlePrecisionSelect = async (types) => {
    this.setState({
      selectedPrecisions: types,
    });
  };

  handleVilleSelect = async (ville) => {
    this.setState({
      selectedVille: ville,
    });
  };

  handleUniquementAvecMessageChange = () => {
    const { uniquementAvecMessage } = this.state;
    this.setState({
      uniquementAvecMessage: !uniquementAvecMessage,
    });
  };

  handleUniquementRuesNonLivrees = () => {
    const { uniquementRuesNonLivrees } = this.state;
    this.setState({
      uniquementRuesNonLivrees: !uniquementRuesNonLivrees,
    });
  };

  handleUniquementRuesExceptLivrees = () => {
    const { uniquementRuesExceptLivrees } = this.state;
    this.setState({
      uniquementRuesExceptLivrees: !uniquementRuesExceptLivrees,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    await this.traitValiderClick({
      page: 1,
      sortColumn: this.state.sortColumn,
    });
  };

  traitValiderClick = async ({ page, sortColumn }) => {
    const {
      selectedFantoir,
      selectedPrecisions,
      selectedVille,
      pageSize,
      searchEnCours,
      uniquementAvecMessage,
      uniquementRuesNonLivrees,
      uniquementRuesExceptLivrees,
    } = this.state;
    if (!searchEnCours) {
      this.setState({ searchEnCours: true });
      const param = {};
      param.typeUserId = auth.getCurrentUser().typeUserId;
      param.selectedPrecisions = selectedPrecisions;
      param.selectedVille = parseInt(selectedVille);
      param.selectedFantoir = selectedFantoir._id;
      param.uniquementAvecMessage = uniquementAvecMessage;
      param.uniquementRuesNonLivrees = uniquementRuesNonLivrees;
      param.uniquementRuesExceptLivrees = uniquementRuesExceptLivrees;
      param.sortColumn = sortColumn;
      param.pageSize = pageSize;
      param.currentPage = page;
      const dataAdresses = await getListAdresses(param);
      dataAdresses.data.data.dataAdresses.dataPage.forEach((adr) => {
        adr.collapse = true;
        adr.majCollapse = true;
      });

      this.setState({
        adresses: dataAdresses.data.data.dataAdresses.dataPage,
        totalCount: dataAdresses.data.data.dataAdresses.totalCount,
        sansCdeCount: dataAdresses.data.data.dataAdresses.sansCdeCount,
        pageCount: dataAdresses.data.data.dataAdresses.pageCount,
        currentPage: page,
        APIKEY_GOOGLEMAPS: dataAdresses.data.data.APIKEY_GOOGLEMAPS,
        searchEnCours: false,
      });
    }
  };

  handlePageChange = async (page) => {
    await this.traitValiderClick({
      page: page,
      sortColumn: this.state.sortColumn,
    });
  };

  handleSort = async (sortColumn) => {
    this.setState({ sortColumn });
    await this.traitValiderClick({
      page: 1,
      sortColumn: sortColumn,
    });
  };

  handleCollapseAdrOnClick = (adr) => {
    const { adresses } = this.state;
    const laAdresse = adresses.find((a) => a._id === adr._id);
    if (laAdresse && laAdresse.majCollapse)
      laAdresse.collapse = !laAdresse.collapse;
    this.setState({ adresses });
  };

  handleCollapseMajOnClick = (adr, forceClose) => {
    const { adresses } = this.state;
    const laAdresse = adresses.find((a) => a._id === adr._id);
    if (
      laAdresse &&
      // (laAdresse.majCollapse || forceClose) &&
      laAdresse.collapse
    ) {
      laAdresse.majCollapse = !laAdresse.majCollapse;
    }
    this.setState({ adresses });
  };

  handleChoixCoordonnees = async (adr, c) => {
    const { adresses, precisionsForSelect } = this.state;
    const param = {};
    param.latitude = c.latLon.split(",")[0];
    param.longitude = c.latLon.split(",")[1];
    param.typeLocalisationId = c.typeLocalisationId;
    const result = await setCoordonneesGPS(adr._id, param);
    if (result.data.success) {
      const laAdresse = adresses.find((a) => a._id === adr._id);
      if (laAdresse) {
        laAdresse.latLon = c.latLon;
        laAdresse.typeLocalisationId = c.typeLocalisationId;
        laAdresse.typeLocalisationLib = precisionsForSelect.find(
          (p) => p.value === c.typeLocalisationId.toString()
        )?.name;
        laAdresse.collapse = true;
      }
      this.setState({ adresses });
    }
  };

  handleDisactiveClick = (adr) => {
    confirmAlert({
      title: "Désactivation de l'adresse " + adr._id,
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.traitDisactiveAdresse(adr);
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  traitDisactiveAdresse = async (adr) => {
    const { searchEnCours } = this.state;
    if (!searchEnCours) {
      this.setState({ searchEnCours: true });

      await disactiveAdresse(adr._id);
      this.setState({ searchEnCours: false });
      await this.traitValiderClick({
        page: 1,
        sortColumn: this.state.sortColumn,
      });
      return;
    }
  };

  handleDeleteCoordonneesGpsClick = (adr) => {
    confirmAlert({
      title: "Suppression des coordonnées GPS pour l'adresse " + adr._id,
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            this.traitDeleteCoordonneesGPSAdresse(adr);
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  traitDeleteCoordonneesGPSAdresse = async (adr) => {
    const { adresses, precisionsForSelect } = this.state;
    const result = await deleteCoordonneesGPS(adr._id);
    if (result.data.success) {
      const laAdresse = adresses.find((a) => a._id === adr._id);
      if (laAdresse) {
        laAdresse.latLon = "";
        laAdresse.typeLocalisationId = -1;
        laAdresse.typeLocalisationLib = precisionsForSelect.find(
          (p) => p.value === "-1"
        )?.name;
        laAdresse.collapse = true;
        laAdresse.majCollapse = true;
      }
      this.setState({ adresses });
    }
  };
  handleValidModifAdresse = async (adresseId, newAdresse) => {
    newAdresse.typeUserId = auth.getCurrentUser().typeUserId;
    const retUpdate = await updateAdresse(adresseId, newAdresse);
    if (retUpdate.data.success) {
      let newArray = [...this.state.adresses];
      let elementsIndex = this.state.adresses.findIndex((x) => {
        return x._id === parseInt(adresseId);
      });
      newArray[elementsIndex] = {
        ...retUpdate.data.data,
        majCollapse: true,
        collapse: true,
      };
      this.setState({ adresses: newArray });
    }
  };

  handleValidNewMessage = async (adresse, newMessage) => {
    const data = {};
    data.message = newMessage;
    data.employeCreateurId = auth.getCurrentUser().typeUserId;
    data.adresseId = adresse._id;
    let retAdd = {};
    if (adresse.conversationId) {
      data.conversationId = adresse.conversationId;
      retAdd = await addMessageConversationAdresse(data);
    } else {
      retAdd = await addConversationAdresse(data);
    }
    if (retAdd.data.success) {
      let newArray = [...this.state.adresses];
      let elementsIndex = this.state.adresses.findIndex((x) => {
        return x._id === parseInt(adresse._id);
      });
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        tabMessage: retAdd.data.data,
        conversationId: retAdd.data.data[0]?.conversationId,
      };
      this.setState({ adresses: newArray });
    }
  };

  handleDisactiveMessage = async (adresse, message) => {
    const data = {};
    data.messageId = message.messageId;
    data.employeDisactiveId = auth.getCurrentUser().typeUserId;
    data.adresseId = adresse._id;
    const retDisactive = await disactiveMessageConversationAdresse(data);
    if (retDisactive.data.success) {
      let newArray = [...this.state.adresses];
      let elementsIndex = this.state.adresses.findIndex((x) => {
        return x._id === parseInt(adresse._id);
      });
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        tabMessage: retDisactive.data.data,
        conversationId: retDisactive.data.data[0]?.conversationId,
      };
      this.setState({ adresses: newArray });
    }
  };

  render() {
    const {
      searchEnCours,
      pageSize,
      currentPage,
      sortColumn,
      precisionsForSelect,
      selectedPrecisions,
      villesForSelect,
      selectedVille,
      fantoirForSelect,
      selectedFantoir,
      totalCount,
      sansCdeCount,
      adresses,
      APIKEY_GOOGLEMAPS,
      tabIndiceRepetitionLettreForSelect,
      tabIndiceRepetitionAdverbeForSelect,
      uniquementAvecMessage,
      uniquementRuesNonLivrees,
      uniquementRuesExceptLivrees,
    } = this.state;

    return (
      <div className="row">
        <div className="col-2" style={{ fontSize: "14px" }}>
          <div>
            <div>
              <label className="font-weight-bold" htmlFor="typePrecision">
                Adresse / Fantoir
              </label>
            </div>
            <ListGroup
              items={fantoirForSelect}
              selectedItem={selectedFantoir}
              onItemSelect={this.handleTypeFantoirSelect}
              textProperty="denomination"
              maxHeight="400px"
            />
          </div>
          <div className="mt-2">
            <div>
              <label className="font-weight-bold" htmlFor="typePrecision">
                Type de précision des coordonnées GPS
              </label>
            </div>
            <div
              className="maxHauteur400"
              style={{ marginTop: "-30px", fontSize: "12px" }}
            >
              <SelectSearch
                options={precisionsForSelect}
                value={selectedPrecisions}
                name="precision"
                multiple
                onChange={this.handlePrecisionSelect}
              />
            </div>
          </div>
          <div style={{ marginTop: "420px", marginBottom: "20px" }}>
            <div>
              <label className="font-weight-bold" htmlFor="ville">
                Ville
              </label>
            </div>
            <div className="maxHauteur100">
              <SelectSearch
                options={villesForSelect}
                value={selectedVille}
                name="ville"
                search
                onChange={this.handleVilleSelect}
              />
            </div>
          </div>
          <div>
            <label className="font-weight-bold" htmlFor="uniquement">
              Uniquement
            </label>
          </div>
          <div className="form-check mt-2" style={{ fontSize: "12px" }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="avecMessage"
              checked={uniquementAvecMessage}
              onChange={this.handleUniquementAvecMessageChange}
            ></input>
            <label className="form-check-label" htmlFor="avecMessage">
              Les adresses avec de nouveaux messages "livraison"
            </label>
          </div>
          <div className="form-check mt-2" style={{ fontSize: "12px" }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="rueNonLivree"
              checked={uniquementRuesNonLivrees}
              onChange={this.handleUniquementRuesNonLivrees}
            ></input>
            <label className="form-check-label" htmlFor="rueNonLivree">
              Les rues non livrées
            </label>
          </div>
          <div className="form-check mt-2" style={{ fontSize: "12px" }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="rueExceptLivree"
              checked={uniquementRuesExceptLivrees}
              onChange={this.handleUniquementRuesExceptLivrees}
            ></input>
            <label className="form-check-label" htmlFor="rueExceptLivree">
              Les rues exceptionnellement livrées
            </label>
          </div>
          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={this.handleValiderClick}
            >
              Valider
            </button>
          </div>
        </div>
        <div className="col-10">
          <div className="row">
            <div className="col-8">
              <h2>Adresses</h2>
            </div>
          </div>
          {searchEnCours && (
            <div className="mt-5 mb-5">
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!searchEnCours && (
            <React.Fragment>
              <div className="row mb-2">
                <div className="col-12">
                  <div>
                    {totalCount} adresses (dont {sansCdeCount} adresses sans
                    commande)
                  </div>
                </div>
              </div>

              {totalCount !== 0 && (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                  prevPageText="Précédent"
                  nextPageText="Suivant"
                  firstPageText="Premier"
                  lastPageText="Dernier"
                />
              )}

              <div className="mb-2" style={{ fontSize: "12px" }}>
                <span className="font-weight-bold">Légende Id Fantoir : </span>
                <div
                  className="bg-danger ml-2"
                  style={{
                    width: "40px",
                    minWidth: "40px",
                    display: "inline-block",
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                  }}
                >
                  Rues non livrées
                </div>
                <div
                  className="bg-success ml-2"
                  style={{
                    width: "40px",
                    minWidth: "40px",
                    display: "inline-block",
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                  }}
                >
                  Rues exceptionnellement livrées
                </div>
              </div>
              <ListeAdresse
                adresses={adresses}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                handleCollapseAdrOnClick={this.handleCollapseAdrOnClick}
                handleCollapseMajOnClick={this.handleCollapseMajOnClick}
                handleChoixCoordonnees={this.handleChoixCoordonnees}
                handleDisactiveClick={this.handleDisactiveClick}
                handleDeleteCoordonneesGpsClick={
                  this.handleDeleteCoordonneesGpsClick
                }
                handleValidModifAdresse={this.handleValidModifAdresse}
                handleValidNewMessage={this.handleValidNewMessage}
                handleDisactiveMessage={this.handleDisactiveMessage}
                villesForSelect={villesForSelect}
                APIKEY_GOOGLEMAPS={APIKEY_GOOGLEMAPS}
                tabIndiceRepetitionLettreForSelect={
                  tabIndiceRepetitionLettreForSelect
                }
                tabIndiceRepetitionAdverbeForSelect={
                  tabIndiceRepetitionAdverbeForSelect
                }
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Adresses;
