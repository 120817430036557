import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import { getNavbarBgColor } from "../utils/tools";
import logo from "../images/hlp_logo.png";

const NavBar = ({ user, menuArbo }) => {
  console.log("process.env.NODE_ENV", process.env.NODE_ENV);
  const bgColor = getNavbarBgColor();
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const getUrl = (menu) => {
    if (menu.module === "CPTA")
      return (
        (menu.suffixeUrl.startsWith("/") ? "" : "/") + menu.suffixeUrl + "/"
      );
    return menu.url;
  };

  const pathname = window.location.pathname;
  const visuDoc =
    pathname.includes("documentfile") || pathname.includes("external");

  return (
    <div>
      <Navbar
        expand="xl"
        color="faded"
        light
        style={{ backgroundColor: bgColor }}
        className="py-0"
      >
        <NavbarBrand
          to="/"
          className="mr-auto navbar-brand-HLP"
          style={{ color: "#FFC000", marginLeft: "20px", marginRight: "20px" }}
        >
          <img
            src={logo}
            alt="HOP'LA PIZZA"
            style={{ height: "40px", marginRight: "260px" }}
          ></img>
        </NavbarBrand>

        <NavbarToggler className="mr-2" onClick={toggleNavbar} />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar className="mr-auto">
            {user &&
              !visuDoc &&
              menuArbo.map((menu) => (
                <div key={menu._id}>
                  <UncontrolledDropdown nav inNavbar className="ml-1">
                    <DropdownToggle nav style={{ color: "#FFC000" }}>
                      {menu.nom}
                    </DropdownToggle>
                    <DropdownMenu style={{ backgroundColor: bgColor }}>
                      {menu.children.map((subItem) => {
                        if (subItem.module === "COMPTA") {
                          return (
                            <DropdownItem
                              tag={Link}
                              className="dropdown-item-HLP"
                              to={getUrl(subItem)}
                              key={subItem._id}
                            >
                              {subItem.nom}
                            </DropdownItem>
                          );
                        } else {
                          return (
                            <DropdownItem
                              href={getUrl(subItem)}
                              className="dropdown-item-HLP"
                              key={subItem._id}
                            >
                              {subItem.nom}
                            </DropdownItem>
                          );
                        }
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              ))}
          </Nav>
          <Nav>
            {user && (
              <NavbarText className="d-none d-lg-block text-white">
                {user.firstname}
              </NavbarText>
            )}
            {user && !visuDoc && (
              <NavItem className="ml-3 mt-0">
                <NavLink
                  to={"/logout" + pathname}
                  className="nav-link-HLP"
                  style={{ backgroundColor: bgColor }}
                >
                  <i className="fa fa-power-off" aria-hidden="true"></i>
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
