import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function calculeInteretsCompteCourant(annee) {
  return await http.get(
    urlApiComptabilite + "/traitements/calculeInteretsCompteCourant/" + annee
  );
}

export async function calculeInteretsCompteCourantByAssocie(
  annee,
  actionnaireId,
  isPhysique,
  isMorale
) {
  return await http.get(
    urlApiComptabilite +
      "/traitements/calculeInteretsCompteCourantByAssocie/" +
      annee +
      "/" +
      actionnaireId +
      "/" +
      isPhysique +
      "/" +
      isMorale
  );
}

export async function genereInteretsCompteCourant(annee, tabFiliale, userId) {
  return await http.post(
    urlApiComptabilite + "/traitements/genereInteretsCompteCourant/" + annee,
    { tabFiliale, userId }
  );
}

export async function genereInteretsCompteCourantByAssocie(
  annee,
  actionnaireId,
  isPhysique,
  isMorale,
  tabFiliale,
  userId
) {
  return await http.post(
    urlApiComptabilite +
      "/traitements/genereInteretsCompteCourantByAssocie/" +
      annee +
      "/" +
      actionnaireId +
      "/" +
      isPhysique +
      "/" +
      isMorale,
    { tabFiliale, userId }
  );
}
