import React, { Component } from "react";
import SelectSearch from "react-select-search";
import { getPizzerias } from "../services/pizzeriaService";
import { soldeCptResultat } from "../services/saisieService";
import auth from "../services/authService";

class SoldeResultat extends Component {
  state = {
    controleEnCours: false,
    traitEnCours: false,
    societesForSelect: [],
    selectedSocietes: [],
    traitFait: false,
    tabTrait: [],
  };
  async componentDidMount() {
    const annee = new Date().getFullYear() - 1;
    const dateMax = annee.toString() + "1231";
    const societes = await getPizzerias();
    let societesForSelect = [];
    let selectedSocietes = [];
    societes.data.data.map((item) => {
      if (item.dateFinExoAAAAMMJJ_1 <= dateMax) {
        societesForSelect.push({
          value: item._id.toString(),
          name: item.denomination,
        });
        selectedSocietes.push(item._id.toString());
      }
      return true;
    });
    this.setState({ societesForSelect, selectedSocietes });
  }

  handleSelectAllClick = (event) => {
    event.preventDefault();
    const societesForSelect = this.state.societesForSelect;
    let selectedSocietes = [];
    societesForSelect.map((item) => {
      selectedSocietes.push(item.value);
      return true;
    });
    this.setState({
      selectedSocietes,
      traitFait: false,
    });
  };

  handleUnSelectAllClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedSocietes: [],
      traitFait: false,
    });
  };

  handleSocieteSelect = (societes) => {
    this.setState({
      selectedSocietes: societes,
      traitFait: false,
    });
  };

  handleSuivantClick = async (event) => {
    event.preventDefault();
    const { selectedSocietes, traitEnCours, controleEnCours } = this.state;
    if (!traitEnCours && !controleEnCours) {
      this.setState({ controleEnCours: true });
      const param = {};
      param.userId = auth.getCurrentUser()._id;

      // On ne met pas allSocietes à true car les sociétés qui cloturent que l'année prochaine ne doivent pas être cloturées
      param.societeIdTab = [];
      selectedSocietes.map((x) => {
        param.societeIdTab.push(parseInt(x));
        return true;
      });
      param.allSocietes = false;

      const ret = await soldeCptResultat(param);
      this.setState({
        controleEnCours: false,
        tabTrait: ret.data.data,
        traitFait: true,
      });
    }
  };

  render() {
    const {
      societesForSelect,
      selectedSocietes,
      tabTrait,
      traitFait,
      controleEnCours,
    } = this.state;
    const annee = new Date().getFullYear() - 1;
    return (
      <React.Fragment>
        <h2 className="mb-4">
          Solder les comptes de charges et produits de {annee}
        </h2>
        <div className="row">
          <div className="col-3">
            <div>
              <div>
                <label htmlFor="societes">Sociétés sélectionnées :</label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleSelectAllClick}
                >
                  Tout sélectionner
                </button>
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleUnSelectAllClick}
                >
                  Tout désélectionner
                </button>
              </div>
              <div>
                <SelectSearch
                  options={societesForSelect}
                  value={this.state.selectedSocietes}
                  name="societes"
                  multiple
                  onChange={this.handleSocieteSelect}
                />
              </div>
            </div>

            <div style={{ marginTop: "600px", marginBottom: "20px" }}>
              <button
                className="btn btn-primary ml-2"
                onClick={this.handleSuivantClick}
                disabled={selectedSocietes.length === 0 || controleEnCours}
              >
                <i className="fa fa-arrow-right mr-2"></i>
                Solder
              </button>
              {controleEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-9">
            {traitFait && !controleEnCours && (
              <React.Fragment>
                <h5 style={{ marginBottom: "20px" }}>
                  Compte-rendu des sociétés sélectionnées
                </h5>
                <div>
                  <ul>
                    {tabTrait.map((item, index) => (
                      <li key={index}>
                        {item.status === "fulfilled" && (
                          <React.Fragment>
                            <span>
                              {JSON.parse(item.value).societeDenomination}
                            </span>
                            <span> : </span>
                            <span className="text-success">
                              {JSON.parse(item.value).message}
                            </span>
                          </React.Fragment>
                        )}
                        {item.status !== "fulfilled" && (
                          <span className="text-danger">{item.reason}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SoldeResultat;
