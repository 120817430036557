import React, { Component } from "react";
import Table from "../common/table";

class ListeComptes extends Component {
  columns = [
    {
      path: "compteNumero",
      label: "Numéro",
      tdClassName: "p-1",
      className: "col-2 p-0",
      content: (c) => (
        <React.Fragment>
          <div
            style={{
              fontSize: "14px",
            }}
            id={"compte_" + c._id}
          >
            <a
              className="btn btn-link"
              role="button"
              href="#/"
              onClick={() => this.props.onSelectCompte(c)}
            >
              <i className="fas fa-edit mr-3" style={{ fontSize: "18px" }}></i>
              {c.compteNumero}
            </a>
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "compteLibelle",
      label: "Libellé",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.compteLibelle}</div>
        </React.Fragment>
      ),
    },
    {
      path: "categorieNom",
      label: "Catégorie",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div className="mb-2" style={{ fontSize: "14px" }}>
            {c.categorieNom}
          </div>
          <div>{this.props.getFilArianeCategorie(c.tabCategorie, true)}</div>
        </React.Fragment>
      ),
    },
    {
      path: "sensNormal",
      label: "Débit/Crédit",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.sensNormal}</div>
        </React.Fragment>
      ),
    },
    {
      path: "isCompteImmoAmortissable",
      label: "Cpt Immo non amortissable",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div
            className="text-center font-weight-bold"
            style={{ fontSize: "14px" }}
          >
            {c.isCompteImmoAmortissable === 0 && <div>&#10003;</div>}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "compteNatureFEC",
      label: "Nature de compte FEC",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.compteNatureFEC}</div>
        </React.Fragment>
      ),
    },
    {
      path: "compteNumeroFEC",
      label: "Numéro de compte FEC",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (fourn) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{fourn.compteNumeroFEC}</div>
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { comptes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={comptes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeComptes;
