import React, { Component } from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";

import VilleLesSocietes from "./villeLesSocietes";

import {
  getAllSocietesLivreuses,
  updateSocieteVilleIsDefaut,
  deleteSocieteVille,
  addSocieteVilleInsee,
  getPizzerias,
  updateVilleIsEntierementLivree,
} from "../../services/pizzeriaService";
import { villeDefautSociete } from "../../utils/tools";

class VillesSocietes extends Component {
  state = {
    villes: [],
    allPizzeriasForSelect: [],
    shouldShowBox: false,
  };
  async componentDidMount() {
    const resultVilles = await getAllSocietesLivreuses();
    const dataVilles = resultVilles.data.data;

    const resultPizzerias = await getPizzerias();
    let allPizzeriasForSelect = [];
    resultPizzerias.data.data
      .filter((item) => item.hasBesoinPlanning === 1)
      .forEach((item) => {
        allPizzeriasForSelect.push({
          value: item._id.toString(),
          name: item.denomination,
        });
      });
    allPizzeriasForSelect = _.orderBy(allPizzeriasForSelect, ["name"], ["asc"]);

    this.setState({
      villes: dataVilles,
      allPizzeriasForSelect,
      shouldShowBox: true,
    });
  }

  handleIsEntierementLivreeChange = async (event) => {
    const { villes } = this.state;
    const villeId = parseInt(event.target.id);
    const laVille = villes.find((v) => v.villeId === villeId);
    const isEntierementLivree = laVille.villeIsEntierementLivree === 1 ? 0 : 1;
    const retUpdate = await updateVilleIsEntierementLivree({
      villeId,
      isEntierementLivree: isEntierementLivree,
    });
    if (retUpdate.data.success) {
      //
      let newArray = [...this.state.villes];
      let elementsIndex = this.state.villes.findIndex((x) => {
        return x.villeId === parseInt(villeId);
      });
      newArray[elementsIndex] = {
        ...laVille,
        villeIsEntierementLivree: isEntierementLivree,
      };
      this.setState({ villes: newArray });
    } else {
      toast.error(retUpdate.data.message);
    }
  };

  handleIsDefautChange = async (event) => {
    const { villes } = this.state;
    const id = event.target.id;
    const tabId = id.split("|");
    const villeId = parseInt(tabId[0]);
    const societeId = parseInt(tabId[1]);
    const laVille = villes.find((v) => v.villeId === villeId);
    const laSociete = laVille.tabSociete.find((s) => s.societeId === societeId);
    laSociete.isDefaut = laSociete.isDefaut === 1 ? 0 : 1;
    const retUpdate = await updateSocieteVilleIsDefaut({
      societeId,
      villeId,
      isDefaut: laSociete.isDefaut,
    });
    if (retUpdate.data.success) {
      this.setState({ villes });
    } else {
      toast.error(retUpdate.data.message);
    }
  };

  handleSupprSocieteVilleOnClick = (societe) => {
    confirmAlert({
      title:
        "Suppression de l'affectation à la société " + societe.denomination,
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => this.traitSupprSociete(societe),
        },
        {
          label: "Non",
        },
      ],
    });
  };

  traitSupprSociete = async (societe) => {
    const { villes } = this.state;
    const tabId = societe._id.split("|");
    const villeId = parseInt(tabId[0]);
    const societeId = parseInt(tabId[1]);
    const laVille = villes.find((s) => s.villeId === villeId);
    laVille.tabSociete = laVille.tabSociete.filter(
      (s) => s.societeId !== societeId
    );
    await deleteSocieteVille({
      societeId,
      villeId,
    });
    this.setState({ villes });
  };

  handleAddSocieteVille = async ({ societeId, idInsee }) => {
    const retAdd = await addSocieteVilleInsee({ societeId, idInsee });
    const resultVilles = await getAllSocietesLivreuses();

    const dataVilles = resultVilles.data.data;
    this.setState({
      villes: dataVilles,
    });
    return retAdd.data.data;
  };

  render() {
    const { villes, allPizzeriasForSelect, shouldShowBox } = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
            <h2>Villes livrées / Sociétés</h2>

            {!shouldShowBox && (
              <div className="mt-5 mb-5">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {shouldShowBox && (
              <React.Fragment>
                <div className="row ml-3" style={{ fontSize: "14px" }}>
                  <span className="font-weight-bold mr-2">Légende : </span>
                  <span>{villeDefautSociete}</span>
                </div>
                <table
                  className="table table-bordered table-striped mt-4"
                  style={{ fontSize: "14px" }}
                >
                  <tbody>
                    {villes.map((item) => (
                      <tr className="row mr-0 ml-0" key={item.villeId}>
                        <td className="col-3" style={{ fontSize: "14px" }}>
                          <div className="font-weight-bold">
                            {item.villeNom + " " + item.villeCP}
                          </div>
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                name="isEntierementLivree"
                                id={item.villeId}
                                type="checkbox"
                                checked={item.villeIsEntierementLivree === 1}
                                onChange={this.handleIsEntierementLivreeChange}
                                className="form-check-input"
                              />
                              <label
                                htmlFor="isEntierementLivree"
                                className="form-check-label"
                              >
                                Entièrement livrée
                              </label>
                            </div>
                          </div>
                        </td>
                        <td className="col-9" style={{ fontSize: "14px" }}>
                          <React.Fragment>
                            <VilleLesSocietes
                              villeId={item.villeId}
                              idInsee={item.idInsee}
                              societes={item.tabSociete}
                              tabSocieteId={item.tabSocieteId}
                              allPizzeriasForSelect={allPizzeriasForSelect}
                              handleIsDefautChange={this.handleIsDefautChange}
                              handleSupprSocieteVilleOnClick={
                                this.handleSupprSocieteVilleOnClick
                              }
                              handleAddSocieteVille={this.handleAddSocieteVille}
                            ></VilleLesSocietes>
                          </React.Fragment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default VillesSocietes;
