import React, { Component } from "react";
import SelectSearch from "react-select-search";
import moment from "moment";
import NumberFormat from "react-number-format";

import { makeId } from "../../utils/tools";

class ProduitLesFournisseurs extends Component {
  state = {
    newFournisseur: false,
    allPizzeriasForSelect: [],
    tabSocieteId: [],
    societe: "",
    fournisseur: "",
    conditionnement: 0,
    referenceFournisseur: "",
    selectedReference: "",
    tabRefDuFournisseur: [],
  };

  async componentDidMount() {}

  handleNewFournisseur = () => {
    const { newFournisseur } = this.state;
    this.setState({ newFournisseur: !newFournisseur });
  };

  handleCancelNewFournisseur = () => {
    const { newFournisseur } = this.state;
    this.setState({ newFournisseur: !newFournisseur });
  };

  handleFournisseurChange = (event) => {
    const { tabRefProduitFournisseur } = this.props;

    const tabRefDuFournisseur = tabRefProduitFournisseur.filter(
      (f) => f.fournisseurId === parseInt(event)
    );
    this.setState({
      fournisseur: event,
      tabRefDuFournisseur,
      selectedReference:
        tabRefDuFournisseur.length > 0 ? tabRefDuFournisseur[0].value : "",
    });
  };

  handleReferenceFournisseurChange = (event) => {
    let { referenceFournisseur } = this.state;
    referenceFournisseur = event.target.value;
    this.setState({ referenceFournisseur });
  };

  handleRefConnueChange = (event) => {
    this.setState({ selectedReference: event });
  };

  handleValueConditionnementChange = (values) => {
    this.setState({ conditionnement: values.floatValue });
  };

  handleReprendreFournisseur = () => {
    const { selectedReference, tabRefDuFournisseur } = this.state;
    const laRef = tabRefDuFournisseur.find(
      (r) => r.value === selectedReference
    );
    if (laRef) {
      this.setState({ referenceFournisseur: laRef.reference });
    }
  };

  handleSubmitNewFournisseur = async () => {
    const {
      newFournisseur,
      fournisseur,
      conditionnement,
      referenceFournisseur,
    } = this.state;

    await this.props.handleAddProduitFournisseur({
      produitId: this.props.item._id,
      fournisseurId: parseInt(fournisseur),
      conditionnement,
      dateFrom: "20220101",
      referenceFournisseur,
    });
    this.setState({
      newFournisseur: !newFournisseur,
      fournisseur: "",
      referenceFournisseur: "",
    });
  };

  handleSupprFournisseur = async (f) => {
    await this.props.handleSupprFournisseurOnClick(f._id, this.props.item._id);
  };

  render() {
    const { item, tabFournisseursForSelect } = this.props;
    const {
      newFournisseur,
      fournisseur,
      conditionnement,
      referenceFournisseur,
      selectedReference,
      tabRefDuFournisseur,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            {item.tabFournisseur.length === 0 && (
              <button
                onClick={this.handleNewFournisseur}
                className="btn btn-primary text-white ml-2"
                disabled={newFournisseur}
              >
                +
              </button>
            )}

            <div>
              {newFournisseur && (
                <React.Fragment>
                  <div className="ml-2 mb-2">
                    <div className="form-group mb-0">
                      <label htmlFor="fournisseur">Fournisseur</label>
                      <SelectSearch
                        options={tabFournisseursForSelect}
                        value={fournisseur}
                        name="societe"
                        search={true}
                        onChange={this.handleFournisseurChange}
                      />
                    </div>
                    <div className="form-group mb-0">
                      <label>Réf. Fourn.</label>
                      <input
                        type="text"
                        name={"referenceFournisseur" + makeId()}
                        id="referenceFournisseur"
                        placeholder="Référence fournisseur"
                        value={referenceFournisseur}
                        className="form-control"
                        style={{ width: "300px" }}
                        autoComplete={makeId()}
                        onChange={(e) => {
                          this.handleReferenceFournisseurChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group mb-0 mt-4">
                      <label
                        htmlFor="refConnue"
                        style={{ float: "left", width: "20%" }}
                      >
                        Référence connue
                      </label>
                      <div style={{ float: "left", width: "40%" }}>
                        <SelectSearch
                          options={tabRefDuFournisseur}
                          value={selectedReference}
                          name="refConnue"
                          search={true}
                          onChange={this.handleRefConnueChange}
                        />
                      </div>
                      <div style={{ float: "left", width: "40%" }}>
                        <button
                          className="btn btn-outline-secondary ml-2"
                          onClick={this.handleReprendreFournisseur}
                        >
                          Reprendre
                        </button>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="mt-2 ml-2 mr-2">
                        Contenant ({item.uniteMesureNom})
                      </label>
                      <NumberFormat
                        className={"form-control text-right mb-2"}
                        style={{ width: "200px" }}
                        value={conditionnement}
                        decimalScale={3}
                        fixedDecimalScale={true}
                        decimalSeparator=","
                        thousandSeparator=" "
                        allowNegative={false}
                        onValueChange={(e, value) => {
                          this.handleValueConditionnementChange(e, value);
                        }}
                      />
                    </div>

                    <button
                      className="btn btn-primary mr-2"
                      disabled={
                        fournisseur === "-1" || conditionnement === "0,000"
                      }
                      onClick={this.handleSubmitNewFournisseur}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewFournisseur}
                    >
                      Annuler
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {item.tabFournisseur.length !== 0 && (
            <div className="col-12 row" style={{ fontSize: "12px" }}>
              <div className="col-3">
                {item.tabFournisseur[0].fournisseurNom} -{" "}
                {item.tabFournisseur[0].fournisseurEmail}
              </div>
              <div className="col-3">
                {item.tabFournisseur[0].referenceFournisseur}
              </div>
              <div className="col-2">
                {moment(item.tabFournisseur[0].dateFrom, "YYYYMMDD").format(
                  "DD/MM/YY"
                )}
              </div>
              <div className="col-3">
                <NumberFormat
                  value={item.tabFournisseur[0].conditionnement}
                  decimalScale={3}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator=" "
                  displayType="text"
                />
              </div>
              <div className="col-1 text-center">
                <button
                  className="btn btn-outline-secondary p-0"
                  onClick={() =>
                    this.handleSupprFournisseur(item.tabFournisseur[0])
                  }
                >
                  <i
                    className="far fa-trash-alt p-1"
                    style={{ fontSize: "24px" }}
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ProduitLesFournisseurs;
