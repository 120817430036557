import React, { Component } from "react";
import SelectSearch from "react-select-search";
import _ from "lodash";

import SearchBox from "../common/searchBox";

import { fullAllModePaiement } from "../../services/paiementService";
import {
  getFullTypesEcriture,
  addTypeEcriture,
  updateTypeEcriture,
} from "../../services/saisieService";
import { getAllCodesJournal } from "../../services/parametresService";
import BarreMenu from "../common/barreMenu";
import ListeTypeEcriture from "./listeTypeEcriture";
import {
  typeMenuParametresComptabilite,
  menuTypesEcriture,
} from "../../utils/menuParametres";

class TypesEcriture extends Component {
  state = {
    searchQuery: "",
    typesEcriture: [],
    tabMethodeForSelect: [],
    sortColumn: { path: "_id", order: "asc" },
    newType: false,
    dataType: {
      _id: -1,
      nom: "",
      isPaiementParCheque: false,
      isRapproBancaire: false,
      isRapproBancaireDebit: false,
      isRapproBancaireCredit: false,
      methodePaiementId: "0",
      isOuvertSaisie: false,
      contactNomTable: "",
      isExclusFEC: false,
      journalCode: "-1",
      gereComptesAuxilaire: true,
    },
    selectedType: -1,
    contactTable: [
      { value: "", name: "" },
      { value: "ACHAT_FOURNISSEUR", name: "Contact" },
      { value: "EMPLOYE", name: "Employé" },
      { value: "PIZZERIA_SOCIETE", name: "Société" },
    ],
    tabCodeJournalForSelect: [],
    texteAideCodeJournal:
      "Lors de l'export FEC, les écritures liées à un compte de  banque sont affectées au code journal de la banque, les écritures liées au compte de caisse sont affectées au code journal CAI. Hormis ces cas, si le code journal n'est pas indiqué, le code journal est fixé à OD.",
  };

  async componentDidMount() {
    const dataTypes = await getFullTypesEcriture();
    const dataCodeJournal = await getAllCodesJournal();
    const tabCodeJournalForSelect = [];
    tabCodeJournalForSelect.push({ value: "-1", name: "" });
    dataCodeJournal.data.data.allCodesJournal.forEach((x) => {
      tabCodeJournalForSelect.push({
        value: x.code,
        name: x.code + " - " + x.libelle,
      });
    });
    const data = await fullAllModePaiement();
    const tabMethodeForSelect = [];
    tabMethodeForSelect.push({ value: "0", name: "" });
    data.data.data.forEach((x) => {
      tabMethodeForSelect.push({
        value: x._id.toString(),
        name: x._id.toString() + " - " + x.designation,
      });
    });

    this.setState({
      typesEcriture: dataTypes.data.data,
      tabCodeJournalForSelect,
      tabMethodeForSelect,
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
    });
  };

  getPagedData = () => {
    const { searchQuery, typesEcriture, sortColumn } = this.state;

    let filtered = typesEcriture;
    if (searchQuery)
      filtered = filtered.filter(
        (m) =>
          (m.nom && m.nom.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (m._id && m._id.toString().includes(searchQuery))
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    return { totalCount: filtered.length, data: sorted };
  };

  handleNewType = () => {
    const { newType, dataType } = this.state;
    dataType._id = -1;
    dataType.nom = "";
    dataType.isPaiementParCheque = false;
    dataType.isRapproBancaire = false;
    dataType.isRapproBancaireDebit = false;
    dataType.isRapproBancaireCredit = false;
    dataType.methodePaiementId = "0";
    dataType.isOuvertSaisie = false;
    dataType.contactNomTable = "";
    dataType.isExclusFEC = false;
    dataType.journalCode = "-1";
    dataType.gereComptesAuxilaire = true;
    this.setState({
      newType: !newType,
      dataType,
    });
  };

  handleCancelNewType = () => {
    const { newType, selectedType } = this.state;
    if (!newType && selectedType !== -1) {
      const releventDiv = document.getElementById("type_" + selectedType);
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }

    this.setState({
      newType: false,
      selectedType: -1,
    });
  };

  handleDesignationChange = async (event) => {
    const { dataType } = this.state;
    dataType.nom = event.target.value;
    this.setState({
      dataType,
    });
  };

  handleIsPaiementParChequeChange = () => {
    const { dataType } = this.state;
    dataType.isPaiementParCheque = !dataType.isPaiementParCheque;
    this.setState({ dataType });
  };

  handleIsRapproBancaireChange = () => {
    const { dataType } = this.state;
    dataType.isRapproBancaire = !dataType.isRapproBancaire;
    if (!dataType.isRapproBancaire) {
      dataType.isRapproBancaireDebit = false;
      dataType.isRapproBancaireCredit = false;
    }
    this.setState({ dataType });
  };

  handleIsRapproBancaireDebitChange = () => {
    const { dataType } = this.state;
    dataType.isRapproBancaireDebit = !dataType.isRapproBancaireDebit;
    this.setState({ dataType });
  };

  handleIsRapproBancaireCreditChange = () => {
    const { dataType } = this.state;
    dataType.isRapproBancaireCredit = !dataType.isRapproBancaireCredit;
    this.setState({ dataType });
  };

  handleMethodePaiementSelect = (methode) => {
    const { dataType } = this.state;
    dataType.methodePaiementId = methode;
    this.setState({ dataType });
  };

  handleContactNomTableSelect = (type) => {
    const { dataType } = this.state;
    dataType.contactNomTable = type;
    this.setState({ dataType });
  };

  handleIsOuvertSaisieChange = () => {
    const { dataType } = this.state;
    dataType.isOuvertSaisie = !dataType.isOuvertSaisie;
    this.setState({ dataType });
  };

  handleIsExclusFECChange = () => {
    const { dataType } = this.state;
    dataType.isExclusFEC = !dataType.isExclusFEC;
    if (dataType.isExclusFEC) {
      dataType.journalCode = "-1";
      dataType.gereComptesAuxilaire = false;
    }
    this.setState({ dataType });
  };

  handleJournalCodeSelect = (code) => {
    const { dataType } = this.state;
    dataType.journalCode = code;
    this.setState({ dataType });
  };

  handleGereComptesAuxilaireChange = () => {
    const { dataType } = this.state;
    dataType.gereComptesAuxilaire = !dataType.gereComptesAuxilaire;
    dataType.affecteAuxiAutre = !dataType.gereComptesAuxilaire;
    this.setState({ dataType });
  };

  onSelectType = (type) => {
    const { selectedType, newType } = this.state;
    if (selectedType === -1 && !newType) {
      const dataType = {};
      dataType._id = type._id;
      dataType.nom = type.nom;
      dataType.isPaiementParCheque = type.isPaiementParCheque === 1;
      dataType.isRapproBancaire = type.isRapproBancaire === 1;
      dataType.isRapproBancaireDebit = type.isRapproBancaireDebit === 1;
      dataType.isRapproBancaireCredit = type.isRapproBancaireCredit === 1;
      dataType.methodePaiementId = type.methodePaiementId
        ? type.methodePaiementId.toString()
        : "";
      dataType.isOuvertSaisie = type.isOuvertSaisie === 1;
      dataType.contactNomTable = type.contactNomTable
        ? type.contactNomTable
        : "";
      dataType.isExclusFEC = type.isExclusFEC === 1;
      dataType.journalCode = type.journalCode ? type.journalCode : "-1";
      dataType.affecteAuxiAutre = type.affecteAuxiAutre === 0;
      dataType.gereComptesAuxilaire = dataType.affecteAuxiAutre;

      this.setState({
        selectedType: type._id,
        dataType,
      });

      const releventDiv = document.getElementById("top-id");
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
  };

  handleSubmitType = async () => {
    const { selectedType, newType } = this.state;
    if (newType && selectedType === -1) {
      await this.traiteNewType();
    } else {
      if (!newType && selectedType !== -1) {
        await this.traiteUpdateType();
      }
    }
  };

  traiteNewType = async () => {
    const { dataType } = this.state;
    await addTypeEcriture(dataType);
    const dataTypes = await getFullTypesEcriture();
    this.setState({
      typesEcriture: dataTypes.data.data,
      newType: false,
    });
    const releventDiv = document.getElementById("bottom-id");
    if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
  };

  traiteUpdateType = async () => {
    const { selectedType, dataType } = this.state;
    await updateTypeEcriture(selectedType, dataType);
    const dataTypes = await getFullTypesEcriture();
    const releventDiv = document.getElementById("type_" + selectedType);
    if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    this.setState({
      typesEcriture: dataTypes.data.data,
      newType: false,
      selectedType: -1,
    });
  };

  render() {
    const {
      sortColumn,
      newType,
      dataType,
      selectedType,
      tabMethodeForSelect,
      typesEcriture,
      contactTable,
      searchQuery,
      tabCodeJournalForSelect,
      texteAideCodeJournal,
    } = this.state;

    const { totalCount, data } = this.getPagedData();

    return (
      <React.Fragment>
        <span id="top-id"></span>
        <BarreMenu
          type_menu={typeMenuParametresComptabilite}
          menu_actif={menuTypesEcriture}
        ></BarreMenu>
        <div className="row">
          <div className="col-12">
            {!newType && selectedType === -1 && (
              <button
                onClick={this.handleNewType}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newType}
              >
                Nouveau type d'écriture
              </button>
            )}
            <div>
              {(newType || selectedType !== -1) && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newType
                      ? "Nouveau type d'écriture"
                      : "Modification du type d'écriture Id " + dataType._id}
                  </h5>
                  <div className="ml-2 mr-2" style={{ fontSize: "14px" }}>
                    <div className="form-group row">
                      <div className="col-9">
                        <label
                          className="font-weight-bold"
                          htmlFor="designation"
                        >
                          Désignation
                        </label>
                        <input
                          type="text"
                          value={dataType.nom}
                          onChange={(event) =>
                            this.handleDesignationChange(event)
                          }
                          className="form-control"
                          placeholder="Désignation"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isPaiementParCheque"
                            checked={dataType.isPaiementParCheque}
                            onChange={this.handleIsPaiementParChequeChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isPaiementParCheque"
                          >
                            Paiement par chèque
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isRapproBancaire"
                            checked={dataType.isRapproBancaire}
                            onChange={this.handleIsRapproBancaireChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isRapproBancaire"
                          >
                            Rapprochement bancaire
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isRapproBancaireDebit"
                            checked={dataType.isRapproBancaireDebit}
                            onChange={this.handleIsRapproBancaireDebitChange}
                            disabled={!dataType.isRapproBancaire}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isRapproBancaireDebit"
                          >
                            Rapprochement bancaire sur les débits
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isRapproBancaireCredit"
                            checked={dataType.isRapproBancaireCredit}
                            onChange={this.handleIsRapproBancaireCreditChange}
                            disabled={!dataType.isRapproBancaire}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isRapproBancaireCredit"
                          >
                            Rapprochement bancaire sur les crédits
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="methodePaiementId"
                        >
                          Méthode de paiement
                        </label>
                        <SelectSearch
                          options={tabMethodeForSelect}
                          value={dataType.methodePaiementId}
                          name="methodePaiementId"
                          search={true}
                          onChange={this.handleMethodePaiementSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isOuvertSaisie"
                            checked={dataType.isOuvertSaisie}
                            onChange={this.handleIsOuvertSaisieChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isOuvertSaisie"
                          >
                            Saisie manuelle d'écritures autorisée
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="contactNomTable"
                        >
                          Type de compte auxiliaire
                        </label>
                        <SelectSearch
                          options={contactTable}
                          value={dataType.contactNomTable}
                          name="contactNomTable"
                          search={false}
                          onChange={this.handleContactNomTableSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isExclusFEC"
                            checked={dataType.isExclusFEC}
                            onChange={this.handleIsExclusFECChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isExclusFEC"
                          >
                            Type d'écriture exclus du fichier FEC
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="journalCode"
                        >
                          Code journal FEC
                          <i className="fas fa-info-circle ml-1"></i>
                        </label>
                        <SelectSearch
                          options={tabCodeJournalForSelect}
                          value={dataType.journalCode}
                          name="journalCode"
                          search={true}
                          onChange={this.handleJournalCodeSelect}
                          disabled={dataType.isExclusFEC}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                      <div className="col-3">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="gereComptesAuxilaire"
                            checked={dataType.gereComptesAuxilaire}
                            onChange={this.handleGereComptesAuxilaireChange}
                            disabled={dataType.isExclusFEC}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="gereComptesAuxilaire"
                          >
                            Gestion des comptes auxiliaires (FEC)
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="alert alert-warning">
                      <i className="fas fa-info-circle mr-2"></i>
                      {texteAideCodeJournal}
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitType}
                      disabled={
                        dataType.nom.length === 0 ||
                        (dataType.isRapproBancaire &&
                          !dataType.isRapproBancaireDebit &&
                          !dataType.isRapproBancaireCredit)
                      }
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewType}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>

            <p className="w-100">{totalCount} types</p>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />

            {!(newType || selectedType !== -1) && (
              <div className="alert alert-warning" style={{ fontSize: "14px" }}>
                {"(*) " + texteAideCodeJournal}
              </div>
            )}

            {typesEcriture && (
              <ListeTypeEcriture
                typesEcriture={data}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onSelectType={this.onSelectType}
                contactTable={contactTable}
                texteAideCodeJournal={texteAideCodeJournal}
              />
            )}
            <span id="bottom-id"></span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TypesEcriture;
