import React from "react";
import moment from "moment";
import SelectSearch from "react-select-search";

import Table from "../common/table";

const BlocActionnaires = (props) => {
  const {
    societe,
    newActionnaire,
    selectedActionnaireId,
    newActionnaireSocieteId,
    dataActionnaire,
    tabSocieteForSelect,
    tabPPForSelect,
    maxTotalParticipation,
    tabCompteForSelect,
    tabCompteExclus,
    tabPPExclusActionnaire,
    tabPMExclusActionnaire,
  } = props;

  const columns = [
    {
      path: "actionnaireNom",
      label: "Nom",
      tdClassName: "p-2",
      className: "",
      content: (a) => (
        <span
          style={{ fontSize: "12px" }}
          className={"clickable " + props.handleGetColor(a)}
          onClick={() => props.onSelectActionnaire(a)}
        >
          <i className="fas fa-edit mr-3" style={{ fontSize: "18px" }}></i>
          <i
            style={{ fontSize: "18px" }}
            className={
              a.isPhysique === 1 ? "fas fa-user mr-2" : "fas fa-building mr-2"
            }
          ></i>{" "}
          {a.actionnaireNom}
        </span>
      ),
    },
    {
      path: "isInterne",
      label: "Interne",
      tdClassName: "p-2",
      className: "",
      content: (a) => (
        <span style={{ fontSize: "12px" }} className={props.handleGetColor(a)}>
          Interne{" "}
          <i
            className={
              a.isInterne === 1 ? "fas fa-check-square" : "far fa-square"
            }
            style={{ fontSize: "18px" }}
          ></i>
        </span>
      ),
    },
    {
      path: "participation",
      label: "Participation",
      tdClassName: "p-2",
      className: "",
      content: (a) => (
        <span style={{ fontSize: "12px" }} className={props.handleGetColor(a)}>
          Part. : {a.participation} %
        </span>
      ),
    },
    {
      path: "compteCCId",
      label: "Cpt courant",
      tdClassName: "p-2",
      className: "",
      content: (a) => (
        <span style={{ fontSize: "12px" }} className={props.handleGetColor(a)}>
          CCA : {a.compteCCId}
        </span>
      ),
    },
    {
      path: "dateFromAAAAMMJJ",
      label: "Depuis le",
      tdClassName: "p-2",
      className: "",
      content: (a) => (
        <span style={{ fontSize: "12px" }} className={props.handleGetColor(a)}>
          Depuis : {moment(a.dateFromAAAAMMJJ, "YYYYMMDD").format("DD/MM/YY")}
        </span>
      ),
    },
    {
      path: "dateToAAAAMMJJ",
      label: "Jusqu'au",
      tdClassName: "p-2",
      className: "",
      content: (a) => (
        <span style={{ fontSize: "12px" }} className={props.handleGetColor(a)}>
          Jusqu'au :{" "}
          {a.dateToAAAAMMJJ !== null
            ? moment(a.dateToAAAAMMJJ, "YYYYMMDD").format("DD/MM/YY")
            : "Maintenant"}
        </span>
      ),
    },
    {
      path: "delete",
      label: "delete",
      tdClassName: "p-2",
      className: "",
      content: (a) => (
        <button
          className={
            "btn btn-sm btn-outline-secondary mb-1 " + props.handleGetColor(a)
          }
          onClick={() => props.handleSupprActionnaire(societe._id, a)}
        >
          <i
            className={"fa fa-trash"}
            style={{ fontSize: "14px" }}
            aria-hidden="true"
          ></i>
        </button>
      ),
    },
  ];
  const sortColumn = { path: "_id", order: "asc" };

  return (
    <React.Fragment>
      {(newActionnaire || selectedActionnaireId !== -1) &&
        newActionnaireSocieteId === societe._id && (
          <div className="jumbotron jumbotron-fluid pt-2 pb-2">
            <h5 className="ml-2">
              {newActionnaire
                ? "Nouvel actionnaire"
                : "Modification de l'actionnaire"}
            </h5>
            <div className="ml-2 mr-2">
              <div className="radio-item">
                <input
                  type="radio"
                  name="type"
                  value="morale"
                  checked={dataActionnaire.typePersonne === "morale"}
                  onChange={(e) => props.handleTypePersonneActionnaireChange(e)}
                />
                <label
                  className="font-weight-bold"
                  onClick={() =>
                    props.handleTypePersonneActionnaireClick("morale")
                  }
                >
                  Personne morale
                </label>
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  name="type"
                  value="physique"
                  checked={dataActionnaire.typePersonne === "physique"}
                  onChange={(e) => props.handleTypePersonneActionnaireChange(e)}
                />
                <label
                  className="font-weight-bold"
                  onClick={() =>
                    props.handleTypePersonneActionnaireClick("physique")
                  }
                >
                  Personne physique
                </label>
              </div>
              {dataActionnaire.isPersonneMorale && (
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="nom">
                    Personne morale
                  </label>
                  <SelectSearch
                    options={tabSocieteForSelect.filter(
                      (s) =>
                        s.value !== newActionnaireSocieteId.toString() &&
                        !tabPMExclusActionnaire.includes(s.value)
                    )}
                    value={dataActionnaire.actionnaireId}
                    name="actionnaireId"
                    search={true}
                    onChange={props.handlePersonneActionnaireSelect}
                  />
                </div>
              )}
              {dataActionnaire.isPersonnePhysique && (
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="nom">
                    Personne physique
                  </label>
                  <SelectSearch
                    options={tabPPForSelect.filter(
                      (p) => !tabPPExclusActionnaire.includes(p.value)
                    )}
                    value={dataActionnaire.actionnaireId}
                    name="actionnaireId"
                    search={true}
                    onChange={props.handlePersonneActionnaireSelect}
                  />
                </div>
              )}
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="isInterne"
                    checked={dataActionnaire.isInterne}
                    onChange={props.handleIsInternePhysiqueChange}
                  ></input>
                  <label
                    className="form-check-label font-weight-bold"
                    htmlFor="isInterne"
                  >
                    Interne
                  </label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Participation en %
                  </label>
                  <input
                    type="number"
                    id="tentacles"
                    name="tentacles"
                    min="1"
                    max={maxTotalParticipation.toString()}
                    className="form-control"
                    value={dataActionnaire.participation}
                    onChange={(event) => props.handleParticipationChange(event)}
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Depuis le
                  </label>
                  <input
                    type="date"
                    value={dataActionnaire.date}
                    onChange={(date) => props.handleDateActionnaireChange(date)}
                    className="form-control"
                    style={{ fontSize: "12px", width: "150px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Jusqu'au
                  </label>
                  <input
                    type="date"
                    value={dataActionnaire.dateTo}
                    onChange={(date) =>
                      props.handleDateToActionnaireToChange(date)
                    }
                    className="form-control"
                    style={{ fontSize: "12px", width: "150px" }}
                  ></input>
                </div>
              </div>

              <div className="form-group">
                <label className="font-weight-bold" htmlFor="nom">
                  Compte courant d'associé
                </label>
                <SelectSearch
                  options={tabCompteForSelect.filter(
                    (c) => !tabCompteExclus.includes(c.value)
                  )}
                  value={dataActionnaire.compteCCId}
                  name="compteCCId"
                  search={true}
                  onChange={props.handleCCSelect}
                />
              </div>
              <div className="ml-2 mr-2">
                <button
                  className="btn btn-primary mr-2"
                  onClick={props.handleSubmitActionnaire}
                  disabled={
                    dataActionnaire.actionnaireId === "-1" ||
                    dataActionnaire.compteCCId === "-1" ||
                    dataActionnaire.dateAAAAMMJJ.length === 0
                  }
                >
                  Enregistrer
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={props.handleCancelNewActionnaire}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        )}
      {societe.totalParticipation !== 100 &&
        !newActionnaire &&
        newActionnaireSocieteId === -1 && (
          <button
            className="btn btn-sm btn-outline-secondary mb-1 mt-2"
            onClick={() => props.handleNewActionnaire(societe._id)}
          >
            <i
              className="fa fa-plus"
              style={{ fontSize: "14px" }}
              aria-hidden="true"
            ></i>
          </button>
        )}
      <div className="row mt-2 ml-0 mr-0">
        <span
          className={societe.totalParticipation !== 100 ? "text-warning" : ""}
        >
          Répartition des actionnaires : {societe.totalParticipation} %
        </span>
        <span
          className={
            societe.totalParticipationInterne !== 100
              ? "text-warning ml-2"
              : "ml-2"
          }
        >
          Répartition en interne des actionnaires :{" "}
          {societe.totalParticipationInterne} %
        </span>
      </div>
      <div className="row mt-2 ml-0 mr-0">
        <Table
          columns={columns}
          data={societe.tabActionnaire}
          sortColumn={sortColumn}
          noHeader={true}
          //onSort={this.handleSort}
        />
      </div>
    </React.Fragment>
  );
};

export default BlocActionnaires;
