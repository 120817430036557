import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export function getAllPersonnesPhysiques() {
  return http.get(
    urlApiComptabilite + "/actionnariat/getAllPersonnesPhysiques"
  );
}

export function addPersonnePhysique({ dataPersonne }) {
  return http.post(urlApiComptabilite + "/actionnariat/addPersonnePhysique", {
    dataPersonne,
  });
}

export function updatePersonnePhysique({ personneId, dataPersonne }) {
  return http.put(
    urlApiComptabilite + "/actionnariat/updatePersonnePhysique/" + personneId,
    {
      dataPersonne,
    }
  );
}
