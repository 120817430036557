import React, { Component } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import Table from "../common/table";

class ListeSaisonnalite extends Component {
  columns = [
    {
      key: "ordre",
      label: "Top",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (ligne) => (
        <div
          className={
            "circle" +
            (ligne.ordre < 3 ? " bg-success" : "") +
            (ligne.ordre > 8 ? " bg-warning" : "")
          }
        >
          <span className={"circle__content"}>{ligne.ordre + 1}</span>
        </div>
      ),
    },
    {
      path: "_id",
      label: "Mois",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (ligne) => (
        <div style={{ fontSize: "14px" }}>
          {moment(ligne._id, "M").format("MMMM").toUpperCase()}
        </div>
      ),
    },
    {
      path: "pourcent",
      label: "Pourcentage",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (ligne) => (
        <div className="text-right">
          <NumberFormat
            value={ligne.pourcent}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
          />
        </div>
      ),
    },
  ];
  render() {
    const { CAMois, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={CAMois}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeSaisonnalite;
