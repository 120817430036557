import React, { Component } from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import SelectSearch from "react-select-search";
import NumberFormat from "react-number-format";
import { getPizzerias } from "../../services/pizzeriaService";
import {
  getAllProduits,
  getAllTypesAnalyse,
} from "../../services/produitsVenteService";
import { getStatsProduit } from "../../services/statsService";
import { roundDecimal } from "../../utils/tools";

moment.locale("fr", localization);
class StatsProduits extends Component {
  state = {
    annees: [],
    selectedAnnee: null,
    societesForSelect: [],
    selectedSociete: null,
    typeAnalyseForSelect: [],
    selectedTypeAnalyse: null,
    typesCommandes: [],
    selectedTypesCommande: [],
    defaultTypesCommande: [],
    tabProduits: [],
    shouldShowBox: false,
    isMonoSociete: false,
    traitFait: false,
    lesProduits: [],
    detailProduit: false,
    uniquementTotalSemaine: true,
    infosTTC: false,
    dataListe: [],
  };

  async componentDidMount() {
    let annees = [];
    const annee = new Date().getFullYear();
    for (let index = annee; index >= 2014; index--) {
      annees.push({
        value: index.toString(),
        name: index.toString(),
      });
    }

    const typesCommandes = [];
    typesCommandes.push({ value: "0", name: "Clients < 2015" });
    typesCommandes.push({ value: "1", name: "Sur place" });
    typesCommandes.push({ value: "2", name: "A emporter" });
    typesCommandes.push({ value: "3", name: "Livraison" });
    typesCommandes.push({ value: "4", name: "Repas employé" });
    typesCommandes.push({ value: "5", name: "Pertes pleines" });

    const tabProduits = await getAllProduits();
    const tabTypeAnalyse = await getAllTypesAnalyse();

    let typeAnalyseForSelect = [];
    tabTypeAnalyse.data.data.map((item) => {
      typeAnalyseForSelect.push({
        value: item._id.toString(),
        name: item.nom,
        tabProduitsId: item.tabProduitsId,
        detailProduit: item.detailProduit,
      });
      return true;
    });

    const societes = await getPizzerias();
    let societesForSelect = [];
    societesForSelect.push({ value: "-1", name: "Toutes les sociétés" });
    societes.data.data.map((item) => {
      const ok = item.listAPE.filter((pizz) => {
        return pizz.codeAPE === "5610C";
      });
      if (ok.length !== 0) {
        societesForSelect.push({
          value: item._id.toString(),
          name: item.denomination,
        });
      }
      return true;
    });
    const defaultTypesCommande = [
      typesCommandes[0].value,
      typesCommandes[1].value,
      typesCommandes[2].value,
      typesCommandes[3].value,
    ];

    this.setState({
      annees,
      selectedAnnee: annees[0].value,
      societesForSelect,
      selectedSociete: societesForSelect[0].value,
      typeAnalyseForSelect,
      selectedTypeAnalyse: typeAnalyseForSelect[0].value,
      tabProduits: tabProduits.data.data,
      typesCommandes,
      selectedTypesCommande: defaultTypesCommande,
      defaultTypesCommande,
      shouldShowBox: true,
    });
  }

  handleSocieteSelect = async (societe) => {
    this.setState({
      selectedSociete: societe,
      traitFait: false,
    });
  };

  handleAnneeSelect = async (annee) => {
    this.setState({
      selectedAnnee: annee,
      traitFait: false,
    });
  };

  handleTypeCommandeSelect = async (types) => {
    this.setState({
      selectedTypesCommande: types,
      traitFait: false,
    });
  };

  handleSelectDefaultTypesCdeClick = () => {
    const { defaultTypesCommande } = this.state;
    this.setState({
      selectedTypesCommande: defaultTypesCommande,
      traitFait: false,
    });
  };

  handleTypeAnalyseSelect = async (type) => {
    this.setState({
      selectedTypeAnalyse: type,
      traitFait: false,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    await this.traitValiderClick();
  };

  traitValiderClick = async () => {
    const {
      tabProduits,
      selectedAnnee,
      societesForSelect,
      selectedSociete,
      selectedTypesCommande,
      typeAnalyseForSelect,
      selectedTypeAnalyse,
    } = this.state;
    this.setState({ shouldShowBox: false, traitFait: false });

    const param = {};
    param.annee = parseInt(selectedAnnee);
    if (parseInt(selectedSociete) === -1) {
      param.tabSocieteId = societesForSelect
        .filter((s) => s.value !== "-1")
        .map((s) => parseInt(s.value));
    } else {
      param.tabSocieteId = [parseInt(selectedSociete)];
    }

    param.tabTypeCommandeId = selectedTypesCommande.map((t) => parseInt(t));
    param.typeAnalyseId = parseInt(selectedTypeAnalyse);
    const dataListe = await getStatsProduit(param);

    const detailProduit = typeAnalyseForSelect.find(
      (a) => a.value === selectedTypeAnalyse
    ).detailProduit;
    const isMonoSociete = parseInt(selectedSociete) !== -1;

    let lesProduits = [];
    if (detailProduit) {
      lesProduits = tabProduits.filter((p) =>
        typeAnalyseForSelect
          .find((a) => a.value === selectedTypeAnalyse)
          .tabProduitsId.includes(p._id)
      );
      lesProduits = lesProduits.sort(
        (a, b) => parseInt(a._id) - parseInt(b._id)
      );
    }

    this.setState({
      dataListe: dataListe.data.data,
      detailProduit,
      isMonoSociete,
      lesProduits,
      shouldShowBox: true,
      traitFait: true,
    });
  };

  handleInfosTTCChange = () => {
    const { infosTTC } = this.state;
    this.setState({
      infosTTC: !infosTTC,
    });
  };

  handleDetailProduitChange = () => {
    const {
      detailProduit,
      tabProduits,
      typeAnalyseForSelect,
      selectedTypeAnalyse,
    } = this.state;
    let lesProduits = [];
    if (!detailProduit) {
      lesProduits = tabProduits.filter((p) =>
        typeAnalyseForSelect
          .find((a) => a.value === selectedTypeAnalyse)
          .tabProduitsId.includes(p._id)
      );
      lesProduits = lesProduits.sort(
        (a, b) => parseInt(a._id) - parseInt(b._id)
      );
    }
    this.setState({
      detailProduit: !detailProduit,
      lesProduits,
    });
  };

  handleUniquementTotalSemaineChange = () => {
    const { uniquementTotalSemaine } = this.state;
    this.setState({
      uniquementTotalSemaine: !uniquementTotalSemaine,
    });
  };

  getColSpanColonnesDates = () => {
    const { isMonoSociete, uniquementTotalSemaine } = this.state;
    if (isMonoSociete) {
      if (uniquementTotalSemaine) return 1;
      return 3;
    } else {
      if (uniquementTotalSemaine) return 2;
      return 4;
    }
  };

  render() {
    const {
      annees,
      selectedAnnee,
      societesForSelect,
      selectedSociete,
      typesCommandes,
      selectedTypesCommande,
      typeAnalyseForSelect,
      selectedTypeAnalyse,
      dataListe,
      isMonoSociete,
      lesProduits,
      infosTTC,
      detailProduit,
      uniquementTotalSemaine,
      shouldShowBox,
      traitFait,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-2">
            <div className="mb-2" style={{ fontSize: "14px" }}>
              <div>
                <label className="font-weight-bold" htmlFor="typeCommande">
                  Type de commande
                </label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "14px" }}
                  onClick={this.handleSelectDefaultTypesCdeClick}
                >
                  Types par défaut
                </button>
              </div>
              <div>
                <SelectSearch
                  options={typesCommandes}
                  value={selectedTypesCommande}
                  name="typeCommande"
                  multiple
                  onChange={this.handleTypeCommandeSelect}
                />
              </div>
            </div>
            <div
              className="mb-2 maxhauteur300"
              style={{ fontSize: "14px", marginTop: "300px" }}
            >
              <div>
                <label className="font-weight-bold" htmlFor="societe">
                  Société
                </label>
              </div>
              <div>
                <SelectSearch
                  options={societesForSelect}
                  value={selectedSociete}
                  name="societe"
                  search
                  onChange={this.handleSocieteSelect}
                />
              </div>
            </div>
            <div className="mb-2 maxhauteur300" style={{ fontSize: "14px" }}>
              <div>
                <label className="font-weight-bold" htmlFor="annee">
                  Analyse
                </label>
              </div>
              <div>
                <SelectSearch
                  options={typeAnalyseForSelect}
                  value={selectedTypeAnalyse}
                  name="annee"
                  onChange={this.handleTypeAnalyseSelect}
                />
              </div>
            </div>

            <div className="mb-4 maxhauteur100" style={{ fontSize: "14px" }}>
              <div>
                <label className="font-weight-bold" htmlFor="annee">
                  Année
                </label>
              </div>
              <div>
                <SelectSearch
                  options={annees}
                  value={selectedAnnee}
                  name="annee"
                  onChange={this.handleAnneeSelect}
                />
              </div>
            </div>

            <div className="mt-2">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderClick}
                disabled={selectedTypesCommande.length === 0}
              >
                Valider
              </button>
            </div>
          </div>
          {!shouldShowBox && (
            <div className="mt-5 mb-5">
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {shouldShowBox && (
            <div className="col-10">
              <div className="row">
                <h2>Statistiques Produit</h2>
                {traitFait && (
                  <React.Fragment>
                    <div className="row col-12" style={{ fontSize: "14px" }}>
                      <div className="form-check ml-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="infosTTC"
                          checked={infosTTC}
                          onChange={this.handleInfosTTCChange}
                        ></input>
                        <label className="form-check-label" htmlFor="infosTTC">
                          Infos sur le TTC
                        </label>
                      </div>
                      <div className="form-check ml-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="detailProduit"
                          checked={detailProduit}
                          onChange={this.handleDetailProduitChange}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="detailProduit"
                        >
                          Détail par produit
                        </label>
                      </div>
                      <div className="form-check ml-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="uniquementTotalSemaine"
                          checked={uniquementTotalSemaine}
                          onChange={this.handleUniquementTotalSemaineChange}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="uniquementTotalSemaine"
                        >
                          Uniquement un total par semaine
                        </label>
                      </div>
                    </div>
                    <div className="overflow-auto">
                      <table
                        className="table table-bordered mt-4"
                        style={{ fontSize: "12px" }}
                      >
                        <thead>
                          <tr className="mr-0 ml-0 text-primary">
                            <th>Semaine</th>
                            {!uniquementTotalSemaine && (
                              <React.Fragment>
                                <th>Date</th>
                                <th>Jour</th>
                              </React.Fragment>
                            )}
                            {!isMonoSociete && <th>Nb Société</th>}

                            {lesProduits.map((p) => (
                              <th colSpan={infosTTC ? "2" : "1"} key={p._id}>
                                {p._id} - {p.nom}
                              </th>
                            ))}
                            <th colSpan={infosTTC ? "3" : "1"}>Total </th>
                            {!isMonoSociete && (
                              <th colSpan={infosTTC ? "2" : "1"}>
                                Moy./Société
                              </th>
                            )}
                          </tr>
                          {infosTTC && (
                            <tr className="mr-0 ml-0 text-primary">
                              <th colSpan={this.getColSpanColonnesDates()}></th>

                              {lesProduits.map((p) => (
                                <React.Fragment key={p._id}>
                                  <th>Qté</th>
                                  <th>TTC</th>
                                </React.Fragment>
                              ))}

                              <th>Qté</th>
                              <th>TTC</th>
                              <th>Ratio / CA</th>

                              {!isMonoSociete && (
                                <React.Fragment>
                                  <th>Qté</th>
                                  <th>TTC</th>
                                </React.Fragment>
                              )}
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {dataListe
                            .filter(
                              (x) =>
                                (uniquementTotalSemaine && x.isTotal) ||
                                !uniquementTotalSemaine
                            )
                            .map((x, index) => (
                              <tr
                                key={index}
                                className={
                                  "mr-0 ml-0 " +
                                  (x.isTotal && !uniquementTotalSemaine
                                    ? "table-active"
                                    : "")
                                }
                              >
                                {(index + 1) % 8 === 1 &&
                                  !uniquementTotalSemaine && (
                                    <td rowSpan={8}>{x.week}</td>
                                  )}
                                {x.isTotal && (
                                  <td
                                    colSpan={
                                      !uniquementTotalSemaine ? "2" : "1"
                                    }
                                  >
                                    {!uniquementTotalSemaine && (
                                      <span>TOTAL SEMAINE {x.week}</span>
                                    )}
                                    {uniquementTotalSemaine && (
                                      <span>Semaine {x.week}</span>
                                    )}
                                  </td>
                                )}
                                {!x.isTotal && (
                                  <React.Fragment>
                                    <td>
                                      {moment(x.date, "YYYY-MM-DD").format(
                                        "DD/MM/YY"
                                      )}
                                    </td>
                                    <td className="text-capitalize">
                                      {moment(x.date, "YYYY-MM-DD").format(
                                        "dddd"
                                      )}
                                    </td>
                                  </React.Fragment>
                                )}
                                {!isMonoSociete && (
                                  <td className="text-right">
                                    <NumberFormat
                                      value={x.nbSociete}
                                      decimalScale={0}
                                      fixedDecimalScale={true}
                                      decimalSeparator=","
                                      thousandSeparator=" "
                                      displayType="text"
                                    ></NumberFormat>
                                  </td>
                                )}

                                {x.tabVentes.map((v, indVentes) => (
                                  <React.Fragment key={indVentes}>
                                    {detailProduit && (
                                      <td className="text-right text-nowrap">
                                        <NumberFormat
                                          value={v.qte}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          decimalSeparator=","
                                          thousandSeparator=" "
                                          displayType="text"
                                        />
                                      </td>
                                    )}
                                    {detailProduit && infosTTC && (
                                      <td className="text-right text-nowrap">
                                        <NumberFormat
                                          value={v.TTC}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          decimalSeparator=","
                                          thousandSeparator=" "
                                          displayType="text"
                                          suffix="€"
                                        />
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}

                                <td className="text-right text-nowrap">
                                  <NumberFormat
                                    value={x.totalVentesQte}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    decimalSeparator=","
                                    thousandSeparator=" "
                                    displayType="text"
                                  />
                                </td>
                                {infosTTC && (
                                  <React.Fragment>
                                    <td className="text-right text-nowrap">
                                      <NumberFormat
                                        value={x.totalVentesTTC}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        decimalSeparator=","
                                        thousandSeparator=" "
                                        displayType="text"
                                        suffix="€"
                                      />
                                    </td>
                                    <td className="text-right text-nowrap">
                                      <NumberFormat
                                        value={roundDecimal(
                                          (x.totalVentesTTC /
                                            x.ventesTousProduits) *
                                            100
                                        )}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        decimalSeparator=","
                                        thousandSeparator=" "
                                        displayType="text"
                                        suffix="%"
                                      />
                                    </td>
                                  </React.Fragment>
                                )}
                                {!isMonoSociete && (
                                  <React.Fragment>
                                    <td className="text-right text-nowrap">
                                      <NumberFormat
                                        value={roundDecimal(
                                          x.totalVentesQte / x.nbSociete
                                        )}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        decimalSeparator=","
                                        thousandSeparator=" "
                                        displayType="text"
                                      />
                                    </td>
                                    {infosTTC && (
                                      <td className="text-right text-nowrap">
                                        <NumberFormat
                                          value={roundDecimal(
                                            x.totalVentesTTC / x.nbSociete
                                          )}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          decimalSeparator=","
                                          thousandSeparator=" "
                                          displayType="text"
                                          suffix="€"
                                        />
                                      </td>
                                    )}
                                  </React.Fragment>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default StatsProduits;
