import React, { Component } from "react";
import moment from "moment";
import Table from "../common/table";

class ListeComptesBanque extends Component {
  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <div
          className="w-100 text-nowrap"
          style={{
            fontSize: "14px",
          }}
          id={"compte_" + c._id}
        >
          <a
            className="btn btn-link"
            role="button"
            href="#/"
            onClick={() => this.props.onSelectCompte(c)}
          >
            <i className="fas fa-edit mr-1" style={{ fontSize: "18px" }}></i>
            {c._id}
          </a>
        </div>
      ),
    },

    {
      path: "societeDenomination",
      label: "Société",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.societeDenomination}</div>
        </React.Fragment>
      ),
    },
    {
      path: "contactNom",
      label: "Contact",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>
            {c.contactId} - {c.contactNom}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "banque",
      label: "Id Banque",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.banque}</div>
        </React.Fragment>
      ),
    },
    {
      path: "numCompteBancaire",
      label: "Compte Bancaire",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.numCompteBancaire}</div>
        </React.Fragment>
      ),
    },
    {
      path: "isCompteCourant",
      label: "Compte courant",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          {c.isCompteCourant === 1 && (
            <div className={"text-center"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check "
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "docRibId",
      label: "RIB",
      tdClassName: "p-1 text-right",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          {c.docRibId &&
            c.docRibId !== 0 &&
            this.props.urlVisuDoc &&
            this.props.urlVisuDoc !== "" && (
              <a
                className="link"
                href={this.props.urlVisuDoc + "documentfile/" + c.docRibId}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer", fontSize: "14px" }}
              >
                {c.docRibId}
              </a>
            )}
          {!(
            c.docRibId &&
            c.docRibId !== 0 &&
            this.props.urlVisuDoc &&
            this.props.urlVisuDoc !== ""
          ) && <div style={{ fontSize: "14px" }}>{c.docRibId}</div>}
        </React.Fragment>
      ),
    },
    {
      path: "format",
      label: "Format d'import des relevés",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>
            <span>{c.format}</span>
            {c.dateMinFormatAAAAMMJJ && (
              <span>
                {" depuis le "}
                {moment(c.dateMinFormatAAAAMMJJ, "YYYYMMDD").format(
                  "DD/MM/YYYY"
                )}
              </span>
            )}
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "journalCode",
      label: "Code journal FEC (*)",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.journalCode}</div>
        </React.Fragment>
      ),
    },
    {
      path: "compteNum",
      label: "N° de compte FEC (*)",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (c) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{c.compteNum}</div>
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { comptes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={comptes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeComptesBanque;
