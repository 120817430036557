import React, { Component } from "react";
import { Link } from "react-router-dom";
import SelectSearch from "react-select-search";
import NumberFormat from "react-number-format";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import ListeGroupeTable from "./listeGroupeEcritures";
import { getPizzerias } from "../services/pizzeriaService";
import { getAllContactList } from "../services/contactService";
import {
  getTypesEcritures,
  getAllEmployes,
  getListGroupeEcriturePeriode,
  deleteGroupeEcriture,
  estExerciceCloture,
} from "../services/saisieService";
import { getAdminFlat } from "../services/mapsService";
import { makeId, getPrefixeUrlHlpBackMenu, minAnneeFec } from "../utils/tools";

const sessionNameFiltresSaisie = "saisieFiltresSaisie";

class SelectionTypeEcriture extends Component {
  state = {
    pageSize: 20,
    pageCount: 0,
    currentPage: 1,
    totalCount: 0,
    societesForSelect: [],
    selectedSociete: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    typeEcritures: [],
    typeEcrituresForSelect: [],
    selectedTypeEcriture: "",
    uniquementSaisieManuelle: true,
    documentId: null,
    rapprochement: "",
    commentaire: "",
    contactId: null,
    urlId: null,
    contacts: [],
    employes: [],
    adminMaps: [],
    listGroupeEcriturePeriode: [],
    ctrlsFaits: false,
    tabCtrl: [],
    sortColumn: { path: "_id", order: "asc" },
    editMode: false,
    urlVisuDoc: "",
    shouldShowBox: false,
    selectionEnCours: false,
    selectionModifiee: true,
  };

  async componentDidMount() {
    const forceValid = this.props.match.params.forceValid;
    const param = sessionStorage.getItem(sessionNameFiltresSaisie)
      ? JSON.parse(sessionStorage.getItem(sessionNameFiltresSaisie))
      : {};

    const selectedSocieteId =
      param.societeId === undefined ? "-1" : param.societeId;
    const selectedTypeEcritureId =
      param.typeEcritureId === undefined ? "-1" : param.typeEcritureId;
    const societes = await getPizzerias();
    const dataTypesEcriture = await getTypesEcritures();
    let societesForSelect = [];
    societesForSelect.push({ value: "-1", name: "Toutes les sociétés" });
    societes.data.data.map((item) => {
      societesForSelect.push({
        value: item._id.toString(),
        name: item.denomination,
      });
      return true;
    });
    let typeEcrituresForSelect = [];
    typeEcrituresForSelect.push({ value: "-1", name: "Tous les types" });
    dataTypesEcriture.data.data.map((item) => {
      typeEcrituresForSelect.push({
        value: item._id.toString(),
        name: item._id.toString() + " - " + item.nom,
        journalCode: item.journalCode,
        contactNomTable: item.contactNomTable,
        nom: item.nom,
        adminMapsId: item.adminMapsId,
      });
      return true;
    });

    const adminMaps = await getAdminFlat();
    let dateFrom = null;
    let dateTo = null;

    if (!param.dateFrom) {
      dateFrom = minAnneeFec().toString() + "-01-01";
    } else {
      dateFrom = param.dateFrom;
    }
    if (!param.dateTo) {
      dateTo = minAnneeFec().toString() + "-12-31";
    } else {
      dateTo = param.dateTo;
    }

    const dataContacts = await getAllContactList();
    const dataEmployes = await getAllEmployes();

    let urlVisuDoc = await getPrefixeUrlHlpBackMenu(49);

    this.setState({
      societesForSelect,
      selectedSociete:
        societesForSelect[selectedSocieteId === "-1" ? 0 : selectedSocieteId]
          .value,
      dateFrom,
      dateTo,
      typeEcrituresForSelect,
      selectedTypeEcriture:
        typeEcrituresForSelect[
          selectedTypeEcritureId === "-1" ? 0 : selectedTypeEcritureId
        ].value,
      contacts: dataContacts.data.data,
      employes: dataEmployes.data.data,
      typeEcritures: dataTypesEcriture.data.data,
      adminMaps: adminMaps,
      uniquementSaisieManuelle:
        param.uniquementSaisieManuelle === undefined
          ? true
          : param.uniquementSaisieManuelle,
      documentId: param.documentId === undefined ? null : param.documentId,
      rapprochement:
        param.rapprochement === undefined ? "" : param.rapprochement,
      contactId: param.contactId === undefined ? null : param.contactId,
      urlId: param.urlId === undefined ? null : param.urlId,
      commentaire: param.commentaire === undefined ? "" : param.commentaire,
      sortColumn:
        param.sortColumn === undefined
          ? { path: "_id", order: "asc" }
          : param.sortColumn,
      urlVisuDoc,
      shouldShowBox: true,
    });
    if (forceValid) {
      await this.traitValiderClick(false, 1);
    }
  }

  handlePageChange = async (page) => {
    await this.traitValiderClick(true, page);
  };

  getPagedData = () => {
    const {
      listGroupeEcriturePeriode,
      contacts,
      employes,
      societesForSelect,
      typeEcrituresForSelect,
      adminMaps,
    } = this.state;

    let filtered = listGroupeEcriturePeriode;

    filtered.map((x) => {
      x.contactNom = "";
      x.societeDenomination = "";
      x.typeEcritureLibelle = "";
      x.dateComptableJJMMAA = moment(x.dateComptable).format("DD/MM/YY");
      x.urlVisuUrl = "";

      const laSocieteGrp = societesForSelect.find((c) => {
        return c.value === x.societeId.toString();
      });
      if (laSocieteGrp) x.societeDenomination = laSocieteGrp.name;

      const leTypeEcritureGrp = typeEcrituresForSelect.find((c) => {
        return c.value === x.idTypeEcriture.toString();
      });
      if (leTypeEcritureGrp) x.typeEcritureLibelle = leTypeEcritureGrp.nom;
      if (
        leTypeEcritureGrp.adminMapsId &&
        leTypeEcritureGrp.adminMapsId !== 0
      ) {
        const menuAdmin = adminMaps.filter(
          (item) => item._id === leTypeEcritureGrp.adminMapsId
        );
        if (
          menuAdmin &&
          menuAdmin[0] &&
          menuAdmin[0].prefixeUrl &&
          menuAdmin[0].suffixeUrl
        )
          x.urlVisuUrl = menuAdmin[0].prefixeUrl + menuAdmin[0].suffixeUrl;
      }

      if (leTypeEcritureGrp && x.contactId) {
        if (leTypeEcritureGrp.contactNomTable === "ACHAT_FOURNISSEUR") {
          const leContact = contacts.find((c) => {
            return c._id === x.contactId;
          });
          if (leContact) x.contactNom = leContact.nom;
        }
        if (leTypeEcritureGrp.contactNomTable === "PIZZERIA_SOCIETE") {
          const laSociete = societesForSelect.find((c) => {
            return c.value === x.contactId.toString();
          });
          if (laSociete) x.contactNom = laSociete.name;
        }
        if (leTypeEcritureGrp.contactNomTable === "EMPLOYE") {
          const leEmp = employes.find((c) => {
            return c._id === x.contactId;
          });
          if (leEmp) x.contactNom = leEmp.prenom + " " + leEmp.nom;
        }
      }
      return true;
    });
    return {
      data: filtered,
    };
  };

  handleSocieteSelect = async (societe) => {
    this.setState({
      selectedSociete: societe,
      selectionModifiee: true,
    });
  };

  handleDateFromChange = (event) => {
    let { dateFrom } = this.state;
    const day = event.target.value;
    if (day != null) {
      dateFrom = day;
      this.setState({ dateFrom, selectionModifiee: true });
    }
  };

  handleDateToChange = (event) => {
    let { dateTo } = this.state;
    const day = event.target.value;
    if (day != null) {
      dateTo = day;
      this.setState({ dateTo, selectionModifiee: true });
    }
  };

  handleTypeEcritureSelect = async (type) => {
    this.setState({
      selectedTypeEcriture: type,
      selectionModifiee: true,
    });
  };

  handleUniquementSaisieManuelleChange = () => {
    const uniquementSaisieManuelle = this.state.uniquementSaisieManuelle;
    this.setState({
      uniquementSaisieManuelle: !uniquementSaisieManuelle,
      selectionModifiee: true,
    });
  };

  handleDocumentIdChange = (event) => {
    this.setState({ documentId: event.target.value, selectionModifiee: true });
  };
  handleRapprochementChange = (event) => {
    this.setState({
      rapprochement: event.target.value,
      selectionModifiee: true,
    });
  };
  handleContactIdChange = (event) => {
    this.setState({ contactId: event.target.value, selectionModifiee: true });
  };
  handleUrlIdChange = (event) => {
    this.setState({ urlId: event.target.value, selectionModifiee: true });
  };
  handleCommentaireChange = (event) => {
    this.setState({
      commentaire: event.target.value,
      selectionModifiee: true,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    await this.traitValiderClick(true, 1);
  };

  traitValiderClick = async (fromBtValider, page) => {
    const {
      pageSize,
      selectedSociete,
      selectedTypeEcriture,
      dateFrom,
      dateTo,
      uniquementSaisieManuelle,
      documentId,
      rapprochement,
      commentaire,
      contactId,
      urlId,
      sortColumn,
    } = this.state;

    const param = {};
    param.societeId = selectedSociete;
    param.typeEcritureId = selectedTypeEcriture;
    param.dateFrom = dateFrom;
    param.dateTo = dateTo;
    param.uniquementSaisieManuelle = uniquementSaisieManuelle;
    param.documentId = documentId;
    param.rapprochement = rapprochement;
    param.commentaire = commentaire;
    param.contactId = contactId;
    param.urlId = urlId;
    param.pageSize = pageSize;
    param.currentPage = page;
    param.sortColumn = sortColumn;
    this.setState({ selectionEnCours: true });
    const dataListe = await getListGroupeEcriturePeriode(param);

    if (fromBtValider)
      sessionStorage.setItem(sessionNameFiltresSaisie, JSON.stringify(param));

    this.setState({
      selectedTypeEcriture: selectedTypeEcriture,
      listGroupeEcriturePeriode: dataListe.data.data.dataPage,
      totalCount: dataListe.data.data.totalCount,
      pageCount: dataListe.data.data.pageCount,
      totalDebit: dataListe.data.data.totalDebit,
      totalCredit: dataListe.data.data.totalCredit,
      currentPage: page,
      selectionEnCours: false,
      selectionModifiee: false,
    });
  };

  handleSort = async (sortColumn) => {
    this.setState({ sortColumn });
    await this.traitValiderClick(true, this.state.currentPage);
  };

  handleSupprGroupeOnClick = async (groupe) => {
    const annee = groupe.dateComptableJJMMAAA.substring(6, 10);
    const retEstExerciceCloture = await estExerciceCloture(
      groupe.societeId,
      annee
    );
    if (
      retEstExerciceCloture.data.data === true &&
      groupe.idTypeEcriture !== 57
    ) {
      toast.error(
        "L'année " +
          annee +
          " est clôturée pour la société " +
          groupe.societeDenomination +
          ". Suppression impossible"
      );
    } else {
      confirmAlert({
        title: "Suppression du groupe " + groupe._id,
        message: "Confirmez-vous votre demande ?",
        buttons: [
          {
            label: "Oui",
            onClick: () => {
              this.traitDeleteGroupeEcriture(groupe);
            },
          },
          {
            label: "Non",
          },
        ],
      });
    }
  };

  traitDeleteGroupeEcriture = async (groupe) => {
    await deleteGroupeEcriture(groupe._id);
    let newArray = [...this.state.listGroupeEcriturePeriode];
    let newList = newArray.filter(
      (item) => item._id.toString() !== groupe._id.toString()
    );
    this.setState({
      listGroupeEcriturePeriode: newList,
      currentPage: 1,
    });

    await this.traitValiderClick(false, 1);
  };

  render() {
    const {
      totalCount,
      totalDebit,
      totalCredit,
      societesForSelect,
      selectedSociete,
      dateFrom,
      dateTo,
      typeEcrituresForSelect,
      selectedTypeEcriture,
      uniquementSaisieManuelle,
      documentId,
      rapprochement,
      commentaire,
      contactId,
      urlId,
      sortColumn,
      urlVisuDoc,
      shouldShowBox,
      selectionEnCours,
      selectionModifiee,
      currentPage,
      pageSize,
    } = this.state;

    let textH5 = "";
    if (selectedSociete) {
      textH5 =
        societesForSelect.find((x) => x.value === selectedSociete).name +
        " / du " +
        moment(dateFrom).format("DD/MM/YYYY") +
        " au " +
        moment(dateTo).format("DD/MM/YYYY") +
        " / " +
        typeEcrituresForSelect.find((x) => x.value === selectedTypeEcriture)
          .name;
    }
    let journalCode = "";
    if (selectedTypeEcriture) {
      const leTypeEcriture = typeEcrituresForSelect.find(
        (x) => x.value === selectedTypeEcriture
      );
      journalCode = leTypeEcriture.journalCode;
    }
    const { data } = this.getPagedData();
    return (
      <React.Fragment>
        {!shouldShowBox && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {shouldShowBox && (
          <div className="row">
            <div className="col-3">
              <div>
                <div>
                  <label className="font-weight-bold" htmlFor="societe">
                    Société
                  </label>
                </div>
                <div>
                  <SelectSearch
                    options={societesForSelect}
                    value={selectedSociete}
                    name="societe"
                    search
                    onChange={this.handleSocieteSelect}
                  />
                </div>
              </div>
              <div className="mt-2">
                <div>
                  <label className="w-100 font-weight-bold mt-2">Du / Au</label>
                  <input
                    className="mr-1"
                    type="date"
                    value={dateFrom}
                    max={dateTo}
                    onChange={(date) => this.handleDateFromChange(date)}
                  ></input>
                  <input
                    type="date"
                    value={dateTo}
                    min={dateFrom}
                    onChange={(date) => this.handleDateToChange(date)}
                  ></input>
                </div>
              </div>
              <div className="mt-2">
                <div>
                  <label className="font-weight-bold" htmlFor="type">
                    Type de groupe
                  </label>
                </div>
                <div>
                  <SelectSearch
                    options={typeEcrituresForSelect}
                    value={selectedTypeEcriture}
                    name="type"
                    search
                    onChange={this.handleTypeEcritureSelect}
                  />
                </div>
                {journalCode && journalCode.length !== 0 && (
                  <div className="mt-4" style={{ fontStyle: "italic" }}>
                    <div>Code journal connu par l'EC : {journalCode}</div>
                  </div>
                )}
                <div className="form-check mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="saisieManuelle"
                    checked={uniquementSaisieManuelle}
                    onChange={this.handleUniquementSaisieManuelleChange}
                  ></input>
                  <label className="form-check-label" htmlFor="sansEmail">
                    Uniquement les groupes saisis manuellement
                  </label>
                </div>
                <div className="mt-2">
                  <label className=" font-weight-bold">Document</label>
                  <NumberFormat
                    className="form-control"
                    id="Document"
                    autoComplete={makeId()}
                    name={"Document" + makeId()}
                    value={documentId}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    decimalSeparator=","
                    thousandSeparator=""
                    allowNegative={false}
                    onChange={(e) => {
                      this.handleDocumentIdChange(e);
                    }}
                    placeholder="Document"
                  />
                </div>
                <div className="mt-2">
                  <label className=" font-weight-bold">Rapprochement</label>
                  <input
                    type="text"
                    name={"Rapprochement" + makeId()}
                    id="Rapprochement"
                    placeholder="Rapprochement"
                    value={rapprochement}
                    className="form-control"
                    autoComplete={makeId()}
                    onChange={(e) => {
                      this.handleRapprochementChange(e);
                    }}
                  />
                </div>
                <div className="mt-2">
                  <label className=" font-weight-bold">Contact</label>
                  <NumberFormat
                    className="form-control"
                    id="Contact"
                    autoComplete={makeId()}
                    name={"Contact" + makeId()}
                    value={contactId}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    decimalSeparator=","
                    thousandSeparator=""
                    allowNegative={false}
                    onChange={(e) => {
                      this.handleContactIdChange(e);
                    }}
                    placeholder="Contact"
                  />
                </div>
                <div className="mt-2">
                  <label className=" font-weight-bold">Url</label>
                  <NumberFormat
                    className="form-control"
                    id="Url"
                    autoComplete={makeId()}
                    name={"Url" + makeId()}
                    value={urlId}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    decimalSeparator=","
                    thousandSeparator=""
                    allowNegative={false}
                    onChange={(e) => {
                      this.handleUrlIdChange(e);
                    }}
                    placeholder="Url"
                  />
                </div>
                <div className="mt-2">
                  <label className=" font-weight-bold">Commentaire</label>
                  <input
                    type="text"
                    name={"Commentaire" + makeId()}
                    id="Commentaire"
                    placeholder="Commentaire"
                    value={commentaire}
                    className="form-control"
                    autoComplete={makeId()}
                    onChange={(e) => {
                      this.handleCommentaireChange(e);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <button
                    className={
                      "btn " +
                      (selectionModifiee
                        ? "btn-primary"
                        : "btn-outline-secondary")
                    }
                    onClick={this.handleValiderClick}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
            <div className="col-9">
              <h2 className="mb-4">Saisie d'écritures</h2>
              {selectionModifiee && (
                <div className="mt-3 mb-3 alert alert-warning">
                  Validez les filtres pour actualiser la liste
                </div>
              )}
              {!selectionEnCours && (
                <React.Fragment>
                  <h5 className="mb-2">{textH5}</h5>
                  <Link
                    role="button"
                    to={"/saisiegroupe/create/"}
                    className="btn btn-primary text-white"
                    style={{ marginBottom: 20 }}
                  >
                    Nouveau groupe
                  </Link>
                  <div
                    className={
                      "mb-2 " +
                      (totalDebit !== totalCredit
                        ? "text-danger font-weight-bold"
                        : "")
                    }
                  >
                    {"Nb groupe : "}
                    <span>
                      <NumberFormat
                        value={totalCount}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        decimalSeparator=","
                        thousandSeparator=" "
                        displayType="text"
                      />
                    </span>
                    {" / Total débit : "}
                    <span>
                      {" "}
                      <NumberFormat
                        value={totalDebit}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalSeparator=","
                        thousandSeparator=" "
                        displayType="text"
                      />
                    </span>
                    {" / Total crédit : "}
                    <NumberFormat
                      value={totalCredit}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalSeparator=","
                      thousandSeparator=" "
                      displayType="text"
                    />
                  </div>
                  <div className="mb-2">
                    {totalCount !== 0 && (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={10}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="Précédent"
                        nextPageText="Suivant"
                        firstPageText="Premier"
                        lastPageText="Dernier"
                      />
                    )}
                  </div>

                  <div className="row">
                    <ListeGroupeTable
                      groupes={data}
                      urlVisuDoc={urlVisuDoc}
                      sortColumn={sortColumn}
                      onSort={this.handleSort}
                      handleSupprGroupeOnClick={this.handleSupprGroupeOnClick}
                    />
                  </div>
                </React.Fragment>
              )}
              {selectionEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SelectionTypeEcriture;
