import React, { Component } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import SelectSearch from "react-select-search";
import ChartBar from "../chart/chartBar";

import { getPizzerias } from "../../services/pizzeriaService";
import { PostesList } from "../../services/parametresService";
import { getStatsAnciennete } from "../../services/statsService";
import Table from "../common/table";

class StatsAnciennete extends Component {
  state = {
    annees: [],
    selectedAnnee: null,
    societesForSelect: [],
    selectedSocietes: [],
    postesForSelect: [],
    selectedPostes: [],
    sortColumn: { path: "mois", order: "asc" },
    stats: [],
    shouldShowBox: false,
  };
  columns = [
    {
      path: "periode",
      label: "Mois",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span>
          <span style={{ fontSize: "12px" }}>
            {moment(x.periode, "YYYYMM").format("MMMM YYYY")}
          </span>
        </span>
      ),
    },

    {
      path: "nbMoisAncienneteMoyen",
      label: "Nb Mois moyen d'ancienneté",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={x.nbMoisAncienneteMoyen}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
          />
        </div>
      ),
    },
    {
      path: "tabEmploye",
      label: "Nb Employé",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={x.tabEmploye.length}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
          />
        </div>
      ),
    },
    {
      path: "CA",
      label: "CA",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={x.CA}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },
  ];

  async componentDidMount() {
    const annees = [];
    const annee = new Date().getFullYear();
    for (let index = annee; index >= 2016; index--) {
      annees.push({ value: index.toString(), name: index.toString() });
    }

    const societes = await getPizzerias();
    let societesForSelect = [];
    let selectedSocietes = [];
    societes.data.data.map((item) => {
      const ok = item.listAPE.filter((pizz) => {
        return pizz.codeAPE === "5610C";
      });
      societesForSelect.push({
        value: item._id.toString(),
        name: item.denomination,
      });
      if (ok.length !== 0) selectedSocietes.push(item._id.toString());
      return true;
    });

    const postes = await PostesList();
    let postesForSelect = [];
    let selectedPostes = [];
    postes.data.data.forEach((p) => {
      postesForSelect.push({
        value: p._id.toString(),
        name: p.libelle,
      });
      if (p.codeAPE === "5610C") selectedPostes.push(p._id.toString());
    });

    this.setState({
      annees,
      selectedAnnee: annees[0].value,
      societesForSelect,
      selectedSocietes,
      postesForSelect,
      selectedPostes,
      shouldShowBox: true,
    });
  }

  genereDataToShow = ({ dataIn, type }) => {
    const dataToShow = [];
    dataIn.map((x) => {
      let axeX = "";
      let labelAxeX = "";
      let y = 0.0;
      let montantLigne = 0.0;

      axeX = moment(x.periode, "YYYYMM").format("MM");
      switch (type) {
        case "anciennete":
          labelAxeX = Math.round(x.nbMoisAncienneteMoyen);
          y =
            x.nbMoisAncienneteMoyen !== 0
              ? Math.round(Number(x.nbMoisAncienneteMoyen))
              : null;
          montantLigne = Math.round(Number(x.nbMoisAncienneteMoyen));
          break;
        case "nbEmploye":
          labelAxeX = Math.round(x.tabEmploye.length);
          y =
            x.tabEmploye.length !== 0
              ? Math.round(Number(x.tabEmploye.length))
              : null;
          montantLigne = Math.round(Number(x.tabEmploye.length));
          break;
        case "CA":
          labelAxeX = Math.round(x.CA / 1000);
          y = x.CA !== 0 ? Math.round(Number(x.CA / 1000)) : null;
          montantLigne = Math.round(Number(x.CA) / 1000);
          break;

        default:
          break;
      }

      dataToShow.push({
        x: axeX,
        y,
        label: labelAxeX,
        montantLigne,
      });
      return true;
    });

    return dataToShow;
  };

  handleAnneeSelect = async (annee) => {
    this.setState({
      selectedAnnee: annee,
    });
  };

  handleSelectAllPosteClick = (event) => {
    event.preventDefault();
    const postesForSelect = this.state.postesForSelect;
    let selectedPostes = [];
    postesForSelect.map((item) => {
      selectedPostes.push(item.value);
      return true;
    });
    this.setState({
      selectedPostes,
    });
  };

  handleSelectAllClick = (event) => {
    event.preventDefault();
    const societesForSelect = this.state.societesForSelect;
    let selectedSocietes = [];
    societesForSelect.map((item) => {
      selectedSocietes.push(item.value);
      return true;
    });
    this.setState({
      selectedSocietes,
    });
  };

  handleUnSelectAllPostesClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedPostes: [],
    });
  };

  handleUnSelectAllClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedSocietes: [],
    });
  };

  handlePosteSelect = (postes) => {
    this.setState({
      selectedPostes: postes,
    });
  };

  handleSocieteSelect = (societes) => {
    this.setState({
      selectedSocietes: societes,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    await this.traitValiderClick();
  };

  traitValiderClick = async () => {
    const { selectedAnnee, selectedSocietes, selectedPostes } = this.state;
    this.setState({ shouldShowBox: false });

    const param = {};
    param.annee = selectedAnnee;

    param.societeIdTab = [];
    selectedSocietes.forEach((x) => {
      param.societeIdTab.push(parseInt(x));
    });
    param.posteIdTab = [];
    selectedPostes.forEach((x) => {
      param.posteIdTab.push(parseInt(x));
    });

    const dataListe = await getStatsAnciennete(param);
    const result = dataListe.data.data.tabMois.filter((m) => !m.isLastMonth);

    let dataToShow = [];
    dataToShow = this.genereDataToShow({
      dataIn: result,
      type: "anciennete",
    });

    let dataToShow_1 = this.genereDataToShow({
      dataIn: result,
      type: "nbEmploye",
    });

    const paramCA = {};
    paramCA.annee = selectedAnnee;
    paramCA.groupBy = "month";
    paramCA.jour = -1;
    paramCA.mois = -1;
    paramCA.rush = -1;
    paramCA.tva = -1;
    paramCA.withN_1 = false;
    paramCA.withN_2 = false;
    paramCA.syntheseMois = false;
    paramCA.syntheseTVA = false;
    paramCA.syntheseRush = false;
    paramCA.syntheseJour = false;
    paramCA.avecSynthese = false;
    paramCA.societeIdTab = [];
    if (selectedSocietes.length !== 0) {
      selectedSocietes.map((x) => {
        paramCA.societeIdTab.push(parseInt(x));
        return true;
      });
    }
    paramCA.tabSynthese = [];
    paramCA.detailParSociete = false;

    const dataToShow_2 = this.genereDataToShow({
      dataIn: result,
      type: "CA",
    });

    this.setState({
      stats: result,
      dataToShow,
      dataToShow_1,
      dataToShow_2,
      shouldShowBox: true,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const {
      annees,
      selectedAnnee,
      societesForSelect,
      selectedSocietes,
      postesForSelect,
      selectedPostes,
      shouldShowBox,
      sortColumn,
      stats,
      dataToShow,
      dataToShow_1,
      dataToShow_2,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-3" style={{ fontSize: "14px" }}>
            <div className="mt-2">
              <label className="w-100 font-weight-bold mt-2" htmlFor="annee">
                Année
              </label>
              <div className="maxHauteur120">
                <SelectSearch
                  options={annees}
                  value={selectedAnnee}
                  name="annee"
                  onChange={this.handleAnneeSelect}
                />
              </div>
            </div>
            <div style={{ marginTop: "140px" }}>
              <div>
                <label className="w-100 font-weight-bold" htmlFor="postes">
                  Postes sélectionnés
                </label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleSelectAllPosteClick}
                >
                  Tout sélectionner
                </button>
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleUnSelectAllPostesClick}
                >
                  Tout désélectionner
                </button>
              </div>

              <div className="maxHauteur300">
                <SelectSearch
                  options={postesForSelect}
                  value={selectedPostes}
                  name="postes"
                  multiple
                  onChange={this.handlePosteSelect}
                />
              </div>
            </div>
            <div style={{ marginTop: "470px" }}>
              <div>
                <label className="w-100 font-weight-bold" htmlFor="societes">
                  Sociétés sélectionnées
                </label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleSelectAllClick}
                >
                  Tout sélectionner
                </button>
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleUnSelectAllClick}
                >
                  Tout désélectionner
                </button>
              </div>

              <div className="maxHauteur300">
                <SelectSearch
                  options={societesForSelect}
                  value={selectedSocietes}
                  name="societes"
                  multiple
                  onChange={this.handleSocieteSelect}
                />
              </div>
            </div>
            <div style={{ marginTop: "500px" }}>
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={this.handleValiderClick}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
          {!shouldShowBox && (
            <div className="mt-5 mb-5">
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {shouldShowBox && (
            <div className="col-9">
              <div className="row mt-4">
                <div className="col-12 col-xl-9">
                  <div>
                    <h5 className="font-weight-bold mb-2">
                      Statistiques sur l'ancienneté des employés
                    </h5>
                  </div>
                  {stats.length !== 0 && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-12">
                          <ChartBar
                            libelleLigneN={"Nb mois moyen"}
                            libelleLigneN_1={"Nb employé"}
                            libelleLigneN_2={"CA en k€"}
                            anneeN={selectedAnnee}
                            dataToShow={dataToShow}
                            dataToShow_1={dataToShow_1}
                            dataToShow_2={dataToShow_2}
                            tickCount={dataToShow.length}
                            agregatPeriode={"month"}
                            fillColor={"red"}
                            tabMois={[]}
                            fileExport={"export"}
                          ></ChartBar>
                        </div>
                        <div className="col-12 mt-4">
                          <Table
                            columns={this.columns}
                            data={stats}
                            sortColumn={sortColumn}
                            onSort={this.handleSort}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default StatsAnciennete;
