import React, { Component } from "react";
import NumberFormat from "react-number-format";
//import MyAutosuggest from "./common/myAutoSuggest";
import SelectSearch from "react-select-search";

class LigneGroupeEcriture extends Component {
  state = {
    compteId: "-1",
  };
  componentDidMount() {
    this.setState({ compteId: this.props.ligne.leCompte._id.toString() });
  }
  InterneCompteSelect = async (compte) => {
    this.props.handleChangeCompte(this.props.ligne._id, compte);
    this.setState({ compteId: compte });
  };

  render() {
    const {
      ligne,
      index,
      comptesForSelect,
      isLastLigne,
      groupeModifiable,
    } = this.props;
    const compteId = this.state.compteId;
    return (
      <React.Fragment>
        <td className="col-4">
          <div className="input-group">
            {groupeModifiable && (
              <SelectSearch
                id={"Cpt_" + index}
                options={comptesForSelect}
                value={compteId}
                name="contact"
                search
                onChange={this.InterneCompteSelect}
                disabled={!groupeModifiable}
              />
            )}
            {!groupeModifiable && <div>{compteId}</div>}
            {/* <MyAutosuggest
              id={"Cpt_" + index}
              placeholder={"Compte"}
              onChange={this.props.handleChangeCompte}
              onBlur={this.props.handleBlurCompte}
              myValue={this.props.ligne.leCompte.compteNumero}
              comptesFull={comptesFull}
            /> */}
          </div>
        </td>
        <td className="col-2">
          {ligne.leCompte._id && ligne.leCompte._id !== "-1" && (
            <span>
              {ligne.leCompte.compteNumero} {ligne.leCompte.compteLibelle}
            </span>
          )}
          {ligne.leCompte._id && ligne.leCompte._id === "-1" && (
            <span className="text-danger">Compte obligatoire</span>
          )}
        </td>
        <td className="col-2 text-right">
          <NumberFormat
            className="col-12 form-control text-right"
            id={"Debit_" + index}
            value={ligne.debit}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            allowNegative={false}
            onChange={(e, value) => {
              this.props.handleDebitChange(e, value);
            }}
            disabled={Number(ligne.credit) !== 0 || !groupeModifiable}
          />
        </td>
        <td className="col-2 text-right">
          <NumberFormat
            className="col-12 form-control text-right"
            id={"Credit_" + index}
            value={ligne.credit}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            allowNegative={false}
            onChange={(e, value) => {
              this.props.handleCreditChange(e, value);
            }}
            disabled={Number(ligne.debit) !== 0 || !groupeModifiable}
          />
        </td>
        <td className="col-2">
          {isLastLigne && groupeModifiable && (
            <button
              className="btn btn-outline-secondary mb-1"
              onClick={() => this.props.handleClickNouvelleLigne()}
            >
              <i
                className="fa fa-plus"
                style={{ fontSize: "20px" }}
                aria-hidden="true"
              ></i>
            </button>
          )}
          {groupeModifiable && (
            <div className="float-right">
              <button
                className="btn btn-outline-secondary mb-1"
                onClick={() => this.props.handleSupprLigne(ligne)}
              >
                <i
                  className="fa fa-trash"
                  style={{ fontSize: "20px" }}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          )}
        </td>
      </React.Fragment>
    );
  }
}

export default LigneGroupeEcriture;
