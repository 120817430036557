import http from "./httpService";
import { urlApiGeneral } from "../config.js";

export async function getAllProduits() {
  return await http.get(urlApiGeneral + "/produitsvente/getAllProduits");
}

export async function getAllTypesAnalyse() {
  return await http.get(urlApiGeneral + "/produitsvente/getAllTypesAnalyse");
}
