import React, { Component } from "react";
import { toast } from "react-toastify";
import SelectSearch from "react-select-search";
import ListeCtrlCompta from "./listeCtrlCompta";
import { getPizzerias } from "../services/pizzeriaService";
import { genereFEC } from "../services/fecService";
import { getControleList } from "../services/ctrlService";
import auth from "../services/authService";
import { getUrlHlpBackMenu, minAnneeFec } from "../utils/tools";

class GenereFec extends Component {
  state = {
    controleEnCours: false,
    traitEnCours: false,
    societesForSelect: [],
    selectedSocietes: [],
    urlVisuGroupe: "",
    ctrlsFaits: false,
    tabCtrl: [],
    hasErreurs: false,
  };
  async componentDidMount() {
    const urlVisuGroupe = await getUrlHlpBackMenu(58);
    const societes = await getPizzerias();
    let societesForSelect = [];

    societes.data.data
      .filter(
        (item) =>
          parseInt(item.dateFinExoAAAAMMJJ_1.substring(0, 4)) <= minAnneeFec()
      )
      .forEach((item) => {
        societesForSelect.push({
          value: item._id.toString(),
          name: item.denomination,
        });
      });
    this.setState({ societesForSelect, selectedSocietes: [], urlVisuGroupe });
  }

  handleSelectAllClick = (event) => {
    event.preventDefault();
    const societesForSelect = this.state.societesForSelect;
    let selectedSocietes = [];
    societesForSelect.map((item) => {
      selectedSocietes.push(item.value);
      return true;
    });
    this.setState({
      selectedSocietes,
      ctrlsFaits: false,
    });
  };

  handleUnSelectAllClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedSocietes: [],
      ctrlsFaits: false,
    });
  };

  handleSocieteSelect = (societes) => {
    this.setState({
      selectedSocietes: societes,
      ctrlsFaits: false,
    });
  };

  handleSuivantClick = async (event) => {
    event.preventDefault();
    const {
      selectedSocietes,
      societesForSelect,
      traitEnCours,
      controleEnCours,
    } = this.state;
    if (!traitEnCours && !controleEnCours) {
      this.setState({ controleEnCours: true });
      const param = {};
      param.limitPreviousMonth = true;
      if (selectedSocietes.length === societesForSelect.length) {
        param.societeIdTab = [];
        param.allSocietes = true;
      } else {
        param.societeIdTab = [];
        selectedSocietes.map((x) => {
          param.societeIdTab.push(parseInt(x));
          return true;
        });
        param.allSocietes = false;
      }
      const ret = await getControleList(param);
      let hasErreurs = false;
      ret.data.data.map((item) => {
        if (item.result && item.result.length !== 0) hasErreurs = true;
        return true;
      });
      this.setState({
        controleEnCours: false,
        tabCtrl: ret.data.data,
        hasErreurs: hasErreurs,
        ctrlsFaits: true,
      });
    }
  };

  handleTerminerClick = async (event) => {
    event.preventDefault();
    const {
      selectedSocietes,
      societesForSelect,
      traitEnCours,
      controleEnCours,
    } = this.state;
    if (!traitEnCours && !controleEnCours) {
      this.setState({ traitEnCours: true });

      const param = {};
      param.userId = auth.getCurrentUser()._id;
      if (selectedSocietes.length === societesForSelect.length) {
        param.societeIdTab = [];
        param.allSocietes = true;
      } else {
        param.societeIdTab = [];
        selectedSocietes.map((x) => {
          param.societeIdTab.push(parseInt(x));
          return true;
        });
        param.allSocietes = false;
      }
      const result = await genereFEC(param);
      const societesATraiter = result.data.data.length;
      const societesTraitees = result.data.data.filter(
        (x) => x.status === "fulfilled"
      ).length;

      if (societesATraiter !== societesTraitees) {
        toast.error(
          "Erreur à l'exécution du traitement (" +
            societesTraitees +
            " élements traités / " +
            societesATraiter +
            " élements à traiter)"
        );
      } else {
        toast.success("Traitement de " + societesTraitees + " sociétés/années");
      }
      this.props.history.push("/exportfec");
    }
  };

  handleBackClick = (event) => {
    event.preventDefault();
    this.props.history.push("/exportfec");
  };

  render() {
    const {
      societesForSelect,
      selectedSocietes,
      urlVisuGroupe,
      tabCtrl,
      ctrlsFaits,
      traitEnCours,
      controleEnCours,
      //hasErreurs,
    } = this.state;
    return (
      <React.Fragment>
        <h2>Génération des FEC</h2>
        <div className="row">
          <div className="col-3">
            <div>
              <div>
                <label htmlFor="societes">Sociétés sélectionnées :</label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleSelectAllClick}
                >
                  Tout sélectionner
                </button>
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleUnSelectAllClick}
                >
                  Tout désélectionner
                </button>
              </div>
              <div>
                <SelectSearch
                  options={societesForSelect}
                  value={this.state.selectedSocietes}
                  name="societes"
                  multiple
                  onChange={this.handleSocieteSelect}
                />
              </div>
            </div>

            <div style={{ marginTop: "600px", marginBottom: "20px" }}>
              <button
                className="btn btn-outline-secondary "
                onClick={this.handleBackClick}
              >
                Retour
              </button>
              <button
                className="btn btn-outline-secondary ml-2"
                onClick={this.handleSuivantClick}
                disabled={
                  selectedSocietes.length === 0 ||
                  controleEnCours ||
                  traitEnCours
                }
              >
                <i className="fa fa-arrow-right mr-2"></i>
                Contrôler
              </button>
              {controleEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-9">
            {ctrlsFaits && !controleEnCours && (
              <React.Fragment>
                <h5 style={{ marginBottom: "20px" }}>
                  Contrôle des données pour les sociétés sélectionnées
                </h5>
                <ListeCtrlCompta
                  tabCtrl={tabCtrl}
                  urlVisuGroupe={urlVisuGroupe}
                ></ListeCtrlCompta>
                <div>
                  <button
                    className="btn btn-primary ml-2"
                    onClick={this.handleTerminerClick}
                    disabled={
                      selectedSocietes.length === 0 ||
                      controleEnCours ||
                      traitEnCours
                      //  ||
                      // hasErreurs
                    }
                  >
                    <i className="fa fa-arrow-right mr-2"></i>
                    Générer
                  </button>
                  {traitEnCours && (
                    <div className="mt-5 mb-5">
                      <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GenereFec;
