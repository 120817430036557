import React, { Component } from "react";
import NumberFormat from "react-number-format";
import Table from "../common/table";

class ListeTVA extends Component {
  columns = [
    {
      key: "taux",
      label: "Taux",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (taux) => (
        <React.Fragment>
          <div>
            <NumberFormat
              value={taux.taux}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalSeparator=","
              thousandSeparator=" "
              displayType="text"
              suffix=" %"
            />
          </div>
        </React.Fragment>
      ),
    },
    {
      key: "CAHT",
      label: "Base",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (taux) => (
        <div className="text-right">
          <NumberFormat
            value={taux.CAHT}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
          />
        </div>
      ),
    },
    {
      key: "pourcent",
      label: "Pourcentage",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (taux) => (
        <div className="text-right">
          <NumberFormat
            value={taux.pourcent}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
          />
        </div>
      ),
    },
  ];
  render() {
    const { CAMois, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={CAMois}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeTVA;
