import React, { Component } from "react";

class ListeCtrlCompta extends Component {
  handleGenereUrlGroupe = (_id) => {
    const { urlVisuGroupe } = this.props;
    return urlVisuGroupe + "&id_grp_e=" + _id.toString();
  };

  render() {
    const { tabCtrl } = this.props;
    return (
      <ul>
        {tabCtrl.map((ctrl) => (
          <li style={{ marginBottom: "10px" }} key={ctrl._id}>
            {ctrl.nom} :{" "}
            {ctrl.result.length === 0 && (
              <span className="text-success font-weight-bold">
                Aucune erreur
              </span>
            )}
            {ctrl.result.map((r, index) => (
              <React.Fragment key={ctrl._id + r._id}>
                {ctrl.typeRetour === "LISTE_ID_GROUPE" && (
                  <a
                    className="text-danger font-weight-bold"
                    href={this.handleGenereUrlGroupe(r._id)}
                    target="_blank"
                    rel="noreferrer"
                    key={ctrl._id + r._id}
                  >
                    <u>{index !== 0 ? ", " + r._id : r._id}</u>
                  </a>
                )}
                {/* LISTE_ID_RAPPROCHEMENT */}
                {ctrl.typeRetour === "LISTE_ID_RAPPROCHEMENT" && (
                  <a
                    className="text-danger font-weight-bold"
                    href={`/detailrapprochement/${r._id}`}
                    target="_blank"
                    rel="noreferrer"
                    key={ctrl._id + r._id}
                  >
                    <u>{index !== 0 ? ", " + r._id : r._id}</u>
                  </a>
                )}
                {ctrl.typeRetour !== "LISTE_ID_GROUPE" &&
                  ctrl.typeRetour !== "LISTE_ID_RAPPROCHEMENT" && (
                    <span
                      className="text-danger font-weight-bold"
                      key={ctrl._id + r._id}
                    >
                      {index !== 0 ? ", " + r._id : r._id}
                    </span>
                  )}
              </React.Fragment>
            ))}
          </li>
        ))}
      </ul>
    );
  }
}

export default ListeCtrlCompta;
