import React, { Component } from "react";

import { getExportFEC } from "../services/fecService";

class DetailErreursFec extends Component {
  state = {
    exp: {},
    tabErreurs: [],
  };
  async componentDidMount() {
    const exportId = this.props.match.params.id;
    const dataExport = await getExportFEC(exportId);
    let tabErreurs = JSON.parse(dataExport.data.data.erreurs);
    this.setState({ exp: dataExport.data.data, tabErreurs: tabErreurs });
  }

  handleGenereUrlGroupe = (data) => {
    if (data.groupeDateComptabilite) {
      return (
        "/saisiegroupe/edit/" +
        data.groupeId +
        "/detailerreursfec/" +
        this.props.match.params.id
      );
    }
  };

  handleBackClick = (event) => {
    event.preventDefault();
    this.props.history.push("/exportfec");
  };

  render() {
    const { tabErreurs } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <h2>Erreurs détectées pendant l'export</h2>
          <table
            className="table table-bordered table-striped mt-4"
            style={{ fontSize: "12px" }}
          >
            <tbody>
              {tabErreurs.map((item, index) => (
                <tr className="row mr-0 ml-0" key={index}>
                  <td className="col-2">
                    <div>{item.libType}</div>
                  </td>
                  <td className="col-8">
                    {/* Rapprochement non trouvé dans banque_comptabilite */}
                    {item.numType === 1 && (
                      <div>
                        Groupe : {item.groupeId}, groupeDateComptabilite :{" "}
                        {item.groupeDateComptabilite}, typeEcritureId :{" "}
                        {item.typeEcritureId}, rapprochementId :{" "}
                        {item.rapprochementId}
                      </div>
                    )}
                    {/* Compte bancaire non trouvé dans banque_compte */}
                    {item.numType === 2 && (
                      <div>
                        Groupe : {item.groupeId}, groupeDateComptabilite :{" "}
                        {item.groupeDateComptabilite}, typeEcritureId :{" "}
                        {item.typeEcritureId}, rapprochementId :{" "}
                        {item.rapprochementId}, numCompteBancaire{" "}
                        {item.numCompteBancaire}
                      </div>
                    )}
                    {/* Ligne sans compte */}
                    {item.numType === 3 && (
                      <div>
                        Groupe : {item.groupeId}, Ligne : {item.ligneId},
                        compteId: {item.compteId}, compteNumero :{" "}
                        {item.compteNumero}, groupeDateComptabilite :{" "}
                        {item.groupeDateComptabilite}, typeEcritureId :{" "}
                        {item.typeEcritureId}
                      </div>
                    )}
                  </td>
                  <td className="col-1">
                    {(item.numType === 1 ||
                      item.numType === 2 ||
                      item.numType === 3) && (
                      <a href={this.handleGenereUrlGroupe(item)}>
                        Voir le groupe
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="btn btn-outline-secondary "
            onClick={this.handleBackClick}
          >
            <i className="fa fa-arrow-left mr-1"></i>
            Retour
          </button>
        </div>
      </div>
    );
  }
}

export default DetailErreursFec;
