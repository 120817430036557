import { Component } from "react";
import auth from "../services/authService";
import { urlApiComptabilite } from "../config";

class Logout extends Component {
  componentDidMount() {
    // Suppression des SessionStorage et localStorage
    const pathRetour = this.props.match.params.call;
    sessionStorage.clear();
    localStorage.clear();
    auth.logout();
    window.location =
      urlApiComptabilite +
      "/auth/disconnect" +
      (pathRetour !== undefined ? "?path=" + pathRetour : "");
  }

  render() {
    return null;
  }
}

export default Logout;
