import React, { Component } from "react";

import moment from "moment";
import _ from "lodash";

import {
  getPizzerias,
  getAllCodeAPE,
  listenfantlieu,
  addSociete,
  updateSociete,
} from "../../services/pizzeriaService";
import { getAllContactList } from "../../services/contactService";

import { replaceAll, generateTabJoursSemaine } from "../../utils/tools";

import ListeSociete from "./listeSociete";
import SocieteForm from "./societeForm";
import BarreMenu from "../common/barreMenu";

import {
  menuSocietes,
  typeMenuParametresSocietes,
} from "../../utils/menuParametres";

class Societes extends Component {
  state = {
    societes: [],
    sortColumn: { path: "_id", order: "asc" },
    newSociete: false,
    dataSociete: {
      nom: "",
      formeJuridique: "",
      denomination: "",
      nomCommercial: "",
      siret: "",
      rcs: "",
      numeroTVA: "",
      codeAPE: "",
      tiersId: "0",
      avecCouleur: false,
      couleur: "",
      backgroundColor: "#ffffff",
      capital: 0,
      nbpart: 0,
      dateCreation: "",
      dateCreationAAAAMMJJ: "",
      dateOuvertureClient: "",
      dateOuvertureClientAAAAMMJJ: "",
      dateFinExo: "",
      dateFinExoAAAAMMJJ_1: "",
      adresse: "",
      cp: "",
      ville: "",
      telephone: "",
      lieuId: 0,
      listAPE: [],
      listAPENonRepris: [],
      jourInventaireId: 0,
      itemJourSemaine: {},
    },
    tabAPEForSelect: [],
    tabContactForSelect: [],
    tabLieuForSelect: [],
    tabJourSemaine: [],
    selectedSocieteId: -1,
  };

  columns = [
    {
      path: "codeAPE",
      label: "Code APE",
      tdClassName: "p-2",
      className: "",
      content: (ape) => <span style={{ fontSize: "12px" }}>{ape.codeAPE}</span>,
    },
    {
      path: "designationAPE",
      label: "Désignation",
      tdClassName: "p-2",
      className: "",
      content: (ape) => (
        <span style={{ fontSize: "12px" }}>{ape.designationAPE}</span>
      ),
    },
    {
      path: "nomInterneAPE",
      label: "Nom interne",
      tdClassName: "p-2",
      className: "",
      content: (ape) => (
        <span style={{ fontSize: "12px" }}>{ape.nomInterneAPE}</span>
      ),
    },
    {
      key: "suppr",
      tdClassName: "text-center m-0",
      className: "",
      content: (ape) => (
        <button
          className="btn btn-outline-secondary p-0"
          onClick={() => this.handleSupprAPEOnClick(ape)}
        >
          <i
            className="far fa-trash-alt p-1"
            style={{ fontSize: "20px" }}
            aria-hidden="true"
          ></i>
        </button>
      ),
    },
  ];

  async componentDidMount() {
    let tabJourSemaine = [];
    tabJourSemaine = generateTabJoursSemaine();
    tabJourSemaine.unshift({
      _id: 0,
      label: "Aucun",
      shortLabel: "A",
      middleLabel: "Aucun",
    });

    const tabAPEForSelect = [];
    tabAPEForSelect.push({ value: "", name: "" });
    const dataAPE = await getAllCodeAPE();
    dataAPE.data.data.forEach((x) => {
      tabAPEForSelect.push({
        value: x.code,
        name: x.code + " " + x.designation,
        designation: x.designation,
        nomInterne: x.nomInterne,
      });
    });
    const societes = await this.chargeSociete(tabJourSemaine, tabAPEForSelect);

    let tabLieuForSelect = [];
    const dataLieux = await listenfantlieu();
    dataLieux.data.data.forEach((x) => {
      tabLieuForSelect.push({
        value: x._id.toString(),
        name: x.designation,
      });
    });
    tabLieuForSelect = _.orderBy(tabLieuForSelect, ["name"], ["asc"]);
    tabLieuForSelect.unshift({ value: "0", name: "sans lieu" });

    const tabContactForSelect = [];
    tabContactForSelect.push({ value: "0", name: "" });
    const dataContact = await getAllContactList();
    dataContact.data.data.forEach((x) => {
      tabContactForSelect.push({
        value: x._id.toString(),
        name: x._id.toString() + " " + x.nom,
      });
    });

    this.setState({
      societes,
      tabAPEForSelect,
      tabContactForSelect,
      tabLieuForSelect,
      tabJourSemaine,
    });
  }

  chargeSociete = async (tabJourSemaine, tabAPEForSelect) => {
    const dataSocietes = await getPizzerias();
    const societes = [];
    dataSocietes.data.data.forEach((s) => {
      if (s.couleur.length === 0) {
        s.backgroundColor = "";
        s.avecCouleur = false;
      } else {
        const tabCouleur = s.couleur.split(",");
        s.backgroundColor = `rgba(${tabCouleur[0]},${tabCouleur[1]},${tabCouleur[2]},0.3)`;
        s.avecCouleur = true;
      }
      s.dateCreation = moment(s.dateCreationAAAAMMJJ, "YYYYMMDD").format(
        "YYYY-MM-DD"
      );
      s.dateOuvertureClient = moment(
        s.dateOuvertureClientAAAAMMJJ,
        "YYYYMMDD"
      ).format("YYYY-MM-DD");
      s.dateFinExo = moment(s.dateFinExoAAAAMMJJ_1, "YYYYMMDD").format(
        "YYYY-MM-DD"
      );
      s.itemJourSemaine = tabJourSemaine.find(
        (j) => j._id === s.jourInventaireId
      );
      s.listAPENonRepris = [];
      tabAPEForSelect
        .filter((a) => a.value.length !== 0)
        .forEach((a) => {
          const exist = s.listAPE.find((x) => x.codeAPE === a.value);
          if (!exist)
            s.listAPENonRepris.push({
              _id: s._id.toString() + "_" + a.value,
              codeAPE: a.value,
              designationAPE: a.designation,
              nomInterneAPE: a.nomInterne,
            });
        });

      societes.push(s);
    });
    return societes;
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleNewSociete = () => {
    const { newSociete, dataSociete, tabAPEForSelect } = this.state;
    dataSociete.nom = "";
    dataSociete.formeJuridique = "";
    dataSociete.denomination = "";
    dataSociete.nomCommercial = "";
    dataSociete.siret = "";
    dataSociete.rcs = "";
    dataSociete.numeroTVA = "";
    dataSociete.codeAPE = "";
    dataSociete.tiersId = "0";
    dataSociete.backgroundColor = "#ffffff";
    dataSociete.couleur = "";
    dataSociete.avecCouleur = false;
    dataSociete.capital = 0;
    dataSociete.nbPart = 0;
    dataSociete.dateCreation = "";
    dataSociete.dateCreationAAAAMMJJ = "";
    dataSociete.dateOuvertureClient = "";
    dataSociete.dateOuvertureClientAAAAMMJJ = "";
    dataSociete.dateFinExo = "";
    dataSociete.dateFinExoAAAAMMJJ_1 = "";
    dataSociete.adresse = "";
    dataSociete.cp = "";
    dataSociete.ville = "";
    dataSociete.telephone = "";
    dataSociete.listAPE = [];
    dataSociete.listAPENonRepris = [];
    tabAPEForSelect
      .filter((a) => a.value.length !== 0)
      .forEach((a) => {
        dataSociete.listAPENonRepris.push({
          _id: "0_" + a.value,
          codeAPE: a.value,
          designationAPE: a.designation,
          nomInterneAPE: a.nomInterne,
        });
      });
    dataSociete.lieuId = 0;
    dataSociete.jourInventaireId = 0;
    dataSociete.itemJourSemaine = {};
    this.setState({
      newSociete: !newSociete,
      dataSociete,
    });
  };

  handleCancelNewSociete = () => {
    const { newSociete, selectedSocieteId } = this.state;
    if (!newSociete && selectedSocieteId !== -1) {
      const releventDiv = document.getElementById(
        "societe_" + selectedSocieteId
      );
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
    this.setState({
      newSociete: false,
      selectedSocieteId: -1,
    });
  };

  handleNomChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.nom = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleFormeJuridiqueChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.formeJuridique = event.target.value.toUpperCase();
    this.setState({
      dataSociete,
    });
  };

  handleDenominationChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.denomination = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleNomCommercialChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.nomCommercial = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleSiretChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.siret = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleRCSChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.rcs = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleNumeroTVAChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.numeroTVA = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleCodeAPESelect = (code) => {
    const { dataSociete } = this.state;
    dataSociete.codeAPE = code;
    if (dataSociete.codeAPE.length !== 0) {
      const exist = dataSociete.listAPE.find(
        (x) => x.codeAPE === dataSociete.codeAPE
      );
      if (exist) dataSociete.listAPENonRepris.unshift(exist);
      dataSociete.listAPE = dataSociete.listAPE.filter(
        (x) => x.codeAPE !== dataSociete.codeAPE
      );
    }
    if (code.length !== 0) {
      const exist = dataSociete.listAPENonRepris.find(
        (x) => x.codeAPE === code
      );
      if (exist) dataSociete.listAPE.unshift(exist);
      dataSociete.listAPENonRepris = dataSociete.listAPENonRepris.filter(
        (x) => x.codeAPE !== code
      );
    }
    this.setState({ dataSociete });
  };

  handleContactSelect = (contact) => {
    const { dataSociete } = this.state;
    dataSociete.tiersId = contact;
    this.setState({ dataSociete });
  };

  handleAvecCouleurChange = () => {
    const { dataSociete } = this.state;
    dataSociete.avecCouleur = !dataSociete.avecCouleur;
    if (!dataSociete.avecCouleur) dataSociete.couleur = "";
    this.setState({ dataSociete });
  };

  handleCapitalChange = (event) => {
    const { dataSociete } = this.state;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    dataSociete.capital = formattedValue;
    this.setState({ dataSociete });
  };

  handleNbPartChange = (event) => {
    const { dataSociete } = this.state;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    dataSociete.nbPart = formattedValue;
    this.setState({ dataSociete });
  };

  handleCouleurChange = (couleur) => {
    const { dataSociete } = this.state;
    if (dataSociete.avecCouleur) {
      dataSociete.backgroundColor = `rgba(${couleur.rgb.r},${couleur.rgb.g},${couleur.rgb.b},0.3)`;
      dataSociete.couleur =
        couleur.rgb.r.toString() +
        "," +
        couleur.rgb.g.toString() +
        "," +
        couleur.rgb.b.toString();
      this.setState({ dataSociete });
    }
  };

  handleDateCreationChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      const { dataSociete } = this.state;
      dataSociete.dateCreation = moment(day).format("YYYY-MM-DD");
      dataSociete.dateCreationAAAAMMJJ = moment(day).format("YYYYMMDD");
      this.setState({ dataSociete });
    }
  };

  handleDateOuvertureClientChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      const { dataSociete } = this.state;
      dataSociete.dateOuvertureClient = moment(day).format("YYYY-MM-DD");
      dataSociete.dateOuvertureClientAAAAMMJJ = moment(day).format("YYYYMMDD");
      this.setState({ dataSociete });
    }
  };

  handleDateFinExoChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      const { dataSociete } = this.state;
      dataSociete.dateFinExo = moment(day).format("YYYY-MM-DD");
      dataSociete.dateFinExoAAAAMMJJ_1 = moment(day).format("YYYYMMDD");
      this.setState({ dataSociete });
    }
  };

  handleAdresseChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.adresse = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleCPChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.cp = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleVilleChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.ville = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleTelephoneChange = async (event) => {
    const { dataSociete } = this.state;
    dataSociete.telephone = event.target.value;
    this.setState({
      dataSociete,
    });
  };

  handleLieuSelect = (lieu) => {
    const { dataSociete } = this.state;
    dataSociete.lieuId = lieu;
    this.setState({ dataSociete });
  };

  handleJourInventaireChange = (event) => {
    const { dataSociete } = this.state;
    dataSociete.jourInventaireId = event.target.value;
    this.setState({ dataSociete });
  };

  handleAjouteAPEOnClick = (ape) => {
    const { dataSociete } = this.state;
    dataSociete.listAPENonRepris = dataSociete.listAPENonRepris.filter(
      (x) => x._id !== ape._id
    );
    dataSociete.listAPE.unshift(ape);
    this.setState({ dataSociete });
  };
  handleSupprAPEOnClick = (ape) => {
    const { dataSociete } = this.state;
    dataSociete.listAPE = dataSociete.listAPE.filter((x) => x._id !== ape._id);
    dataSociete.listAPENonRepris.unshift(ape);
    this.setState({ dataSociete });
  };

  onSelectSociete = (societe) => {
    const { selectedSocieteId, newSociete } = this.state;
    if (selectedSocieteId === -1 && !newSociete) {
      const dataSociete = {};
      dataSociete._id = societe._id;
      dataSociete.nom = societe.nom;
      dataSociete.formeJuridique = societe.formeJuridique;
      dataSociete.denomination = societe.denomination;
      dataSociete.nomCommercial = societe.nomCommercial;
      dataSociete.siret = societe.siret;
      dataSociete.rcs = societe.rcs;
      dataSociete.numeroTVA = societe.numeroTVA;
      dataSociete.codeAPE = societe.codeAPE;
      dataSociete.tiersId = societe.tiersId.toString();
      dataSociete.backgroundColor = societe.backgroundColor;
      dataSociete.couleur = societe.couleur;
      dataSociete.avecCouleur = societe.avecCouleur;
      dataSociete.capital = societe.capital;
      dataSociete.nbPart = societe.nbPart;
      dataSociete.dateCreation = societe.dateCreation;
      dataSociete.dateCreationAAAAMMJJ = societe.dateCreationAAAAMMJJ;
      dataSociete.dateOuvertureClient = societe.dateOuvertureClient;
      dataSociete.dateOuvertureClientAAAAMMJJ =
        societe.dateOuvertureClientAAAAMMJJ;
      dataSociete.dateFinExo = societe.dateFinExo;
      dataSociete.dateFinExoAAAAMMJJ_1 = societe.dateFinExoAAAAMMJJ_1;
      dataSociete.adresse = societe.adresse;
      dataSociete.cp = societe.cp;
      dataSociete.ville = societe.ville;
      dataSociete.telephone = societe.telephone;
      dataSociete.lieuId = societe.lieuId.toString();
      dataSociete.jourInventaireId = societe.jourInventaireId;
      dataSociete.itemJourSemaine = societe.itemJourSemaine;
      dataSociete.listAPE = societe.listAPE;
      dataSociete.listAPENonRepris = societe.listAPENonRepris;
      this.setState({
        selectedSocieteId: societe._id,
        dataSociete,
      });
      const releventDiv = document.getElementById("top-id");
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
  };

  handleSubmitSociete = async () => {
    const { selectedSocieteId, newSociete } = this.state;
    if (newSociete && selectedSocieteId === -1) {
      await this.traiteNewSociete();
    } else {
      if (!newSociete && selectedSocieteId !== -1) {
        await this.traiteUpdateSociete();
      }
    }
  };

  traiteNewSociete = async () => {
    const { newSociete, dataSociete, tabJourSemaine, tabAPEForSelect } =
      this.state;
    await addSociete({
      dataSociete,
    });
    const societes = await this.chargeSociete(tabJourSemaine, tabAPEForSelect);

    this.setState({
      newSociete: !newSociete,
      societes,
    });
    const releventDiv = document.getElementById("bottom_id");
    if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
  };

  traiteUpdateSociete = async () => {
    const { selectedSocieteId, dataSociete, tabJourSemaine, tabAPEForSelect } =
      this.state;
    await updateSociete({
      societeId: selectedSocieteId,
      dataSociete,
    });
    const societes = await this.chargeSociete(tabJourSemaine, tabAPEForSelect);
    const releventDiv = document.getElementById("societe_" + selectedSocieteId);
    if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    this.setState({
      newSociete: false,
      societes,
      selectedSocieteId: -1,
    });
  };

  render() {
    const {
      sortColumn,
      newSociete,
      dataSociete,
      selectedSocieteId,
      societes,
      tabAPEForSelect,
      tabContactForSelect,
      tabLieuForSelect,
      tabJourSemaine,
    } = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <span id="top-id"></span>
          <BarreMenu
            type_menu={typeMenuParametresSocietes}
            menu_actif={menuSocietes}
          ></BarreMenu>
          {!newSociete && selectedSocieteId === -1 && (
            <button
              onClick={this.handleNewSociete}
              className="btn btn-primary text-white"
              style={{ marginBottom: 20, height: 40 }}
              disabled={newSociete}
            >
              Nouvelle société
            </button>
          )}
          <SocieteForm
            dataSociete={dataSociete}
            newSociete={newSociete}
            selectedSocieteId={selectedSocieteId}
            tabAPEForSelect={tabAPEForSelect}
            tabContactForSelect={tabContactForSelect}
            tabLieuForSelect={tabLieuForSelect}
            tabJourSemaine={tabJourSemaine}
            sortColumn={this.sortColumn}
            handleNomChange={this.handleNomChange}
            handleFormeJuridiqueChange={this.handleFormeJuridiqueChange}
            handleDenominationChange={this.handleDenominationChange}
            handleNomCommercialChange={this.handleNomCommercialChange}
            handleSiretChange={this.handleSiretChange}
            handleRCSChange={this.handleRCSChange}
            handleNumeroTVAChange={this.handleNumeroTVAChange}
            handleCodeAPESelect={this.handleCodeAPESelect}
            handleContactSelect={this.handleContactSelect}
            handleCapitalChange={this.handleCapitalChange}
            handleNbPartChange={this.handleNbPartChange}
            handleDateCreationChange={this.handleDateCreationChange}
            handleDateOuvertureClientChange={
              this.handleDateOuvertureClientChange
            }
            handleDateFinExoChange={this.handleDateFinExoChange}
            handleAdresseChange={this.handleAdresseChange}
            handleCPChange={this.handleCPChange}
            handleVilleChange={this.handleVilleChange}
            handleTelephoneChange={this.handleTelephoneChange}
            handleLieuSelect={this.handleLieuSelect}
            handleAvecCouleurChange={this.handleAvecCouleurChange}
            handleCouleurChange={this.handleCouleurChange}
            handleJourInventaireChange={this.handleJourInventaireChange}
            handleSubmitSociete={this.handleSubmitSociete}
            handleCancelNewSociete={this.handleCancelNewSociete}
            handleAjouteAPEOnClick={this.handleAjouteAPEOnClick}
            columns={this.columns}
          ></SocieteForm>
          {societes && (
            <ListeSociete
              societes={societes}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onSelectSociete={this.onSelectSociete}
            />
          )}
          <span id="bottom_id"></span>
        </div>
      </div>
    );
  }
}

export default Societes;
