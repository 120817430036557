import React, { Component } from "react";
import SelectSearch from "react-select-search";
import NumberFormat from "react-number-format";
import { confirmAlert } from "react-confirm-alert";

import {
  calculeInteretsCompteCourantByAssocie,
  genereInteretsCompteCourantByAssocie,
} from "../services/traitementsService";
import { getListeAssocie } from "../services/pizzeriaService";
import auth from "../services/authService";
import { replaceAll, roundDecimal } from "../utils/tools";

class InteretsCompteGroupe extends Component {
  state = {
    annees: [],
    selectedAnnee: null,
    dataSociete: {},
    traitEnCours: false,
    totalRevenusTauxLimite: 0.0,
    totalRevenusTauxSpecifique: 0.0,
    calculFait: false,
    traitFait: false,
    tabTrait: [],
    dataAllAssocies: [],
    tabAssocieForSelect: [],
    selectedAssocie: "-1",
  };
  async componentDidMount() {
    let annees = [];
    const annee = new Date().getFullYear();
    for (let index = annee; index >= 2019; index--) {
      annees.push({
        value: index.toString(),
        name: index.toString(),
      });
    }
    const selectedAnnee = annees[0].value;

    this.setState({
      annees,
      selectedAnnee,
      tabAssocieForSelect: await this.getInternalListeAssocie(selectedAnnee),
    });
  }

  getInternalListeAssocie = async (annee) => {
    const dataAssocies = await getListeAssocie(annee);
    const tabAssocieForSelect = [];
    tabAssocieForSelect.push({
      value: "-1",
      name: "",
      actionnaireId: null,
      isPhysique: null,
      isMorale: null,
    });
    dataAssocies.data.data
      //.filter((x) => x.isMorale === 1)
      .forEach((x) => {
        tabAssocieForSelect.push({
          value: x._id.toString(),
          name: x.actionnaireNom,
          actionnaireId: x.actionnaireId,
          isPhysique: x.isPhysique,
          isMorale: x.isMorale,
        });
      });
    return tabAssocieForSelect;
  };

  handleAnneeSelect = async (annee) => {
    this.setState({
      selectedAnnee: annee,
      calculFait: false,
      selectedAssocie: "-1",
      tabAssocieForSelect: await this.getInternalListeAssocie(annee),
    });
  };

  handleAssocieSelect = (associe) => {
    this.setState({
      calculFait: false,
      selectedAssocie: associe,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    const {
      traitEnCours,
      selectedAnnee,
      selectedAssocie,
      tabAssocieForSelect,
    } = this.state;
    const leAssocie = tabAssocieForSelect.find(
      (x) => x.value === selectedAssocie
    );
    if (!traitEnCours && leAssocie) {
      this.setState({ traitEnCours: true });
      let totalRevenusTauxLimite = 0.0;
      let totalRevenusTauxSpecifique = 0.0;

      const data = await calculeInteretsCompteCourantByAssocie(
        selectedAnnee,
        parseInt(leAssocie.actionnaireId),
        leAssocie.isMorale,
        leAssocie.isPhysique
      );
      data.data.data.filiales.map((societe) => {
        societe.collapse = true;
        societe.generationEcr = false;
        societe.tauxInteretSpecifique = data.data.data.tauxMoyen;
        totalRevenusTauxLimite = roundDecimal(
          totalRevenusTauxLimite + societe.mtTotalRevenus
        );
        totalRevenusTauxSpecifique = roundDecimal(
          totalRevenusTauxSpecifique + societe.mtSpecifiqueTotalRevenus
        );
        return true;
      });
      this.setState({
        traitEnCours: false,
        dataSociete: data.data.data,
        totalRevenusTauxLimite,
        totalRevenusTauxSpecifique,
        calculFait: true,
      });
    }
  };

  handleTauxChange = (event) => {
    const { dataSociete } = this.state;
    let { totalRevenusTauxSpecifique } = this.state;
    const nbJourAnnee = dataSociete.anneeBisextile ? 366 : 365;
    const id = event.target.id;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " %", "");
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    if (Number(formattedValue) <= dataSociete.tauxMoyen) {
      if (dataSociete.filiales[id].generationEcr) {
        totalRevenusTauxSpecifique = roundDecimal(
          totalRevenusTauxSpecifique -
            dataSociete.filiales[id].mtSpecifiqueTotalRevenus
        );
      }

      dataSociete.filiales[id].tauxInteretSpecifique = Number(formattedValue);
      dataSociete.filiales[id].mtSpecifiqueTotalRevenus = 0.0;

      dataSociete.filiales[id].detailJour.map((jour) => {
        jour.mtProvisionSpecifique = roundDecimal(
          (jour.soldeJour *
            (jour.diffDay / nbJourAnnee) *
            dataSociete.filiales[id].tauxInteretSpecifique) /
            100
        );
        dataSociete.filiales[id].mtSpecifiqueTotalRevenus = roundDecimal(
          dataSociete.filiales[id].mtSpecifiqueTotalRevenus +
            jour.mtProvisionSpecifique
        );
        return true;
      });
      if (dataSociete.filiales[id].generationEcr) {
        totalRevenusTauxSpecifique = roundDecimal(
          totalRevenusTauxSpecifique +
            dataSociete.filiales[id].mtSpecifiqueTotalRevenus
        );
      }

      this.setState({ dataSociete, totalRevenusTauxSpecifique });
    } else {
      dataSociete.filiales[id].tauxInteretSpecifique = dataSociete.tauxMoyen;
      this.setState({ dataSociete });
    }
  };

  handleClickCollapse = (i) => {
    this.setState((state) => {
      const dataSocietes = state.dataSociete.filiales.map((item, j) => {
        if (j === i) {
          item.collapse = !item.collapse;
        }
        return item;
      });

      return {
        dataSocietes,
      };
    });
  };

  handleGenererEcrChange = (event) => {
    let { totalRevenusTauxLimite, totalRevenusTauxSpecifique } = this.state;
    const { dataSociete } = this.state;
    const id = event.target.id;
    const valIndex = parseInt(id.split("_")[1]);
    dataSociete.filiales.map((item, j) => {
      if (j === valIndex) {
        item.generationEcr = !item.generationEcr;
        if (item.generationEcr) {
          totalRevenusTauxLimite = roundDecimal(
            totalRevenusTauxLimite + item.mtTotalRevenus
          );
          totalRevenusTauxSpecifique = roundDecimal(
            totalRevenusTauxSpecifique + item.mtSpecifiqueTotalRevenus
          );
        } else {
          totalRevenusTauxLimite = roundDecimal(
            totalRevenusTauxLimite - item.mtTotalRevenus
          );
          totalRevenusTauxSpecifique = roundDecimal(
            totalRevenusTauxSpecifique - item.mtSpecifiqueTotalRevenus
          );
        }
      }
      return item;
    });
    this.setState({
      dataSociete,
      totalRevenusTauxLimite,
      totalRevenusTauxSpecifique,
    });
  };

  handleGenererOnClick = async () => {
    const { selectedAnnee, selectedAssocie, tabAssocieForSelect, dataSociete } =
      this.state;
    confirmAlert({
      title:
        "Génération des écritures comptables pour les filiales sélectionnées",
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            let tabFiliale = [];
            dataSociete.filiales.map((item) => {
              if (item.generationEcr) {
                tabFiliale.push({
                  _id: item.societe._id,
                  denomination: item.societe.denomination,
                  totalRevenus: item.mtSpecifiqueTotalRevenus,
                });
              }
              return true;
            });
            const leAssocie = tabAssocieForSelect.find(
              (x) => x.value === selectedAssocie
            );
            const ret = await genereInteretsCompteCourantByAssocie(
              selectedAnnee,
              parseInt(leAssocie.actionnaireId),
              leAssocie.isMorale,
              leAssocie.isPhysique,
              tabFiliale,
              auth.getCurrentUser()._id
            );
            this.setState({
              tabTrait: ret.data.data,
              traitFait: true,
            });
            await this.handleValiderClick();
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  render() {
    const {
      annees,
      selectedAnnee,
      tabAssocieForSelect,
      selectedAssocie,
      traitEnCours,
      dataSociete,
      totalRevenusTauxLimite,
      totalRevenusTauxSpecifique,
      calculFait,
      traitFait,
      tabTrait,
    } = this.state;
    const nbSelectionnees = dataSociete.filiales
      ? dataSociete.filiales.filter((x) => x.generationEcr).length
      : 0;

    return (
      <React.Fragment>
        <h2>Revenus des créances rattachées à des partications (451)</h2>
        <div className="row">
          <div className="col-2">
            <div>
              <div>
                <label className="font-weight-bold" htmlFor="annee">
                  Année
                </label>
              </div>
              <SelectSearch
                options={annees}
                value={selectedAnnee}
                name="annee"
                onChange={this.handleAnneeSelect}
              />
            </div>
            <div>
              <div>
                <label className="font-weight-bold" htmlFor="associe">
                  Associé
                </label>
              </div>
              <SelectSearch
                options={tabAssocieForSelect}
                value={selectedAssocie}
                name="associe"
                onChange={this.handleAssocieSelect}
              />
            </div>

            <div className="mt-4">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderClick}
                disabled={selectedAssocie === "-1"}
              >
                Calculer
              </button>
              {traitEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {calculFait && (
            <div className="col-10">
              {traitFait && !traitEnCours && (
                <React.Fragment>
                  <h5 className="mt-2">Compte-rendu de la génération</h5>
                  <div className="mb-5">
                    <ul>
                      {tabTrait.map((item, index) => (
                        <li key={index}>
                          {item.status === "fulfilled" && (
                            <React.Fragment>
                              <span>
                                {JSON.parse(item.value).societeDenomination}
                              </span>
                              <span> : </span>
                              <span className="text-success">
                                {JSON.parse(item.value).message}
                              </span>
                            </React.Fragment>
                          )}
                          {item.status !== "fulfilled" && (
                            <span className="text-danger">{item.reason}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </React.Fragment>
              )}
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={this.handleGenererOnClick}
                  disabled={nbSelectionnees === 0}
                >
                  <i className="fas fa-coins mr-2"></i>
                  Générer les écritures
                </button>
              </div>

              <div className="mt-3 row">
                <div className="col-4">
                  <i className="mr-2 fas fa-balance-scale-right"></i>
                  {dataSociete.societeMere.denomination} doit,
                  <i className="ml-2 mr-2 fas fa-balance-scale-left text-warning"></i>
                  La société (filiale) doit.
                </div>
                <div className="col-8 text-right">
                  Taux d'intérêt maximum de l'année {selectedAnnee} :
                  <span className="ml-2">
                    <NumberFormat
                      value={dataSociete.tauxMoyen}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalSeparator=","
                      thousandSeparator=" "
                      displayType="text"
                      suffix=" %"
                    />
                  </span>
                </div>
              </div>
              {dataSociete.filiales.length === 0 && (
                <div className="mt-2">Aucune donnée</div>
              )}
              {dataSociete.filiales.length !== 0 && (
                <table
                  className="table table-bordered table-striped mt-4"
                  style={{ fontSize: "12px" }}
                >
                  <tbody>
                    <tr className="row mr-0 ml-0" key={"TOTAL"}>
                      <td className="col-1">
                        <div className="font-weight-bold">Sélection</div>
                      </td>
                      <td className="col-2" style={{ fontSize: "14px" }}>
                        <div className="font-weight-bold">
                          <i
                            className={
                              "mr-2 fas " +
                              (totalRevenusTauxLimite > 0
                                ? "fa-balance-scale-left text-warning"
                                : "fa-balance-scale-right")
                            }
                          ></i>
                          TOTAL
                        </div>
                      </td>
                      <td className="col-9" style={{ fontSize: "14px" }}>
                        <div className="row">
                          <div className="col-3 text-right">
                            <label className="font-weight-bold ml-2 mt-1">
                              Revenus des créances (taux limite)
                            </label>
                            <br></br>
                            <div className="ml-2 mt-1 text-rigth">
                              <NumberFormat
                                value={totalRevenusTauxLimite}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                                suffix=" €"
                                className={
                                  totalRevenusTauxLimite > 0
                                    ? "font-weight-bold text-warning"
                                    : "font-weight-bold"
                                }
                              />
                            </div>
                          </div>
                          <div className="col-3"></div>
                          <div className="col-3 text-right">
                            <label className="font-weight-bold ml-2 mt-1">
                              Revenus des créances (taux spécifique)
                            </label>
                            <div className="ml-2 mt-1 text-right">
                              <NumberFormat
                                value={totalRevenusTauxSpecifique}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                                suffix=" €"
                                className={
                                  totalRevenusTauxSpecifique > 0
                                    ? "font-weight-bold text-warning"
                                    : "font-weight-bold"
                                }
                              />
                            </div>
                          </div>
                          <div className="col-3"></div>
                        </div>
                      </td>
                    </tr>
                    {dataSociete.filiales.map((societe, index) => (
                      <tr className="row mr-0 ml-0" key={societe.societe._id}>
                        <td className="col-1">
                          <input
                            type="checkbox"
                            className="form-check-input ml-0"
                            id={"generation_" + index.toString()}
                            checked={societe.generationEcr}
                            onChange={(e) => this.handleGenererEcrChange(e)}
                          ></input>
                        </td>
                        <td className="col-2" style={{ fontSize: "14px" }}>
                          <div>
                            <div
                              className={
                                societe.generationEcr ? "font-weight-bold" : ""
                              }
                            >
                              <i
                                className={
                                  "mr-2 fas " +
                                  (societe.mtTotalRevenus > 0
                                    ? "fa-balance-scale-left text-warning"
                                    : "fa-balance-scale-right")
                                }
                              ></i>
                              {societe.societe.denomination}

                              {societe.oldGroupeTypeEcritureMere &&
                                societe.oldGroupeTypeEcritureMere._id && (
                                  <div className="mt-2">
                                    Une écriture est déjà enregistrée pour un
                                    revenu{" "}
                                    <NumberFormat
                                      value={
                                        societe.oldGroupeTypeEcritureMere
                                          .creance
                                      }
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      decimalSeparator=","
                                      thousandSeparator=" "
                                      displayType="text"
                                      suffix=" €"
                                      className={
                                        societe.oldGroupeTypeEcritureMere
                                          .creance > 0
                                          ? " text-warning"
                                          : ""
                                      }
                                    />{" "}
                                  </div>
                                )}
                            </div>
                          </div>
                        </td>
                        <td className="col-9" style={{ fontSize: "14px" }}>
                          <div className="row">
                            <div className="col-3 text-right">
                              <label className="ml-2 mt-1 ">
                                Revenus des créances (taux limite)
                              </label>
                              <br></br>
                              <div className="ml-2 mt-1 text-right">
                                <NumberFormat
                                  value={societe.mtTotalRevenus}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator=" "
                                  displayType="text"
                                  suffix=" €"
                                  className={
                                    societe.mtTotalRevenus > 0
                                      ? " text-warning"
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <label className="ml-2 mt-1">
                                Taux d'intérêt spécifique
                              </label>
                              <div className="ml-2">
                                <NumberFormat
                                  id={index}
                                  value={societe.tauxInteretSpecifique}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator=" "
                                  allowNegative={false}
                                  onChange={(e, value) => {
                                    this.handleTauxChange(e, value);
                                  }}
                                  suffix=" %"
                                  className="form-control text-right"
                                  style={{ width: "80px" }}
                                />
                              </div>
                            </div>
                            <div className="col-3 text-right">
                              <label className="ml-2 mt-1">
                                Revenus des créances (taux spécifique)
                              </label>
                              <br></br>
                              <div className="ml-2 mt-1 text-right">
                                <NumberFormat
                                  value={societe.mtSpecifiqueTotalRevenus}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  decimalSeparator=","
                                  thousandSeparator=" "
                                  displayType="text"
                                  suffix=" €"
                                  className={
                                    societe.mtSpecifiqueTotalRevenus > 0
                                      ? " text-warning"
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-3 text-right">
                              <button
                                className="btn btn-outline-primary mb-2"
                                onClick={() => this.handleClickCollapse(index)}
                                style={{ fontSize: "14px" }}
                              >
                                <i
                                  className={
                                    "mr-2 fa text-primary " +
                                    (societe.collapse ? "fa-plus" : "fa-minus")
                                  }
                                  style={{ fontSize: "14px" }}
                                  aria-hidden="true"
                                ></i>
                                Détail
                              </button>
                            </div>
                          </div>

                          {!societe.collapse && (
                            <div
                              style={{
                                maxHeight: "500px",
                                overflowY: "auto",
                              }}
                            >
                              <table
                                className="table table-bordered table-striped mt-4"
                                key={"TABLE_" + societe.societe._id}
                              >
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th className="text-right">Base</th>
                                    <th className="text-right">Nb Jour</th>
                                    <th className="text-right">
                                      Revenus / taux limite
                                    </th>
                                    <th className="text-right">
                                      Revenus / taux spécifique
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {societe.detailJour.map((jour) => (
                                    <tr
                                      key={
                                        societe.societe._id +
                                        "_" +
                                        jour.dateComptableAAAAMMJJ
                                      }
                                    >
                                      <td>{jour.dateJJMMAAAA}</td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={jour.soldeJour}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          decimalSeparator=","
                                          thousandSeparator=" "
                                          displayType="text"
                                          suffix=" €"
                                          className={
                                            jour.soldeJour > 0
                                              ? "text-warning"
                                              : ""
                                          }
                                        />
                                      </td>
                                      <td className="text-right">
                                        {jour.diffDay}
                                      </td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={jour.mtProvision}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          decimalSeparator=","
                                          thousandSeparator=" "
                                          displayType="text"
                                          suffix=" €"
                                          className={
                                            jour.mtProvision > 0
                                              ? "text-warning"
                                              : ""
                                          }
                                        />
                                      </td>
                                      <td className="text-right">
                                        <NumberFormat
                                          value={jour.mtProvisionSpecifique}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          decimalSeparator=","
                                          thousandSeparator=" "
                                          displayType="text"
                                          suffix=" €"
                                          className={
                                            jour.mtProvisionSpecifique > 0
                                              ? "text-warning"
                                              : ""
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default InteretsCompteGroupe;
