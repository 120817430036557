import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function allModePaiement(annee) {
  return await http.get(urlApiComptabilite + "/paiement/allModePaiement");
}

export async function calculeValeurEncaissement(annee) {
  return await http.get(
    urlApiComptabilite + "/paiement/calculeValeurEncaissement/" + annee
  );
}

export async function fullAllModePaiement() {
  return await http.get(urlApiComptabilite + "/paiement/fullAllModePaiement");
}

export async function addMethode(dataMethode) {
  return await http.post(
    urlApiComptabilite + "/paiement/addMethode",
    dataMethode
  );
}

export async function updateMethode(id, dataMethode) {
  return await http.post(
    urlApiComptabilite + "/paiement/updateMethode/" + id,
    dataMethode
  );
}

export async function getFullAllBanqueCompte() {
  return await http.get(
    urlApiComptabilite + "/paiement/getFullAllBanqueCompte"
  );
}

export async function getBanqueCompteBySocieteIdJournalCode(
  societeId,
  journalCode
) {
  return await http.get(
    urlApiComptabilite +
      "/paiement/getBanqueCompteBySocieteIdJournalCode/" +
      societeId +
      "/" +
      journalCode
  );
}

export async function getBanqueCompteBySocieteIdCompteNum(
  societeId,
  compteNum
) {
  return await http.get(
    urlApiComptabilite +
      "/paiement/getBanqueCompteBySocieteIdCompteNum/" +
      societeId +
      "/" +
      compteNum
  );
}

export async function addBanqueCompte(dataCompte) {
  return await http.post(urlApiComptabilite + "/paiement/addBanqueCompte", {
    dataCompte,
  });
}

export async function updateBanqueCompte(id, dataCompte) {
  return await http.post(
    urlApiComptabilite + "/paiement/updateBanqueCompte/" + id,
    { dataCompte }
  );
}
