import { Component } from "react";
import { populateIframe } from "../../utils/tools";
import { urlGenerateurPdf } from "../../config";
import { getFacture } from "../../services/factureService";
import { toast } from "react-toastify";

class Facture extends Component {
  state = {
    isLoaded: false,
    fileName: "",
  };

  componentDidMount() {
    const { factureId } = this.props.match.params;

    getFacture(factureId).then((res) => {
      console.log("res", res.data);
      if (res.data.success) {
        this.setState({ fileName: res.data.filename });

        const url = `${urlGenerateurPdf}/generationPDF/getContentPDF/${this.state.fileName}`;

        this.setState({ isLoaded: true });

        populateIframe(document.querySelector("#pdf"), url, [
          // à rajouter une fois qu'on aura fait cette évolution côté generateur PDF
          // ["client_secret", API_CLIENT_SECRET],
          // ["client_id", API_CLIENT_ID],
        ]);
      } else {
        toast.error("Il y a eu une erreur");
      }
    });
  }

  downloadPDF = () => {
    let fileName = this.state.fileName;

    let myiframe = document.querySelector("#pdf");
    let dl = document.querySelector("#download_do");
    const blobUrl = myiframe.src;
    dl.href = blobUrl;
    dl.download = fileName;
    dl.click();
  };

  render() {
    const { isLoaded } = this.state;

    return (
      <>
        {!isLoaded && (
          <div className="mt-5 mb-5">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {isLoaded && (
          <div className="row mt-2 mb-5">
            <div className="col-8">
              <button className="btn btn-outline-secondary mb-2" onClick={this.downloadPDF}>
                Télécharger le fichier
              </button>
              <a id="download_do" href="/" style={{ display: "none" }}>
                __
              </a>
              <iframe title="Contenu du document" id="pdf" type="application/pdf" width="100%" height="800" />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Facture;
