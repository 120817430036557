import React, { Component } from "react";
import SelectSearch from "react-select-search";

import Table from "./../common/table";
import { generateTabDept } from "../../utils/tools";

class SocieteLesVilles extends Component {
  state = {
    newVille: false,
    villesPossiblesForSelect: [],
    departement: "45",
    ville: "",
  };

  columns = [
    {
      path: "villeNom",
      label: "Ville",
      tdClassName: "p-2 w-50",
      className: "",
      content: (ville) => (
        <span style={{ fontSize: "12px" }}>
          {ville.villeNom + " " + ville.villeCP}
        </span>
      ),
    },
    {
      path: "isDefaut",
      label: "Defaut",
      tdClassName: "p-2 w-50",
      className: "",
      content: (ville) => (
        <div className="form-group">
          <div className="form-check">
            <input
              name="isDefaut"
              id={ville._id}
              type="checkbox"
              checked={ville.isDefaut === 1}
              onChange={this.props.handleIsDefautChange}
              className="form-check-input"
            />
            <label
              htmlFor="isDefaut"
              className="form-check-label"
              style={{ fontSize: "12px" }}
            >
              Par défaut
            </label>
          </div>
        </div>
      ),
    },
    {
      key: "suppr",
      tdClassName: "text-center m-0",
      className: "",
      content: (ville) => (
        <button
          className="btn btn-outline-secondary p-0"
          onClick={() => this.handleSupprSocieteVilleOnClick(ville)}
        >
          <i
            className="far fa-trash-alt p-1"
            style={{ fontSize: "24px" }}
            aria-hidden="true"
          ></i>
        </button>
      ),
    },
  ];

  async componentDidMount() {
    let villesPossiblesForSelect = this.props.allVillesForSelect;
    let tabVilleId = this.props.tabVilleId;

    this.setState({
      villesPossiblesForSelect,
      tabVilleId,
    });
  }

  handleNewVille = () => {
    const { newVille } = this.state;
    this.setState({ newVille: !newVille });
  };

  handleCancelNewVille = () => {
    const { newVille } = this.state;
    this.setState({ newVille: !newVille });
  };

  handleDepartementChange = (event) => {
    this.setState({ departement: event.target.value, ville: "" });
  };

  handleVilleChange = (event) => {
    this.setState({ ville: event });
  };

  handleSubmitNewVille = async () => {
    const { newVille, ville } = this.state;
    let { tabVilleId } = this.state;
    const retAdd = await this.props.handleAddSocieteVille({
      societeId: this.props.societeId,
      idInsee: ville,
    });
    tabVilleId.push(retAdd);
    this.setState({
      newVille: !newVille,
      ville: "",
      departement: "45",
      tabVilleId: JSON.parse(JSON.stringify(tabVilleId)),
    });
  };
  handleSupprSocieteVilleOnClick = async (ville) => {
    let { tabVilleId } = this.state;
    await this.props.handleSupprSocieteVilleOnClick(ville);
    tabVilleId = tabVilleId.filter((v) => v !== ville.villeId);
    this.setState({ tabVilleId });
  };

  render() {
    const { villes } = this.props;
    const { newVille, departement, ville, tabVilleId } = this.state;
    const departements = generateTabDept();
    let { villesPossiblesForSelect } = this.state;
    villesPossiblesForSelect = villesPossiblesForSelect.filter(
      (v) => !tabVilleId.includes(v.villeId)
    );

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-3">
            <button
              onClick={this.handleNewVille}
              className="btn btn-primary text-white ml-2"
              style={{ marginBottom: 20, height: 40 }}
              disabled={newVille}
            >
              +
            </button>

            <div>
              {newVille && (
                <React.Fragment>
                  <div className="ml-4 mb-4">
                    <div className="form-group">
                      <label htmlFor="departement">Département</label>
                      <select
                        name="departement"
                        id="departement"
                        value={departement}
                        onChange={this.handleDepartementChange}
                        className="form-control"
                        style={{ width: "70px" }}
                      >
                        {departements.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="ville">Ville</label>
                      <SelectSearch
                        options={villesPossiblesForSelect.filter(
                          (item) => item.departement === departement
                        )}
                        value={ville}
                        name="ville"
                        search={true}
                        onChange={this.handleVilleChange}
                      />
                    </div>

                    <button
                      className="btn btn-primary mr-2"
                      disabled={ville === ""}
                      onClick={this.handleSubmitNewVille}
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewVille}
                    >
                      Annuler
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="col-9">
            <Table noHeader={true} columns={this.columns} data={villes} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SocieteLesVilles;
