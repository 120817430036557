import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import { toast } from "react-toastify";

import ProduitLesFournisseurs from "./produitLesFournisseurs";
import { getAllFournisseursPizztList } from "../../services/contactService";
import {
  getProduitsFournisseurs,
  getProduitFournisseurs,
  getAllGroupeProduit,
  addProduitFournisseur,
  deleteProduitFournisseur,
  getSomeRefProduitFournisseur,
  updateNomCommandeProduit,
} from "../../services/produitsFournisseursService";
import SelectSearch from "react-select-search";
import SearchBox from "../common/searchBox";
import { makeId } from "../../utils/tools";

class ProduitsFournisseurs extends Component {
  state = {
    searchQuery: "",
    newFournisseur: false,
    sansFournisseur: true,
    groupesForSelect: [],
    selectedGroupes: [],
    tabFournisseursForSelect: [],
    selectedFournisseur: "-1",
    tabRefProduitFournisseur: [],
    dataPF: [],
    shouldShowBox: false,
  };
  async componentDidMount() {
    const resultFourn = await getAllFournisseursPizztList();
    const tabFournisseurId = [];
    let tabFournisseursForSelect = [];
    tabFournisseursForSelect.push({
      value: "-1",
      name: "",
    });
    resultFourn.data.data
      .filter((f) => f.email && f.email.length !== 0)
      .forEach((item) => {
        tabFournisseursForSelect.push({
          value: item._id.toString(),
          name: item.nom,
        });
        tabFournisseurId.push(item._id);
      });
    tabFournisseursForSelect = _.orderBy(
      tabFournisseursForSelect,
      ["name"],
      ["asc"]
    );

    let tabRefProduitFournisseur = [];
    const resultRefFourn = await getSomeRefProduitFournisseur(tabFournisseurId);
    resultRefFourn.data.data.forEach((r, index) => {
      tabRefProduitFournisseur.push({
        value: index.toString(),
        name: r.ref + " - " + r.designation,
        fournisseurId: r.fournisseurId,
        reference: r.ref,
        uniteMesureId: r.uniteMesureId,
      });
    });

    const resultPF = await getProduitsFournisseurs();
    const resultGroupe = await getAllGroupeProduit();
    let groupesForSelect = [];
    let selectedGroupes = [];
    resultGroupe.data.data.map((item) => {
      groupesForSelect.push({
        value: item._id.toString(),
        name: item.nom,
      });
      selectedGroupes.push(item._id.toString());
      return true;
    });

    const dataPF = resultPF.data.data;
    const nbSansFournisseur = dataPF.filter(
      (p) => p.tabFournisseur.length === 0
    ).length;

    this.setState({
      dataPF,
      tabFournisseursForSelect,
      groupesForSelect,
      selectedGroupes,
      tabRefProduitFournisseur,
      sansFournisseur: nbSansFournisseur !== 0,
      shouldShowBox: true,
    });
  }

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
    });
  };

  handleSansFournisseurChange = () => {
    const { sansFournisseur } = this.state;
    this.setState({
      sansFournisseur: !sansFournisseur,
    });
  };

  handleSelectAllClick = (event) => {
    event.preventDefault();
    const { groupesForSelect } = this.state;
    let selectedGroupes = [];
    groupesForSelect.map((item) => {
      selectedGroupes.push(item.value);
      return true;
    });
    this.setState({
      selectedGroupes,
    });
  };

  handleUnSelectAllClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedGroupes: [],
    });
  };

  handleGroupesSelect = (groupes) => {
    this.setState({
      selectedGroupes: groupes,
    });
  };

  handleFournisseurSelect = (fournisseur) => {
    this.setState({
      selectedFournisseur: fournisseur,
    });
  };

  getPagedData = () => {
    const {
      sansFournisseur,
      searchQuery,
      selectedGroupes,
      selectedFournisseur,
      dataPF,
    } = this.state;
    const tabIdGroupe = [];
    selectedGroupes.map((g) => tabIdGroupe.push(parseInt(g)));
    let filtered = dataPF;
    if (sansFournisseur)
      filtered = filtered.filter((p) => p.tabFournisseur.length === 0);
    filtered = filtered.filter((p) => tabIdGroupe.includes(p.groupeId));
    if (selectedFournisseur !== "-1")
      filtered = filtered.filter((p) => {
        return (
          p.tabFournisseur.filter(
            (f) => f.fournisseurId === parseInt(selectedFournisseur)
          ).length !== 0
        );
      });
    if (searchQuery)
      filtered = filtered.filter(
        (p) =>
          (p.designation &&
            p.designation.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (p._id && p._id.toString().includes(searchQuery)) ||
          (p.nomCommande &&
            p.nomCommande.toLowerCase().includes(searchQuery.toLowerCase()))
      );

    return { totalCount: filtered.length, data: filtered };
  };

  handleNomCommandeChange = async (item, event) => {
    const { dataPF } = this.state;
    const laLigne = dataPF.find((x) => {
      return x._id === item._id;
    });
    if (laLigne) {
      item.nomCommande = event.target.value;
      await updateNomCommandeProduit({
        produitId: laLigne._id,
        nomCommande: item.nomCommande,
      });
      this.setState({ dataPF });
    }
  };

  handleNomCommandeChange = (item, event) => {
    const { dataPF } = this.state;
    const laLigne = dataPF.find((x) => {
      return x._id === item._id;
    });
    if (laLigne) {
      item.nomCommande = event.target.value;
      this.setState({ dataPF });
    }
  };

  handleNomCommandeValid = async (item) => {
    const { dataPF } = this.state;
    const laLigne = dataPF.find((x) => {
      return x._id === item._id;
    });
    if (laLigne) {
      await updateNomCommandeProduit({
        produitId: laLigne._id,
        nomCommande: item.nomCommande,
      });
      this.setState({ dataPF });
    }
  };

  handleSupprFournisseurOnClick = (id, produitId) => {
    confirmAlert({
      title: "Suppression de l'affectation du fournisseur ",
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => this.traitSupprFournisseur(id, produitId),
        },
        {
          label: "Non",
        },
      ],
    });
  };

  traitSupprFournisseur = async (id, produitId) => {
    const { dataPF } = this.state;
    await deleteProduitFournisseur(id);

    const leProduit = dataPF.find((p) => p._id === produitId);
    if (leProduit) {
      leProduit.tabFournisseur = leProduit.tabFournisseur.filter(
        (f) => f._id !== id
      );
    }
    this.setState({ dataPF });
  };

  handleAddProduitFournisseur = async ({
    produitId,
    fournisseurId,
    conditionnement,
    dateFrom,
    referenceFournisseur,
  }) => {
    const { dataPF } = this.state;
    const retAdd = await addProduitFournisseur({
      produitId,
      fournisseurId,
      conditionnement,
      dateFrom,
      referenceFournisseur,
    });
    if (retAdd.data.success) {
      const newTab = await getProduitFournisseurs(produitId);
      const leProduit = dataPF.find((p) => p._id === produitId);
      if (leProduit) {
        leProduit.tabFournisseur = newTab.data.data;
      }
      this.setState({ dataPF });
      return retAdd.data.data;
    } else {
      toast.error(retAdd.data.message);
    }
  };

  render() {
    const {
      sansFournisseur,
      groupesForSelect,
      selectedGroupes,
      tabFournisseursForSelect,
      tabRefProduitFournisseur,
      selectedFournisseur,
      searchQuery,
      shouldShowBox,
    } = this.state;

    const { totalCount, data } = this.getPagedData();

    return (
      <div className="row">
        <div className="col-12">
          <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
            <h2>Produits / Fournisseurs</h2>

            {!shouldShowBox && (
              <div className="mt-5 mb-5">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {shouldShowBox && (
              <div className="row">
                <div className="d-none d-md-block col-2">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="sansFournisseur"
                      checked={sansFournisseur}
                      onChange={this.handleSansFournisseurChange}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="sansFournisseur"
                      style={{ fontSize: "12px" }}
                    >
                      Uniquement les produits sans fournisseur
                    </label>
                  </div>

                  <div className="mt-2">
                    <label htmlFor="fournisseurs" className="font-weight-bold">
                      Fournisseur :
                    </label>
                    <SelectSearch
                      options={tabFournisseursForSelect}
                      value={selectedFournisseur}
                      name="fournisseurs"
                      search
                      onChange={this.handleFournisseurSelect}
                      disabled={sansFournisseur}
                    />
                  </div>

                  <div style={{ marginTop: "100px" }}>
                    <div>
                      <label htmlFor="societes" className="font-weight-bold">
                        Groupes sélectionnés :
                      </label>
                    </div>
                    <div className="btn-group">
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "12px" }}
                        onClick={this.handleSelectAllClick}
                      >
                        Tout sélectionner
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "12px" }}
                        onClick={this.handleUnSelectAllClick}
                      >
                        Tout désélectionner
                      </button>
                    </div>
                    <SelectSearch
                      options={groupesForSelect}
                      value={selectedGroupes}
                      name="societes"
                      multiple
                      onChange={this.handleGroupesSelect}
                    />
                  </div>
                </div>
                <div className="col-10">
                  <p className="w-100">{totalCount} produits</p>
                  <SearchBox value={searchQuery} onChange={this.handleSearch} />

                  <table
                    className="table table-bordered table-striped mt-4"
                    style={{ fontSize: "14px" }}
                  >
                    <tbody>
                      {data.map((item) => (
                        <tr className="row mr-0 ml-0" key={item._id}>
                          <td className="col-2" style={{ fontSize: "14px" }}>
                            <div className="font-weight-bold">
                              {item._id + " " + item.designation}
                            </div>
                            <div>
                              {item.groupeNom} - {item.uniteMesureNom}
                            </div>
                          </td>
                          <td className="col-2">
                            <div className="input-group input-group-sm">
                              <input
                                type="text"
                                name={"nomCommande" + makeId()}
                                id="nomCommande"
                                placeholder="Nom pour les commandes"
                                value={item.nomCommande}
                                className="form-control"
                                style={{ fontSize: "12px" }}
                                autoComplete={makeId()}
                                onChange={(e) => {
                                  this.handleNomCommandeChange(item, e);
                                }}
                              />
                              <div className="input-group-append">
                                <span
                                  className="input-group-text"
                                  onClick={() =>
                                    this.handleNomCommandeValid(item)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fa fa-solid fa-check text-primary"></i>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="col-8" style={{ fontSize: "14px" }}>
                            <React.Fragment>
                              <ProduitLesFournisseurs
                                item={item}
                                tabFournisseursForSelect={
                                  tabFournisseursForSelect
                                }
                                tabRefProduitFournisseur={tabRefProduitFournisseur.filter(
                                  (r) => r.uniteMesureId === item.uniteMesureId
                                )}
                                handleAddProduitFournisseur={
                                  this.handleAddProduitFournisseur
                                }
                                handleSupprFournisseurOnClick={
                                  this.handleSupprFournisseurOnClick
                                }
                              ></ProduitLesFournisseurs>
                            </React.Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProduitsFournisseurs;
