import React from "react";
import SelectSearch from "react-select-search";
import { ChromePicker } from "react-color";
import NumberFormat from "react-number-format";
import Table from "../common/table";

const SocieteForm = (props) => {
  const {
    dataSociete,
    newSociete,
    selectedSocieteId,
    tabAPEForSelect,
    tabContactForSelect,
    tabLieuForSelect,
    tabJourSemaine,
    sortColumn,
  } = props;
  return (
    <React.Fragment>
      <div>
        {(newSociete || selectedSocieteId !== -1) && (
          <div
            className="jumbotron jumbotron-fluid pt-2 pb-2"
            style={{ fontSize: "12px" }}
          >
            <h5 className="ml-2">
              {newSociete ? "Nouvelle société" : "Modification de la société"}
            </h5>
            <div className="ml-2 mr-2">
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="nom">
                  Nom (*)
                </label>
                <input
                  type="text"
                  value={dataSociete.nom}
                  onChange={(event) => props.handleNomChange(event)}
                  className="form-control"
                  placeholder="Nom"
                  style={{ fontSize: "12px" }}
                ></input>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Forme juridique (*)
                  </label>
                  <input
                    type="text"
                    value={dataSociete.formeJuridique}
                    onChange={(event) =>
                      props.handleFormeJuridiqueChange(event)
                    }
                    className="form-control"
                    placeholder="Forme juridique"
                    style={{ fontSize: "12px", width: "120px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Dénomination sociale (*)
                  </label>
                  <input
                    type="text"
                    value={dataSociete.denomination}
                    onChange={(event) => props.handleDenominationChange(event)}
                    className="form-control"
                    placeholder="Dénomination sociale"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Nom commercial
                  </label>
                  <input
                    type="text"
                    value={dataSociete.nomCommercial}
                    onChange={(event) => props.handleNomCommercialChange(event)}
                    className="form-control"
                    placeholder="Nom commercial"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    SIRET (*)
                  </label>
                  <input
                    type="text"
                    value={dataSociete.siret}
                    onChange={(event) => props.handleSiretChange(event)}
                    className="form-control"
                    placeholder="SIRET"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    RCS (*)
                  </label>
                  <input
                    type="text"
                    value={dataSociete.rcs}
                    onChange={(event) => props.handleRCSChange(event)}
                    className="form-control"
                    placeholder="Registre du commerce et des sociétés"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    TVA intracommunautaire (*)
                  </label>
                  <input
                    type="text"
                    value={dataSociete.numeroTVA}
                    onChange={(event) => props.handleNumeroTVAChange(event)}
                    className="form-control"
                    placeholder="N° de TVA intracommunautaire"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    APE (*)
                  </label>
                  <SelectSearch
                    options={tabAPEForSelect}
                    value={dataSociete.codeAPE}
                    name="codeAPE"
                    search={true}
                    onChange={props.handleCodeAPESelect}
                  />
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Contact interne (*)
                  </label>
                  <SelectSearch
                    options={tabContactForSelect}
                    value={dataSociete.tiersId}
                    name="contact"
                    search={true}
                    onChange={props.handleContactSelect}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Capital (*)
                  </label>
                  <NumberFormat
                    className="form-control text-right"
                    value={dataSociete.capital}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    decimalSeparator=","
                    thousandSeparator=" "
                    allowNegative={false}
                    onChange={(e, value) => {
                      props.handleCapitalChange(e, value);
                    }}
                    style={{ fontSize: "12px", width: "150px" }}
                  />
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Nombre de parts (*)
                  </label>
                  <NumberFormat
                    className="form-control text-right"
                    value={dataSociete.nbPart}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    decimalSeparator=","
                    thousandSeparator=" "
                    allowNegative={false}
                    onChange={(e, value) => {
                      props.handleNbPartChange(e, value);
                    }}
                    style={{ fontSize: "12px", width: "150px" }}
                  />
                </div>
                <div className="col-4" style={{ marginTop: "1.5rem" }}></div>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Date de création (*)
                  </label>
                  <input
                    type="date"
                    value={dataSociete.dateCreation}
                    onChange={(date) => props.handleDateCreationChange(date)}
                    className="form-control"
                    style={{ fontSize: "12px", width: "150px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Date d'ouverture
                  </label>
                  <input
                    type="date"
                    value={dataSociete.dateOuvertureClient}
                    onChange={(date) =>
                      props.handleDateOuvertureClientChange(date)
                    }
                    className="form-control"
                    style={{ fontSize: "12px", width: "150px" }}
                  ></input>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Date de fin du 1er exercice (*)
                  </label>
                  <input
                    type="date"
                    value={dataSociete.dateFinExo}
                    onChange={(date) => props.handleDateFinExoChange(date)}
                    className="form-control"
                    style={{ fontSize: "12px", width: "150px" }}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Adresse
                  </label>
                  <input
                    type="text"
                    value={dataSociete.adresse}
                    onChange={(event) => props.handleAdresseChange(event)}
                    className="form-control"
                    placeholder="Adresse"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
                <div className="col-2">
                  <label className="font-weight-bold" htmlFor="nom">
                    Code postal
                  </label>
                  <input
                    type="text"
                    value={dataSociete.cp}
                    onChange={(event) => props.handleCPChange(event)}
                    className="form-control"
                    placeholder="CP"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
                <div className="col-3">
                  <label className="font-weight-bold" htmlFor="nom">
                    Ville
                  </label>
                  <input
                    type="text"
                    value={dataSociete.ville}
                    onChange={(event) => props.handleVilleChange(event)}
                    className="form-control"
                    placeholder="Ville"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
                <div className="col-3">
                  <label className="font-weight-bold" htmlFor="nom">
                    Téléphone
                  </label>
                  <input
                    type="tel"
                    pattern="[0-9]{10}"
                    value={dataSociete.telephone}
                    onChange={(event) => props.handleTelephoneChange(event)}
                    className="form-control"
                    placeholder="Téléphone"
                    style={{ fontSize: "12px" }}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-4">
                  <label className="font-weight-bold" htmlFor="nom">
                    Lieu associé
                  </label>
                  <SelectSearch
                    options={tabLieuForSelect}
                    value={dataSociete.lieuId}
                    name="lieu"
                    search={true}
                    onChange={props.handleLieuSelect}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-3">
                  <label className="font-weight-bold" htmlFor="nom">
                    Couleur associée
                  </label>
                  <div className="form-check mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="sansEmail"
                      checked={dataSociete.avecCouleur}
                      onChange={props.handleAvecCouleurChange}
                    ></input>
                    <label className="form-check-label" htmlFor="sansEmail">
                      Avec une couleur pour les plannings
                    </label>
                  </div>

                  <ChromePicker
                    color={dataSociete.backgroundColor}
                    onChange={props.handleCouleurChange}
                  />
                </div>
                <div className="col-2">
                  <label
                    className="font-weight-bold"
                    htmlFor="jourInventaireId"
                  >
                    Jour d'inventaire
                  </label>
                  <select
                    name="jourInventaireId"
                    id="jourInventaireId"
                    value={dataSociete.jourInventaireId}
                    onChange={props.handleJourInventaireChange}
                    className="form-control"
                    style={{ width: "150px", fontSize: "12px" }}
                  >
                    {tabJourSemaine.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-7">
                  <label className="font-weight-bold" htmlFor="listAPE">
                    Codes APE possibles / Codes APE retenus
                  </label>
                  <div className="row">
                    <ul className="col-12 row" style={{ listStyle: "none" }}>
                      {dataSociete.listAPENonRepris.map((x) => (
                        <li key={x._id} className="col-3">
                          <button
                            type="button"
                            className="btn btn-link btn-sm text-warning"
                            style={{ fontSize: "12px" }}
                            onClick={() => props.handleAjouteAPEOnClick(x)}
                          >
                            {x.nomInterneAPE}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Table
                    noHeader={true}
                    columns={props.columns}
                    blocs={undefined}
                    data={dataSociete.listAPE}
                    sortColumn={sortColumn}
                  />
                </div>
              </div>
            </div>
            <div className="ml-2 mr-2">
              <button
                className="btn btn-primary mr-2"
                onClick={props.handleSubmitSociete}
                disabled={
                  dataSociete.nom.length === 0 ||
                  dataSociete.formeJuridique.length === 0 ||
                  dataSociete.denomination.length === 0 ||
                  dataSociete.siret.length === 0 ||
                  dataSociete.rcs.length === 0 ||
                  dataSociete.numeroTVA.length === 0 ||
                  dataSociete.tiersId === "0" ||
                  dataSociete.codeAPE.length === 0 ||
                  dataSociete.capital === 0 ||
                  dataSociete.nbPart === 0 ||
                  dataSociete.dateCreation.length === 0 ||
                  dataSociete.dateFinExo.length === 0
                }
              >
                Enregistrer
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={props.handleCancelNewSociete}
              >
                Annuler
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SocieteForm;
