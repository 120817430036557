import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function updateBanoData() {
  return await http.post(urlApiComptabilite + "/latlon/updateBanoData/");
}

// eslint-disable-next-line
export default {
  updateBanoData,
};
