import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function exportImmos(societeId, mois, annee) {
  return await http.get(
    urlApiComptabilite +
      "/immos/exportImmos/" +
      societeId.toString() +
      "/" +
      mois.toString() +
      "/" +
      annee.toString()
  );
}

// eslint-disable-next-line
export default {
  exportImmos,
};
