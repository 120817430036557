import React, { Component } from "react";
import Table from "../common/table";

class ListeTypeEcriture extends Component {
  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <div
          className="w-100 text-nowrap"
          style={{
            fontSize: "14px",
          }}
          id={"type_" + t._id}
        >
          <a
            className="btn btn-link"
            role="button"
            href="#/"
            onClick={() => this.props.onSelectType(t)}
          >
            <i className="fas fa-edit mr-1" style={{ fontSize: "18px" }}></i>
            {t._id}
          </a>
        </div>
      ),
    },
    {
      path: "nom",
      label: "Désignation",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{t.nom}</div>
        </React.Fragment>
      ),
    },
    {
      path: "isPaiementParCheque",
      label: "Paiement par chèque",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          {t.isPaiementParCheque === 1 && (
            <div className={"text-center"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check "
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "isRapproBancaire",
      label: "Rapprochement bancaire",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <div className="text-nowrap">
          {t.isRapproBancaire === 1 && (
            <span className={"mr-2"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check mr-1"
                style={{ fontSize: "14px" }}
              ></i>
              Rappro
            </span>
          )}
          {t.isRapproBancaireDebit === 1 && (
            <span className={"mr-2"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check mr-1"
                style={{ fontSize: "14px" }}
              ></i>
              Débit
            </span>
          )}
          {t.isRapproBancaireCredit === 1 && (
            <span className={"mr-2"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check mr-1"
                style={{ fontSize: "14px" }}
              ></i>
              Crédit
            </span>
          )}
        </div>
      ),
    },
    {
      path: "methodePaiementId",
      label: "Méthode de paiement",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          {t.methodePaiementId && (
            <div style={{ fontSize: "14px" }}>
              {t.methodePaiementId} - {t.methodePaiementDesignation}
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "isOuvertSaisie",
      label: "Saisie manuelle d'écritures autorisée",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          {t.isOuvertSaisie === 1 && (
            <div className={"text-center"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check "
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "contactNomTable",
      label: "Type de compte auxiliaire / saisie d'écritures",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>
            {
              this.props.contactTable.find((x) => x.value === t.contactNomTable)
                ?.name
            }
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "isExclusFEC",
      label: "Exclus du fichier FEC",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          {t.isExclusFEC === 1 && (
            <div className={"text-center"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check "
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "journalCode",
      label: "Code journal FEC (*)",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          {t.journalCode && (
            <div style={{ fontSize: "14px" }}>
              {t.journalCode} - {t.journalLibelle}
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "affecteAuxiAutre",
      label: "Gestion des comptes auxiliaires (FEC)",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (t) => (
        <React.Fragment>
          {t.affecteAuxiAutre === 0 && (
            <div className={"text-center"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check mr-1"
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { typesEcriture, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={typesEcriture}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeTypeEcriture;
