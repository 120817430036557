import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  prepareSocieteFournisseur,
  getListeCommandes,
  traiteSocieteFournisseur,
} from "../../services/produitsFournisseursService";

class EmailCommande extends Component {
  state = {
    loading: true,
    infos: null,
    id_societe: null,
    id_fournisseur: null,
    date: null,
    traitPossible: false,
  };

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const tabSocieteId = params.get("tabSocieteId");
    const societeId = params.get("societeId");
    const fournisseurId = params.get("fournisseurId");
    const date = params.get("date");
    const tabCategorieId = params.get("tabCategorieId");
    const selectedFournisseur = params.get("selectedFournisseur");

    const id_societe = parseInt(societeId);
    const id_fournisseur = parseInt(fournisseurId);
    const tabStringIdSociete = tabSocieteId.split(",");
    const tabIdSociete = [];
    tabStringIdSociete.forEach((x) => tabIdSociete.push(parseInt(x)));
    const tabIdCategorie = [];
    const tabStringIdCategorie = tabCategorieId.split(",");
    tabStringIdCategorie.forEach((x) => tabIdCategorie.push(parseInt(x)));

    this.setState({
      id_societe,
      id_fournisseur,
      date,
      tabSocieteId,
      tabCategorieId,
      selectedFournisseur,
    });
    const produits_fournisseurs = await getListeCommandes({
      tabSocieteId: tabIdSociete,
      tabFournisseurId: [id_fournisseur],
      date,
      tabCategorieId: tabIdCategorie,
    });
    let traitPossible = true;
    let isCdeGroupee = false;
    produits_fournisseurs.data.data.tabSociete.forEach((s) => {
      s.tabFournisseur.forEach((f) => {
        if (f.fournisseur.isCdeGroupee === 1) {
          isCdeGroupee = true;
          if (!f.traitPossible) traitPossible = false;
        }
      });
    });
    if (!isCdeGroupee) {
      const laSociete = produits_fournisseurs.data.data.tabSociete.find(
        (s) => s.societe._id === id_societe
      );
      traitPossible = laSociete.tabFournisseur[0].traitPossible;
    }

    if (produits_fournisseurs.data.success) {
      const infos = await prepareSocieteFournisseur({
        fournisseurId: id_fournisseur,
        societeId: id_societe,
        tabSociete: produits_fournisseurs.data.data.tabSociete,
      });
      if (infos.data.success) {
        this.setState({ infos: infos.data.data, loading: false });
      }
    }
    this.setState({ traitPossible });
  }

  getListeProduits = () => {
    const { infos } = this.state;
    return (
      <>
        <div className="mt-4">
          {infos.infos.tabLignes.map((p) => (
            <div className="email-liste-produits" key={p.produitId}>
              <span>
                {p.cdeLigneQteCommandee} x {p.produitNomCommande}{" "}
                {p.fournisseurCond} {p.produitUniteMesureNom} ref ({p.produitRF}
                )
              </span>
            </div>
          ))}
        </div>
      </>
    );
  };

  getListeSocietes = (texte) => {
    const { infos } = this.state;
    return (
      <>
        <div className="mt-4">
          <div className="mb-2">{texte}</div>
          <ul style={{ listStyleType: "none", paddingInlineStart: "0" }}>
            {infos.infos.tabSociete.map((s) => (
              <div className="email-liste-societes" key={s.societe.societeId}>
                <li>
                  {s.societe.societeDenomination}
                  <ul
                    style={{
                      listStyleType: "none",
                      paddingInlineStart: "20px",
                    }}
                  >
                    {s.listeProduit.map((p) => (
                      <li key={p.cdeLigneId}>
                        <span>
                          {p.cdeLigneQteCommandee} x {p.produitDesignation}
                        </span>
                      </li>
                    ))}
                  </ul>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </>
    );
  };

  sendMail = async () => {
    const { infos, date, tabSocieteId, tabCategorieId, selectedFournisseur } =
      this.state;
    const res = await traiteSocieteFournisseur(infos);
    if (res.data.success) {
      toast.success("Mail envoyé");
      this.props.history.push(
        "/commandes_fournisseurs" +
          "?tabSocieteId=" +
          tabSocieteId +
          "&date=" +
          date +
          "&tabCategorieId=" +
          tabCategorieId +
          "&selectedFournisseur=" +
          selectedFournisseur
      );
    } else toast.error("Une erreur est survenue");
    return;
  };

  render() {
    const {
      infos,
      loading,
      date,
      tabSocieteId,
      tabCategorieId,
      selectedFournisseur,
      traitPossible,
    } = this.state;
    const okMail = infos && infos.infos.tabLignes.length !== 0;
    if (!infos) return null;
    return (
      <>
        <h2 className="text-center">
          Contenu de la commande du{" "}
          {moment(date, "YYYYMMDD").format("DD/MM/YYYY")} pour{" "}
          {infos.infos.fournisseur.nom}
        </h2>
        <div className="text-center mb-3">
          <Link
            role="button"
            className="btn btn-outline-secondary mr-2"
            to={
              "/commandes_fournisseurs" +
              "?tabSocieteId=" +
              tabSocieteId +
              "&date=" +
              date +
              "&tabCategorieId=" +
              tabCategorieId +
              "&selectedFournisseur=" +
              selectedFournisseur
            }
          >
            <i className="fa fa-arrow-left mr-1"></i>
            Retour
          </Link>
          {traitPossible && (
            <Button disabled={!okMail} onClick={() => this.sendMail()}>
              Envoyer le mail au fournisseur
            </Button>
          )}
        </div>

        <div className="mt-2 mb-2 font-weight-bold text-warning">
          {infos.type.includes("multisociete")
            ? "Commande pour toutes les pizzerias"
            : "Commande pour " + infos.infos.societeDenomination}
          {" - "}
          {infos.infos.fournisseur.isEnvoiMail === 1
            ? "Le fournisseur reçoit la commande par mail"
            : "Le fournisseur ne reçoit pas la commande par mail"}
          {" - "}
          {infos.infos.fournisseur.isLivreur === 1
            ? "Le fournisseur livre les pizzerias"
            : "Le fournisseur ne livre pas les pizzerias"}
          {" - "}
          {infos.infos.fournisseur.isStockFactureAuto === 1
            ? "Facturation auto"
            : "Sans facturation auto"}
        </div>
        <div>
          <b>Email fournisseur : </b>
          {infos.infos.to}
        </div>
        {infos.infos.dateEnvoiMail && infos.infos.dateEnvoiMail.length !== 0 && (
          <div className="text-success font-weight-bold">
            Référence du mail : {infos.infos.cleMail} , date/heure d'envoi :{" "}
            {moment(infos.infos.dateEnvoiMail, "YYYYMMDDHHmmSS").format(
              "DD/MM/YYYY HH:mm:SS"
            )}
          </div>
        )}

        <div className="jumbotron jumbotron-fluid mt-2 pt-2 pb-2 pl-2">
          {traitPossible && (
            <React.Fragment>
              <div className="mt-2">{infos.infos.textesMail.cdeTitreMail}</div>
              {infos.infos.fournisseur.isEnvoiMail === 1 && <div>Bonjour,</div>}
              <div>{infos.infos.textesMail.cdeTexteIntroMail}</div>
              {infos.infos.fournisseur.isLivreur === 1 && (
                <div className="mt-2">
                  Adresse de livraison : {infos.infos.societeDenomination}{" "}
                  {infos.infos.adresseLivraison}
                </div>
              )}
            </React.Fragment>
          )}
          {this.getListeProduits()}

          {infos.type.includes("multisociete") &&
            this.getListeSocietes(
              traitPossible
                ? infos.infos.textesMail.cdeTexteIntroRepartitionPizz
                : ""
            )}
          {traitPossible && (
            <div className="mt-2">{infos.infos.textesMail.cdeTexteFinMail}</div>
          )}
        </div>

        {loading && (
          <div className="text-center">
            <span>
              <i className="fa fa-solid fa-spinner fa-spin fa-4x"></i>
            </span>
          </div>
        )}
      </>
    );
  }
}

export default EmailCommande;
