import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import localization from "moment/locale/fr";
import NumberFormat from "react-number-format";
import SelectSearch from "react-select-search";

import { getPizzerias } from "../services/pizzeriaService";
import { getComparaisonBalance } from "../services/balanceService";
import { roundDecimal, minAnneeFec } from "../utils/tools";

moment.locale("fr", localization);
class ComparaisonBalance extends Component {
  state = {
    societesForSelect: [],
    selectedSociete: 0,
    date: new Date(),
    dateDebut: new Date(),
    dateFin: new Date(),
    traitEnCours: false,
    balance: [],
    comptesECAbsentHOP: [],
    totDifference: {},
    uniquementDifference: true,
    fichiers: null,
    shouldShowBox: false,
  };
  async componentDidMount() {
    const result = await getPizzerias();
    let societesForSelect = [];
    result.data.data.map((item) => {
      societesForSelect.push({
        value: item._id.toString(),
        name: item.denomination,
      });
      return true;
    });

    const annee = minAnneeFec();
    const dateDebut = moment(annee.toString() + "0101", "YYYYMMDD").format(
      "YYYY-MM-DD"
    );
    const dateFin = moment(annee.toString() + "1231", "YYYYMMDD").format(
      "YYYY-MM-DD"
    );

    this.setState({
      societesForSelect,
      selectedSociete: societesForSelect[0].value,
      date: moment(dateFin).toDate(),
      dateDebut,
      dateFin,
      shouldShowBox: true,
    });
  }

  handleSocieteSelect = (societe) => {
    this.setState({
      selectedSociete: societe,
    });
  };

  handleDateChange = (day) => {
    let oldDate = this.state.date;
    const laDate = day;
    if (laDate != null) {
      oldDate = day;
      const currentDate = new Date(
        oldDate.getFullYear(),
        oldDate.getMonth(),
        2
      );
      const dateFinMois = moment(currentDate).endOf("month");
      const dateFin = moment()
        .set("year", dateFinMois.year())
        .set("month", dateFinMois.month())
        .set("date", dateFinMois.date())
        .format("YYYY-MM-DD");
      this.setState({
        date: oldDate,
        dateDebut: moment()
          .set("year", dateFinMois.year())
          .set("month", 0)
          .set("date", 1)
          .format("YYYY-MM-DD"),
        dateFin,
      });
    }
  };

  handleValiderClick = async (event) => {
    event.preventDefault();
    const { traitEnCours } = this.state;
    if (!traitEnCours) {
      this.setState({ traitEnCours: true });
      const { selectedSociete, date } = this.state;
      const data = new FormData();
      Array.from(this.state.fichiers).forEach((file) => {
        data.append("file", file);
      });
      const mois = date.getMonth();
      const annee = date.getFullYear();
      const result = await getComparaisonBalance(
        data,
        parseInt(selectedSociete),
        mois + 1,
        annee
      );
      let balanceAffichee = [];
      const totDifference = {};
      totDifference.debitAffiche = 0.0;
      totDifference.creditAffiche = 0.0;
      totDifference.soldeAffiche = 0.0;

      const totCharges = {};
      totCharges.debitAffiche = 0.0;
      totCharges.creditAffiche = 0.0;
      totCharges.soldeAffiche = 0.0;
      totCharges.debitAfficheEC = 0.0;
      totCharges.creditAfficheEC = 0.0;
      totCharges.soldeAfficheEC = 0.0;
      totCharges.sens = "";
      totCharges.sensEC = "";
      const totProduits = {};
      totProduits.debitAffiche = 0.0;
      totProduits.creditAffiche = 0.0;
      totProduits.soldeAffiche = 0.0;
      totProduits.debitAfficheEC = 0.0;
      totProduits.creditAfficheEC = 0.0;
      totProduits.soldeAfficheEC = 0.0;
      totProduits.sens = "";
      totProduits.sensEC = "";
      const resultat = {};
      resultat.montantAffiche = 0.0;
      resultat.sens = "";
      resultat.montantAfficheEC = 0.0;
      resultat.sensEC = "";

      result.data.data.balance.map((x) => {
        const item = x;
        if (x.debit === x.credit) {
          item.debitAffiche = "";
          item.creditAffiche = "";
        } else {
          if (x.debit > x.credit) {
            x.debitAffiche = roundDecimal(x.debit - x.credit);
            x.creditAffiche = "";
          } else {
            x.debitAffiche = "";
            x.creditAffiche = roundDecimal(x.credit - x.debit);
          }
        }
        if (x.debitEC === x.creditEC) {
          item.debitAfficheEC = "";
          item.creditAfficheEC = "";
        } else {
          if (x.debitEC > x.creditEC) {
            x.debitAfficheEC = roundDecimal(
              Number(x.debitEC) - Number(x.creditEC)
            );
            x.creditAfficheEC = "";
          } else {
            x.debitAfficheEC = "";
            x.creditAfficheEC = roundDecimal(
              Number(x.creditEC) - Number(x.debitEC)
            );
          }
        }
        x.differenceDebit = roundDecimal(x.debitAffiche - x.debitAfficheEC);
        x.differenceCredit = roundDecimal(x.creditAffiche - x.creditAfficheEC);
        x.hasDiffence = x.differenceDebit !== x.differenceCredit;

        totDifference.debitAffiche = roundDecimal(
          totDifference.debitAffiche + x.differenceDebit
        );
        totDifference.creditAffiche = roundDecimal(
          totDifference.creditAffiche + x.differenceCredit
        );

        balanceAffichee.push(x);

        if (x.numCompte && x.numCompte.substring(0, 1) === "6") {
          totCharges.debitAffiche = roundDecimal(
            Number(totCharges.debitAffiche) + Number(x.debitAffiche)
          );
          totCharges.creditAffiche = roundDecimal(
            Number(totCharges.creditAffiche) + Number(x.creditAffiche)
          );
          totCharges.debitAfficheEC = roundDecimal(
            Number(totCharges.debitAfficheEC) + Number(x.debitAfficheEC)
          );
          totCharges.creditAfficheEC = roundDecimal(
            Number(totCharges.creditAfficheEC) + Number(x.creditAfficheEC)
          );
        }
        if (x.numCompte && x.numCompte.substring(0, 1) === "7") {
          totProduits.debitAffiche = roundDecimal(
            Number(totProduits.debitAffiche) + Number(x.debitAffiche)
          );
          totProduits.creditAffiche = roundDecimal(
            Number(totProduits.creditAffiche) + Number(x.creditAffiche)
          );
          totProduits.debitAfficheEC = roundDecimal(
            Number(totProduits.debitAfficheEC) + Number(x.debitAfficheEC)
          );
          totProduits.creditAfficheEC = roundDecimal(
            Number(totProduits.creditAfficheEC) + Number(x.creditAfficheEC)
          );
        }
        return true;
      });
      totDifference.soldeAffiche = roundDecimal(
        totDifference.debitAffiche - totDifference.creditAffiche
      );
      if (totCharges.debitAffiche > totCharges.creditAffiche) {
        totCharges.soldeAffiche = roundDecimal(
          totCharges.debitAffiche - totCharges.creditAffiche
        );
        totCharges.sens = "D";
      } else {
        totCharges.soldeAffiche = roundDecimal(
          totCharges.creditAffiche - totCharges.debitAffiche
        );
        totCharges.sens = "C";
      }
      if (totCharges.debitAfficheEC > totCharges.creditAfficheEC) {
        totCharges.soldeAfficheEC = roundDecimal(
          totCharges.debitAfficheEC - totCharges.creditAfficheEC
        );
        totCharges.sensEC = "D";
      } else {
        totCharges.soldeAfficheEC = roundDecimal(
          totCharges.creditAfficheEC - totCharges.debitAfficheEC
        );
        totCharges.sensEC = "C";
      }

      if (totProduits.debitAffiche > totProduits.creditAffiche) {
        totProduits.soldeAffiche = roundDecimal(
          totProduits.debitAffiche - totProduits.creditAffiche
        );
        totProduits.sens = "D";
      } else {
        totProduits.soldeAffiche = roundDecimal(
          totProduits.creditAffiche - totProduits.debitAffiche
        );
        totProduits.sens = "C";
      }

      if (totProduits.debitAfficheEC > totProduits.creditAfficheEC) {
        totProduits.soldeAfficheEC = roundDecimal(
          totProduits.debitAfficheEC - totProduits.creditAfficheEC
        );
        totProduits.sensEC = "D";
      } else {
        totProduits.soldeAfficheEC = roundDecimal(
          totProduits.creditAfficheEC - totProduits.debitAfficheEC
        );
        totProduits.sensEC = "C";
      }

      if (totProduits.soldeAffiche >= totCharges.soldeAffiche) {
        resultat.montantAffiche = roundDecimal(
          totProduits.soldeAffiche - totCharges.soldeAffiche
        );
        resultat.sens = "D";
      } else {
        resultat.montantAffiche = roundDecimal(
          totCharges.soldeAffiche - totProduits.soldeAffiche
        );
        resultat.sens = "C";
      }
      if (totProduits.soldeAfficheEC >= totCharges.soldeAfficheEC) {
        resultat.montantAfficheEC = roundDecimal(
          totProduits.soldeAfficheEC - totCharges.soldeAfficheEC
        );
        resultat.sensEC = "D";
      } else {
        resultat.montantAfficheEC = roundDecimal(
          totCharges.soldeAfficheEC - totProduits.soldeAfficheEC
        );
        resultat.sensEC = "C";
      }
      this.setState({
        traitEnCours: false,
        balance: balanceAffichee,
        comptesECAbsentHOP: result.data.data.comptesECAbsentHOP,
        totDifference,
        totCharges,
        totProduits,
        resultat,
      });
    }
  };

  handleSetFile = async (e) => {
    this.setState({ fichiers: e });
  };
  handleUniquementDifferenceChange = () => {
    const uniquementDifference = this.state.uniquementDifference;
    this.setState({
      uniquementDifference: !uniquementDifference,
    });
  };

  handleGenereUrlGL = (cpt) => {
    const { dateDebut, dateFin } = this.state;
    return (
      "https://hlp-back.fr/ADMIN/admin_index.php?cat=comptabilite&scat=grand_livre&date_from=" +
      dateDebut +
      "&date_to=" +
      dateFin +
      "&id_compte=" +
      cpt._id
    );
  };

  render() {
    const {
      societesForSelect,
      selectedSociete,
      date,
      traitEnCours,
      balance,
      comptesECAbsentHOP,
      totDifference,
      totCharges,
      totProduits,
      resultat,
      fichiers,
      uniquementDifference,
    } = this.state;
    let textH5 = "";
    if (selectedSociete) {
      textH5 =
        societesForSelect.find((x) => x.value === selectedSociete).name +
        " à " +
        moment(date).format("MMMM YYYY");
    }
    if (fichiers && Array.from(fichiers).length !== 0) {
      textH5 = textH5 + " / Fichier : " + Array.from(fichiers)[0].name;
    }
    return (
      <React.Fragment>
        <h2 className="mb-4">Comparaison des balances générales</h2>
        <div className="row">
          <div className="col-2">
            <div>
              <div>
                <label className="font-weight-bold" htmlFor="societes">
                  Société
                </label>
              </div>
              <SelectSearch
                options={societesForSelect}
                value={selectedSociete}
                name="societe"
                search
                onChange={this.handleSocieteSelect}
              />
            </div>
            <div className="mt-2">
              <div>
                <label className="font-weight-bold" htmlFor="mois">
                  Mois{" "}
                </label>
              </div>
              <DatePicker
                className="form-control"
                onChange={(date) => this.handleDateChange(date)}
                placeholder="Sélectionner une date"
                dateFormat="MM/yyyy"
                value={date}
                selected={date instanceof Date ? date : null}
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
            <div className="mt-4">
              <div className="form-group upload">
                <label className="font-weight-bold" htmlFor="file">
                  Fichier Balance de l'EC
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  name="file"
                  accept=".txt"
                  onChange={(e) => this.handleSetFile(e.target.files)}
                />
              </div>
              {(fichiers === null || Array.from(fichiers).length === 0) && (
                <div className="alert alert-danger mt-2">
                  Fichier obligatoire
                </div>
              )}
            </div>

            <div className="mt-2">
              <button
                className="btn btn-primary"
                onClick={this.handleValiderClick}
                disabled={
                  selectedSociete === 0 ||
                  fichiers === null ||
                  Array.from(fichiers).length === 0
                }
              >
                Valider
              </button>
              {traitEnCours && (
                <div className="mt-5 mb-5">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-10">
            <h5 className="mb-2">{textH5}</h5>
            {comptesECAbsentHOP.length !== 0 && (
              <table
                className="table table-striped table-bordered"
                style={{ fontSize: "12px" }}
              >
                <thead>
                  <tr className="row mr-0 ml-0">
                    <th className="col-1">NUM HOP</th>
                    <th className="col-1">NUM EC</th>
                    <th className="col-4">NOM</th>
                    <th className="col-1 text-right">Débit HOP</th>
                    <th className="col-1 text-right">Débit HOP</th>
                    <th className="col-1 text-right">Débit EC</th>
                    <th className="col-1 text-right">Crédit EC</th>
                    <th className="col-1 text-right">Différence débit</th>
                    <th className="col-1 text-right">Différence crédit</th>
                  </tr>
                </thead>
                <tbody>
                  {comptesECAbsentHOP.map((x) => (
                    <tr className="row mr-0 ml-0" key={x.numCompteEC}>
                      <td className="col-1 ">N.A.</td>
                      <td className="col-1 font-weight-bold">
                        {x.numCompteEC}
                      </td>
                      <td className="col-4">{x.nomCompte}</td>
                      <td className="col-1 text-right">N.A.</td>
                      <td className="col-1 text-right">N.A.</td>
                      <td className="col-1 text-right">
                        <NumberFormat
                          value={x.debitEC}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </td>
                      <td className="col-1 text-right">
                        <NumberFormat
                          value={x.creditEC}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </td>
                      <td className="col-1 text-right">N.A.</td>
                      <td className="col-1 text-right">N.A.</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {balance.length !== 0 && (
              <React.Fragment>
                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="sansEmail"
                    checked={uniquementDifference}
                    onChange={this.handleUniquementDifferenceChange}
                  ></input>
                  <label className="form-check-label" htmlFor="sansEmail">
                    Uniquement les comptes avec des différences
                  </label>
                </div>
                <table
                  className="table table-striped table-bordered"
                  style={{ fontSize: "12px" }}
                >
                  <thead>
                    <tr className="row mr-0 ml-0">
                      <th className="col-1">NUM HOP</th>
                      <th className="col-1">NUM EC</th>
                      <th className="col-4">NOM</th>
                      <th className="col-1 text-right">Débit HOP</th>
                      <th className="col-1 text-right">Crédit HOP</th>
                      <th className="col-1 text-right couleurteogest">
                        Débit EC
                      </th>
                      <th className="col-1 text-right couleurteogest">
                        Crédit EC
                      </th>
                      <th className="col-1 text-right text-danger">
                        Différence débit
                      </th>
                      <th className="col-1 text-right text-danger">
                        Différence crédit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {balance
                      .filter(
                        (x) =>
                          (uniquementDifference && x.hasDiffence) ||
                          !uniquementDifference
                      )
                      .map((x, index) => (
                        <tr key={index} className="row mr-0 ml-0">
                          <td className="col-1 font-weight-bold">{x._id}</td>
                          <td className="col-1">{x.numCompteEC}</td>
                          <td className="col-4">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={this.handleGenereUrlGL(x)}
                            >
                              {x.nomCompte}
                            </a>
                          </td>
                          <td className="col-1 text-right">
                            <NumberFormat
                              value={x.debitAffiche}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                            />
                          </td>
                          <td className="col-1 text-right">
                            <NumberFormat
                              value={x.creditAffiche}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                            />
                          </td>
                          <td className="col-1 text-right couleurteogest">
                            <NumberFormat
                              value={x.debitAfficheEC}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                            />
                          </td>
                          <td className="col-1 text-right couleurteogest">
                            <NumberFormat
                              value={x.creditAfficheEC}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                              thousandSeparator=" "
                              displayType="text"
                            />
                          </td>
                          <td className="col-1 text-right text-danger">
                            {x.differenceDebit !== 0 && (
                              <NumberFormat
                                value={x.differenceDebit}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                              />
                            )}
                          </td>
                          <td className="col-1 text-right text-danger">
                            {x.differenceCredit !== 0 && (
                              <NumberFormat
                                value={x.differenceCredit}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                decimalSeparator=","
                                thousandSeparator=" "
                                displayType="text"
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    <tr className="row mr-0 ml-0 font-weight-bold">
                      <td className="col-1"></td>
                      <td className="col-1"> </td>
                      <td className="col-4">
                        <span>Total des différences (Solde : </span>
                        <NumberFormat
                          value={totDifference.soldeAffiche}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                        <span>)</span>
                      </td>
                      <td className="col-1 text-right"></td>
                      <td className="col-1 text-right"></td>
                      <td className="col-1 text-right couleurteogest"></td>
                      <td className="col-1 text-right couleurteogest"></td>
                      <td className="col-1 text-right text-danger">
                        <NumberFormat
                          value={totDifference.debitAffiche}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </td>
                      <td className="col-1 text-right text-danger">
                        <NumberFormat
                          value={totDifference.creditAffiche}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator=" "
                          displayType="text"
                        />
                      </td>
                    </tr>
                    <tr className="row mr-0 ml-0 font-weight-bold">
                      <td className="col-1"></td>
                      <td className="col-1"> </td>
                      <td className="col-4">Comptes de classe 6</td>
                      <td className="col-1 text-right">
                        {totCharges.sens === "D" && (
                          <NumberFormat
                            value={totCharges.soldeAffiche}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right">
                        {totCharges.sens === "C" && (
                          <NumberFormat
                            value={totCharges.soldeAffiche}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right couleurteogest">
                        {totCharges.sensEC === "D" && (
                          <NumberFormat
                            value={totCharges.soldeAfficheEC}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right couleurteogest">
                        {totCharges.sensEC === "C" && (
                          <NumberFormat
                            value={totCharges.soldeAfficheEC}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right text-danger"></td>
                      <td className="col-1 text-right text-danger"></td>
                    </tr>
                    <tr className="row mr-0 ml-0 font-weight-bold">
                      <td className="col-1"></td>
                      <td className="col-1"> </td>
                      <td className="col-4">Comptes de classe 7</td>
                      <td className="col-1 text-right">
                        {totProduits.sens === "D" && (
                          <NumberFormat
                            value={totProduits.soldeAffiche}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right">
                        {totProduits.sens === "C" && (
                          <NumberFormat
                            value={totProduits.soldeAffiche}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right couleurteogest">
                        {totProduits.sensEC === "D" && (
                          <NumberFormat
                            value={totProduits.soldeAfficheEC}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right couleurteogest">
                        {totProduits.sensEC === "C" && (
                          <NumberFormat
                            value={totProduits.soldeAfficheEC}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right text-danger"></td>
                      <td className="col-1 text-right text-danger"></td>
                    </tr>
                    <tr className="row mr-0 ml-0 font-weight-bold">
                      <td className="col-1"></td>
                      <td className="col-1"> </td>
                      <td className="col-4">
                        {"Résultat " +
                          (resultat.sens === "C" ? "(perte)" : "(bénéfice)")}
                      </td>
                      <td className="col-1 text-right">
                        {resultat.sens === "D" && (
                          <NumberFormat
                            value={resultat.montantAffiche}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right">
                        {resultat.sens === "C" && (
                          <NumberFormat
                            value={resultat.montantAffiche}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right couleurteogest">
                        {resultat.sensEC === "D" && (
                          <NumberFormat
                            value={resultat.montantAfficheEC}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right couleurteogest">
                        {resultat.sensEC === "C" && (
                          <NumberFormat
                            value={resultat.montantAfficheEC}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator=" "
                            displayType="text"
                          />
                        )}
                      </td>
                      <td className="col-1 text-right text-danger"></td>
                      <td className="col-1 text-right text-danger"></td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ComparaisonBalance;
