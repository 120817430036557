import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { getAllFournisseursPizztList } from "../../services/contactService";
import { getPizzerias } from "../../services/pizzeriaService";

import moment from "moment";
import { orderBy } from "lodash";
import {
  updateQteCommandeeLigne,
  getListeCommandes,
  getMaxDateInventaire,
  getAllCaisseCategorieProduit,
} from "../../services/produitsFournisseursService";
import Table from "../common/table";
import { makeId } from "../../utils/tools";

class Commande extends Component {
  state = {
    date: "",
    allPizzeriasForSelect: [],
    pizzsForSelect: [],
    fournisseurs: [],
    selectedFournisseur: "-1",
    produitsSansFournisseurs: [],
    societesFournisseursProduits: [],
    categoriesForSelect: [],
    selectedCategories: [],
    tabProduitSansFournisseur: [],
    tabProduitSansCondFournisseur: [],
    stats: {},
    open: null,
    sortColumn: { path: "categoriePos", order: "asc" },
    loading: true,
  };

  columns = [
    {
      path: "cdeLigneQteCommandee",
      label: "Qte à saisir",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <div className="input-group input-group-sm">
          <input
            type="number"
            name={"nomCommande" + makeId()}
            id="nomCommande"
            value={x.cdeLigneQteCommandee}
            className="form-control"
            style={{ fontSize: "12px" }}
            autoComplete={makeId()}
            onChange={(e) => {
              this.handleQteACommanderChange(x, e);
            }}
            disabled={!x.cdeLigneQteModifiable}
          />
          <div className="input-group-append">
            <span
              className="input-group-text"
              onClick={() => this.handleACommanderValid(x)}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-solid fa-check text-primary"></i>
            </span>
          </div>
        </div>
      ),
    },
    {
      path: "cdeLigneQteACommander",
      label: "A cder",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.cdeLigneQteACommander}</span>
      ),
    },
    {
      path: "produitNomCommande",
      label: "Désignation",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.produitNomCommande}</span>
      ),
    },
    {
      path: "prefixeRefF",
      label: "",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.prefixeRefF}</span>,
    },
    {
      path: "produitRF",
      label: "Ref. F",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.produitRF}</span>,
    },
    {
      path: "suffixeRefF",
      label: "",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.suffixeRefF}</span>,
    },
    {
      path: "produitId",
      label: "Id Produit",
      tdClassName: "p-1",
      className: "",
      content: (x) => <span style={{ fontSize: "12px" }}>{x.produitId}</span>,
    },

    {
      path: "categoriePos",
      label: "Catégorie",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.categorieNom}</span>
      ),
    },
    {
      path: "produitUniteMesureAbr",
      label: "Unité",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.produitUniteMesureAbr}</span>
      ),
    },
    {
      path: "fournisseurCond_comma",
      label: "Four. Cond.",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.fournisseurCond_comma}</span>
      ),
    },
    {
      path: "inventaireCond_comma",
      label: "Courses Cond.",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireCond_comma}</span>
      ),
    },
    {
      path: "inventaireNb_comma",
      label: "Courses Nb",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireNb_comma}</span>
      ),
    },
    {
      path: "inventaireBesoin_comma",
      label: "Courses Besoin",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireBesoin_comma}</span>
      ),
    },
    {
      path: "inventaireALivrer_comma",
      label: "Courses A livrer",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.inventaireALivrer_comma}</span>
      ),
    },
    {
      path: "cdeLigneStatut",
      label: "Statut",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>{x.cdeLigneStatut}</span>
      ),
    },
  ];

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const paramTabSocieteId = params.get("tabSocieteId");
    const paramDate = params.get("date");
    const paramTabCategorieId = params.get("tabCategorieId");
    const paramSelectedFournisseur = params.get("selectedFournisseur");

    let dateInventaire = new Date();
    if (!paramDate) {
      const last_date_inventaire = await getMaxDateInventaire();
      dateInventaire = new Date();
      if (last_date_inventaire.data.success) {
        dateInventaire = last_date_inventaire.data.data.dateInventaireAAAAMMJJ;
        this.setState({ date: dateInventaire });
      }
    } else {
      dateInventaire = paramDate;
    }
    this.setState({ date: dateInventaire });
    const res_pizzs = await getPizzerias();

    let tabPizzeriaId = [];
    let pizzsForSelect = [];
    let allPizzeriasForSelect = [];
    res_pizzs.data.data
      .filter((item) => item.hasBesoinPlanning === 1)
      .forEach((item) => {
        allPizzeriasForSelect.push({
          value: item._id.toString(),
          name: item.denomination,
        });
        tabPizzeriaId.push(item._id);
        pizzsForSelect.push(item._id.toString());
      });

    if (paramTabSocieteId && paramTabSocieteId.length !== 0) {
      const tabIdSociete = paramTabSocieteId.split(",");
      tabPizzeriaId = [];
      pizzsForSelect = [];
      tabIdSociete.forEach((x) => {
        tabPizzeriaId.push(parseInt(x));
        pizzsForSelect.push(x);
      });
    }

    let selectedFournisseur = "-1";
    let tabFournisseurId = [];
    const res_fournisseurs = await getAllFournisseursPizztList();
    let fournisseurs = res_fournisseurs.data.data.filter(
      (f) => f.isEnvoiMail === 1
    );
    fournisseurs.forEach((f) => tabFournisseurId.push(f._id));
    fournisseurs = orderBy(fournisseurs, ["name"], ["asc"]);

    fournisseurs.unshift({
      _id: -1,
      nom: "Tous",
    });

    if (res_fournisseurs.data.success) {
      this.setState({
        fournisseurs: fournisseurs,
      });
    }
    if (paramSelectedFournisseur && paramSelectedFournisseur.length !== 0) {
      selectedFournisseur = paramSelectedFournisseur;
    }

    const resultCategorie = await getAllCaisseCategorieProduit();
    let categoriesForSelect = [];
    let selectedCategories = [];
    resultCategorie.data.data.map((item, index) => {
      categoriesForSelect.push({
        value: item._id.toString(),
        name: item.nom,
      });
      selectedCategories.push(item._id.toString());
      return true;
    });

    if (paramTabCategorieId && paramTabCategorieId.length !== 0) {
      const tabIdCategorie = paramTabCategorieId.split(",");
      selectedCategories = [];
      tabIdCategorie.forEach((x) => {
        selectedCategories.push(x);
      });
    }

    await this.handleExecuteValider(
      dateInventaire,
      pizzsForSelect,
      selectedFournisseur,
      tabFournisseurId,
      selectedCategories
    );
    this.setState({
      tabFournisseurId,
      categoriesForSelect,
      selectedCategories,
      tabPizzeriaId,
      allPizzeriasForSelect,
      pizzsForSelect,
      selectedFournisseur,
      loading: false,
    });
  }

  handleValiderClick = async () => {
    const {
      date,
      pizzsForSelect,
      selectedFournisseur,
      tabFournisseurId,
      selectedCategories,
    } = this.state;
    await this.handleExecuteValider(
      date,
      pizzsForSelect,
      selectedFournisseur,
      tabFournisseurId,
      selectedCategories
    );
  };

  handleExecuteValider = async (
    date,
    pizzsForSelect,
    selectedFournisseur,
    tabFournisseurId,
    selectedCategories
  ) => {
    if (!this.state.loading) this.setState({ loading: true });
    let tabId = [];
    if (selectedFournisseur === "-1") {
      tabId = tabFournisseurId;
    } else {
      tabId = [parseInt(selectedFournisseur)];
    }
    const tabSocieteId = [];
    pizzsForSelect.forEach((x) => tabSocieteId.push(parseInt(x)));
    const tabCategorieId = [];
    selectedCategories.forEach((x) => tabCategorieId.push(parseInt(x)));
    const res_produits = await getListeCommandes({
      tabSocieteId,
      date: date,
      tabFournisseurId: tabId,
      tabCategorieId,
    });
    if (res_produits.data.success) {
      this.setState({
        tabProduitSansFournisseur:
          res_produits.data.data.tabProduitSansFournisseur,
        tabProduitSansCondFournisseur:
          res_produits.data.data.tabProduitSansCondFournisseur,
        societesFournisseursProduits: res_produits.data.data.tabSociete,
        stats: res_produits.data.data.stats,
      });
    }
    this.setState({ loading: false });
  };

  isModifiable = (id_pizz, id_fournisseur) => {
    const societe = this.state.societesFournisseursProduits.find(
      (line) => line.societe._id === id_pizz
    );
    if (!societe) return false;
    const fournisseur_index = societe.tabFournisseur.findIndex(
      (line) => line.fournisseur._id === id_fournisseur
    );
    if (fournisseur_index < 0) return false;
    return societe.tabFournisseur[fournisseur_index].traitPossible;
  };

  handleDateChange = async (event) => {
    const day = event.target.valueAsDate;
    if (day !== null) {
      //await this.handleValiderClick(moment(day).format("YYYYMMDD"));
      this.setState({ date: moment(day).format("YYYYMMDD") });
    }
  };

  handleFournisseurSelect = (event) => {
    this.setState({
      selectedFournisseur: event.target.value,
    });
  };

  handleSelectAllCategorieClick = (event) => {
    event.preventDefault();
    const { categoriesForSelect } = this.state;
    let selectedCategories = [];
    categoriesForSelect.map((item) => {
      selectedCategories.push(item.value);
      return true;
    });
    this.setState({
      selectedCategories,
    });
  };

  handleUnSelectAllCategorieClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedCategories: [],
    });
  };

  handleCategoriesSelect = (categories) => {
    this.setState({
      selectedCategories: categories,
    });
  };

  handleSelectAllPizzClick = (event) => {
    event.preventDefault();
    const { allPizzeriasForSelect } = this.state;
    let pizzsForSelect = [];
    allPizzeriasForSelect.map((item) => {
      pizzsForSelect.push(item.value);
      return true;
    });
    this.setState({
      pizzsForSelect,
    });
  };

  handleUnSelectAllPizzClick = (event) => {
    event.preventDefault();
    this.setState({
      pizzsForSelect: [],
    });
  };

  handlePizzsSelect = (pizzs) => {
    this.setState({
      pizzsForSelect: pizzs,
    });
  };

  toggleAccordion = (key) => {
    this.setState({ open: key === this.state.open ? null : key });
  };

  isOpen = (key) => {
    return key === this.state.open;
  };

  handleQteACommanderChange = async (item, event) => {
    const { societesFournisseursProduits } = this.state;
    const laSociete = societesFournisseursProduits.find((x) => {
      return x.societe._id === item.societeId;
    });

    if (laSociete) {
      const leFournisseur = laSociete.tabFournisseur.find(
        (x) => x.fournisseur._id === item.fournisseurId
      );
      if (leFournisseur) {
        const leProduit = leFournisseur.tabProduit.find(
          (x) => x.produitId === item.produitId
        );
        if (leProduit) {
          item.cdeLigneQteCommandee = event.target.value;
          this.setState({ societesFournisseursProduits });
        }
      }
    }
  };

  handleACommanderValid = async (item) => {
    await updateQteCommandeeLigne({
      cdeLigneId: item.cdeLigneId,
      cdeLigneQteCommandee: item.cdeLigneQteCommandee,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getListeProduits = (id_pizz, id_fournisseur) => {
    const { sortColumn, loading } = this.state;
    if (loading) return null;
    if (!this.state.societesFournisseursProduits)
      return <div>Aucun produit</div>;
    const societe = this.state.societesFournisseursProduits.find(
      (line) => line.societe._id === id_pizz
    );
    if (!societe) return <div>Société non trouvée</div>;
    const fournisseur = societe.tabFournisseur.find(
      (line) => line.fournisseur._id === id_fournisseur
    );
    if (!fournisseur) return <div>Fournisseur introuvable</div>;
    if (!this.columns) return <div>columns introuvable</div>;
    const produits = fournisseur.tabProduit;

    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          data={produits}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </React.Fragment>
    );

    // return (
    //   <div>
    //     {produits.map((p) => (
    //       <div
    //         key={`${id_pizz}-${id_fournisseur}-${p.produitId}`}
    //         className="liste-produits"
    //       >
    //         <span>
    //           {p.produitId} - {p.produitDesignation}
    //         </span>
    //         <span>{p.produitRF && <>Ref: {p.produitRF}</>}</span>
    //         <span>Inventaire: {p.inventaireNb}</span>
    //         <span>
    //           Cdt frs: {p.fournisseurCond} {p.produitUniteMesureAbr}
    //         </span>
    //         <div>
    //           <label className="font-weight-bold" htmlFor="a_commander">
    //             A commander:
    //           </label>
    //           <span className="ml-3">
    //             {p.cdeLigneQteACommander}{" "}
    //             {p.cdeLigneQteModifiable && <>{"/"}</>}
    //           </span>

    //           {p.cdeLigneQteModifiable ? (
    //             <input
    //               type="number"
    //               className="form-control-cmd"
    //               name="a_commander"
    //               onChange={(e) =>
    //                 this.handleChangeACommander(
    //                   id_pizz,
    //                   id_fournisseur,
    //                   p.produitId,
    //                   e
    //                 )
    //               }
    //               defaultValue={p.cdeLigneQteCommandee}
    //             />
    //           ) : (
    //             <span></span>
    //           )}
    //           {this.isChangedACommander(
    //             id_pizz,
    //             id_fournisseur,
    //             p.produitId,
    //             p.cdeLigneQteCommandee
    //           ) && (
    //             <>
    //               {this.validating(p.cdeLigneId) ? (
    //                 <span>
    //                   <i className="fa fa-solid fa-spinner fa-spin"></i>
    //                 </span>
    //               ) : (
    //                 <button
    //                   className="btn btn-sm btn-success"
    //                   onClick={() => this.validQte(id_pizz, id_fournisseur, p)}
    //                 >
    //                   <i className="fa fa-solid fa-check"></i>
    //                 </button>
    //               )}
    //             </>
    //           )}
    //           <span className="ml-2" style={{ fontSize: "10px" }}>
    //             Statut : {p.cdeLigneStatut}
    //           </span>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // );
  };

  getNbProduits = (id_pizz, id_fournisseur) => {
    let ret = null;
    const societe = this.state.societesFournisseursProduits.find(
      (line) => line.societe._id === id_pizz
    );
    if (!societe) return ret;
    const fournisseur = societe.tabFournisseur.find(
      (line) => line.fournisseur._id === id_fournisseur
    );
    if (!fournisseur) return ret;
    return (
      <React.Fragment>
        <div style={{ float: "left", width: "150px" }}>
          {fournisseur.nbProduitsRetenus} / {fournisseur.nbProduits} produits
          retenus
        </div>
        {fournisseur.fournisseur.isEnvoiMail === 1 && (
          <React.Fragment>
            <div style={{ float: "left", width: "55px" }}>
              <i
                className="fas fa-paper-plane ml-2 mr-2"
                style={{ fontSize: "20px" }}
              ></i>
              {fournisseur.nbProduitsMailEnvoye}
            </div>

            <div style={{ float: "left", width: "55px" }}>
              <i
                className="fas fa-inbox ml-2 mr-2"
                style={{ fontSize: "20px" }}
              ></i>
              {fournisseur.nbProduitsMailRecu}
            </div>
          </React.Fragment>
        )}
        {fournisseur.fournisseur.isStockFactureAuto === 1 && (
          <div style={{ float: "left", width: "55px" }}>
            <i
              className="fas fa-file-invoice ml-2 mr-2"
              style={{ fontSize: "20px" }}
            ></i>
            {fournisseur.nbProduitsMajStock}
          </div>
        )}
      </React.Fragment>
    );
  };

  getListSociete = () => {
    let ret = "";
    this.state.pizzsForSelect.map((p) => {
      if (ret.length === 0) {
        ret = parseInt(p);
      } else {
        ret = ret + "," + parseInt(p);
      }
      return ret;
    });
    return ret;
  };

  getListCategorie = () => {
    let ret = "";
    this.state.selectedCategories.map((p) => {
      if (ret.length === 0) {
        ret = parseInt(p);
      } else {
        ret = ret + "," + parseInt(p);
      }
      return ret;
    });

    return ret;
  };

  render() {
    const {
      date,
      fournisseurs,
      selectedFournisseur,
      societesFournisseursProduits,
      categoriesForSelect,
      selectedCategories,
      allPizzeriasForSelect,
      pizzsForSelect,
      tabProduitSansFournisseur,
      tabProduitSansCondFournisseur,
      stats,
      loading,
    } = this.state;
    const laDate = moment(date).format("YYYYMMDD");

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <h2 className="text-center">Commandes fournisseur</h2>
          </div>
          <div className="row">
            <div className="d-none d-md-block col-2">
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={() => this.handleValiderClick()}
                >
                  Valider la sélection
                </button>
              </div>
              <div className="mt-3">
                <label htmlFor="date" className="font-weight-bold">
                  Date :
                </label>
                <input
                  type="date"
                  value={moment(date).format("YYYY-MM-DD")}
                  onChange={(date) => this.handleDateChange(date)}
                  className="ml-2"
                ></input>
              </div>
              <div className="mt-2">
                <label htmlFor="fournisseurs" className="font-weight-bold">
                  Fournisseur :
                </label>
                <select
                  name="fournisseurs"
                  id="fournisseurs"
                  value={selectedFournisseur}
                  onChange={this.handleFournisseurSelect}
                  className="form-control"
                >
                  {fournisseurs.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.nom}
                    </option>
                  ))}
                </select>
              </div>

              <div
                className="mt-3"
                style={{ height: "500px", marginBottom: 0 }}
              >
                <div>
                  <label htmlFor="societes" className="font-weight-bold">
                    Pizzerias sélectionnées :
                  </label>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline-secondary"
                    style={{ fontSize: "12px" }}
                    onClick={this.handleSelectAllPizzClick}
                  >
                    Tout sélectionner
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    style={{ fontSize: "12px" }}
                    onClick={this.handleUnSelectAllPizzClick}
                  >
                    Tout désélectionner
                  </button>
                </div>
                <div style={{ marginTop: "-30px" }}>
                  <SelectSearch
                    options={allPizzeriasForSelect}
                    value={pizzsForSelect}
                    name="pizzeria"
                    multiple
                    onChange={this.handlePizzsSelect}
                  />
                </div>
              </div>
              <div
                className="mt-3"
                style={{ height: "450px", marginBottom: 0 }}
              >
                <div>
                  <label htmlFor="societes" className="font-weight-bold">
                    Catégories sélectionnées :
                  </label>
                </div>
                <div className="btn-group">
                  <button
                    className="btn btn-outline-secondary"
                    style={{ fontSize: "12px" }}
                    onClick={this.handleSelectAllCategorieClick}
                  >
                    Tout sélectionner
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    style={{ fontSize: "12px" }}
                    onClick={this.handleUnSelectAllCategorieClick}
                  >
                    Tout désélectionner
                  </button>
                </div>
                <div style={{ marginTop: "-30px" }}>
                  <SelectSearch
                    options={categoriesForSelect}
                    value={selectedCategories}
                    name="categorie"
                    multiple
                    onChange={this.handleCategoriesSelect}
                  />
                </div>
              </div>
            </div>
            <div className="col-10">
              {!loading && tabProduitSansFournisseur.length > 0 && (
                <div
                  className="row alert alert-warning"
                  style={{ fontSize: "14px" }}
                >
                  <div className="col-12">Produit(s) sans fournisseur :</div>
                  <ul className="col-12">
                    {tabProduitSansFournisseur.map((p) => (
                      <li key={p._id}>
                        {p._id} {p.designation}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {!loading && tabProduitSansCondFournisseur.length > 0 && (
                <div
                  className="row alert alert-warning"
                  style={{ fontSize: "14px" }}
                >
                  <div className="col-12">
                    Produit(s) avec un conditionnement fournisseur à 0 :
                  </div>
                  <ul className="col-12">
                    {tabProduitSansCondFournisseur.map((p) => (
                      <li key={p._id}>
                        {p._id} {p.designation}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {!loading && (
                <div className="row">
                  <div className="col-3 ml-2">
                    Total : {stats.nbProduitsRetenus} / {stats.nbProduits}{" "}
                    produits retenus
                  </div>
                  <div
                    className={
                      "col-2 " +
                      (stats.nbProduitsMailEnvoye ===
                      stats.nbProduitsRetenusMailEnvoye
                        ? "text-success"
                        : "text-warning")
                    }
                  >
                    <i
                      className={"fas fa-paper-plane ml-2 mr-2 "}
                      style={{ fontSize: "20px" }}
                    ></i>
                    {stats.nbProduitsMailEnvoye} /{" "}
                    {stats.nbProduitsRetenusMailEnvoye}
                  </div>
                  <div
                    className={
                      "col-2 " +
                      (stats.nbProduitsMailRecu ===
                      stats.nbProduitsRetenusMailRecu
                        ? "text-success"
                        : "text-warning")
                    }
                  >
                    <i
                      className="fas fa-inbox ml-2 mr-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                    {stats.nbProduitsMailRecu} /{" "}
                    {stats.nbProduitsRetenusMailRecu}
                  </div>
                  <div
                    className={
                      "col-2 " +
                      (stats.nbProduitsMajStock ===
                      stats.nbProduitsRetenusMajStock
                        ? "text-success"
                        : "text-warning")
                    }
                  >
                    <i
                      className="fas fa-file-invoice ml-2 mr-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                    {stats.nbProduitsMajStock} /{" "}
                    {stats.nbProduitsRetenusMajStock}
                  </div>
                </div>
              )}
              <div className="commandes-fournisseurs py-2">
                <div className="mt-4">
                  {loading ? (
                    <div className="text-center">
                      <span>
                        <i className="fa fa-solid fa-spinner fa-spin fa-4x"></i>
                      </span>
                    </div>
                  ) : (
                    <>
                      {societesFournisseursProduits.length > 0 &&
                        societesFournisseursProduits.map((ps, idx) => (
                          <div
                            className={`table-commandes-fournisseurs ${
                              idx === societesFournisseursProduits.length - 1 &&
                              "last"
                            } px-0`}
                            key={`${ps.societe._id}_pizzs`}
                          >
                            <div className="pizz-name">
                              <span className="font-weight-bold">
                                {ps.societe.denominationCourte}
                              </span>
                            </div>
                            <div className="pizz-fournisseurs">
                              {ps.tabFournisseur.length > 0 &&
                                ps.tabFournisseur.map((fs) => (
                                  <Card
                                    key={`${fs.fournisseur._id}_fournisseur`}
                                  >
                                    <Card.Header className="header-fournisseurs">
                                      <div
                                        onClick={() =>
                                          this.toggleAccordion(
                                            `${ps.societe._id}-${fs.fournisseur._id}`
                                          )
                                        }
                                      >
                                        <span className="clickable">
                                          {fs.fournisseur.nom}
                                        </span>
                                        <span
                                          className={
                                            this.isOpen(
                                              `${ps.societe._id}-${fs.fournisseur._id}`
                                            )
                                              ? ""
                                              : "d-none"
                                          }
                                        >
                                          <i className="fa fa-chevron-down ml-2"></i>
                                        </span>
                                        <span
                                          className={
                                            this.isOpen(
                                              `${ps.societe._id}-${fs.fournisseur._id}`
                                            )
                                              ? "d-none"
                                              : ""
                                          }
                                        >
                                          <i className="fa fa-chevron-right ml-2"></i>
                                        </span>
                                      </div>
                                      <div style={{ fontSize: "14px" }}>
                                        {this.getNbProduits(
                                          ps.societe._id,
                                          fs.fournisseur._id
                                        )}
                                      </div>

                                      <Link
                                        className={
                                          "btn " +
                                          (this.isModifiable(
                                            ps.societe._id,
                                            fs.fournisseur._id
                                          )
                                            ? "btn-primary"
                                            : "btn-outline-secondary")
                                        }
                                        to={
                                          "/email_commande_fournisseur" +
                                          "?tabSocieteId=" +
                                          this.getListSociete() +
                                          "&societeId=" +
                                          ps.societe._id.toString() +
                                          "&fournisseurId=" +
                                          fs.fournisseur._id.toString() +
                                          "&date=" +
                                          laDate +
                                          "&tabCategorieId=" +
                                          this.getListCategorie() +
                                          "&selectedFournisseur=" +
                                          selectedFournisseur
                                        }
                                      >
                                        {"Commander / " +
                                          (fs.fournisseur.isCdeGroupee === 1
                                            ? "Toutes les sociétés"
                                            : "La société")}
                                      </Link>
                                    </Card.Header>
                                    <Card.Body
                                      className={
                                        this.isOpen(
                                          `${ps.societe._id}-${fs.fournisseur._id}`
                                        )
                                          ? ""
                                          : "d-none"
                                      }
                                    >
                                      {!loading &&
                                        this.getListeProduits(
                                          ps.societe._id,
                                          fs.fournisseur._id
                                        )}
                                    </Card.Body>
                                  </Card>
                                ))}
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Commande;
