import http from "./httpService";
import { urlApiGeneral } from "../config.js";
import auth from "./authService";

export async function getAdminArbro() {
  const name = "arboAdmin";
  if (sessionStorage.getItem(name)) {
    const result = await JSON.parse(sessionStorage.getItem(name));
    return result;
  } else {
    const result = await http.get(
      urlApiGeneral + "/maps/adminarbo/+" + auth.getCurrentUser()._id
    );
    sessionStorage.setItem(name, JSON.stringify(result));
    return JSON.parse(sessionStorage.getItem(name));
  }
}

export async function getAdminFlat() {
  const name = "flatAdmin";
  if (sessionStorage.getItem(name)) {
    const result = await JSON.parse(sessionStorage.getItem(name));
    return result;
  } else {
    const retAdminArbo = await getAdminArbro();
    let tabArbo = [];
    retAdminArbo.data.data.map((item) => {
      if (item.children) {
        item.children.map((i) => {
          tabArbo.push(i);
          return true;
        });
      }
      return true;
    });
    sessionStorage.setItem(name, JSON.stringify(tabArbo));
    return JSON.parse(sessionStorage.getItem(name));
  }
}

export function isMenuAuthorized(menu) {
  const name = "flatAdmin";
  const retAdminFlat = JSON.parse(sessionStorage.getItem(name));
  let ret = false;
  if (retAdminFlat && retAdminFlat.find((item) => item.suffixeUrl === menu)) {
    ret = true;
  }
  return ret;
}
