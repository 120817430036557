import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";

const BlocCapitalSociete = (props) => {
  const { societe } = props;

  return (
    <React.Fragment>
      <div className="row mt-2">
        <div className="col-6">
          <label>Capital</label>
          <NumberFormat
            value={societe.capital}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix={" €"}
            className="ml-1"
          />
        </div>
        <div className="col-6">
          <label>Nb parts</label>
          <NumberFormat
            value={societe.nbPart}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            className="ml-1"
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-12">
          <label>Date de création</label>{" "}
          {moment(societe.dateCreationAAAAMMJJ, "YYYYMMDD").format(
            "DD/MM/YYYY"
          )}
        </div>
      </div>
      {societe.dateOuvertureClientAAAAMMJJ && (
        <div className="row mt-1">
          <div className="col-12">
            <label>Ouverture le</label>{" "}
            {moment(societe.dateOuvertureClientAAAAMMJJ, "YYYYMMDD").format(
              "DD/MM/YYYY"
            )}
          </div>
        </div>
      )}
      {societe.dateFinExoAAAAMMJJ_1 && (
        <div className="row mt-1">
          <div className="col-12">
            <label>Fin du 1er exercice</label>{" "}
            {moment(societe.dateFinExoAAAAMMJJ_1, "YYYYMMDD").format(
              "DD/MM/YYYY"
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default BlocCapitalSociete;
