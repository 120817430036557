import React, { Component } from "react";
import Table from "../common/table";

class ListeMethodesPaiement extends Component {
  columns = [
    {
      path: "_id",
      label: "Id",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (p) => (
        <React.Fragment>
          <div
            className="w-100"
            style={{
              fontSize: "14px",
            }}
          >
            <a
              className="btn btn-link"
              role="button"
              href="#/"
              onClick={() => this.props.onSelectCodeJournal(p)}
            >
              <i className="fas fa-edit mr-3" style={{ fontSize: "18px" }}></i>
              {p._id}
            </a>
          </div>
        </React.Fragment>
      ),
    },
    {
      path: "designation",
      label: "Désignation",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{j.designation}</div>
        </React.Fragment>
      ),
    },
    {
      path: "abreviation",
      label: "Abréviation",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{j.abreviation}</div>
        </React.Fragment>
      ),
    },
    {
      path: "parent",
      label: "Méthode parente",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          {j.parentId !== 0 && (
            <div style={{ fontSize: "14px" }}>
              {j.parentId} - {j.parentDesignation}
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "isCaisseActif",
      label: "Utilisable dans la Caisse",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          {j.isCaisseActif === 1 && (
            <div className={"text-center"} style={{ fontSize: "14px" }}>
              <i
                className="fa fa-solid fa-check "
                style={{ fontSize: "14px" }}
              ></i>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "texteReleveBanque",
      label: "Texte à rechercher / import des relevés de banque",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          <div style={{ fontSize: "14px" }}>{j.texteReleveBanque}</div>
        </React.Fragment>
      ),
    },
    {
      path: "compteAEncaisserId",
      label: "Compte d'encaissement à imputer",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          {j.compteAEncaisserId && parseInt(j.compteAEncaisserId) !== 0 && (
            <div
              className={
                j.compteAEncaisserLibelle === null
                  ? "font-weight-bold text-danger"
                  : ""
              }
              style={{ fontSize: "14px" }}
            >
              {j.compteAEncaisserId}
              {" - "}
              {j.compteAEncaisserLibelle === null
                ? "Compte inexistant"
                : j.compteAEncaisserLibelle}
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "compteErreurId",
      label: "Compte à imputer en cas d'erreur",
      tdClassName: "p-1",
      className: "col-1 p-0",
      content: (j) => (
        <React.Fragment>
          {j.compteErreurId && parseInt(j.compteErreurId) !== 0 && (
            <div
              className={
                j.compteErreurLibelle === null
                  ? "font-weight-bold text-danger"
                  : ""
              }
              style={{ fontSize: "14px" }}
            >
              {j.compteErreurId}
              {" - "}
              {j.compteErreurLibelle === null
                ? "Compte inexistant"
                : j.compteErreurLibelle}
            </div>
          )}
        </React.Fragment>
      ),
    },
  ];
  render() {
    const { methodesPaiement, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        blocs={this.blocs}
        data={methodesPaiement}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ListeMethodesPaiement;
