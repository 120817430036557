import React, { Component } from "react";
import SelectSearch from "react-select-search";
import NumberFormat from "react-number-format";
import _ from "lodash";

import {
  getAllScanRelevesCompteBanque,
  addScanReleveCompte,
  updateScanReleveCompte,
} from "../../services/parametresService";
import { getFullTypesEcriture } from "../../services/saisieService";
import { getAllContactList } from "../../services/contactService";

import BarreMenu from "../common/barreMenu";
import ListeScanRelevesCompte from "./listeScanRelevesCompte";
import {
  typeMenuParametresBanque,
  menuScanRelevesComptesBanque,
} from "../../utils/menuParametres";
import { replaceAll } from "../../utils/tools";

class ScanRelevesCompteBanque extends Component {
  state = {
    scans: [],
    typesEcritureForSelect: [],
    societesForSelect: [],
    tabContactForSelect: [],
    sortColumn: { path: "_id", order: "asc" },
    newParam: false,
    dataParam: {
      _id: -1,
      commentaire: "",
      typeEcritureId: "-1",
      contactDefautId: "-1",
      string1: "",
      positionString1: "",
      sousString1: "",
      string2: "",
      positionString2: "",
      sousString2: "",
      isAutoConfirme: false,
      contactNomTable: "",
    },
    selectedParam: -1,
  };

  async componentDidMount() {
    const dataScan = await getAllScanRelevesCompteBanque();
    const result = await getFullTypesEcriture();
    const typesEcritureForSelect = [];
    typesEcritureForSelect.push({ value: "-1", name: "" });
    result.data.data.forEach((item) => {
      typesEcritureForSelect.push({
        value: item._id.toString(),
        name: item._id.toString() + " - " + item.nom,
        contactNomTable: item.contactNomTable,
      });
    });
    const tabContactForSelect = [];
    tabContactForSelect.push({ value: "-1", name: "" });
    const dataContact = await getAllContactList();
    dataContact.data.data.forEach((x) => {
      tabContactForSelect.push({
        value: x._id.toString(),
        name: x._id.toString() + " - " + x.nom,
      });
    });

    this.setState({
      scans: dataScan.data.data,
      typesEcritureForSelect,
      tabContactForSelect,
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { scans, sortColumn } = this.state;
    let filtered = scans;
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    return { totalCount: filtered.length, data: sorted };
  };

  handleNewParam = () => {
    const { newParam, dataParam } = this.state;
    dataParam._id = -1;
    dataParam.commentaire = "";
    dataParam.typeEcritureId = "-1";
    dataParam.contactNomTable = "";
    dataParam.contactDefautId = null;
    dataParam.string1 = "";
    dataParam.positionString1 = "";
    dataParam.sousString1 = "";
    dataParam.string2 = "";
    dataParam.positionString2 = "";
    dataParam.sousString2 = "";
    dataParam.isAutoConfirme = false;
    this.setState({
      newParam: !newParam,
      dataParam,
    });
  };

  handleCancelNewParam = () => {
    const { newParam, selectedParam } = this.state;
    if (!newParam && selectedParam !== -1) {
      const releventDiv = document.getElementById("param_" + selectedParam);
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
    this.setState({
      newParam: false,
      selectedParam: -1,
    });
  };

  handleTypeEcritureSelect = async (typeEcritureId) => {
    const { dataParam, typesEcritureForSelect } = this.state;
    dataParam.typeEcritureId = typeEcritureId;
    dataParam.contactNomTable = "";
    const leTypeEcriture = typesEcritureForSelect.find(
      (x) => x.value === typeEcritureId
    );
    dataParam.contactNomTable = leTypeEcriture
      ? leTypeEcriture.contactNomTable
      : "";
    this.setState({
      dataParam,
    });
  };

  handleContactSelect = (contactDefautId) => {
    const { dataParam } = this.state;
    dataParam.contactDefautId = contactDefautId;
    this.setState({ dataParam });
  };

  handleContactChange = async (event) => {
    const { dataParam } = this.state;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    if (formattedValue.length !== 0) {
      dataParam.contactDefautId = Number(formattedValue).toString();
    } else {
      dataParam.contactDefautId = "";
    }
    this.setState({ dataParam });
  };

  handleCommentaireChange = async (event) => {
    const { dataParam } = this.state;
    dataParam.commentaire = event.target.value;
    this.setState({
      dataParam,
    });
  };

  handleIsAutoConfirmeChange = () => {
    const { dataParam } = this.state;
    dataParam.isAutoConfirme = !dataParam.isAutoConfirme;
    this.setState({ dataParam });
  };

  handleString1Change = async (event) => {
    const { dataParam } = this.state;
    dataParam.string1 = event.target.value;
    this.setState({
      dataParam,
    });
  };

  handleSousString1Change = async (event) => {
    const { dataParam } = this.state;
    dataParam.sousString1 = event.target.value;
    this.setState({
      dataParam,
    });
  };

  handleString2Change = async (event) => {
    const { dataParam } = this.state;
    dataParam.string2 = event.target.value;
    this.setState({
      dataParam,
    });
  };

  handlePositionString1Change = async (event) => {
    const { dataParam } = this.state;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    if (formattedValue.length !== 0) {
      dataParam.positionString1 = Number(formattedValue).toString();
    } else {
      dataParam.positionString1 = "";
    }
    this.setState({ dataParam });
  };

  handlePositionString2Change = async (event) => {
    const { dataParam } = this.state;
    let formattedValue = event.target.value;
    formattedValue = replaceAll(formattedValue, " ", "");
    formattedValue = replaceAll(formattedValue, ",", ".");
    if (formattedValue.length !== 0) {
      dataParam.positionString2 = Number(formattedValue).toString();
    } else {
      dataParam.positionString2 = "";
    }
    this.setState({ dataParam });
  };

  handleSousString2Change = async (event) => {
    const { dataParam } = this.state;
    dataParam.sousString2 = event.target.value;
    this.setState({
      dataParam,
    });
  };

  onSelectParam = (param) => {
    const { selectedParam, newParam } = this.state;
    if (selectedParam === -1 && !newParam) {
      const dataParam = {};
      dataParam._id = param._id;
      dataParam.commentaire = param.commentaire;
      dataParam.typeEcritureId = param.typeEcritureId.toString();
      dataParam.contactDefautId = param.contactDefautId
        ? param.contactDefautId.toString()
        : "-1";
      dataParam.contactNomTable = param.contactNomTable;
      dataParam.isAutoConfirme = param.isAutoConfirme === 1;
      dataParam.string1 = param.string1;
      dataParam.positionString1 = param.positionString1;
      dataParam.sousString1 = param.sousString1;
      dataParam.string2 = param.string2;
      dataParam.positionString2 = param.positionString2;
      dataParam.sousString2 = param.sousString2;
      this.setState({
        selectedParam: param._id,
        dataParam,
      });

      const releventDiv = document.getElementById("top-id");
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
  };

  handleSubmitParam = async () => {
    const { selectedParam, newParam } = this.state;
    if (newParam && selectedParam === -1) {
      await this.traiteNewParam();
    } else {
      if (!newParam && selectedParam !== -1) {
        await this.traiteUpdateParam();
      }
    }
  };

  traiteNewParam = async () => {
    const { dataParam } = this.state;
    const retAdd = await addScanReleveCompte(dataParam);
    const dataScans = await getAllScanRelevesCompteBanque();
    this.setState({
      scans: dataScans.data.data,
      newParam: false,
    });
    if (
      retAdd.data.success &&
      retAdd.data.data.insertId &&
      retAdd.data.data.insertId !== 0
    ) {
      const releventDiv = document.getElementById(
        "param_" + retAdd.data.data.insertId
      );
      if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    }
  };

  traiteUpdateParam = async () => {
    const { selectedParam, dataParam } = this.state;
    await updateScanReleveCompte(selectedParam, dataParam);
    const dataScans = await getAllScanRelevesCompteBanque();

    const releventDiv = document.getElementById("param_" + selectedParam);
    if (releventDiv) releventDiv.scrollIntoView({ behavior: "auto" });
    this.setState({
      scans: dataScans.data.data,
      newParam: false,
      selectedParam: -1,
    });
  };

  render() {
    const {
      sortColumn,
      scans,
      newParam,
      dataParam,
      selectedParam,
      typesEcritureForSelect,
      tabContactForSelect,
    } = this.state;

    const { totalCount, data } = this.getPagedData();

    return (
      <React.Fragment>
        <span id="top-id"></span>
        <BarreMenu
          type_menu={typeMenuParametresBanque}
          menu_actif={menuScanRelevesComptesBanque}
        ></BarreMenu>
        <div className="row">
          <div className="col-12">
            {!newParam && selectedParam === -1 && (
              <button
                onClick={this.handleNewParam}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newParam}
              >
                Nouveau paramétrage
              </button>
            )}
            <div>
              {(newParam || selectedParam !== -1) && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newParam
                      ? "Nouveau paramétrage"
                      : "Modification du paramétrage Id " + dataParam._id}
                  </h5>
                  <div className="ml-2 mr-2" style={{ fontSize: "14px" }}>
                    <div className="form-group row mb-0">
                      <div className="col-4">
                        <label
                          className="font-weight-bold"
                          htmlFor="commentaire"
                        >
                          Commentaire
                        </label>
                        <input
                          type="text"
                          value={dataParam.commentaire}
                          onChange={(event) =>
                            this.handleCommentaireChange(event)
                          }
                          className="form-control"
                          placeholder="Commentaire"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>
                      <div className="col-3">
                        <label
                          className="font-weight-bold"
                          htmlFor="typeEcritureId"
                        >
                          Type d'écriture
                        </label>
                        <SelectSearch
                          options={typesEcritureForSelect}
                          value={dataParam.typeEcritureId}
                          name="typeEcritureId"
                          search={true}
                          onChange={this.handleTypeEcritureSelect}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                      {dataParam.contactNomTable === "ACHAT_FOURNISSEUR" && (
                        <div className="col-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="contactDefautId"
                          >
                            Contact
                          </label>
                          <SelectSearch
                            options={tabContactForSelect}
                            value={dataParam.contactDefautId}
                            name="contactDefautId"
                            search={true}
                            onChange={this.handleContactSelect}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      )}
                      {dataParam.contactNomTable !== "ACHAT_FOURNISSEUR" && (
                        <div className="col-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="contactDefautId"
                          >
                            Id Contact
                          </label>
                          <NumberFormat
                            className="form-control text-right"
                            value={dataParam.contactDefautId}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator={false}
                            allowNegative={false}
                            onChange={(e, value) => {
                              this.handleContactChange(e, value);
                            }}
                            style={{ width: "100px", fontSize: "14px" }}
                            placeholder={"Id contact"}
                          />
                        </div>
                      )}
                      <div className="col-2">
                        <div className="form-check mt-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isAutoConfirme"
                            checked={dataParam.isAutoConfirme}
                            onChange={this.handleIsAutoConfirmeChange}
                          ></input>
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="isAutoConfirme"
                          >
                            Confirmation automatique
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mt-0">
                      <div className="col-4">
                        <label className="font-weight-bold" htmlFor="string1">
                          Texte 1
                        </label>
                        <input
                          type="text"
                          value={dataParam.string1}
                          onChange={(event) => this.handleString1Change(event)}
                          className="form-control"
                          placeholder="Texte"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>

                      <React.Fragment>
                        <div className="col-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="positionString1"
                          >
                            Position du texte
                          </label>
                          <NumberFormat
                            className="form-control text-right"
                            value={dataParam.positionString1}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator={false}
                            allowNegative={false}
                            onChange={(e, value) => {
                              this.handlePositionString1Change(e, value);
                            }}
                            style={{ width: "100px", fontSize: "14px" }}
                            placeholder={"Position"}
                          />
                        </div>
                        <div className="col-4">
                          <label className="font-weight-bold" htmlFor="string1">
                            Texte
                          </label>
                          <input
                            type="text"
                            value={dataParam.sousString1}
                            onChange={(event) =>
                              this.handleSousString1Change(event)
                            }
                            className="form-control"
                            placeholder="Texte"
                            style={{ fontSize: "14px" }}
                          ></input>
                        </div>
                      </React.Fragment>
                    </div>
                    <div className="form-group row">
                      <div className="col-4">
                        <label className="font-weight-bold" htmlFor="string2">
                          Et Texte 2
                        </label>
                        <input
                          type="text"
                          value={dataParam.string2}
                          onChange={(event) => this.handleString2Change(event)}
                          className="form-control"
                          placeholder="Texte"
                          style={{ fontSize: "14px" }}
                        ></input>
                      </div>

                      <React.Fragment>
                        <div className="col-3">
                          <label
                            className="font-weight-bold"
                            htmlFor="positionString2"
                          >
                            Position du texte
                          </label>
                          <NumberFormat
                            className="form-control text-right"
                            value={dataParam.positionString2}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            decimalSeparator=","
                            thousandSeparator={false}
                            allowNegative={false}
                            onChange={(e, value) => {
                              this.handlePositionString2Change(e, value);
                            }}
                            style={{ width: "100px", fontSize: "14px" }}
                            placeholder={"Position"}
                          />
                        </div>
                        <div className="col-4">
                          <label className="font-weight-bold" htmlFor="string2">
                            Texte
                          </label>
                          <input
                            type="text"
                            value={dataParam.sousString2}
                            onChange={(event) =>
                              this.handleSousString2Change(event)
                            }
                            className="form-control"
                            placeholder="Texte"
                            style={{ fontSize: "14px" }}
                          ></input>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitParam}
                      disabled={
                        dataParam.commentaire.trim().length === 0 ||
                        dataParam.typeEcritureId === "-1"
                      }
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewParam}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>

            <p className="w-100">{totalCount} paramétrages</p>

            {data && (
              <ListeScanRelevesCompte
                scans={scans}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onSelectParam={this.onSelectParam}
              />
            )}
            <span id="bottom-id"></span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScanRelevesCompteBanque;
