import React, { Component } from "react";
import _ from "lodash";

class TableBloc extends Component {
  renderCell = (item, bloc) => {
    if (bloc.content) return bloc.content(item);

    return _.get(item, bloc.path);
  };

  createKey = (item, column) => {
    return item._id + (column.path || column.key);
  };

  render() {
    const { data, blocs } = this.props;

    return (
      <div className="mb-2">
        {data.map((item) => (
          <div className="row ml-1 mt-1" key={item._id}>
            {blocs.map((bloc) => (
              <div className={bloc.className} key={bloc.path}>
                <div className="text-nowrap" style={{ fontSize: "12px" }}>
                  {this.renderCell(item, bloc)}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default TableBloc;
