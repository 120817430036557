import React, { Component } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import SelectSearch from "react-select-search";
import { getPizzerias } from "../../services/pizzeriaService";
import { getStatsTypeCommande } from "../../services/statsService";
import Table from "../common/table";

class StatsTypeCommande extends Component {
  state = {
    dateFrom: new Date(),
    dateTo: new Date(),
    societesForSelect: [],
    selectedSocietes: [],
    sortColumn: { path: "mois", order: "asc" },
    stats: [],
    shouldShowBox: false,
  };
  columns = [
    {
      path: "_id",
      label: "Mois",
      tdClassName: "p-1",
      className: "",
      content: (x) => (
        <span>
          {!x.isTotal && (
            <span style={{ fontSize: "12px" }}>
              {moment(x._id, "YYYYMM").format("MMMM YYYY")}
            </span>
          )}
          {x.isTotal && (
            <span
              style={{ fontSize: "12px" }}
              className="text-warning font-weight-bold"
            >
              {x.label}
            </span>
          )}
        </span>
      ),
    },
    {
      path: "surPlace",
      label: "Sur place",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={Math.round(x.surPlace)}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </div>
      ),
    },
    {
      path: "pourcentSurPlace",
      label: "% Sur place",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={x.pourcentSurPlace}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </div>
      ),
    },
    {
      path: "aEmporter",
      label: "A Emporter",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>
          <NumberFormat
            value={Math.round(x.aEmporter)}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </span>
      ),
    },
    {
      path: "pourcentAEmporter",
      label: "% A Emporter",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={x.pourcentAEmporter}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </div>
      ),
    },
    {
      path: "livraison",
      label: "Livraison",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>
          <NumberFormat
            value={Math.round(x.livraison)}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </span>
      ),
    },
    {
      path: "pourcentLivraison",
      label: "% Livraison",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={x.pourcentLivraison}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </div>
      ),
    },
    {
      path: "nonClasse",
      label: "Non classé",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <span style={{ fontSize: "12px" }}>
          <NumberFormat
            value={Math.round(x.nonClasse)}
            decimalScale={0}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </span>
      ),
    },
    {
      path: "pourcentNonClasse",
      label: "% Non classé",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={x.pourcentNonClasse}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" %"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </div>
      ),
    },
    {
      path: "total",
      label: "Total",
      tdClassName: "p-1 text-right",
      className: "",
      content: (x) => (
        <div style={{ fontSize: "12px" }}>
          <NumberFormat
            value={Math.round(x.total)}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
            className={x.isTotal ? "text-warning font-weight-bold" : ""}
          />
        </div>
      ),
    },
  ];

  async componentDidMount() {
    const dateTo = moment().add(-1, "days").format("YYYY-MM-DD");
    const dateFrom = "2018-01-01";

    const societes = await getPizzerias();
    let societesForSelect = [];
    let selectedSocietes = [];
    societes.data.data
      .filter((item) => item.hasBesoinPlanning === 1)
      .map((item) => {
        const ok = item.listAPE.filter((pizz) => {
          return pizz.codeAPE === "5610C";
        });
        if (ok.length !== 0) {
          societesForSelect.push({
            value: item._id.toString(),
            name: item.denomination,
          });
          selectedSocietes.push(item._id.toString());
        }
        return true;
      });

    this.setState({
      dateFrom,
      dateTo,
      societesForSelect,
      selectedSocietes,
      shouldShowBox: true,
    });
  }

  handleDateFromChange = (event) => {
    let { dateFrom } = this.state;
    const day = event.target.value;
    if (day != null) {
      dateFrom = day;
      this.setState({ dateFrom });
    }
  };

  handleDateToChange = (event) => {
    let { dateTo } = this.state;
    const day = event.target.value;
    if (day != null) {
      dateTo = day;
      this.setState({ dateTo });
    }
  };

  handleSelectAllClick = (event) => {
    event.preventDefault();
    const societesForSelect = this.state.societesForSelect;
    let selectedSocietes = [];
    societesForSelect.map((item) => {
      selectedSocietes.push(item.value);
      return true;
    });
    this.setState({
      selectedSocietes,
    });
  };

  handleUnSelectAllClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedSocietes: [],
    });
  };

  handleSocieteSelect = (societes) => {
    this.setState({
      selectedSocietes: societes,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    await this.traitValiderClick();
  };

  traitValiderClick = async () => {
    const { dateFrom, dateTo, selectedSocietes } = this.state;
    this.setState({ shouldShowBox: false });

    const param = {};
    param.dateDebutAAAAMMJJ = moment(dateFrom).format("YYYYMMDD");
    param.dateFinAAAAMMJJ = moment(dateTo).format("YYYYMMDD");

    param.societeIdTab = [];
    selectedSocietes.forEach((x) => {
      param.societeIdTab.push(parseInt(x));
    });
    param.allSocietes = false;

    const dataListe = await getStatsTypeCommande(param);

    this.setState({
      stats: dataListe.data.data.tabMois,
      shouldShowBox: true,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const {
      dateFrom,
      dateTo,
      societesForSelect,
      selectedSocietes,
      shouldShowBox,
      sortColumn,
      stats,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-2">
            <div className="mt-2">
              <div>
                <label className="w-100 font-weight-bold mt-2">Du / Au</label>
                <input
                  className="mr-1"
                  type="date"
                  value={dateFrom}
                  max={dateTo}
                  onChange={(date) => this.handleDateFromChange(date)}
                ></input>
                <input
                  type="date"
                  value={dateTo}
                  min={dateFrom}
                  onChange={(date) => this.handleDateToChange(date)}
                ></input>
              </div>
            </div>
            <div className="mt-2">
              <div>
                <label className="w-100 font-weight-bold" htmlFor="societes">
                  Sociétés sélectionnées
                </label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleSelectAllClick}
                >
                  Tout sélectionner
                </button>
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleUnSelectAllClick}
                >
                  Tout désélectionner
                </button>
              </div>
              <div className="maxHauteur300">
                <SelectSearch
                  options={societesForSelect}
                  value={selectedSocietes}
                  name="societes"
                  multiple
                  onChange={this.handleSocieteSelect}
                />
              </div>
            </div>
            <div style={{ marginTop: "500px" }}>
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={this.handleValiderClick}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
          {!shouldShowBox && (
            <div className="mt-5 mb-5">
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {shouldShowBox && (
            <div className="col-10">
              <div className="row mt-4">
                <div className="col-12 col-xl-9">
                  <div>
                    <h5 className="font-weight-bold mb-2">
                      Répartition du CA HT par type de commande
                    </h5>
                  </div>
                  {stats.length !== 0 && (
                    <React.Fragment>
                      <Table
                        columns={this.columns}
                        data={stats}
                        sortColumn={sortColumn}
                        onSort={this.handleSort}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default StatsTypeCommande;
