import React, { Component } from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

import SocieteLesVilles from "./societeLesVilles";

import {
  getAllVillesLivrees,
  updateSocieteVilleIsDefaut,
  deleteSocieteVille,
  addSocieteVilleInsee,
} from "../../services/pizzeriaService";
import { getVilles } from "../../services/inseeService";
import { villeDefautSociete } from "../../utils/tools";

class SocietesVilles extends Component {
  state = {
    societes: [],
    shouldShowBox: false,
  };
  async componentDidMount() {
    const resultSocietes = await getAllVillesLivrees();
    const dataSocietes = resultSocietes.data.data;

    const resultVilles = await getVilles();
    const allVillesForSelect = [];
    resultVilles.data.data.forEach((item) => {
      allVillesForSelect.push({
        value: item.idInsee.toString(),
        name: item.nom + " " + item.codePostal,
        villeId: item.villeId,
        departement: item.departement,
      });
    });

    this.setState({
      societes: dataSocietes,
      allVillesForSelect,
      shouldShowBox: true,
    });
  }

  handleIsDefautChange = async (event) => {
    const { societes } = this.state;
    const id = event.target.id;
    const tabId = id.split("|");
    const societeId = parseInt(tabId[0]);
    const villeId = parseInt(tabId[1]);
    const laSociete = societes.find((s) => s.societe._id === societeId);
    const laVille = laSociete.villes.find((v) => v.villeId === villeId);
    laVille.isDefaut = laVille.isDefaut === 1 ? 0 : 1;
    const retUpdate = await updateSocieteVilleIsDefaut({
      societeId,
      villeId,
      isDefaut: laVille.isDefaut,
    });
    if (retUpdate.data.success) {
      this.setState({ societes });
    } else {
      toast.error(retUpdate.data.message);
    }
  };

  handleSupprSocieteVilleOnClick = (ville) => {
    confirmAlert({
      title: "Suppression de l'affectation de la ville " + ville.villeNom,
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => this.traitSupprVilleSociete(ville),
        },
        {
          label: "Non",
        },
      ],
    });
  };

  traitSupprVilleSociete = async (ville) => {
    const { societes } = this.state;
    const tabId = ville._id.split("|");
    const societeId = parseInt(tabId[0]);
    const villeId = parseInt(tabId[1]);
    const laSociete = societes.find((s) => s.societe._id === societeId);
    laSociete.villes = laSociete.villes.filter((v) => v.villeId !== villeId);
    await deleteSocieteVille({
      societeId,
      villeId,
    });
    this.setState({ societes });
  };

  handleAddSocieteVille = async ({ societeId, idInsee }) => {
    const retAdd = await addSocieteVilleInsee({ societeId, idInsee });
    const resultSocietes = await getAllVillesLivrees();
    const dataSocietes = resultSocietes.data.data;
    this.setState({
      societes: dataSocietes,
    });
    return retAdd.data.data;
  };

  render() {
    const { societes, allVillesForSelect, shouldShowBox } = this.state;

    return (
      <div className="row">
        <div className="col-12">
          <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
            <h2>Sociétés / Villes livrées</h2>

            {!shouldShowBox && (
              <div className="mt-5 mb-5">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {shouldShowBox && (
              <React.Fragment>
                <div className="row ml-3" style={{ fontSize: "14px" }}>
                  <span className="font-weight-bold mr-2">Légende : </span>
                  <span>{villeDefautSociete}</span>
                </div>
                <table
                  className="table table-bordered table-striped mt-4"
                  style={{ fontSize: "14px" }}
                >
                  <tbody>
                    {societes.map((item) => (
                      <tr className="row mr-0 ml-0" key={item.societe._id}>
                        <td className="col-3" style={{ fontSize: "14px" }}>
                          <div className="font-weight-bold">
                            {item.societe.denomination}
                          </div>
                        </td>
                        <td className="col-9" style={{ fontSize: "14px" }}>
                          <React.Fragment>
                            <SocieteLesVilles
                              allVillesForSelect={allVillesForSelect}
                              societeId={item.societe._id}
                              villes={item.villes}
                              tabVilleId={item.tabVilleId}
                              handleIsDefautChange={this.handleIsDefautChange}
                              handleSupprSocieteVilleOnClick={
                                this.handleSupprSocieteVilleOnClick
                              }
                              handleAddSocieteVille={this.handleAddSocieteVille}
                            ></SocieteLesVilles>
                          </React.Fragment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SocietesVilles;
