import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import SelectSearch from "react-select-search";
import { CSVLink } from "react-csv";
import { getPizzerias } from "../../services/pizzeriaService";
import { getCAMoyenAndSaisonnalite } from "../../services/statsService";
import ListeSocieteCAMoyen from "./listeSocieteCAMoyen";
import ListeSaisonnalite from "./listeSaisonnalite";
import ListeDetailSociete from "./listeDetailSociete";
import ListeTVA from "./listeTVA";

const headersCSV = [
  { label: "Société", key: "_id" },
  { label: "Dénomination", key: "societeDenomination" },
  { label: "Date de début d'activité", key: "dateDebutActiviteAAAAMMJJ" },
  { label: "Fermeture Client", key: "dateFermetureClientAAAAMMJJ" },
  { label: "Nb jour activité", key: "nbJourPeriode" },
  { label: "CA réel", key: "CAReferenceHT" },
  { label: "Indice de confiance", key: "totalPourcentSur" },
  { label: "CA calculé", key: "CACalculeHT" },
  { label: "CA non fait", key: "CAManque" },
  { label: "CA Total", key: "CACalculeEtManque" },
  { label: "Rush non faits", key: "nbRushManquant" },
];

class CAMoyen extends Component {
  state = {
    dateFrom: new Date(),
    dateTo: new Date(),
    exclureRecentes: false,
    dureeRecente: [],
    selectedDureeRecente: null,
    societesForSelect: [],
    selectedSocietes: [],
    sortColumn: { path: "_id", order: "asc" },
    sortColumnSaisonnalite: { path: "_id", order: "asc" },
    detailCAMoyen: true,
    dureePeriode: 0,
    CAMoyenPizzeria: [],
    CAMoyen: 0,
    detailSociete: {},
    CAMoisNbSociete: 0,
    CAMois: [],
    syntheseTVA: [],
    nbSocieteCAMoyen: 0,
    shouldShowBox: false,
  };

  async componentDidMount() {
    const dateTo = moment().add(-1, "days").format("YYYY-MM-DD");
    const dateFrom = moment().add(-365, "days").format("YYYY-MM-DD");

    const dureeRecente = [];
    dureeRecente.push({ value: "15", name: "15 jours" });
    dureeRecente.push({ value: "30", name: "30 jours" });
    dureeRecente.push({ value: "60", name: "60 jours" });
    const societes = await getPizzerias();
    let societesForSelect = [];
    let selectedSocietes = [];
    societes.data.data
      .filter((item) => item.hasBesoinPlanning === 1)
      .map((item) => {
        const ok = item.listAPE.filter((pizz) => {
          return pizz.codeAPE === "5610C";
        });
        if (ok.length !== 0) {
          societesForSelect.push({
            value: item._id.toString(),
            name: item.denomination,
          });
          selectedSocietes.push(item._id.toString());
        }
        return true;
      });

    this.setState({
      dateFrom,
      dateTo,
      dureeRecente,
      selectedDureeRecente: dureeRecente[0].value,
      societesForSelect,
      selectedSocietes,
      shouldShowBox: true,
    });
  }

  handleDateFromChange = (event) => {
    let { dateFrom } = this.state;
    const day = event.target.value;
    if (day != null) {
      dateFrom = day;
      this.setState({ dateFrom });
    }
  };

  handleDateToChange = (event) => {
    let { dateTo } = this.state;
    const day = event.target.value;
    if (day != null) {
      dateTo = day;
      this.setState({ dateTo });
    }
  };

  handleCalculerDateDuClick = () => {
    const { dateTo } = this.state;
    const laDate = moment(dateTo, "YYYY-MM-DD");
    const dateFrom = laDate.add(-364, "days").format("YYYY-MM-DD");
    this.setState({ dateFrom });
  };

  handleCalculerDateAuClick = () => {
    const { dateFrom } = this.state;
    const laDate = moment(dateFrom, "YYYY-MM-DD");
    const dateTo = laDate.add(364, "days").format("YYYY-MM-DD");
    this.setState({ dateTo });
  };

  handleExclureRecentesChange = async () => {
    let { exclureRecentes } = this.state;
    this.setState({
      exclureRecentes: !exclureRecentes,
    });
  };

  handleDureeRecenteSelect = async (duree) => {
    this.setState({ selectedDureeRecente: duree });
  };

  handleSelectAllClick = (event) => {
    event.preventDefault();
    const societesForSelect = this.state.societesForSelect;
    let selectedSocietes = [];
    societesForSelect.map((item) => {
      selectedSocietes.push(item.value);
      return true;
    });
    this.setState({
      selectedSocietes,
    });
  };

  handleUnSelectAllClick = (event) => {
    event.preventDefault();
    this.setState({
      selectedSocietes: [],
    });
  };

  handleSocieteSelect = (societes) => {
    this.setState({
      selectedSocietes: societes,
    });
  };

  handleValiderClick = async (event) => {
    if (event) event.preventDefault();
    await this.traitValiderClick();
  };

  traitValiderClick = async () => {
    const {
      dateFrom,
      dateTo,
      exclureRecentes,
      selectedDureeRecente,
      selectedSocietes,
      societesForSelect,
    } = this.state;
    this.setState({ shouldShowBox: false });

    const param = {};
    param.dateDebutAAAAMMJJ = moment(dateFrom).format("YYYYMMDD");
    param.dateFinAAAAMMJJ = moment(dateTo).format("YYYYMMDD");
    param.exclureRecentes = exclureRecentes;
    param.limiteDureeRecente = parseInt(selectedDureeRecente);
    if (selectedSocietes.length === societesForSelect.length) {
      param.societeIdTab = [];
      param.allSocietes = true;
    } else {
      param.societeIdTab = [];
      selectedSocietes.map((x) => {
        param.societeIdTab.push(parseInt(x));
        return true;
      });
      param.allSocietes = false;
    }
    const dataListe = await getCAMoyenAndSaisonnalite(param);

    const moisTrie = dataListe.data.data.saisonnalite.tabMois.sort(
      (a, b) => b.pourcent - a.pourcent
    );
    let CAMois = [];
    CAMois = dataListe.data.data.saisonnalite.tabMois.map((obj) => {
      obj._id = obj.mois;
      delete obj["mois"];
      const existrie = moisTrie.findIndex((x) => x._id === obj._id);
      obj.ordre = existrie;
      return obj;
    });

    this.setState({
      dureePeriode: dataListe.data.data.dureePeriode,
      CAMoyenPizzeria: dataListe.data.data.CAMoyenPizzeria,
      dataDetailCAMoyen: {},
      detailSociete: {},
      CAMoyen: dataListe.data.data.CAMoyen,
      CAMois,
      CAMoisNbSociete: dataListe.data.data.saisonnalite.nbSociete,
      syntheseTVA: dataListe.data.data.syntheseTVA,
      nbSocieteCAMoyen: dataListe.data.data.nbSocieteCAMoyen,
      shouldShowBox: true,
    });
  };

  handleClickCollapseCAMoyen = () => {
    const { detailCAMoyen } = this.state;
    this.setState({ detailCAMoyen: !detailCAMoyen });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSortSaisonnalite = (sortColumn) => {
    this.setState({ sortColumnSaisonnalite: sortColumn });
  };

  handleDetailClick = (societe) => {
    let { detailSociete } = this.state;
    if (societe._id && societe._id === detailSociete.societeId) {
      detailSociete = {};
    } else {
      detailSociete.societeId = societe._id;
      detailSociete.societeDenomination = societe.societeDenomination;
      detailSociete.detailMois = societe.detailMois;
    }
    this.setState({ detailSociete: detailSociete });
  };

  getPagedData = () => {
    const { CAMoyenPizzeria, sortColumn, detailSociete } = this.state;
    let filtered = CAMoyenPizzeria;
    if (detailSociete && detailSociete.societeId) {
      filtered = filtered.filter((x) => x._id === detailSociete.societeId);
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    return { dataDetailCAMoyen: sorted };
  };

  getPagedDataSaisonnalite = () => {
    const { CAMois, sortColumnSaisonnalite } = this.state;
    let filtered = CAMois;
    const sorted = _.orderBy(
      filtered,
      [sortColumnSaisonnalite.path],
      [sortColumnSaisonnalite.order]
    );
    return { dataCAMois: sorted };
  };

  render() {
    const {
      dateFrom,
      dateTo,
      exclureRecentes,
      selectedDureeRecente,
      dureeRecente,
      societesForSelect,
      selectedSocietes,
      shouldShowBox,
      dureePeriode,
      CAMoyen,
      sortColumn,
      detailSociete,
      sortColumnSaisonnalite,
      detailCAMoyen,
      CAMois,
      CAMoisNbSociete,
      syntheseTVA,
      nbSocieteCAMoyen,
    } = this.state;

    const { dataDetailCAMoyen } = this.getPagedData();
    const { dataCAMois } = this.getPagedDataSaisonnalite();
    const nomfichier =
      "CA du " +
      moment(dateFrom).format("DD-MM-YYYY") +
      " au " +
      moment(dateTo).format("DD-MM-YYYY") +
      ".txt";

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-2">
            <div className="mt-2">
              <div>
                <label className="w-100 font-weight-bold mt-2">Du / Au</label>
                <input
                  className="mr-1"
                  type="date"
                  value={dateFrom}
                  max={dateTo}
                  onChange={(date) => this.handleDateFromChange(date)}
                ></input>
                <input
                  type="date"
                  value={dateTo}
                  min={dateFrom}
                  onChange={(date) => this.handleDateToChange(date)}
                ></input>
              </div>
            </div>
            <div className="mt-3">
              <button
                className="btn btn-outline-secondary"
                onClick={this.handleCalculerDateDuClick}
              >
                <i className="fa fa-calculator mr-2" aria-hidden="true"></i>
                Date Du à partir Date Au
              </button>
            </div>
            <div className="mt-3">
              <button
                className="btn btn-outline-secondary"
                onClick={this.handleCalculerDateAuClick}
              >
                <i className="fa fa-calculator mr-2" aria-hidden="true"></i>
                Date Au à partir Date Du
              </button>
            </div>
            <div className="mt-2">
              <div>
                <label className="w-100 font-weight-bold" htmlFor="societes">
                  Sociétés sélectionnées
                </label>
              </div>
              <div className="btn-group">
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleSelectAllClick}
                >
                  Tout sélectionner
                </button>
                <button
                  className="btn btn-outline-secondary"
                  style={{ fontSize: "12px" }}
                  onClick={this.handleUnSelectAllClick}
                >
                  Tout désélectionner
                </button>
              </div>
              <div className="maxHauteur300">
                <SelectSearch
                  options={societesForSelect}
                  value={selectedSocietes}
                  name="societes"
                  multiple
                  onChange={this.handleSocieteSelect}
                />
              </div>
            </div>

            <div style={{ marginTop: "470px", marginBottom: "10px" }}>
              <div className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exclureRecentes"
                  checked={exclureRecentes}
                  onChange={this.handleExclureRecentesChange}
                ></input>
                <label className="form-check-label" htmlFor="exclureRecentes">
                  Exclure les sociétés ouvertes depuis moins de
                </label>
              </div>
            </div>
            <div className="maxHauteur300">
              <SelectSearch
                options={dureeRecente}
                value={selectedDureeRecente}
                name="dureeRecente"
                search
                onChange={this.handleDureeRecenteSelect}
                disabled={!exclureRecentes}
              />
            </div>

            <div className="mt-2">
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={this.handleValiderClick}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
          {!shouldShowBox && (
            <div className="mt-5 mb-5">
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {shouldShowBox && (
            <div className="col-10">
              <div className="row mt-4">
                <div className="col-12 col-xl-9">
                  <div>
                    <h5
                      className="font-weight-bold mb-2"
                      onClick={() => this.handleClickCollapseCAMoyen()}
                      style={{ cursor: "pointer", height: "50px" }}
                    >
                      <i className="fas fa-play mr-2"></i>
                      Chiffre d'affaires HT moyen sur {nbSocieteCAMoyen}{" "}
                      sociétés{" "}
                      <NumberFormat
                        value={CAMoyen}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        decimalSeparator=","
                        thousandSeparator=" "
                        displayType="text"
                        suffix=" €"
                        style={{
                          color: "#f68131",
                          fontSize: "30px",
                          marginLeft: "30px",
                          whiteSpace: "nowrap",
                        }}
                      />
                    </h5>
                  </div>
                  {dataDetailCAMoyen.length !== 0 && detailCAMoyen && (
                    <React.Fragment>
                      <CSVLink
                        data={dataDetailCAMoyen}
                        headers={headersCSV}
                        separator={"\t"}
                        enclosingCharacter={``}
                        filename={nomfichier}
                        className="btn btn-primary mb-4"
                      >
                        <i
                          className="fas fa-cloud-download-alt mr-2"
                          style={{ fontSize: "20px" }}
                          aria-hidden="true"
                        ></i>
                        Télécharger le fichier
                      </CSVLink>
                      <ListeSocieteCAMoyen
                        societes={dataDetailCAMoyen}
                        sortColumn={sortColumn}
                        onSort={this.handleSort}
                        handleDetailClick={this.handleDetailClick}
                      />
                    </React.Fragment>
                  )}
                  {detailSociete &&
                    detailSociete.detailMois &&
                    detailSociete.detailMois.length !== 0 && (
                      <React.Fragment>
                        <h5>Détail pour {detailSociete.societeDenomination}</h5>
                        <ListeDetailSociete
                          detailMois={detailSociete.detailMois}
                          sortColumn={sortColumn}
                          onSort={this.handleSort}
                          handleDetailClick={this.handleDetailClick}
                        />
                      </React.Fragment>
                    )}
                </div>
                <div className="col-12 col-xl-3">
                  <h5 className="font-weight-bold" style={{ height: "50px" }}>
                    Saisonnalité établie sur {CAMoisNbSociete} sociétés et une
                    période {dureePeriode} jours
                  </h5>
                  {CAMois.length !== 0 && (
                    <ListeSaisonnalite
                      CAMois={dataCAMois}
                      sortColumn={sortColumnSaisonnalite}
                      onSort={this.handleSortSaisonnalite}
                    />
                  )}
                  <h5 className="font-weight-bold">
                    Répartition par taux de TVA
                  </h5>
                  {syntheseTVA.length !== 0 && (
                    <ListeTVA
                      CAMois={syntheseTVA}
                      sortColumn={sortColumnSaisonnalite}
                      onSort={this.handleSortSaisonnalite}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default CAMoyen;
