import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function fantoirImport(formData) {
  return await http.post(
    urlApiComptabilite + "/fantoir/importFantoir/",
    formData
  );
}

// eslint-disable-next-line
export default {
  fantoirImport,
};
