import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

import {
  getAllCodesJournal,
  getCodeJournal,
  addCodeJournal,
  updateCodeJournal,
  deleteCodeJournal,
} from "../../services/parametresService";
import BarreMenu from "../common/barreMenu";
import ListeCodesJournal from "./listeCodesJournal";
import {
  typeMenuParametresComptabilite,
  menuCodesJournal,
} from "../../utils/menuParametres";

class CodesJournal extends Component {
  state = {
    tabBanqueSansCodeJournal: [],
    codesJournal: [],
    sortColumn: { path: "_id", order: "asc" },
    newCodeJournal: false,
    dataCodeJournal: {
      code: "",
      libelle: "",
    },
    existCode: false,
    selectedCodeJournal: "@@@@@@@",
  };

  async componentDidMount() {
    const dataCodesJournal = await getAllCodesJournal();
    this.setState({
      codesJournal: dataCodesJournal.data.data.allCodesJournal,
      tabBanqueSansCodeJournal:
        dataCodesJournal.data.data.tabBanqueSansCodeJournal,
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleNewCodeJournal = () => {
    const { newCodeJournal, dataCodeJournal } = this.state;
    dataCodeJournal.code = "";
    dataCodeJournal.libelle = "";
    this.setState({
      newCodeJournal: !newCodeJournal,
      dataCodeJournal,
      existCode: false,
    });
  };

  handleCancelNewCodeJournal = () => {
    this.setState({
      newCodeJournal: false,
      selectedCodeJournal: "@@@@@@@",
    });
  };

  handleCodeChange = async (event) => {
    const { dataCodeJournal } = this.state;
    if (event.target.value.trim().length < 7) {
      dataCodeJournal.code = event.target.value.trim().toUpperCase();
      const exist = await getCodeJournal(event.target.value.trim());
      this.setState({
        dataCodeJournal,
        existCode: exist.data.success,
      });
    }
  };

  handleLibelleChange = async (event) => {
    const { dataCodeJournal } = this.state;
    dataCodeJournal.libelle = event.target.value;
    this.setState({
      dataCodeJournal,
    });
  };

  onSelectCodeJournal = (journal) => {
    const { selectedCodeJournal, newCodeJournal } = this.state;
    if (selectedCodeJournal === "@@@@@@@" && !newCodeJournal) {
      const dataCodeJournal = {};
      dataCodeJournal.code = journal.code;
      dataCodeJournal.libelle = journal.libelle;
      this.setState({
        selectedCodeJournal: journal.code,
        dataCodeJournal,
        existCode: false,
      });
    }
  };

  handleSubmitCodeJournal = async () => {
    const { selectedCodeJournal, newCodeJournal } = this.state;
    if (newCodeJournal && selectedCodeJournal === "@@@@@@@") {
      await this.traiteNewCodeJournal();
    } else {
      if (!newCodeJournal && selectedCodeJournal !== "@@@@@@@") {
        await this.traiteUpdateCodeJournal();
      }
    }
  };

  traiteNewCodeJournal = async () => {
    const { dataCodeJournal } = this.state;
    await addCodeJournal({
      dataCodeJournal,
    });
    const data = await getAllCodesJournal();
    this.setState({
      newCodeJournal: false,
      codesJournal: data.data.data.allCodesJournal,
      tabBanqueSansCodeJournal: data.data.data.tabBanqueSansCodeJournal,
    });
  };

  traiteUpdateCodeJournal = async () => {
    const { selectedCodeJournal, dataCodeJournal } = this.state;
    await updateCodeJournal(selectedCodeJournal, { dataCodeJournal });
    const data = await getAllCodesJournal();
    this.setState({
      newCodeJournal: false,
      codesJournal: data.data.data.allCodesJournal,
      tabBanqueSansCodeJournal: data.data.data.tabBanqueSansCodeJournal,
      selectedCodeJournal: "@@@@@@@",
    });
  };

  handleSupprCodejournal = async (j) => {
    let { codesJournal } = this.state;
    confirmAlert({
      title: "Suppression du code journal : " + j.code,
      message: "Confirmez-vous votre demande ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            const retDelete = await deleteCodeJournal(j.code);
            if (retDelete.data.success) {
              codesJournal = codesJournal.filter((x) => x.code !== j.code);
              this.setState({ codesJournal });
            } else {
              toast.error(retDelete.data.message);
            }
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };

  render() {
    const {
      sortColumn,
      newCodeJournal,
      dataCodeJournal,
      selectedCodeJournal,
      codesJournal,
      existCode,
      tabBanqueSansCodeJournal,
    } = this.state;

    return (
      <React.Fragment>
        <BarreMenu
          type_menu={typeMenuParametresComptabilite}
          menu_actif={menuCodesJournal}
        ></BarreMenu>
        {tabBanqueSansCodeJournal.length !== 0 && (
          <div className="alert alert-warning">
            Contrôle Comptabilité - ID des banques sans code journal :{" "}
            {tabBanqueSansCodeJournal.map((r, index) => (
              <span key={r._id}>{index !== 0 ? ", " + r._id : r._id}</span>
            ))}
          </div>
        )}
        <div className="row">
          <div className="col-12">
            {!newCodeJournal && selectedCodeJournal === "@@@@@@@" && (
              <button
                onClick={this.handleNewCodeJournal}
                className="btn btn-primary text-white"
                style={{ marginBottom: 20, height: 40 }}
                disabled={newCodeJournal}
              >
                Nouveau code journal
              </button>
            )}
            <div>
              {(newCodeJournal || selectedCodeJournal !== "@@@@@@@") && (
                <div className="jumbotron jumbotron-fluid pt-2 pb-2">
                  <h5 className="ml-2">
                    {newCodeJournal
                      ? "Nouveau code journal"
                      : "Modification du code journal"}
                  </h5>
                  <div className="ml-2 mr-2">
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="code">
                        Code
                      </label>
                      <input
                        type="text"
                        value={dataCodeJournal.code}
                        onChange={(event) => this.handleCodeChange(event)}
                        className="form-control"
                        placeholder="Code"
                        disabled={!newCodeJournal}
                      ></input>
                      {existCode && (
                        <div className="alert alert-danger mt-2">
                          Ce code existe déjà
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold" htmlFor="libelle">
                        Libellé
                      </label>
                      <input
                        type="text"
                        value={dataCodeJournal.libelle}
                        onChange={(event) => this.handleLibelleChange(event)}
                        className="form-control"
                        placeholder="Libellé"
                      ></input>
                    </div>
                  </div>
                  <div className="ml-2 mr-2">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={this.handleSubmitCodeJournal}
                      disabled={
                        dataCodeJournal.code.length === 0 ||
                        dataCodeJournal.libelle.length === 0 ||
                        existCode
                      }
                    >
                      Enregistrer
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={this.handleCancelNewCodeJournal}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              )}
            </div>

            {codesJournal && (
              <ListeCodesJournal
                codesJournal={codesJournal}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                onSelectCodeJournal={this.onSelectCodeJournal}
                handleSupprCodejournal={this.handleSupprCodejournal}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CodesJournal;
