import http from "./httpService";
import { urlApiComptabilite } from "../config.js";

export async function getExportFECList(minAnnee) {
  return await http.get(urlApiComptabilite + "/fec/exportFECList/" + minAnnee);
}

export async function getContentFEC(id) {
  return await http.post(urlApiComptabilite + "/fec/contentFEC/" + id);
}

export async function getExportFEC(id) {
  return await http.get(urlApiComptabilite + "/fec/exportFEC/" + id);
}

export async function deleteExportFEC(id) {
  return await http.delete(urlApiComptabilite + "/fec/exportFEC/" + id);
}

export async function genereFEC({ allSocietes, societeIdTab, userId }) {
  return await http.post(urlApiComptabilite + "/fec/genereFEC/", {
    allSocietes,
    societeIdTab,
    userId,
  });
}

export async function updateFECDataImport({ id, isImporte, userId }) {
  return await http.put(urlApiComptabilite + "/fec/putFECDataImport/" + id, {
    isImporte,
    userId,
  });
}

// eslint-disable-next-line
export default {
  getExportFECList,
  getContentFEC,
  getExportFEC,
  genereFEC,
  updateFECDataImport,
};
